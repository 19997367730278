import { Box, Tab, Tabs } from '@mui/material';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import { LinkTab } from '../../elements/LinkTab';
import { RouterLink } from '../../elements/RouterLink';

function useRouteMatch(patterns: readonly string[]) {
    const { pathname } = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
        const pattern = patterns[i];
        const possibleMatch = matchPath(pattern, pathname);
        if (possibleMatch !== null) {
            return possibleMatch;
        }
    }

    return null;
}

export function ChargeLocationTopMenu() {
    const { operatorId } = useParams();
    const routeMatch = useRouteMatch([
        `/${operatorId}/roaminglocations/:id`,
        `/${operatorId}/roaminglocations/:id/settings`,
        `/${operatorId}/roaminglocations/:id/transactions`,
        `/${operatorId}/roaminglocations/:id/stats`,
        `/${operatorId}/roaminglocations/:id/management`,
    ]);
    const currentTab = routeMatch?.pattern?.path;

    return (
        <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTab} variant="scrollable" scrollButtons="auto">
                <Tab
                    label="Панель управления"
                    value={`/${operatorId}/roaminglocations/:id`}
                    component={RouterLink}
                    to=""
                />
                <Tab
                    label="Настройки"
                    value={`/${operatorId}/roaminglocations/:id/settings`}
                    component={RouterLink}
                    to="settings"
                />

                <Tab
                    label="Транзакции"
                    value={`/${operatorId}/roaminglocations/:id/transactions`}
                    component={RouterLink}
                    to="transactions"
                />
                <Tab
                    label="Статистика"
                    value={`/${operatorId}/roaminglocations/:id/stats`}
                    component={RouterLink}
                    to="stats"
                />
                <Tab
                    label="Администрирование"
                    value={`/${operatorId}/roaminglocations/:id/management`}
                    component={RouterLink}
                    to="management"
                />
            </Tabs>
        </Box>
    );
}
