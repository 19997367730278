import { ReactNode } from 'react';
import { useRegistry } from '../../services/GlobalRegistry';
import invariant from 'tiny-invariant';
import create, { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { EVSE } from '@electrifly/central-client-api/data';

interface EVSEWrapperStore {
    id: string;
}

function createStore(evseId: string) {
    return create<EVSEWrapperStore>(set => ({
        id: evseId,
    }));
}

const { Provider, useStore } = createContext<StoreApi<EVSEWrapperStore>>();

type Props = {
    id: string;
    children: ReactNode | ((evse: EVSE) => ReactNode);
};

export const WithEVSE = ({ id, children }: Props) => {
    const evse = useRegistry(store => store.evses[id]);

    if (!evse) {
        return null;
    }

    if (typeof children === 'function') {
        return <Provider createStore={() => createStore(id)}>{children(evse)}</Provider>;
    }

    return <Provider createStore={() => createStore(id)}>{children}</Provider>;
};

export function useEVSE() {
    const id = useStore(store => store.id);
    invariant(!!id, 'Error getting evse. Possible you forgot to add WithEVSE wrapper');
    const connector = useRegistry(store => store.evses[id!]);
    return connector;
}
