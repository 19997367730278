import { Button, Container, Stack, Typography } from '@mui/material';
import { RouterLink } from '../elements/RouterLink';

export function NotFound404() {
    return (
        <Container sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack spacing={1}>
                <Typography>Страница не найдена</Typography>

                <Button component={RouterLink} to="/">
                    На главную
                </Button>
            </Stack>
        </Container>
    );
}
