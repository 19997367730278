import * as yup from 'yup';
import * as OCPI from '@electrifly/ocpi';
import { Connector } from '../../data/infrastructure/ChargeLocation';

export type ReqParams = { id: string };
export type ReqBody = {
    power?: number;
    standard?: OCPI.V221.Types.ConnectorType;
    format?: OCPI.V221.Types.ConnectorFormat;
    powerType?: OCPI.V221.Types.PowerType;
    isTurnedOff?: boolean;
};

export type ResData = Connector;

export const validationSchema = yup.object({
    power: yup.number().optional().min(0, 'Мощность должна быть больше 0'),
    standard: yup.mixed<OCPI.V221.Types.ConnectorType>().oneOf(Object.values(OCPI.V221.Types.ConnectorType)).optional(),
    format: yup
        .mixed<OCPI.V221.Types.ConnectorFormat>()
        .oneOf(Object.values(OCPI.V221.Types.ConnectorFormat))
        .optional(),
    powerType: yup.mixed<OCPI.V221.Types.PowerType>().oneOf(Object.values(OCPI.V221.Types.PowerType)).optional(),
    isTurnedOff: yup.boolean().optional(),
});
