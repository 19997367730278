import * as yup from 'yup';

export type ReqParams = { id: string };
export type ReqBody = { password: string };

export type ResData = { result: boolean };

export const validationSchema = yup.object({
    password: yup.string().required('Поле пароль должно быть заполнено'),
});
