import { HolderNotification } from './HolderNotification';

export enum HolderState {
    Active = 'Active',
    Suspended = 'Suspended',
}

export type Holder = {
    _id: string;
    identity: string;
    operatorId: string;
    account: string;
    state: HolderState;
    notifications: HolderNotification[];
    comment?: string;
    deleted: boolean;
};

export type HolderInfo = {
    _id: string;
    identity: string;
    operatorId: string;
};

export interface HolderDeleted {
    _id: string;
    deleted: boolean;
}
