import * as yup from 'yup';
import { Transaction } from '../../data/transaction/Transaction';

export type ReqQuery = {
    skip?: number;
    limit?: number;
    search?: string;
    active?: boolean;
    chargePoint?: string;
    chargeLocation?: string;
    holder?: string;
    operator?: string;
    owner?: string;
    ownerAccount?: string;
    emspOperator?: string;
};

export type ResData = Transaction[];

export const validationSchema = yup.object({
    skip: yup.number().optional().min(0).default(0),
    limit: yup.number().optional().min(1).max(1000).default(100),
    search: yup.string().optional().max(100),
    active: yup.boolean().optional(),
    chargePoint: yup.string().optional(),
    chargeLocation: yup.string().optional(),
    holder: yup.string().optional(),
    operator: yup.string().optional(),
    owner: yup.string().optional(),
    ownerAccount: yup.string().optional(),
    emspOperator: yup.string().optional(),
});
