import * as yup from 'yup';
import { PaymentMethodType } from '../../data';

export type ReqParams = { id: string };
export type ReqBody = {
    amount: number;
    type?: PaymentMethodType;
};

export type ResData = {
    link: string;
};

export const validationSchema = yup.object({
    amount: yup.number().required().min(1),
    type: yup.mixed<PaymentMethodType>().default('bank_card').oneOf(Object.values(PaymentMethodType)),
});
