import { GeneratePaymentLink, PaymentMethodType } from '@electrifly/central-client-api';
import { LoadingButton } from '@mui/lab';
import { Button, FormHelperText, Stack, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useState } from 'react';
import create from 'zustand';
import { API } from '../../../core/api-client';
import BigNumber from 'bignumber.js';

type Store = {
    open: boolean;
    customerId?: string;
    maxValue?: string;
    show: (customerId: string, maxValue: string) => void;
    hide: () => void;
};

const useStore = create<Store>((set, get) => ({
    open: false,
    userId: undefined,
    maxValue: undefined,
    show: (customerId: string, maxValue: string) => set({ open: true, customerId: customerId, maxValue: maxValue }),
    hide: () => set({ open: false, customerId: undefined, maxValue: undefined }),
}));

interface FirstStepProps {
    onSuccess?: () => void;
}

type FormValues = {
    amount: number;
    comment: string;
};

function FirstStep({ onSuccess }: FirstStepProps) {
    const customerId = useStore(store => store.customerId);
    const maxValue = useStore(store => store.maxValue);
    const hide = useStore(store => store.hide);

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<FormValues>({
        initialValues: {
            amount: Number(maxValue),
            comment: '',
        },
        validationSchema: GeneratePaymentLink.validationSchema,

        onSubmit: async values => {
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.customerRefund(customerId!, {
                amount: new BigNumber(values.amount).toFixed(2),
                reason: values.comment,
            });
            setIsRequesting(false);
            if (!error) {
                // hide();
                formik.resetForm();
                onSuccess && onSuccess();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    if (!customerId) {
        return null;
    }

    return (
        <>
            <DialogContent>
                <DialogContentText>
                    {/* После изменения пароля администратор сможет войти только с помощью нового пароля */}
                </DialogContentText>
                <form onSubmit={formik.handleSubmit}>
                    <FormHelperText sx={{ textAlign: 'end' }}>Максимальная сумма: {maxValue || 0} ₽</FormHelperText>
                    <Stack spacing={2} sx={{ marginBottom: 2 }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="amount"
                            label="Сумма"
                            type="number"
                            fullWidth
                            variant="outlined"
                            autoComplete="none"
                            value={formik.values.amount}
                            onChange={formik.handleChange}
                            error={formik.touched.amount && Boolean(formik.errors.amount)}
                            helperText={formik.touched.amount && formik.errors.amount}
                            inputProps={{ min: '0', step: '0.01' }}
                            InputProps={{
                                endAdornment: (
                                    <Button
                                        tabIndex={-1}
                                        variant="text"
                                        onClick={event => {
                                            console.log(maxValue);
                                            formik.setValues(values => ({ ...values, amount: Number(maxValue) }));
                                        }}
                                    >
                                        max
                                    </Button>
                                ),
                            }}
                        />
                        <TextField
                            required
                            autoFocus
                            id="comment"
                            label="Причина возврата"
                            variant="outlined"
                            fullWidth
                            spellCheck={false}
                            value={formik.values.comment}
                            onChange={formik.handleChange}
                            error={formik.touched.comment && Boolean(formik.errors.comment)}
                            helperText={formik.touched.comment && formik.errors.comment}
                        />
                    </Stack>
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Создать</span>
                </LoadingButton>
            </DialogActions>
        </>
    );
}

function SecondStep() {
    const hide = useStore(store => store.hide);

    return (
        <>
            <DialogContent>
                <Stack spacing={1}>
                    <DialogContentText>
                        Запрос на возврат отправлен. Проверьте результат через несколько минут.
                    </DialogContentText>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide}>Закрыть</Button>
            </DialogActions>
        </>
    );
}

function DialogInternal() {
    const [stage, setStage] = useState<'first' | 'second'>('first');

    const onSuccessFirstStep = () => {
        setStage('second');
    };

    return (
        <>
            {stage === 'first' && <FirstStep onSuccess={onSuccessFirstStep} />}
            {stage === 'second' && <SecondStep />}
        </>
    );
}

export function CustomerBalanceRefundDialog() {
    const open = useStore(store => store.open);
    const hide = useStore(store => store.hide);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Оформление воврата</DialogTitle>
            <DialogInternal />
        </Dialog>
    );
}

export const CustomerBalanceRefundDialogHelper = {
    show: (customerId: string, maxValue: string) => useStore.getState().show(customerId, maxValue),
    hide: () => useStore.getState().hide(),
};
