import create, { StoreApi } from 'zustand';
import { API } from '../../core/api-client';
import { Account } from '@electrifly/central-client-api';
import { WebsocketClient } from '../../core/ws-client';
import { PropsWithChildren, ReactNode, useEffect } from 'react';
import { WithAccount } from './WithAccount';
import createContext from 'zustand/context';
import { useEffectOnce } from 'usehooks-ts';

interface ComponentService {
    account?: Account;
    loading: boolean;

    loadInformation: () => Promise<void>;
}

const createStore = (id: string) => {
    return create<ComponentService>((set, get) => {
        WebsocketClient.events.ACCOUNT.on(data => {
            const { account } = get();
            if (account?._id !== data._id) {
                return;
            }

            set({ account: data });
        });

        return {
            account: undefined,
            loading: false,

            loadInformation: async () => {
                if (get().loading) {
                    return;
                }
                set({ loading: true });
                const [error, res] = await API.accountDetails(id);
                set({ loading: false });

                if (error) {
                    console.error(error);
                    return;
                }

                set({ account: res.data });
            },
        };
    });
};

const { Provider, useStore } = createContext<StoreApi<ComponentService>>();

const AccountLoaderService = {
    Provider,
    createStore,
    useStore,
};

interface AccountLoaderInternalProps {
    children: ReactNode | ((account: Account) => ReactNode);
}
function AccountLoaderInternal({ children }: PropsWithChildren<AccountLoaderInternalProps>) {
    const loadInformation = AccountLoaderService.useStore(store => store.loadInformation);
    const isLoading = AccountLoaderService.useStore(store => store.loading);
    const account = AccountLoaderService.useStore(store => store.account);

    useEffectOnce(() => void loadInformation());

    if (!account) {
        return null;
    }

    if (typeof children === 'function') {
        return <WithAccount account={account}>{children(account)}</WithAccount>;
    }

    return <WithAccount account={account}>{children}</WithAccount>;
}

type Options = {
    id: string;
    children: ReactNode | ((account: Account) => ReactNode);
};

export function WithAccountLoader({ id, children }: PropsWithChildren<Options>) {
    return (
        <AccountLoaderService.Provider createStore={() => AccountLoaderService.createStore(id)}>
            <AccountLoaderInternal children={children} />
        </AccountLoaderService.Provider>
    );
}
