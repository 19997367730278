import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import {
    Autocomplete,
    Chip,
    CircularProgress,
    IconButton,
    Popover,
    Stack,
    TextField,
    Typography,
    colors,
} from '@mui/material';
import { useFormik } from 'formik';
import _ from 'lodash';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React, { useMemo, useRef } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { usePopupStateInternal, WithPopupState } from '../../../../../../elements/WithPopupState';
import { useCustomerTitle } from '../../../../../customers/elements/CustomerTitle';
import {
    createCustomerSuggestionStore,
    CustomerSuggestionContext,
} from '../../../../../customers/services/CustomerSuggestionContext';
import { ActionEventListPageService } from '../../ListPageService';
import { DataWrap } from '../../../DataWrap';
import { OperatorName } from '../../../../../operators/elements/OperatorName';

interface AutocompleteTypeFormProps<T extends string | number> {
    initialValue: T;
    onCancel: () => void;
    onSave: (value: T) => void;
}

export function CustomerAutocompleteForm<T extends string | number>({
    initialValue,
    onCancel,
    onSave,
}: AutocompleteTypeFormProps<T>) {
    const loading = CustomerSuggestionContext.useStore(store => store.loading);
    const setSearch = CustomerSuggestionContext.useStore(store => store.setSearch);
    const customersInfo = CustomerSuggestionContext.useStore(store => store.customers);

    const customersInfoOptions = useMemo(() => {
        return customersInfo.map(item => ({
            value: item._id,
            label: item.phone,
        }));
    }, [customersInfo]);

    const formik = useFormik({
        initialValues: {
            value: initialValue,
        },

        onSubmit: async values => {
            onSave(values.value);
            onCancel();
        },
    });

    const selectedValue = useMemo(
        () => customersInfo.find(item => item._id === formik.values.value),
        [customersInfo, formik.values.value],
    );

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack direction={'row'} sx={{ p: 1 }} alignItems="center" spacing={1}>
                <Autocomplete
                    sx={{ minWidth: '15em' }}
                    size="small"
                    fullWidth
                    // loading={loading}
                    options={customersInfo}
                    value={selectedValue || null} //null is neede for initialization render in controlled state
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    getOptionLabel={option => option.phone}
                    renderOption={(props, option, state) => (
                        <li {...props} key={option._id}>
                            <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
                                {option.operator && (
                                    <DataWrap color={colors.grey[200]}>
                                        <OperatorName id={option.operator} />
                                    </DataWrap>
                                )}
                                <Typography>{option.phone}</Typography>
                            </Stack>
                        </li>
                    )}
                    onInputChange={(event, newValue) => setSearch(newValue)}
                    onChange={(event, val) => formik.setFieldValue('value', val?._id)}
                    renderInput={params => (
                        <TextField
                            {...params}
                            autoFocus
                            variant="outlined"
                            placeholder="+79990000000"
                            error={formik.touched.value && Boolean(formik.errors.value)}
                            helperText={formik.touched.value && formik.errors.value}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading && <CircularProgress color="inherit" size={20} />}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />

                <IconButton size="small" color="primary" type="submit">
                    <DoneRoundedIcon />
                </IconButton>
                <IconButton size="small" onClick={onCancel}>
                    <CloseRoundedIcon />
                </IconButton>
            </Stack>
        </form>
    );
}

function SelectorInternal() {
    const setFilter = ActionEventListPageService.useStore(store => store.setFilter);
    const selectedChargePoint = ActionEventListPageService.useStore(store => store.filter.chargePoint);

    const popupState = usePopupStateInternal();

    return (
        <CustomerSuggestionContext.Provider createStore={() => createCustomerSuggestionStore()}>
            <CustomerAutocompleteForm
                initialValue={selectedChargePoint || ''}
                onCancel={popupState.close}
                onSave={value => {
                    setFilter({ customer: value });
                }}
            />
        </CustomerSuggestionContext.Provider>
    );
}

function SelectorChip() {
    const popupState = usePopupStateInternal();

    const setFilter = ActionEventListPageService.useStore(store => store.setFilter);
    const setFilterVisibility = ActionEventListPageService.useStore(store => store.setFilterVisibility);

    const customer = ActionEventListPageService.useStore(store => store.filter.customer);
    const customerTitle = useCustomerTitle(customer);

    const ref = useRef<HTMLDivElement>(null);

    useEffectOnce(() => {
        if (!ref.current) {
            return;
        }
        popupState.setOpen(true, ref.current);
    });

    return (
        <Chip
            ref={ref}
            variant="outlined"
            sx={{ maxWidth: 200 }}
            label={<Typography fontSize="inherit">Клиент: {customerTitle || ''}</Typography>}
            {...bindTrigger(popupState)}
            onClick={event => {
                const selection = window.getSelection && window.getSelection();
                if (selection && selection.type !== 'Range') {
                    popupState.setOpen(true, event);
                }
            }}
            onDelete={() => {
                setFilterVisibility({ type: 'customer', visibility: false });
                setFilter({ customer: undefined });
            }}
        />
    );
}

export function CustomerSelector() {
    return (
        <WithPopupState>
            {popupState => (
                <>
                    <SelectorChip />

                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                        <SelectorInternal />
                    </Popover>
                </>
            )}
        </WithPopupState>
    );
}
