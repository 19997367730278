import { Navigate, Outlet, useParams } from 'react-router-dom';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { ApiHelper } from '../core/api-client';
import create from 'zustand';
import { useAuthContext } from './AuthContext';
import { NotFound404 } from '../components/404';
import { WebsocketClient } from '../core/ws-client';
import { useOperatorId } from '../hooks/useOperatorId';

type OperatorContextStore = {
    initialized: boolean;
    operatorId?: string;
    setData: (operatorId?: string) => void;
};
const useStore = create<OperatorContextStore>((set, get) => {
    return {
        initialized: false,
        setData: (operatorId?: string) => {
            set({ initialized: false });
            if (!operatorId) {
                ApiHelper.removeOperatorContext();
                WebsocketClient.removeOperator();
            } else {
                ApiHelper.setOperatorContext(operatorId);
                WebsocketClient.setOperator(operatorId);
            }
            set({ operatorId, initialized: true });
        },
    };
});

export function NavigateToDefaultOperator() {
    const profile = useAuthContext(store => store.profile);
    return <Navigate to={`/${profile.accessContexts[0].operator}`} />;
}

export function WithValidOperatorIdParams({ children }: PropsWithChildren<{}>) {
    const operatorId = useOperatorId();
    const profile = useAuthContext(store => store.profile);

    const isOperatorIdValid = useMemo(
        () => profile.accessContexts.find(item => item.operator === operatorId),
        [operatorId, profile.accessContexts],
    );

    if (!isOperatorIdValid) {
        return <NavigateToDefaultOperator />;
    }

    return <>{children}</>;
}

export function WithOperatorContext({ children }: PropsWithChildren<{}>) {
    const operatorId = useOperatorId();
    const setData = useStore(store => store.setData);
    const initialized = useStore(store => store.initialized);

    useEffect(() => {
        setData(operatorId);
    }, [operatorId, setData]);

    if (!initialized) {
        return null;
    }

    return <>{children}</>;
}
