import type { AcquiringYookassaPayment } from '@electrifly/central-client-api';
import { colors, Typography, Stack, Divider, Tooltip } from '@mui/material';
import { DataWrap } from '../../../event-log/components/DataWrap';

const CancellationReasonsTitles: Record<string, string> = {
    '3d_secure_failed':
        'Не пройдена аутентификация по 3-D Secure. При новой попытке оплаты пользователю следует пройти аутентификацию, использовать другое платежное средство или обратиться в банк за уточнениями',
    call_issuer:
        'Оплата данным платежным средством отклонена по неизвестным причинам. Пользователю следует обратиться в организацию, выпустившую платежное средство',
    canceled_by_merchant: 'Платеж отменен по API при оплате в две стадии',
    card_expired:
        'Истек срок действия банковской карты. При новой попытке оплаты пользователю следует использовать другое платежное средство',
    country_forbidden:
        'Нельзя заплатить банковской картой, выпущенной в этой стране. При новой попытке оплаты пользователю следует использовать другое платежное средство. Вы можете настроить ограничения на оплату иностранными банковскими картами.',
    deal_expired:
        'Для тех, кто использует Безопасную сделку: закончился срок жизни сделки. Если вы еще хотите принять оплату, создайте новую сделку и проведите для нее новый платеж',
    expired_on_capture:
        'Истек срок списания оплаты у двухстадийного платежа. Если вы еще хотите принять оплату, повторите платеж с новым ключом идемпотентности и спишите деньги после подтверждения платежа пользователем',
    expired_on_confirmation:
        'Истек срок оплаты: пользователь не подтвердил платеж за время, отведенное на оплату выбранным способом. Если пользователь еще хочет оплатить, вам необходимо повторить платеж с новым ключом идемпотентности, а пользователю — подтвердить его',
    fraud_suspected:
        'Платеж заблокирован из-за подозрения в мошенничестве. При новой попытке оплаты пользователю следует использовать другое платежное средство',
    general_decline:
        'Причина не детализирована. Пользователю следует обратиться к инициатору отмены платежа за уточнением подробностей',
    identification_required:
        'Превышены ограничения на платежи для кошелька ЮMoney. При новой попытке оплаты пользователю следует идентифицировать кошелек или выбрать другое платежное средство',
    insufficient_funds:
        'Не хватает денег для оплаты. Пользователю следует пополнить баланс или использовать другое платежное средство',
    internal_timeout:
        'Технические неполадки на стороне ЮKassa: не удалось обработать запрос в течение 30 секунд. Повторите платеж с новым ключом идемпотентности',
    invalid_card_number:
        'Неправильно указан номер карты. При новой попытке оплаты пользователю следует ввести корректные данные',
    invalid_csc:
        'Неправильно указан код CVV2 (CVC2, CID). При новой попытке оплаты пользователю следует ввести корректные данные',
    issuer_unavailable:
        'Организация, выпустившая платежное средство, недоступна. При новой попытке оплаты пользователю следует использовать другое платежное средство или повторить оплату позже',
    payment_method_limit_exceeded:
        'Исчерпан лимит платежей для данного платежного средства или вашего магазина. При новой попытке оплаты пользователю следует использовать другое платежное средство или повторить оплату на следующий день',
    payment_method_restricted:
        'Запрещены операции данным платежным средством (например, карта заблокирована из-за утери, кошелек — из-за взлома мошенниками). Пользователю следует обратиться в организацию, выпустившую платежное средство',
    permission_revoked:
        'Нельзя провести безакцептное списание: пользователь отозвал разрешение на автоплатежи. Если пользователь еще хочет оплатить, вам необходимо создать новый платеж, а пользователю — подтвердить оплату',
    unsupported_mobile_operator:
        'Нельзя заплатить с номера телефона этого мобильного оператора. При новой попытке оплаты пользователю следует использовать другое платежное средство. Список поддерживаемых операторов',
};

interface CancellationDetailProps {
    cancellationDetails: AcquiringYookassaPayment['payment']['cancellation_details'];
}

export function CancellationDetail({ cancellationDetails }: CancellationDetailProps) {
    if (!cancellationDetails) {
        return null;
    }

    return (
        <Tooltip title={CancellationReasonsTitles[cancellationDetails.reason]}>
            <div>
                <DataWrap color={colors.grey[200]}>
                    <Typography fontSize={'inherit'} component="div">
                        <Stack direction={'row'} spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                            <Typography fontSize={'inherit'}>{cancellationDetails.party}</Typography>
                            <Typography fontSize={'inherit'}>{cancellationDetails.reason}</Typography>
                        </Stack>
                    </Typography>
                </DataWrap>
            </div>
        </Tooltip>
    );
}
