import { ForceStop } from '@electrifly/central-client-api';
import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Box,
    Button,
    Collapse,
    DialogContentText,
    FormControl,
    FormHelperText,
    InputAdornment,
    List,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Radio,
    RadioGroup,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import create, { StoreApi } from 'zustand';
import { API } from '../../../core/api-client';
import { createWithImmer } from '../../../misc/CreateWithImmer';
import createContext from 'zustand/context';
import shallow from 'zustand/shallow';

type FormValues = {
    token: string;
    versionInfoURL: string;
};

type Store = {
    open: boolean;
    roamingXPId: string;
    show: (roamingXPId: string) => void;
    hide: () => void;
};

export const useRoamingXPTokenEditDialog = create<Store>((set, get) => ({
    open: false,
    roamingXPId: '',
    show: (roamingXPId: string) => set({ open: true, roamingXPId }),
    hide: () => set({ open: false, roamingXPId: '' }),
}));

type ActionType = 'Generate' | 'Register';
type StageType = 'stage1' | 'stage2';
interface Stage2Data {
    token: string;
    versionInfoURL: string;
}
interface InternalStore {
    type: 'Generate' | 'Register';
    stage: StageType;
    stage2Data?: Stage2Data;
    setType: (type: ActionType) => void;
    setStage: (stage: StageType) => void;
    setStage2Data: (data: Stage2Data) => void;
}
const createInternalStore = () =>
    createWithImmer<InternalStore>((set, get) => ({
        type: 'Generate',
        stage: 'stage1',
        setType: (type: ActionType) => set({ type }),
        setStage: (stage: StageType) => set({ stage }),
        setStage2Data: (data: Stage2Data) => set({ stage2Data: data }),
    }));

const InternalContext = createContext<StoreApi<InternalStore>>();

function Stage1() {
    const hide = useRoamingXPTokenEditDialog(store => store.hide);
    const roamingXPId = useRoamingXPTokenEditDialog(store => store.roamingXPId);
    const [type, setType] = InternalContext.useStore(store => [store.type, store.setType], shallow);
    const setStage = InternalContext.useStore(store => store.setStage);
    const setStage2Data = InternalContext.useStore(store => store.setStage2Data);

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<FormValues>({
        initialValues: {
            token: '',
            versionInfoURL: '',
        },

        // validationSchema: ForceStop.validationSchema,

        onSubmit: async values => {
            if (type === 'Register') {
                return registerToken(values);
            }

            return generateToken(values);
        },
    });

    const registerToken = async (values: FormValues) => {
        console.log(values);
        setDisplayError(false);
        setIsRequesting(true);
        const [error, res] = await API.roamingXPTokenRegister({
            _id: roamingXPId,
            token: values.token,
            versionInfoURL: values.versionInfoURL,
        });
        setIsRequesting(false);
        if (!error) {
            formik.resetForm();
            setStage('stage2');
            return;
        }
        setDisplayError(true);
        console.log(error.response?.data);
        setError(error.response?.data.message || 'Неизвестная ошибка');
        return;
    };

    const generateToken = useCallback(
        async (values: FormValues) => {
            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.roamingXPTokenGenerate({ _id: roamingXPId });
            setIsRequesting(false);
            if (!error) {
                formik.resetForm();
                setStage2Data(res.data);
                setStage('stage2');
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
        [roamingXPId, hide],
    );

    return (
        <>
            <DialogTitle>Изменение токена</DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <FormControl fullWidth>
                        <List>
                            <RadioGroup>
                                <ListItemButton onClick={() => setType('Generate')}>
                                    <ListItemText primary={`Генерация`} secondary={`Генерация токена А`} />
                                    <ListItemSecondaryAction>
                                        <Radio checked={type === 'Generate'} />
                                    </ListItemSecondaryAction>
                                </ListItemButton>
                                <ListItemButton onClick={() => setType('Register')}>
                                    <ListItemText primary={`Регистрация`} secondary={`Ввод данных токена А`} />
                                    <ListItemSecondaryAction>
                                        <Radio checked={type === 'Register'} />
                                    </ListItemSecondaryAction>
                                </ListItemButton>
                            </RadioGroup>
                        </List>
                    </FormControl>

                    <Collapse in={type === 'Register'}>
                        <Stack spacing={2}>
                            <FormControl fullWidth>
                                <TextField
                                    autoFocus
                                    id="token"
                                    label="Токен"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    value={formik.values.token}
                                    onChange={formik.handleChange}
                                    error={formik.touched.token && Boolean(formik.errors.token)}
                                    helperText={formik.touched.token && formik.errors.token}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    autoFocus
                                    id="versionInfoURL"
                                    label="Адрес подключения (version info url)"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    value={formik.values.versionInfoURL}
                                    onChange={formik.handleChange}
                                    error={formik.touched.versionInfoURL && Boolean(formik.errors.versionInfoURL)}
                                    helperText={formik.touched.versionInfoURL && formik.errors.versionInfoURL}
                                />
                            </FormControl>
                        </Stack>
                    </Collapse>
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Отправить команду</span>
                </LoadingButton>
            </DialogActions>
        </>
    );
}

function Stage2() {
    const type = InternalContext.useStore(store => store.type);

    const stage2Data = InternalContext.useStore(store => store.stage2Data);
    const hide = useRoamingXPTokenEditDialog(store => store.hide);

    return (
        <>
            <DialogTitle>Изменение токена</DialogTitle>

            <DialogContent>
                {type === 'Register' && (
                    <Typography variant="subtitle1" fontWeight={500}>
                        Токен зарегистрирован
                    </Typography>
                )}
                {type === 'Generate' && (
                    <Stack direction={'column'} spacing={2}>
                        <Box>
                            <Typography variant="subtitle1" fontWeight={500}>
                                Токен
                            </Typography>
                            <Typography>{stage2Data?.token}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="subtitle1" fontWeight={500}>
                                Version info URL
                            </Typography>
                            <Typography>{stage2Data?.versionInfoURL}</Typography>
                        </Box>
                    </Stack>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={hide}>Закрыть</Button>
            </DialogActions>
        </>
    );
}
function InternalDialog() {
    const [stage, setStage] = InternalContext.useStore(store => [store.stage, store.setStage], shallow);
    if (stage === 'stage1') {
        return <Stage1 />;
    }
    return <Stage2 />;
}

export function RoamingXPTokenEditDialog() {
    const open = useRoamingXPTokenEditDialog(store => store.open);
    const hide = useRoamingXPTokenEditDialog(store => store.hide);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <InternalContext.Provider createStore={() => createInternalStore()}>
                <InternalDialog />
            </InternalContext.Provider>
        </Dialog>
    );
}
