import { Alert, AlertTitle, Box, Stack, Typography } from '@mui/material';

import { Title } from '../../elements/Title';

export function PageHeader2() {
    return (
        <Stack direction={'row'} spacing={1} sx={{ mb: 4, alignItems: 'center' }}>
            <Title sx={{ flex: 1 }}>Отчёты</Title>
        </Stack>
    );
}

export default function ReportListPage() {
    return (
        <Box sx={{ pb: 10 }}>
            <PageHeader2 />
            <Stack spacing={2}>
                <Alert severity="info" icon={null} sx={{ display: 'flex', width: '100%' }}>
                    Обратитесь к оператору за отчётом за август 2022 года.
                </Alert>
            </Stack>
        </Box>
    );
}
