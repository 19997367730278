import { Stack, TextField, InputAdornment, IconButton } from '@mui/material';
import { useFormik } from 'formik';
import React, { useMemo } from 'react';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface SimpleTypeFormProps<T = unknown> {
    initialValue: T;
    endAdornmentText?: string;
    type?: React.InputHTMLAttributes<unknown>['type'];
    onCancel: () => void;
    onSave: (value: T) => void;
    onInputChange?: (event: React.SyntheticEvent, value: string) => void;
}

export function SimpleTypeForm<T = unknown>({
    initialValue,
    endAdornmentText,
    type,
    onCancel,
    onSave,
    onInputChange,
}: SimpleTypeFormProps<T>) {
    const inputType = useMemo<React.InputHTMLAttributes<unknown>['type']>(() => {
        return typeof initialValue === 'number' ? 'number' : 'text';
    }, [initialValue]);

    const formik = useFormik({
        initialValues: {
            value: initialValue,
        },

        onSubmit: async values => {
            onSave(values.value);
            onCancel();
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack direction={'row'} sx={{ p: 1 }} alignItems="center" spacing={1}>
                <TextField
                    size="small"
                    variant="outlined"
                    autoFocus
                    onFocus={e => e.target.select()}
                    InputProps={{
                        endAdornment: endAdornmentText ? (
                            <InputAdornment position="end">{endAdornmentText}</InputAdornment>
                        ) : null,
                    }}
                    name="value"
                    type={inputType}
                    value={formik.values.value}
                    onChange={formik.handleChange}
                    error={formik.touched.value && Boolean(formik.errors.value)}
                    helperText={formik.touched.value && formik.errors.value}
                    autoComplete={'off'}
                    sx={{ minWidth: '15em' }}
                />

                <IconButton size="small" color="primary" type="submit">
                    <DoneRoundedIcon />
                </IconButton>
                <IconButton size="small" onClick={onCancel}>
                    <CloseRoundedIcon />
                </IconButton>
            </Stack>
        </form>
    );
}
