import { Link } from '@mui/material';
import { useEffect } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { RouterLink } from '../../../elements/RouterLink';
import { useDataCache } from '../../../services/DataCache';
import { useParams } from 'react-router-dom';

interface OperatorLinkProps {
    id: string;
}

export function OperatorLink({ id }: OperatorLinkProps) {
    const loadOperator = useDataCache(store => store.loadOperator);
    const operator = useDataCache(store => store.operators[id]);
    const { operatorId } = useParams();

    useEffect(() => {
        loadOperator(id);
    }, [id, loadOperator]);

    return (
        <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={`/${operatorId}/operators/${id}`}
            onClick={event => event.stopPropagation()}
        >
            {operator?.name || id}
        </Link>
    );
}
