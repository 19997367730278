import {
    Button,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import { API } from '../../../core/api-client';
import { useRoamingXP } from '../../wrappers/WithRoamingXP';
import { Timestamp } from '../../billings/components/elements/Timestamp';
import { useRoamingXPTokenEditDialog } from '../dialogs/RoamingXPTokenEditDialog';
import { useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';

function TokenHandshakeButton() {
    const roamingXP = useRoamingXP();
    const [isRequesting, setIsRequesting] = useState(false);

    const startHandshake = async () => {
        // console.log(values);
        // setDisplayError(false);
        setIsRequesting(true);
        const [error, res] = await API.roamingXPHandshake({ _id: roamingXP._id });
        setIsRequesting(false);
        if (!error) {
            //     hide();
            //     formik.resetForm();
            //     // navigate(`/roaming/${res.data._id}`);
            return;
        }
        // setDisplayError(true);
        console.log(error.response?.data);
        return;
    };

    return (
        <ListItem divider>
            <ListItemText primary="Handshake" secondary="Начать процесс обмена ключами" />
            <ListItemSecondaryAction>
                <LoadingButton fullWidth variant="contained" onClick={() => startHandshake()} loading={isRequesting}>
                    <span>Начать регистрацию</span>
                </LoadingButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}

function TokenRenewButton() {
    const roamingXP = useRoamingXP();
    const [isRequesting, setIsRequesting] = useState(false);

    const startHandshake = async () => {
        // console.log(values);
        // setDisplayError(false);
        setIsRequesting(true);
        const [error, res] = await API.roamingXPTokenRenew({ _id: roamingXP._id });
        setIsRequesting(false);
        if (!error) {
            //     hide();
            //     formik.resetForm();
            //     // navigate(`/roaming/${res.data._id}`);
            return;
        }
        // setDisplayError(true);
        console.log(error.response?.data);
        // setError(error.response?.data.message || 'Неизвестная ошибка');
        return;
    };

    return (
        <ListItem divider>
            <ListItemText primary="Renew" secondary="Начать процесс обновления ключей" />
            <ListItemSecondaryAction>
                <LoadingButton fullWidth variant="contained" onClick={() => startHandshake()} loading={isRequesting}>
                    <span>Обновить</span>
                </LoadingButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}

function TokenBlock() {
    const roamingXP = useRoamingXP();
    const showTokenEditDialog = useRoamingXPTokenEditDialog(store => store.show);

    const isPreparing = useMemo(
        () =>
            roamingXP.status === 'CREATED' ||
            roamingXP.status === 'WAITING_FOR_PARTY' ||
            roamingXP.status === 'WAITING_FOR_HANDSHAKE',
        [roamingXP.status],
    );

    return (
        <Grid item lg={6} xs={12}>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Действия</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <List component={Paper} disablePadding>
                {isPreparing && (
                    <ListItem divider>
                        <ListItemText
                            primary="Изменить токен"
                            secondary="Сгенерировать или зарегистрировать новый токен"
                        />
                        <ListItemSecondaryAction>
                            <Button fullWidth variant="contained" onClick={() => showTokenEditDialog(roamingXP._id)}>
                                Изменить токен
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                )}
                {roamingXP.status === 'WAITING_FOR_HANDSHAKE' && <TokenHandshakeButton />}
                {roamingXP.status === 'ACTIVE' && <TokenRenewButton />}
            </List>
        </Grid>
    );
}

function RolesBlock() {
    const roamingXP = useRoamingXP();

    return (
        <Grid item lg={6} xs={12}>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Роли</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <List component={Paper} disablePadding>
                {roamingXP.roles.map(role => (
                    <ListItem key={`${roamingXP._id}${role.country_code}${role.party_id}${role.role}`} divider>
                        <ListItemText
                            primary={role.business_details.name}
                            secondaryTypographyProps={{ component: 'div' }}
                            secondary={
                                <Stack
                                    direction={'row'}
                                    spacing={0.5}
                                    divider={<Typography fontSize={'inherit'}>*</Typography>}
                                >
                                    <Typography fontSize={'inherit'}>{role.country_code}</Typography>
                                    <Typography fontSize={'inherit'}>{role.party_id}</Typography>
                                </Stack>
                            }
                        />
                        <ListItemSecondaryAction>{role.role}</ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Grid>
    );
}

function VersionsBlock() {
    const roamingXP = useRoamingXP();

    return (
        <Grid item lg={6} xs={12}>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Версии</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <List component={Paper} disablePadding>
                <ListItem divider>
                    <ListItemText primary={roamingXP.versionInformationURL} secondary={'Version info URL'} />
                </ListItem>
                {roamingXP.versions.map(item => (
                    <ListItem key={item.url} divider>
                        <ListItemText primary={item.url} secondary={item.version} />
                    </ListItem>
                ))}
            </List>
        </Grid>
    );
}

function EndpointsBlock() {
    const roamingXP = useRoamingXP();

    return (
        <Grid item xs={12}>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Endpoints</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <List component={Paper} disablePadding>
                {roamingXP.endpoints.map((item, index) => (
                    <ListItem key={index} divider>
                        <ListItemText primary={item.url} secondary={item.role} />
                        <ListItemSecondaryAction>{item.identifier}</ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Grid>
    );
}

export function RoamingXPOcpiPage() {
    const roamingXP = useRoamingXP();

    return (
        <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
                <List>
                    <ListItem>
                        <ListItemText>
                            <Typography variant="h5">Информация</Typography>
                        </ListItemText>
                    </ListItem>
                </List>

                <List component={Paper} disablePadding>
                    <ListItem divider>
                        <ListItemText primary="OCPI код" />
                        <ListItemSecondaryAction>
                            <Typography fontSize={'inherit'}>{roamingXP.prefix}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary="Дата создания" />
                        <ListItemSecondaryAction>
                            <Timestamp time={roamingXP.created} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary="Дата регистрации" />
                        <ListItemSecondaryAction>
                            <Timestamp time={roamingXP.registrationDate} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary="Дата обновления" />
                        <ListItemSecondaryAction>
                            <Timestamp time={roamingXP.lastHandshakeDate} />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Grid>
            <TokenBlock />

            <RolesBlock />
            <VersionsBlock />
            <EndpointsBlock />
        </Grid>
    );
}
