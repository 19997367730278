import * as yup from 'yup';
import * as OCPI from '@electrifly/ocpi';
import { ChargePoint, Connector } from '../../data/infrastructure/ChargeLocation';

export type ReqParams = { id: string };
export type ReqBody = {
    power: number;
    standard: OCPI.V221.Types.ConnectorType;
    format: OCPI.V221.Types.ConnectorFormat;
    powerType: OCPI.V221.Types.PowerType;
};

export type ResData = ChargePoint;

export const validationSchema = yup.object({
    power: yup.number().required('Поле мощность должно быть заполнено').min(0, 'Мощность должна быть больше 0'),
    standard: yup.mixed<OCPI.V221.Types.ConnectorType>().required().oneOf(Object.values(OCPI.V221.Types.ConnectorType)),
    format: yup
        .mixed<OCPI.V221.Types.ConnectorFormat>()
        .required()
        .oneOf(Object.values(OCPI.V221.Types.ConnectorFormat)),
    powerType: yup.mixed<OCPI.V221.Types.PowerType>().required().oneOf(Object.values(OCPI.V221.Types.PowerType)),
});
