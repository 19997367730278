import * as yup from 'yup';
import { Corporate } from '../../data/Corporate';

export type ReqParams = { id: string };
export type ReqBody = {
    name?: string;
};

export type ResData = Corporate;

export const validationSchema = yup.object({
    name: yup.string().optional(),
});
