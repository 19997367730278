import * as yup from 'yup';
import { Integration } from '../../../data';

export type ReqParams = {
    id: string;
};
export type ReqBody = {
    autoId: string;
    locationId: string;
};

export type ResData = Integration;

export const validationSchema = yup.object({
    autoId: yup.string().required(),
    locationId: yup.string().required(),
});
