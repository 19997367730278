import * as yup from 'yup';
import { Corporate } from '../../data';

export type ReqBody = {
    name: string;
    operator?: string;
};

export type ResData = Corporate;

export const validationSchema = yup.object({
    name: yup.string().required(),
    operator: yup.string().optional(),
});
