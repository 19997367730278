import { LoadingButton } from '@mui/lab';
import {
    Button,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    colors,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { API } from '../../../core/api-client';
import { Timestamp } from '../../billings/components/elements/Timestamp';
import { DataWrap } from '../../event-log/components/DataWrap';
import { OperatorLink } from '../../operators/elements/OperatorLink';
import { useRoamingXP } from '../../wrappers/WithRoamingXP';
import { useIntegration } from '../../wrappers/WithIntegration';
import { useRoamingXPTokenEditDialog } from '../../roaming/dialogs/RoamingXPTokenEditDialog';
import { WialonTokenEditDialogHelper } from '../dialogs/WialonTokenEditDialog';
import { EditableValue } from '../../editable-values/EditableValue';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import AddIcon from '@mui/icons-material/Add';
import { WialonAddBindingDialogHelper } from '../dialogs/WialonAddBindingDialog';
import { ChargeLocationLink } from '../../charge-location/elements/ChargeLocationLink';

function TokenBlock() {
    const integration = useIntegration();

    return (
        <Grid item lg={6} xs={12}>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Токен</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <List component={Paper} disablePadding>
                <ListItem divider>
                    <ListItemText primary="Изменить токен" secondary="Зарегистрировать новый токен" />
                    <ListItemSecondaryAction>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => WialonTokenEditDialogHelper.show(integration._id)}
                        >
                            Изменить токен
                        </Button>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        </Grid>
    );
}

function BindingsBlock() {
    const integration = useIntegration();

    const remove = async (bindingId: string) => {
        const [error, res] = await API.wialonIntegrationBindingsRemove(integration._id, {
            bindingId: bindingId,
        });
    };

    return (
        <Grid item xs={6}>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Cвязки</Typography>
                    </ListItemText>
                    {/* <ListItemSecondaryAction>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            // onClick={event => showConnectorCreateDialog(chargePoint._id)}
                        >
                            Добавить
                        </Button>
                    </ListItemSecondaryAction> */}
                </ListItem>
            </List>

            <Stack spacing={1}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300 }} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="right">ID авто</TableCell>
                                <TableCell align="right">Зарядная локация</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {integration.data.bindings?.map(item => (
                                <TableRow
                                    key={item.autoId}
                                    // hover={true}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
                                >
                                    <TableCell align="right">{item.autoId}</TableCell>
                                    <TableCell align="right">
                                        <ChargeLocationLink id={item.location} />
                                    </TableCell>
                                    <TableCell padding="checkbox">
                                        <IconButton onClick={() => remove(item._id)}>
                                            <DeleteRoundedIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button
                    fullWidth
                    variant="text"
                    startIcon={<AddIcon />}
                    onClick={event => WialonAddBindingDialogHelper.show(integration)}
                >
                    Добавить
                </Button>
            </Stack>
        </Grid>
    );
}

export function IntegrationDetailsPage() {
    const integration = useIntegration();

    const update = (name: string) => {
        void API.integrationUpdate(integration._id, {
            name: name,
        });
    };

    return (
        <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
                <List>
                    <ListItem>
                        <ListItemText>
                            <Typography variant="h5">Информация</Typography>
                        </ListItemText>
                    </ListItem>
                </List>

                <List component={Paper} disablePadding>
                    <ListItem divider>
                        <ListItemText primary="Название" />
                        <ListItemSecondaryAction>
                            <EditableValue value={integration.name} onNewValue={newValue => update(newValue)} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary="Тип интеграции" />
                        <ListItemSecondaryAction>
                            <DataWrap color={colors.grey[200]}>
                                <Typography fontSize={'inherit'}>{integration.type}</Typography>
                            </DataWrap>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary="Оператор" />
                        <ListItemSecondaryAction>
                            <OperatorLink id={integration.operator} />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Grid>
            <TokenBlock />

            <BindingsBlock />
        </Grid>
    );
}
