import * as yup from 'yup';
import { Connector } from '../../data/infrastructure/ChargeLocation';

// Restrictions like OCPI
interface TariffRestrictions {
    start_time?: string;
    end_time?: string;
}

export type ReqParams = { id: string };
export type ReqBody = {
    tariffGroupId: string;
    restrictions?: TariffRestrictions
};

export type ResData = {};

export const validationSchema = yup.object({
    tariffGroupId: yup.string().required('Идентификатор тарифа должен быть заполнен'),
    restrictions: yup.object({
        start_time: yup.string(),
        end_time: yup.string()
    }).optional(),
});
