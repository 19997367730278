import * as OCPI from '@electrifly/ocpi';
import { useMemo } from 'react';

export const FacilityToText: Record<OCPI.V221.Types.Facility, string> = {
    [OCPI.V221.Types.Facility.HOTEL]: 'Отель',
    [OCPI.V221.Types.Facility.RESTAURANT]: 'Ресторан',
    [OCPI.V221.Types.Facility.CAFE]: 'Кафе',
    [OCPI.V221.Types.Facility.MALL]: 'Торговый центр',
    [OCPI.V221.Types.Facility.SUPERMARKET]: 'Супермаркет',
    [OCPI.V221.Types.Facility.SPORT]: 'Спортивный объект',
    [OCPI.V221.Types.Facility.RECREATION_AREA]: 'Зона отдыха',
    [OCPI.V221.Types.Facility.NATURE]: 'Парк',
    [OCPI.V221.Types.Facility.MUSEUM]: 'Музей',
    [OCPI.V221.Types.Facility.BIKE_SHARING]: 'Велопрокат',
    [OCPI.V221.Types.Facility.BUS_STOP]: 'Автобусная остановка',
    [OCPI.V221.Types.Facility.TAXI_STAND]: 'Стоянка такси',
    [OCPI.V221.Types.Facility.TRAM_STOP]: 'Трамвайная остановка',
    [OCPI.V221.Types.Facility.METRO_STATION]: 'Метро',
    [OCPI.V221.Types.Facility.TRAIN_STATION]: 'Железнодорожная станция',
    [OCPI.V221.Types.Facility.AIRPORT]: 'Аэропорт',
    [OCPI.V221.Types.Facility.PARKING_LOT]: 'Парковка',
    [OCPI.V221.Types.Facility.CARPOOL_PARKING]: 'Автостоянка',
    [OCPI.V221.Types.Facility.FUEL_STATION]: 'Заправка',
    [OCPI.V221.Types.Facility.WIFI]: 'WIFI',
};

export function useFacilityText(type: OCPI.V221.Types.Facility) {
    const text = useMemo(() => FacilityToText[type] || type, [type]);
    return text;
}

interface ChargePointStateTextProps {
    facility: OCPI.V221.Types.Facility;
}

export function FacilityText({ facility }: ChargePointStateTextProps) {
    const text = useFacilityText(facility);
    return <>{text}</>;
}
