import * as OCPI from '@electrifly/ocpi';
import { useMemo } from 'react';

export const ParkingTypeToText: Record<OCPI.V221.Types.ParkingType, string> = {
    [OCPI.V221.Types.ParkingType.PARKING_LOT]: 'Парковочная площадка',
    [OCPI.V221.Types.ParkingType.ON_DRIVEWAY]: 'Гостевая',
    [OCPI.V221.Types.ParkingType.ON_STREET]: 'Уличная', //'Уличная парковка',
    [OCPI.V221.Types.ParkingType.ALONG_MOTORWAY]: 'Шоссе',
    [OCPI.V221.Types.ParkingType.PARKING_GARAGE]: 'Многоэтажная парковка',
    [OCPI.V221.Types.ParkingType.UNDERGROUND_GARAGE]: 'Подземный паркинг',
};

export function useParkingTypeText(type: OCPI.V221.Types.ParkingType) {
    const text = useMemo(() => ParkingTypeToText[type] || type, [type]);
    return text;
}

interface ChargePointStateTextProps {
    parkingType: OCPI.V221.Types.ParkingType;
}

export function ParkingTypeText({ parkingType }: ChargePointStateTextProps) {
    const text = useParkingTypeText(parkingType);
    return <>{text}</>;
}
