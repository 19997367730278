import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { Container } from '@mui/material';
import Topbar from './Topbar';
import Sidebar from './Sidebar';
import { DialogRegistry } from './DialogRegistry';

export default function Layout() {
    return (
        <Box
            sx={{
                display: 'flex',
                backgroundColor: 'rgb(250, 250, 251)',
                minHeight: '100vh',
            }}
        >
            <CssBaseline />
            <Topbar />
            <Sidebar />
            <Container maxWidth="lg" sx={{ pb: 10 }}>
                <Toolbar />
                <Outlet />
            </Container>
            <DialogRegistry />
        </Box>
    );
}
