import { OperatorNotification, OperatorNotifictionType } from '@electrifly/central-client-api';
import {
    List,
    Paper,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Stack,
    Chip,
    Button,
    Switch,
    Popover,
} from '@mui/material';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import React from 'react';
import { useMemo } from 'react';
import { EditForm } from '../../editable-values/EditForm';

interface TextData {
    title: string;
    description: string;
}

const TypeToTextData: Record<OperatorNotifictionType, TextData> = {
    [OperatorNotifictionType.ChargePointOffline]: {
        title: 'Станция оффлайн',
        description: 'Сообщение, когда станция потеряла соединение с сервером',
    },
    [OperatorNotifictionType.MonthlyReport]: {
        title: 'Ежемесячные отчёты',
        description: 'Отчёт о зарядных сессиях за прошедший месяц',
    },
};

interface OperatorNotificationComponentProps {
    data: OperatorNotification;
    onChangeActive?: (newValue: boolean) => void;
    onAddEmail?: (email: string) => void;
    onRemoveEmail?: (email: string) => void;
}

export function OperatorNotificationComponent({
    data,
    onChangeActive,
    onAddEmail,
    onRemoveEmail,
}: OperatorNotificationComponentProps) {
    const { title, description } = useMemo(() => TypeToTextData[data.type], [data.type]);

    return (
        <List component={Paper} disablePadding>
            <ListItem divider>
                <ListItemText primary={title} secondary={description} />
                <ListItemSecondaryAction>
                    <Switch
                        checked={data.active}
                        onChange={event => {
                            const value = event.target.checked;
                            onChangeActive && onChangeActive(value);
                        }}
                    />
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
                <Stack spacing={1} direction="row">
                    {data.emails.map((item, index) => (
                        <Chip
                            key={index}
                            label={item}
                            onDelete={() => {
                                onRemoveEmail && onRemoveEmail(item);
                            }}
                        />
                    ))}
                    <PopupState variant="popover">
                        {popupState => (
                            <React.Fragment>
                                <Button
                                    variant="text"
                                    size="small"
                                    {...bindTrigger(popupState)}
                                    onClick={event => {
                                        const selection = window.getSelection && window.getSelection();
                                        if (selection && selection.type !== 'Range') {
                                            popupState.setOpen(true, event);
                                        }
                                    }}
                                >
                                    + Добавить email
                                </Button>

                                <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                                    transformOrigin={{ vertical: 'center', horizontal: 'right' }}
                                >
                                    <EditForm
                                        initialValue={''}
                                        onCancel={popupState.close}
                                        onSave={value => {
                                            onAddEmail && onAddEmail(value);
                                        }}
                                    />
                                </Popover>
                            </React.Fragment>
                        )}
                    </PopupState>
                </Stack>
            </ListItem>
        </List>
    );
}
