import { EventLogType } from '@electrifly/central-client-api';

const TextMap: Record<EventLogType, string> = {
    'emsp|remotestart': ' Запрос старта',
    'emsp|remotestop': 'Запрос остановки',
    'emsp|forcestop': 'Аварийная остановка',
    'emsp|manual-transaction': 'Ручное создание транзакции',
    'cpo|starttransaction': 'Старт зарядки',
    'cpo|stoptransaction': 'Остановка зарядки',
    'cpo|chargepoint|connection': 'Станция | Cтатус соединения',
    'cpo|reinvoice': 'Перевыставление счёта',
};

export function eventLogTypeToText(type: EventLogType): string {
    return TextMap[type] || type;
}

interface Props {
    type: EventLogType;
}
export function EventLogTypeText({ type }: Props) {
    return <>{TextMap[type] || type}</>;
}
