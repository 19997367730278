import { useEffect } from 'react';
import { useDataCache } from '../../../services/DataCache';

interface Props {
    id: string;
}

export function UserTitle({ id }: Props) {
    const loadUser = useDataCache(store => store.loadUser);
    const user = useDataCache(store => store.users[id]);

    useEffect(() => {
        loadUser(id);
    }, [id, loadUser]);

    return <>{user?.name || id}</>;
}
