import { ChargePoint, ChargePointConnectionStatus } from '@electrifly/central-client-api';
import { Chip, Box } from '@mui/material';

type ChargePointStatusShortProps = {
    chargePoint: ChargePoint;
};

const ChargePointStatusShortInternal = ({ chargePoint }: ChargePointStatusShortProps) => {
    if (chargePoint.ocpp.connectionStatus === ChargePointConnectionStatus.OFFLINE) {
        return <Chip size="small" label="Offline" color="status.offline" />;
    }

    switch (chargePoint.statusMessage.status) {
        case 'Faulted':
            return <Chip size="small" label="Faulted" color="status.faulted" />;
        case 'Unavailable':
            return <Chip size="small" label="Unavailable" color="status.unavailable" />;
    }

    return null;
};

export const ChargePointStatusShort = ({ chargePoint }: ChargePointStatusShortProps) => {
    return (
        <Box sx={{ marginLeft: 1 }}>
            <ChargePointStatusShortInternal chargePoint={chargePoint} />
        </Box>
    );
};
