import { GeneratePaymentLink, PaymentMethodType, UserChangePassword } from '@electrifly/central-client-api';
import { LoadingButton } from '@mui/lab';
import {
    Button,
    FormControl,
    FormHelperText,
    InputAdornment,
    List,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Radio,
    RadioGroup,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import create from 'zustand';
import { API } from '../../../core/api-client';

type Store = {
    open: boolean;
    customerId?: string;
    show: (customerId: string) => void;
    hide: () => void;
};

export const useCustomerCreatePayLinkDialog = create<Store>((set, get) => ({
    open: false,
    userId: undefined,
    show: (customerId: string) => set({ open: true, customerId: customerId }),
    hide: () => set({ open: false, customerId: undefined }),
}));

interface FirstStepProps {
    onSuccess?: (link: string) => void;
}

type FormValues = {
    type: PaymentMethodType;
    amount: number;
};

function FirstStep({ onSuccess }: FirstStepProps) {
    const customerId = useCustomerCreatePayLinkDialog(store => store.customerId);
    const hide = useCustomerCreatePayLinkDialog(store => store.hide);

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<FormValues>({
        initialValues: {
            type: 'sbp',
            amount: 1000,
        },
        validationSchema: GeneratePaymentLink.validationSchema,

        onSubmit: async values => {
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.customerGeneratePaymentLink(customerId!, {
                amount: values.amount,
                type: values.type,
            });
            setIsRequesting(false);
            if (!error) {
                // hide();
                formik.resetForm();
                onSuccess && onSuccess(res.data.link);
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    if (!customerId) {
        return null;
    }

    return (
        <>
            <DialogContent>
                <DialogContentText>
                    {/* После изменения пароля администратор сможет войти только с помощью нового пароля */}
                </DialogContentText>
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="amount"
                            label="Сумма"
                            type="number"
                            fullWidth
                            variant="outlined"
                            autoComplete="none"
                            value={formik.values.amount}
                            onChange={formik.handleChange}
                            error={formik.touched.amount && Boolean(formik.errors.amount)}
                            helperText={formik.touched.amount && formik.errors.amount}
                            inputProps={{ min: '0', step: '0.01' }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">₽</InputAdornment>,
                            }}
                        />

                        <FormControl fullWidth>
                            <Typography flex={1} variant="h6">
                                Тип оплаты
                            </Typography>
                            <List>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                >
                                    <ListItemButton
                                        onClick={() => formik.setValues(values => ({ ...values, type: 'sbp' }))}
                                    >
                                        <ListItemText
                                            id={'radio-list-label-1'}
                                            primary={`Система Быстрых Платежей`}
                                            secondary={`Предпочтительно, если клиент согласен`}
                                        />
                                        <ListItemSecondaryAction>
                                            <Radio checked={formik.values.type === 'sbp'} />
                                        </ListItemSecondaryAction>
                                    </ListItemButton>
                                    <ListItemButton
                                        onClick={() =>
                                            formik.setValues(values => ({
                                                ...values,
                                                type: 'bank_card',
                                            }))
                                        }
                                    >
                                        <ListItemText
                                            id={'radio-list-label-1'}
                                            primary={`Банковская карта`}
                                            secondary={`Оплата банковскими картами`}
                                        />
                                        <ListItemSecondaryAction>
                                            <Radio checked={formik.values.type === 'bank_card'} />
                                        </ListItemSecondaryAction>
                                    </ListItemButton>
                                </RadioGroup>
                            </List>
                        </FormControl>
                    </Stack>
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Создать</span>
                </LoadingButton>
            </DialogActions>
        </>
    );
}

interface SecondStepProps {
    paymentLink: string;
}
function SecondStep({ paymentLink }: SecondStepProps) {
    const hide = useCustomerCreatePayLinkDialog(store => store.hide);

    return (
        <>
            <DialogContent>
                <Stack spacing={1}>
                    <DialogContentText>Отправьте ссылку пользователю:</DialogContentText>
                    <Typography>{paymentLink}</Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide}>Закрыть</Button>
            </DialogActions>
        </>
    );
}

function DialogInternal() {
    const [stage, setStage] = useState<'first' | 'second'>('first');
    const [paymentLink, setPaymentLink] = useState<string>('');

    const onSuccessFirstStep = useCallback((link: string) => {
        setPaymentLink(link);
        setStage('second');
    }, []);

    return (
        <>
            {stage === 'first' && <FirstStep onSuccess={onSuccessFirstStep} />}
            {stage === 'second' && <SecondStep paymentLink={paymentLink} />}
        </>
    );
}

export function CustomerCreatePayLinkDialog() {
    const open = useCustomerCreatePayLinkDialog(store => store.open);
    const hide = useCustomerCreatePayLinkDialog(store => store.hide);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Создание ссылки для пополнения баланса</DialogTitle>
            <DialogInternal />
        </Dialog>
    );
}
