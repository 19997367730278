import { Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';

import { useRoamingLocation } from '../../wrappers/WithRoamingLocation';
import StatsBlockComponent from '../../stats/main/components/StatsBlockComponent';

export function RoamingLocationStatsPage() {
    const location = useRoamingLocation();

    return <StatsBlockComponent filter={{ locations: [location._id] }} />;
}
