import { Measurement, TransactionFull } from '@electrifly/central-client-api';
import OCPP from '@electrifly/ocpp';
import { Box, colors, Grid, Paper } from '@mui/material';
import BigNumber from 'bignumber.js';
import _ from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { ResponsiveContainer, LineChart, Line, YAxis, XAxis, Tooltip, CartesianGrid, Label } from 'recharts';
import { useTransaction } from '../../wrappers/WithTransaction';

function EnergyActiveImportRegisterChart({ measurements }: { measurements: Measurement[] }) {
    const transaction = useTransaction();

    const data = useMemo(() => {
        const startMeasurement: Measurement = {
            timestamp: moment(transaction.startTime).valueOf(),
            originTimestamp: moment(transaction.originStartTime).valueOf(),
            context: 'Other',
            format: 'Raw',
            measurand: 'Energy.Active.Import.Register',
            location: 'Outlet',
            unit: 'W',
            value: new BigNumber(transaction.meterStart).toString(),
        };

        const populatedUnfoldedItems = [startMeasurement, ...measurements];
        if (transaction.completed) {
            const endMeasurement: Measurement = {
                timestamp: moment(transaction.endTime).valueOf(),
                originTimestamp: moment(transaction.originEndTime).valueOf(),
                context: 'Other',
                format: 'Raw',
                measurand: 'Energy.Active.Import.Register',
                location: 'Outlet',
                unit: 'W',
                value: new BigNumber(transaction.meterStop).toString(),
            };

            populatedUnfoldedItems.push(endMeasurement);
        }

        const result = populatedUnfoldedItems
            .filter(item => item.measurand === 'Energy.Active.Import.Register')
            .map(item => {
                const timeDiff = moment(item.originTimestamp).diff(transaction.originStartTime);
                const minutes = moment.duration(timeDiff, 'milliseconds').asMinutes();

                const energy = new BigNumber(item.value)
                    .times(item.unit === 'kWh' ? 1000 : 1)
                    .minus(transaction.meterStart)
                    .div(1000)
                    .toNumber();

                return {
                    minutes: Math.round(minutes * 100) / 100,
                    energy: energy,
                };
            })
            .filter(item => !!item);

        // const endMeasurement;

        return result;
    }, [
        measurements,
        transaction.completed,
        transaction.endTime,
        transaction.meterStart,
        transaction.meterStop,
        transaction.originEndTime,
        transaction.originStartTime,
        transaction.startTime,
    ]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data} margin={{ top: 30, right: 30, left: 30, bottom: 40 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis type="number" domain={[0, 'auto']}>
                    <Label style={{ textAnchor: 'middle' }} value="Энергия | кВт⋅ч" position="insideLeft" angle={-90} />
                </YAxis>
                <XAxis type="number" dataKey="minutes" domain={[0, 'auto']} tickCount={13} allowDecimals={false}>
                    <Label value="Минуты" offset={5} position="bottom" />
                </XAxis>
                <Tooltip />

                <Line type="monotone" dataKey="energy" stroke="#8884d8" strokeWidth={2} dot={false} />
            </LineChart>
        </ResponsiveContainer>
    );
}

function PowerActiveImportChart({ measurements }: { measurements: Measurement[] }) {
    const transaction = useTransaction();

    const data = useMemo(() => {
        const result = measurements
            .filter(item => item.measurand === 'Power.Active.Import')
            .map(item => {
                const timeDiff = moment(item.originTimestamp).diff(transaction.originStartTime);
                const minutes = moment.duration(timeDiff, 'milliseconds').asMinutes();

                const power = new BigNumber(item.value).div(item.unit === 'W' ? 1000 : 1).toNumber();

                return {
                    minutes: Math.round(minutes * 100) / 100,
                    power: power,
                };
            })
            .filter(item => !!item);

        return result;
    }, [transaction.originStartTime, measurements]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data} margin={{ top: 30, right: 30, left: 30, bottom: 40 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis type="number" domain={[0, 'auto']}>
                    <Label style={{ textAnchor: 'middle' }} value="Мощность | кВт" position="insideLeft" angle={-90} />
                </YAxis>
                <XAxis type="number" dataKey="minutes" domain={[0, 'auto']} tickCount={13} allowDecimals={false}>
                    <Label value="Минуты" offset={5} position="bottom" />
                </XAxis>
                <Tooltip />

                <Line type="monotone" dataKey="power" stroke="#8884d8" strokeWidth={2} dot={false} />
            </LineChart>
        </ResponsiveContainer>
    );
}

function SOCChart({ measurements }: { measurements: Measurement[] }) {
    const transaction = useTransaction();

    const data = useMemo(() => {
        const result = measurements
            .filter(item => item.measurand === 'SoC')
            .map(item => {
                const timeDiff = moment(item.originTimestamp).diff(transaction.originStartTime);
                const minutes = moment.duration(timeDiff, 'milliseconds').asMinutes();

                const soc = new BigNumber(item.value).toNumber();

                return {
                    minutes: Math.round(minutes * 100) / 100,
                    SoC: soc,
                };
            })
            .filter(item => !!item);

        return result;
    }, [measurements, transaction.originStartTime]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data} margin={{ top: 30, right: 30, left: 30, bottom: 40 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis type="number" domain={[0, 'auto']}>
                    <Label style={{ textAnchor: 'middle' }} value="SoC" position="insideLeft" angle={-90} />
                </YAxis>
                <XAxis type="number" dataKey="minutes" domain={[0, 'auto']} tickCount={13} allowDecimals={false}>
                    <Label value="Минуты" offset={5} position="bottom" />
                </XAxis>
                <Tooltip />

                <Line type="monotone" dataKey="SoC" stroke="#8884d8" strokeWidth={2} dot={false} />
            </LineChart>
        </ResponsiveContainer>
    );
}

function VoltageChart({ measurements }: { measurements: Measurement[] }) {
    const transaction = useTransaction();

    const data = useMemo(() => {
        const result = _.chain(measurements)
            .filter(item => item.measurand === 'Voltage')
            .map(item => {
                const timeDiff = moment(item.originTimestamp).diff(transaction.originStartTime);
                const minutes = moment.duration(timeDiff, 'milliseconds').asMinutes();

                const voltage = new BigNumber(item.value).toNumber();

                const mapped = {
                    minutes: Math.round(minutes * 100) / 100,
                    // voltage: voltage,
                    [item.phase || 'voltage']: voltage,
                };

                return mapped;
            })
            .filter(item => !!item)
            .groupBy(item => item.minutes)
            .mapValues(groupped =>
                _.assignIn<{
                    [x: string]: number;
                    minutes: number;
                }>(groupped[0], ...groupped),
            )
            .sortBy(item => item.minutes)
            .value();

        return result;
    }, [measurements, transaction.originStartTime]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data} margin={{ top: 30, right: 30, left: 30, bottom: 40 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis type="number" domain={[0, 'auto']}>
                    <Label style={{ textAnchor: 'middle' }} value="Напряжение | В" position="insideLeft" angle={-90} />
                </YAxis>
                <XAxis type="number" dataKey="minutes" domain={[0, 'auto']} tickCount={13} allowDecimals={false}>
                    <Label value="Минуты" offset={5} position="bottom" />
                </XAxis>
                <Tooltip />

                <Line type="monotone" dataKey="voltage" stroke="#8884d8" strokeWidth={2} dot={false} />
                <Line type="monotone" connectNulls dataKey="L1" stroke={colors.red[500]} strokeWidth={2} dot={false} />
                <Line
                    type="monotone"
                    connectNulls
                    dataKey="L2"
                    stroke={colors.green[500]}
                    strokeWidth={2}
                    dot={false}
                />
                <Line
                    type="monotone"
                    connectNulls
                    dataKey="L3"
                    stroke={colors.orange[500]}
                    strokeWidth={2}
                    dot={false}
                />
            </LineChart>
        </ResponsiveContainer>
    );
}

function CurrentImportChart({ measurements }: { measurements: Measurement[] }) {
    const transaction = useTransaction();

    const data = useMemo(() => {
        const result = _.chain(measurements)
            .filter(item => item.measurand === 'Current.Import')
            .map(item => {
                const timeDiff = moment(item.originTimestamp).diff(transaction.originStartTime);
                const minutes = moment.duration(timeDiff, 'milliseconds').asMinutes();

                const current = new BigNumber(item.value).toNumber();

                const mapped = {
                    minutes: Math.round(minutes * 100) / 100,
                    // current: current,
                    [item.phase || 'current']: current,
                };

                // return {
                //     minutes: Math.round(minutes * 100) / 100,
                //     current: current,
                // };

                return mapped;
            })
            .filter(item => !!item)
            .groupBy(item => item.minutes)
            .mapValues(groupped =>
                _.assignIn<{
                    [x: string]: number;
                    minutes: number;
                }>(groupped[0], ...groupped),
            )
            .sortBy(item => item.minutes)
            .value();

        return result;
    }, [measurements, transaction.originStartTime]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data} margin={{ top: 30, right: 30, left: 30, bottom: 40 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis type="number" domain={[0, 'auto']}>
                    <Label style={{ textAnchor: 'middle' }} value="Ток | А" position="insideLeft" angle={-90} />
                </YAxis>
                <XAxis type="number" dataKey="minutes" domain={[0, 'auto']} tickCount={13} allowDecimals={false}>
                    <Label value="Минуты" offset={5} position="bottom" />
                </XAxis>
                <Tooltip />

                <Line type="monotone" dataKey="current" stroke="#8884d8" strokeWidth={2} dot={false} />
                <Line type="monotone" connectNulls dataKey="L1" stroke={colors.red[500]} strokeWidth={2} dot={false} />
                <Line
                    type="monotone"
                    connectNulls
                    dataKey="L2"
                    stroke={colors.green[500]}
                    strokeWidth={2}
                    dot={false}
                />
                <Line
                    type="monotone"
                    connectNulls
                    dataKey="L3"
                    stroke={colors.orange[500]}
                    strokeWidth={2}
                    dot={false}
                />
            </LineChart>
        </ResponsiveContainer>
    );
}

export function TransactionMeasurement() {
    const transaction = useTransaction() as TransactionFull;

    // const data = useMemo(() => {
    //     const unfolded: UnfoldedItem[] = _.chain(transaction.measurements)
    //         .map(measurement => {
    //             const sampledMap = _.keyBy(measurement.sampledValue, item => item.measurand) as SampledMap;
    //             return {
    //                 timestamp: measurement.timestamp,
    //                 originTimestamp: measurement.originTimestamp,
    //                 sampledMap: sampledMap,
    //             };
    //         })
    //         .flatten()
    //         .valueOf();

    //     return unfolded;
    // }, [transaction]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{ height: 300 }} component={Paper}>
                    <EnergyActiveImportRegisterChart measurements={transaction.measurements} />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ height: 300 }} component={Paper}>
                    <PowerActiveImportChart measurements={transaction.measurements} />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ height: 300 }} component={Paper}>
                    <SOCChart measurements={transaction.measurements} />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ height: 300 }} component={Paper}>
                    <VoltageChart measurements={transaction.measurements} />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ height: 300 }} component={Paper}>
                    <CurrentImportChart measurements={transaction.measurements} />
                </Box>
            </Grid>
        </Grid>
    );
}
