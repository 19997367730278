import MobileFriendlyRounded from '@mui/icons-material/MobileFriendlyRounded';
import MobileOffRounded from '@mui/icons-material/MobileOffRounded';
import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Stack,
    Switch,
    Typography,
} from '@mui/material';
import { useRoamingLocation } from '../../wrappers/WithRoamingLocation';
// import { useRegistryEditor } from '../../services/GlobalRegistryEditor';
// import { EditableValue, ListItemTextClickable } from '../editable-values/EditableValue';
// import { useChargeLocation } from '../wrappers/WithChargeLocation';
// import { AmenitiesItemRow } from './components/AmenitiesItemRow';
// import { FacilitiesItemRow } from './components/FacilitiesItemRow';
// import { useParkingTypeDialog } from './dialogs/ParkingTypeDialog';
// import { useParkingTypeText } from './elements/ParkingTypeText';
// import { WorkingHoursBlock } from './components/WorkingHours';
// import { GeolocationBlock } from './components/GeoBlock';
// import { ChargeLocationType } from '@electrifly/central-client-api';
// import { EditableValueOption } from '../editable-values/forms/EditableValueOption';
// import { ChargeLocationTypeText, useChargeLocationTypeText } from './components/ChargeLocationTypeText';

// const LOCATION_TYPE_OPTIONS: EditableValueOption<ChargeLocationType>[] = ChargeLocationType.map(value => ({
//     label: <ChargeLocationTypeText type={value} />,
//     value: value,
// }));

// function InformationBlock() {
//     const location = useChargeLocation();
//     const updateChargeLocation = useRegistryEditor(store => store.updateChargeLocation);

//     return (
//         <>
//             <List>
//                 <ListItem>
//                     <ListItemText>
//                         <Typography variant="h5">Информация</Typography>
//                     </ListItemText>
//                 </ListItem>
//             </List>

//             <List component={Paper} disablePadding>
//                 <ListItem divider>
//                     <ListItemText primary="Тип локации" />
//                     <ListItemSecondaryAction>
//                         <EditableValue
//                             value={location.type}
//                             valueText={useChargeLocationTypeText}
//                             formVariant="select"
//                             options={LOCATION_TYPE_OPTIONS}
//                             onNewValue={newValue => updateChargeLocation(location, { type: newValue })}
//                         />
//                     </ListItemSecondaryAction>
//                 </ListItem>
//                 <ListItem divider>
//                     <ListItemText primary="Название" />
//                     <ListItemSecondaryAction>
//                         <EditableValue
//                             value={location.name}
//                             onNewValue={newValue => updateChargeLocation(location, { name: newValue })}
//                         />
//                     </ListItemSecondaryAction>
//                 </ListItem>
//                 <ListItem divider>
//                     <ListItemIcon sx={{ marginRight: 2, minWidth: 0 }}>
//                         {location.publish && <MobileFriendlyRounded />}
//                         {!location.publish && <MobileOffRounded />}
//                     </ListItemIcon>
//                     <ListItemText primary="Публичная локация" secondary="Локация доступна в мобильном приложении" />
//                     <ListItemSecondaryAction>
//                         <Switch
//                             checked={location.publish}
//                             onChange={event => {
//                                 const publish = event.target.checked;
//                                 updateChargeLocation(location, { publish: publish });
//                             }}
//                         />
//                     </ListItemSecondaryAction>
//                 </ListItem>
//             </List>
//         </>
//     );
// }

// function AdditionalGeoBlock() {
//     const location = useChargeLocation();
//     const updateChargeLocation = useRegistryEditor(store => store.updateChargeLocation);

//     return (
//         <>
//             <List>
//                 <ListItem>
//                     <ListItemText>
//                         <Typography variant="h5">Дополнительно</Typography>
//                     </ListItemText>
//                 </ListItem>
//             </List>

//             <List component={Paper} disablePadding>
//                 <ListItem divider>
//                     <Stack direction={'row'} spacing={2} sx={{ flex: 1 }}>
//                         <ListItemText primary="Как проехать" sx={{ minWidth: 'inherit', whiteSpace: 'nowrap' }} />

//                         <Box sx={{ textAlign: 'end' }}>
//                             <EditableValue
//                                 value={location.directions || ''}
//                                 onNewValue={newValue => updateChargeLocation(location, { directions: newValue })}
//                             />
//                         </Box>
//                     </Stack>
//                 </ListItem>
//             </List>
//         </>
//     );
// }

// function StationaryInformationBlock() {
//     const location = useChargeLocation();
//     const updateChargeLocation = useRegistryEditor(store => store.updateChargeLocation);
//     const showParkingTypeDialog = useParkingTypeDialog(store => store.show);
//     const parkingTypeText = useParkingTypeText(location.parkingType);

//     return (
//         <>
//             <List>
//                 <ListItem>
//                     <ListItemText>
//                         <Typography variant="h5">Стационарная локация</Typography>
//                     </ListItemText>
//                 </ListItem>
//             </List>

//             <List component={Paper} disablePadding>
//                 <FacilitiesItemRow />
//                 <AmenitiesItemRow />

//                 <ListItem divider>
//                     <ListItemText primary="Тип парковки" />
//                     <ListItemSecondaryAction>
//                         <ListItemTextClickable
//                             value={parkingTypeText}
//                             onClick={event => showParkingTypeDialog(location._id)}
//                         />
//                     </ListItemSecondaryAction>
//                 </ListItem>
//                 <ListItem divider>
//                     <ListItemText
//                         primary="Парковка только для посетителей"
//                         secondary="Парковка, въезд на которую ограничен шлагбаумом, воротами, пропуском, либо иными ограничениями"
//                     />
//                     <ListItemSecondaryAction>
//                         <Switch
//                             checked={location.parkingCustomersOnly}
//                             onChange={(event, checked) =>
//                                 updateChargeLocation(location, { parkingCustomersOnly: checked })
//                             }
//                         />
//                     </ListItemSecondaryAction>
//                 </ListItem>
//                 <ListItem divider>
//                     <ListItemText
//                         primary="Парковка EV-Only"
//                         secondary={`Есть разметка и/или знак "Только для электромобилей"`}
//                     />
//                     <ListItemSecondaryAction>
//                         <Switch
//                             checked={location.parkingEVOnly}
//                             onChange={(event, checked) => updateChargeLocation(location, { parkingEVOnly: checked })}
//                         />
//                     </ListItemSecondaryAction>
//                 </ListItem>
//             </List>
//         </>
//     );
// }

// function MobileInformationBlock() {
//     const location = useChargeLocation();
//     const updateChargeLocation = useRegistryEditor(store => store.updateChargeLocation);
//     const showParkingTypeDialog = useParkingTypeDialog(store => store.show);
//     const parkingTypeText = useParkingTypeText(location.parkingType);

//     return (
//         <>
//             <List>
//                 <ListItem>
//                     <ListItemText>
//                         <Typography variant="h5">Мобильная локация</Typography>
//                     </ListItemText>
//                 </ListItem>
//             </List>

//             <List component={Paper} disablePadding>
//                 <ListItem divider>
//                     <ListItemText primary="ID авто" />
//                     <ListItemSecondaryAction>
//                         <EditableValue
//                             value={location.mobileLocationId || ''}
//                             onNewValue={newValue => updateChargeLocation(location, { mobileLocationId: newValue })}
//                         />
//                     </ListItemSecondaryAction>
//                 </ListItem>
//             </List>
//         </>
//     );
// }

// function StationaryVariantBlock() {
//     return (
//         <Grid container item xs={12} spacing={2}>
//             <Grid item lg={6} xs={12}>
//                 <StationaryInformationBlock />
//             </Grid>
//             <Grid item lg={6} xs={12}>
//                 <GeolocationBlock />
//                 <AdditionalGeoBlock />
//             </Grid>
//         </Grid>
//     );
// }

// function MobileVariantBlock() {
//     return (
//         <Grid container item xs={12} spacing={2}>
//             <Grid item lg={6} xs={12}>
//                 <MobileInformationBlock />
//             </Grid>
//             <Grid item lg={6} xs={12}>
//                 <GeolocationBlock />
//                 {/* <AdditionalGeoBlock /> */}
//             </Grid>
//         </Grid>
//     );
// }

export function RoamingLocationDetailsPage() {
    const location = useRoamingLocation();
    return (
        <Grid container spacing={2}>
            {/* <Grid container item xs={12} spacing={2}>
                <Grid item lg={6} xs={12}>
                    <InformationBlock />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <WorkingHoursBlock />
                </Grid>
            </Grid>
            {location.type === 'stationary' && <StationaryVariantBlock />}
            {location.type === 'mobile' && <MobileVariantBlock />} */}
        </Grid>
    );
}
