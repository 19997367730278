import * as yup from 'yup';
import { Integration } from '../../../data';

export type ReqParams = {
    id: string;
};
export type ReqBody = {
    bindingId: string;
};

export type ResData = Integration;

export const validationSchema = yup.object({
    bindingId: yup.string().required(),
});
