import { Box, Button, Stack } from '@mui/material';
import { Title } from '../../../elements/Title';
import AddIcon from '@mui/icons-material/Add';
import { CorporateList } from './CorporateList';
import { CorporateCreateDialogHelper } from '../dialogs/CorporateCreateDialog';

export function PageHeader2() {
    return (
        <Stack direction={'row'} spacing={1} sx={{ mb: 4, alignItems: 'center' }}>
            <Title sx={{ flex: 1 }}>Корпоративные клиенты</Title>
            <Button variant="contained" startIcon={<AddIcon />} onClick={() => CorporateCreateDialogHelper.show()}>
                Добавить
            </Button>
        </Stack>
    );
}

export default function CorporateListPage() {
    return (
        <Box sx={{ pb: 10 }}>
            <PageHeader2 />
            <CorporateList filter={{ limit: 100 }} />
        </Box>
    );
}
