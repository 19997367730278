import { Credentials } from '../../Types';
import { Empty, Request, Response } from '../Base';

export const GetRequest = {
    create: (url: string): GetRequest => ({
        method: 'GET',
        url: url,
    }),
};
export type GetRequest = Request<Empty>;
export type GetResponse = Response<Credentials>;

export const PostRequest = {
    create: (url: string, data: Credentials): PostRequest => ({
        method: 'POST',
        url,
        data,
    }),
};
export type PostRequest = Request<Credentials>;
export type PostResponse = Response<Credentials>;

export const PutRequest = {
    create: (url: string, data: Credentials): PutRequest => ({
        method: 'PUT',
        url,
        data,
    }),
};
export type PutRequest = Request<Credentials>;
export type PutResponse = Response<Credentials>;

export const DeleteRequest = {
    create: (url: string): DeleteRequest => ({
        method: 'DELETE',
        url,
    }),
};
export type DeleteRequest = Request<Empty>;
export type DeleteResponse = Response<Empty>;
