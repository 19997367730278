export enum OperatorNotifictionType {
    ChargePointOffline = 'ChargePointOffline',
    MonthlyReport = 'MonthlyReport',
}

export type OperatorNotification = {
    type: OperatorNotifictionType;
    active: boolean;
    emails: string[];
};

export const OperatorNotificationDefaults: Record<OperatorNotifictionType, OperatorNotification> = {
    [OperatorNotifictionType.ChargePointOffline]: {
        type: OperatorNotifictionType.ChargePointOffline,
        active: false,
        emails: [],
    },
    [OperatorNotifictionType.MonthlyReport]: { type: OperatorNotifictionType.MonthlyReport, active: false, emails: [] },
};
