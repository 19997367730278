import { HolderInfo, OperatorInfo } from '@electrifly/central-client-api/data';
import { Autocomplete, TextField } from '@mui/material';
import _ from 'lodash';
import { useContext, useMemo } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import create, { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { API } from '../../../../core/api-client';
import { FormContext } from './FirstStep';
import { useParams } from 'react-router-dom';

interface InternalService {
    holderInfos: HolderInfo[];
    holdersLoading: boolean;
    loadHoldersInfo: (operatorId: string) => Promise<void>;
}

const createStore = () => {
    return create<InternalService>((set, get) => ({
        holderInfos: [],
        holdersLoading: false,
        loadHoldersInfo: async (operatorId: string) => {
            if (get().holdersLoading) {
                return;
            }
            set({ holdersLoading: true });
            const [error, res] = await API.holderListInfo({ operatorId });
            set({ holdersLoading: false });

            if (error) {
                console.error(error);
                return;
            }

            set({ holderInfos: [...res.data] });
        },
    }));
};

const { Provider, useStore } = createContext<StoreApi<InternalService>>();

export const InternalService = {
    Provider,
    createStore,
    useStore,
};

function HolderFieldInternal() {
    const { operatorId } = useParams();
    const loadHoldersInfo = InternalService.useStore(store => store.loadHoldersInfo);
    const holderInfos = InternalService.useStore(store => store.holderInfos);
    const { formik } = useContext(FormContext);
    const initialHolders = useMemo(() => {
        return holderInfos.filter(holderInfo => formik.values.holders?.includes(holderInfo._id));
    }, [formik.values.holders, holderInfos]);

    useEffectOnce(() => {
        loadHoldersInfo(operatorId!);
    });

    return (
        <Autocomplete
            multiple
            id="operator"
            fullWidth
            options={holderInfos}
            getOptionLabel={option => option.identity}
            renderOption={(props, option, state) => (
                <li {...props} key={option._id}>
                    {option.identity}
                </li>
            )}
            value={initialHolders} //null is needed for initialization render in controlled state
            onChange={(event, val) => {
                formik.setFieldValue(
                    'holders',
                    val.map(item => item._id),
                );
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Владелец"
                    placeholder="Выберите владельца"
                    // error={formik.touched.location && Boolean(formik.errors.location)}
                    // helperText={formik.touched.location && formik.errors.location}
                />
            )}
        />
    );
}

const WithInternalService: React.FC = ({ children }) => {
    return (
        <InternalService.Provider createStore={() => InternalService.createStore()}>
            {children}
        </InternalService.Provider>
    );
};

export function HolderField() {
    return (
        <WithInternalService>
            <HolderFieldInternal />
        </WithInternalService>
    );
}
