import EvStationRoundedIcon from '@mui/icons-material/EvStationRounded';
import FaceRoundedIcon from '@mui/icons-material/FaceRounded';
import { Button, ListItemText, Paper, Popover, SvgIcon } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useMemo } from 'react';
import { usePopupStateInternal, WithPopupState } from '../../../../../../elements/WithPopupState';
import { ActionEventListPageService, FilterUIType } from '../../ListPageService';

interface FilterItem {
    type: FilterUIType;
    title: string;
    icon?: typeof SvgIcon;
}

const FILTER_ITEMS: FilterItem[] = [
    { type: 'eventlog', title: 'Тип события' },
    { type: 'chargepoint', title: 'Зарядная станция' },
    { type: 'transaction', title: 'Транзакция' },
    { type: 'customer', title: 'Клиент' },
    { type: 'date', title: 'Дата' },
];

function FilterSelectorInternal() {
    const popupState = usePopupStateInternal();
    const setFilterVisibility = ActionEventListPageService.useStore(store => store.setFilterVisibility);
    const filterVisability = ActionEventListPageService.useStore(store => store.filterVisability);
    const filterItems = useMemo(
        () => FILTER_ITEMS.filter(item => !filterVisability.includes(item.type)),
        [filterVisability],
    );

    return (
        <Paper sx={{ width: 320, maxWidth: '100%' }}>
            <MenuList>
                {filterItems.map(item => (
                    <MenuItem
                        key={item.type}
                        onClick={() => {
                            popupState.close();
                            setFilterVisibility({ type: item.type, visibility: true });
                        }}
                    >
                        {item.icon && (
                            <ListItemIcon>
                                <item.icon fontSize="small" />
                            </ListItemIcon>
                        )}
                        <ListItemText>{item.title}</ListItemText>
                    </MenuItem>
                ))}
            </MenuList>
        </Paper>
    );
}

export function FilterSelector() {
    return (
        <WithPopupState>
            {popupState => (
                <>
                    <Button
                        // color="inherit"
                        {...bindTrigger(popupState)}
                        onClick={event => {
                            const selection = window.getSelection && window.getSelection();
                            if (selection && selection.type !== 'Range') {
                                popupState.setOpen(true, event);
                            }
                        }}
                    >
                        + Фильтр
                    </Button>

                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                        <FilterSelectorInternal />
                    </Popover>
                </>
            )}
        </WithPopupState>
    );
}
