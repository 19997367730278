import {
    ChargeLocation,
    ChargePoint,
    Connector,
    EVSE,
    MessageType,
    RegistryLoad,
    RoamingLocation,
    Transaction,
} from '@electrifly/central-client-api';
import create from 'zustand';
import { API } from '../core/api-client';
import { WebsocketClient } from '../core/ws-client';
import produce from 'immer';
import { subscribeWithSelector } from 'zustand/middleware';
import _ from 'lodash';
import { WritableDraft } from 'immer/dist/types/types-external';

export type RegistryData = {
    chargeLocationIdsList: string[];
    chargeLocations: Record<string, ChargeLocation>;
    chargePoints: Record<string, ChargePoint>;
    evses: Record<string, EVSE>;
    connectors: Record<string, Connector>;
    transactions: Transaction[];
    transactionMap: Record<string, Transaction>;

    roamingLocations: Record<string, RoamingLocation>;
};

type RegistryActions = {
    load: () => Promise<unknown>;
    flush: () => void;
};

type RegistryType = RegistryData & RegistryActions;

export const Registry = create(
    subscribeWithSelector<RegistryType>((set, get) => {
        WebsocketClient.events[MessageType.ROAMING_LOCATION].on(chargeLocation => {
            set(
                produce<RegistryType>(draft => {
                    draft.roamingLocations[chargeLocation._id] = chargeLocation;
                }),
            );
        });

        WebsocketClient.events[MessageType.CHARGE_LOCATION].on(chargeLocation => {
            if (ChargeLocation.isDeleted(chargeLocation)) {
                set(produce<RegistryType>(draft => removeChargeLocation(draft, chargeLocation._id)));
                return;
            }

            set(
                produce<RegistryType>(draft => {
                    for (const chargePoint of chargeLocation.chargePoints) {
                        for (const evse of chargePoint.evses) {
                            for (const connector of evse.connectors) {
                                draft.connectors[connector._id] = connector;
                            }
                            draft.evses[evse._id] = evse;
                        }
                        draft.chargePoints[chargePoint._id] = chargePoint;
                    }
                    draft.chargeLocations[chargeLocation._id] = chargeLocation;

                    const index = draft.chargeLocationIdsList.findIndex(item => item === chargeLocation._id);
                    if (index === -1) {
                        draft.chargeLocationIdsList.push(chargeLocation._id);
                    }
                }),
            );
        });

        WebsocketClient.events[MessageType.CHARGE_POINT].on(chargePoint => {
            if (ChargePoint.isDeleted(chargePoint)) {
                set(produce<RegistryType>(draft => removeChargePoint(draft, chargePoint._id)));
                return;
            }

            set(
                produce<RegistryType>(draft => {
                    for (const evse of chargePoint.evses) {
                        for (const connector of evse.connectors) {
                            draft.connectors[connector._id] = connector;
                        }
                        draft.evses[evse._id] = evse;
                    }
                    draft.chargePoints[chargePoint._id] = chargePoint;
                }),
            );
        });

        WebsocketClient.events[MessageType.EVSE].on(evse => {
            if (EVSE.isDeleted(evse)) {
                set(produce<RegistryType>(draft => removeEVSE(draft, evse._id)));
                return;
            }

            set(
                produce<RegistryType>(draft => {
                    for (const connector of evse.connectors) {
                        draft.connectors[connector._id] = connector;
                    }
                    draft.evses[evse._id] = evse;
                }),
            );
        });

        WebsocketClient.events[MessageType.CONNECTOR].on(connector => {
            if (Connector.isDeleted(connector)) {
                set(produce<RegistryType>(draft => removeConnector(draft, connector._id)));
                return;
            }

            set(
                produce<RegistryType>(draft => {
                    draft.connectors[connector._id] = connector;
                }),
            );
        });

        WebsocketClient.events[MessageType.TRANSACTION].on(transaction => {
            set(
                produce<RegistryType>(draft => {
                    const index = draft.transactions.findIndex(item => item._id === transaction._id);
                    if (transaction.completed) {
                        if (index === -1) {
                            return;
                        }

                        draft.transactions.splice(index, 1);
                        return;
                    }

                    if (index === -1) {
                        draft.transactions.push(transaction);
                        return;
                    }

                    draft.transactions[index] = transaction;
                }),
            );
        });

        WebsocketClient.events[MessageType.REGISTRY].on(data => {
            set(produce<RegistryType>(draft => onRegistryLoad(draft, data)));
        });

        function removeChargeLocation(draft: WritableDraft<RegistryType>, _id: string) {
            console.log(`removeChargeLocation, ${_id}`);

            const chargeLocation = draft.chargeLocations[_id];

            _.remove(draft.chargeLocationIdsList, item => item === chargeLocation._id);
            delete draft.chargeLocations[chargeLocation._id];

            chargeLocation.chargePoints.forEach(chargePoint => removeChargePoint(draft, chargePoint._id));
        }

        function removeChargePoint(draft: WritableDraft<RegistryType>, _id: string) {
            console.log(`removeChargePoint, ${_id}`);

            const chargePoint = draft.chargePoints[_id];
            const chargeLocation = draft.chargeLocations[chargePoint.location];

            if (chargeLocation) {
                _.remove(chargeLocation.chargePoints, item => item._id === chargePoint._id);
            }

            delete draft.chargePoints[chargePoint._id];

            chargePoint.evses.forEach(evse => removeEVSE(draft, evse._id));
        }

        function removeEVSE(draft: WritableDraft<RegistryType>, _id: string) {
            console.log(`removeEVSE, ${_id}`);

            const evse = draft.evses[_id];
            const chargePoint = draft.chargePoints[evse.chargePoint];

            if (chargePoint) {
                _.remove(chargePoint.evses, item => item._id === evse._id);
            }

            delete draft.evses[evse._id];

            evse.connectors.forEach(connector => removeConnector(draft, connector._id));
        }

        function removeConnector(draft: WritableDraft<RegistryType>, _id: string) {
            console.log(`removeConnector, ${_id}`);

            const connector = draft.connectors[_id];
            const evse = draft.evses[connector.evse];

            if (evse) {
                _.remove(evse.connectors, item => item._id === connector._id);
            }

            delete draft.connectors[connector._id];
        }

        function onRegistryLoad(draft: WritableDraft<RegistryType>, data: RegistryLoad.ResData) {
            const chargeLocationIdsList: string[] = [];
            const chargeLocations: Record<string, ChargeLocation> = {};
            const chargePoints: Record<string, ChargePoint> = {};
            const evses: Record<string, EVSE> = {};
            const connectors: Record<string, Connector> = {};

            for (const location of data.locations) {
                for (const chargePoint of location.chargePoints) {
                    for (const evse of chargePoint.evses) {
                        for (const connector of evse.connectors) {
                            connectors[connector._id] = connector;
                        }
                        evses[evse._id] = evse;
                    }
                    chargePoints[chargePoint._id] = chargePoint;
                }
                chargeLocationIdsList.push(location._id);
                chargeLocations[location._id] = location;
            }

            const filteredTransaction = data.transactions.filter(transaction => transaction.completed === false);
            const transactionMap: Record<string, Transaction> = {};
            filteredTransaction.forEach(transaction => {
                transactionMap[transaction._id] = transaction;
            });

            const roamingLocationsReduced = data.roamingLocations.reduce((acc, item) => {
                acc[item._id] = item;
                return acc;
            }, {} as Record<string, RoamingLocation>);

            draft.chargeLocationIdsList = chargeLocationIdsList;
            draft.chargeLocations = chargeLocations;
            draft.chargePoints = chargePoints;
            draft.evses = evses;
            draft.connectors = connectors;
            draft.transactions = filteredTransaction;
            draft.transactionMap = transactionMap;
            draft.roamingLocations = roamingLocationsReduced;
        }

        return {
            chargeLocationIdsList: [],
            chargeLocations: {},
            chargePoints: {},
            evses: {},
            connectors: {},
            transactions: [],
            transactionMap: {},
            roamingLocations: {},

            load: async () => {
                const [error, res] = await API.registryLoad();

                if (error || !res) {
                    return error;
                }

                const chargeLocationIdsList: string[] = [];
                const chargeLocations: Record<string, ChargeLocation> = {};
                const chargePoints: Record<string, ChargePoint> = {};
                const evses: Record<string, EVSE> = {};
                const connectors: Record<string, Connector> = {};

                for (const location of res.data.locations) {
                    for (const chargePoint of location.chargePoints) {
                        for (const evse of chargePoint.evses) {
                            for (const connector of evse.connectors) {
                                connectors[connector._id] = connector;
                            }
                            evses[evse._id] = evse;
                        }
                        chargePoints[chargePoint._id] = chargePoint;
                    }
                    chargeLocationIdsList.push(location._id);
                    chargeLocations[location._id] = location;
                }

                const filteredTransaction = res.data.transactions.filter(
                    transaction => transaction.completed === false,
                );
                const transactionMap: Record<string, Transaction> = {};
                filteredTransaction.forEach(transaction => {
                    transactionMap[transaction._id] = transaction;
                });

                const roamingLocationsReduced = res.data.roamingLocations.reduce((acc, item) => {
                    acc[item._id] = item;
                    return acc;
                }, {} as Record<string, RoamingLocation>);

                set({
                    chargeLocationIdsList,
                    chargeLocations,
                    chargePoints,
                    evses,
                    connectors,
                    transactions: filteredTransaction,
                    transactionMap,
                    roamingLocations: roamingLocationsReduced,
                });
            },

            flush: () => {
                set({
                    chargeLocationIdsList: [],
                    chargeLocations: {},
                    chargePoints: {},
                    evses: {},
                    connectors: {},
                    transactions: [],
                    transactionMap: {},
                    roamingLocations: {},
                });
            },
        };
    }),
);

export const useRegistry = Registry;
