import { Box, Button, Stack } from '@mui/material';
import { Title } from '../../../elements/Title';
import { IntegrationList } from './IntegrationList';
import AddIcon from '@mui/icons-material/Add';
import { useIntegrationCreateDialog } from '../dialogs/IntegrationCreateDialog';

export function PageHeader2() {
    const show = useIntegrationCreateDialog(store => store.show);

    return (
        <Stack direction={'row'} spacing={1} sx={{ mb: 4, alignItems: 'center' }}>
            <Title sx={{ flex: 1 }}>Интеграции</Title>
            <Button variant="contained" startIcon={<AddIcon />} onClick={() => show()}>
                Добавить
            </Button>
        </Stack>
    );
}

export default function IntegrationListPage() {
    return (
        <Box sx={{ pb: 10 }}>
            <PageHeader2 />
            <IntegrationList filter={{ limit: 100 }} />
        </Box>
    );
}
