import * as yup from 'yup';

export type ReqParams = { id: string };

export type ResData = {
    success: boolean;
    authorizationKey: string;
};

export const validationSchema = yup.object({});
