import { Box, Paper, Tabs, Tab, Stack, TextField, InputAdornment, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useRouteMatch } from '../../../hooks/useRouteMatch';
import { RouterLink } from '../../../elements/RouterLink';
import { CustomerListFilterService } from '../components/customers-table/WithCustomerListFilter';
import { useParams } from 'react-router-dom';

export function CustomerListTopMenu() {
    const { operatorId } = useParams();
    const routeMatch = useRouteMatch([`/${operatorId}/customers`]);
    const currentTab = routeMatch?.pattern?.path;

    const searchValue = CustomerListFilterService.useStore(store => store.filter.search);
    const setSearch = CustomerListFilterService.useStore(store => store.setSearch);

    return (
        <Box sx={{ marginTop: 2, marginBottom: 1 }} component={Paper}>
            <Box sx={{ display: 'flex', width: '100%', borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={currentTab} sx={{ flex: 1 }} variant="scrollable" scrollButtons="auto">
                    <Tab label="Все" value={`/${operatorId}/customers`} component={RouterLink} to="" />
                    {/* <Tab label="Активные" value={'/transactions/active'} component={RouterLink} to="active" /> */}
                </Tabs>

                <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
                    <TextField
                        placeholder="Поиск"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        size="small"
                        // disabled={true}
                        value={searchValue}
                        onChange={event => setSearch(event.target.value)}
                    />
                    <Button color="inherit" disabled>
                        Фильтр
                    </Button>
                    <Button color="inherit" disabled>
                        Опции
                    </Button>
                </Stack>
            </Box>
            <Stack direction={'row'} spacing={1} sx={{ padding: 1 }}>
                {/* {chargePointStatuses.map(status => (
                    <Chip key={status} label={`Станции: ${status}`} onDelete={() => removeChargePointFilter(status)} />
                ))}
                {connectorStatuses.map(status => (
                    <Chip key={status} label={`Коннекторы: ${status}`} onDelete={() => removeConnectorFilter(status)} />
                ))} */}
            </Stack>
        </Box>
    );
}
