import { EventLog, EventLogType } from '@electrifly/central-client-api';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { API } from '../../../../core/api-client';
import { WebsocketClient } from '../../../../core/ws-client';
import { createWithImmer } from '../../../../misc/CreateWithImmer';
import { ColumnKey } from './TableColumns';

export type Filter = {
    limit: number;
    search?: string;
    types: EventLogType[];
    transactionHumanId?: number;
    chargePoint?: string;
    customer?: string;
    rangeStart?: number;
    rangeEnd?: number;
};

export type FilterUIType = 'eventlog' | 'chargepoint' | 'transaction' | 'customer' | 'date';

export type Options = Record<ColumnKey, boolean>;

type Data = {
    events: EventLog[];
    loading: boolean;
    canLoadMore: boolean;

    filter: Filter;
    filterVisability: FilterUIType[];
};

type Actions = {
    reset: () => void;
    loadNext: () => Promise<void>;
    setFilter: (data: Partial<Filter>) => void;
    setFilterVisibility: (data: { type: FilterUIType; visibility: boolean }) => void;
};

type Service = Data & Actions;

const DEFAULT_FILTER: Filter = {
    search: '',
    limit: 100,
    types: [],
};

function createDefaultData(): Data {
    return {
        events: [],
        loading: false,
        canLoadMore: false,

        filter: DEFAULT_FILTER,
        filterVisability: [],
    };
}

function transactionPassFilter(eventLog: EventLog, filter: Filter): boolean {
    let passed = true;
    if (filter.search) {
        // passed = passed && new BigNumber(filter.search).isEqualTo(eventLog.transactionId);
    }

    return passed;
}

const createStore = (filter?: Partial<Filter>, options?: Partial<Options>) => {
    const initialData = {
        ...createDefaultData(),
        filter: { ...createDefaultData().filter, ...filter },
    };

    return createWithImmer<Service>((set, get) => {
        WebsocketClient.events.ACTION_EVENT.on(actionEvent => {
            const { events, filter } = get();
            const passed = transactionPassFilter(actionEvent, filter);
            if (!passed) {
                set(draft => {
                    const index = draft.events.findIndex(item => item._id === actionEvent._id);
                    if (index !== -1) {
                        draft.events.splice(index, 1);
                    }
                });

                return;
            }

            // const index = events.findIndex(item => item._id === actionEvent._id);
            // if (index === -1) {
            //     const youngest = _.maxBy(events, item => DateTime.fromISO(item.created).is.toMillis());
            //     if (!youngest) {
            //         return set({ events: [...events, actionEvent] });
            //     }

            //     if (youngest.transactionId > actionEvent.transactionId) {
            //         return set({ events: [...events, actionEvent] });
            //     } else {
            //         return set({ events: [actionEvent, ...events] });
            //     }
            // }

            // set(
            //     produce<Data>(draft => {
            //         draft.events[index] = actionEvent;
            //     }),
            // );
        });

        function resetData() {
            set({ events: [] });
        }

        async function loadNext() {
            if (get().loading) {
                debouncedLoadNext();
                return;
            }

            set({ loading: true });

            const { filter, events } = get();

            const skip = events.length;
            const [error, res] = await API.eventList({
                skip,
                limit: filter.limit,
                types: filter.types,
                transactionHumanId: filter.transactionHumanId,
                chargePoints: filter.chargePoint ? [filter.chargePoint] : undefined,
                customers: filter.customer ? [filter.customer] : undefined,
                rangeStart: filter.rangeStart,
                // Range End day is included
                rangeEnd: filter.rangeEnd && DateTime.fromMillis(filter.rangeEnd).plus({ days: 1 }).toMillis(),
            });

            if (error) {
                console.error(error);
                set({ loading: false });
                return;
            }

            const newData = res.data;
            const canLoadMore = newData.length === filter.limit;

            set({
                loading: false,
                canLoadMore: canLoadMore,
                events: [...get().events, ...newData],
            });
        }

        function loadNexWithReset() {
            resetData();
            loadNext();
        }

        const debouncedLoadNext = _.debounce(() => loadNext(), 300);
        const debouncedLoadNextWithReset = _.debounce(() => loadNexWithReset(), 300);

        return {
            ...initialData,

            reset: () => set({ ...initialData }),

            setFilter: (data: Partial<Filter>) => {
                set(draft => {
                    draft.filter = { ...draft.filter, ...data };
                });

                debouncedLoadNextWithReset();
            },

            setFilterVisibility: (data: { type: FilterUIType; visibility: boolean }) => {
                set(draft => {
                    if (data.visibility) {
                        draft.filterVisability.push(data.type);
                        draft.filterVisability = _.uniqBy(draft.filterVisability, item => item);
                        return draft;
                    }

                    _.remove(draft.filterVisability, item => item === data.type);
                    // draft.filterVisability = data.visibility
                    //     ? draft.filterVisability.push
                    //     : draft.filterVisability.filter(item => item !== data.type);
                });
            },

            loadNext: loadNext,
        };
    });
};

const { Provider, useStore } = createContext<StoreApi<Service>>();

export const ActionEventListPageService = {
    Provider,
    createStore,
    useStore,
};
