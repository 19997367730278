import { Box, Chip, Stack } from '@mui/material';
import { Title } from '../../elements/Title';
import { useRoamingLocation } from '../wrappers/WithRoamingLocation';

export function ChargeLocationHeader() {
    const location = useRoamingLocation();

    return (
        <Stack direction={'column'} sx={{ mb: 2 }}>
            <Box sx={{ mb: 1 }}>
                <Chip label="Роуминг" color="primary" variant="outlined" />
            </Box>
            <Title sx={{ flex: 1 }}>Зарядная локация {location.name}</Title>
        </Stack>
    );
}
