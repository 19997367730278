export enum HolderNotifictionType {
    ChargePointOffline = 'ChargePointOffline',
    MonthlyReport = 'MonthlyReport',
}

export type HolderNotification = {
    type: HolderNotifictionType;
    active: boolean;
    emails: string[];
};

export const HolderNotificationDefaults: Record<HolderNotifictionType, HolderNotification> = {
    [HolderNotifictionType.ChargePointOffline]: {
        type: HolderNotifictionType.ChargePointOffline,
        active: false,
        emails: [],
    },
    [HolderNotifictionType.MonthlyReport]: { type: HolderNotifictionType.MonthlyReport, active: false, emails: [] },
};
