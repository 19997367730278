import { LoadingButton } from '@mui/lab';
import {
    Button,
    DialogContentText,
    FormControl,
    FormHelperText,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useState } from 'react';
import create from 'zustand';
import { useRegistryEditor } from '../../../services/GlobalRegistryEditor';
import { useChargePoint, WithChargePoint } from '../../wrappers/WithChargePoint';

type FormValues = {
    comment: string;
};

type Store = {
    open: boolean;
    chargePointId: string;
    show: (chargePointId: string) => void;
    hide: () => void;
};

export const useChargePointEditCommentDialog = create<Store>((set, get) => ({
    open: false,
    chargePointId: '',
    show: (chargePointId: string) => set({ open: true, chargePointId: chargePointId }),
    hide: () => set({ open: false, chargePointId: '' }),
}));

function DialogInternal() {
    const open = useChargePointEditCommentDialog(store => store.open);
    const hide = useChargePointEditCommentDialog(store => store.hide);
    // const humanTransactionId = useChargePointChangeStateDialog(store => store.humanTransactionId);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');
    const chargePoint = useChargePoint();
    const updateChargePoint = useRegistryEditor(store => store.updateChargePoint);

    const formik = useFormik<FormValues>({
        initialValues: {
            comment: chargePoint.comment || '',
        },

        onSubmit: async values => {
            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);

            updateChargePoint(chargePoint, { comment: values.comment });
            hide();

            return;
        },
    });

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Изменение операционной информации</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ paddingBottom: 1 }}></DialogContentText>
                <form onSubmit={formik.handleSubmit}>
                    <FormControl fullWidth>
                        <TextField
                            autoFocus
                            id="comment"
                            label="Комментарий"
                            variant="outlined"
                            fullWidth
                            multiline
                            minRows={6}
                            // maxRows={20}
                            spellCheck={false}
                            value={formik.values.comment}
                            onChange={formik.handleChange}
                            error={formik.touched.comment && Boolean(formik.errors.comment)}
                            helperText={formik.touched.comment && formik.errors.comment}
                        />
                    </FormControl>
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Изменить</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export function ChargePointEditCommentDialog() {
    const chargePointId = useChargePointEditCommentDialog(store => store.chargePointId);

    return (
        <WithChargePoint id={chargePointId}>
            <DialogInternal />
        </WithChargePoint>
    );
}
