import * as yup from 'yup';
import { EventLog, EventLogType, EventLogTypeValues } from '../../data/event-log/EventLog';

export type ReqQuery = {
    filter?: string;
    skip?: number;
    limit?: number;
    types?: EventLogType[];
    transactionHumanId?: number;
    chargePoints?: string[];
    customers?: string[];
    rangeStart?: number;
    rangeEnd?: number;
};

export type ResData = EventLog[];

export const validationSchema = yup.object({
    filter: yup.string().optional().max(100),
    skip: yup.number().optional().min(0).default(0),

    limit: yup.number().optional().min(1).max(1000).default(100),
    types: yup
        .array()
        .of(yup.mixed<EventLogType>().oneOf([...EventLogTypeValues]))
        .optional(),
    transactionHumanId: yup.number().optional().min(0).default(0),
    chargePoints: yup.array().of(yup.string()).optional(),
    customers: yup.array().of(yup.string()).optional(),
    rangeStart: yup.number().optional().min(0),
    rangeEnd: yup.number().optional().min(0),
});
