import * as yup from 'yup';
import { Corporate } from '../../data/Corporate';

export type ReqParams = { id: string };
export type ReqBody = {
    customer: string;
};

export type ResData = Corporate;

export const validationSchema = yup.object({
    customer: yup.string().required(),
});
