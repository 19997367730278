import { ChargePointReset, PriceResourceType, UserChangePassword } from '@electrifly/central-client-api';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputAdornment,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Switch,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useState } from 'react';
import create from 'zustand';
import { API } from '../../../core/api-client';

type Store = {
    open: boolean;
    show: () => void;
    hide: () => void;
};

export const useTransactionChangePriceDialog = create<Store>((set, get) => ({
    open: false,
    show: () => set({ open: true }),
    hide: () => set({ open: false }),
}));

export function TransactionChangePriceDialog() {
    const open = useTransactionChangePriceDialog(store => store.open);
    const hide = useTransactionChangePriceDialog(store => store.hide);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik({
        initialValues: {
            humanId: 0,
            free: false,
            resources: {
                session: 0,
                energy: 0,
                time: 0,
            },
        },

        onSubmit: async values => {
            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.flushPrice(values.humanId, {
                free: values.free,
                resources: [
                    { type: PriceResourceType.Session, amount: values.resources.session },
                    { type: PriceResourceType.Energy, amount: values.resources.energy },
                    { type: PriceResourceType.Time, amount: values.resources.time },
                ],
            });
            setIsRequesting(false);
            if (!error) {
                hide();
                formik.resetForm();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <Dialog
            open={open}
            // open={true}
            onClose={hide}
            maxWidth={'sm'}
            fullScreen={fullScreen}
            fullWidth
        >
            <DialogTitle>Изменение тарифа транзакции</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: 2 }}>
                    Для изменение тарифа транзакция должна быть в активной фазе
                </DialogContentText>
                <form onSubmit={formik.handleSubmit}>
                    <FormControl fullWidth>
                        <TextField
                            autoFocus
                            id="humanId"
                            label="Номер транзакции"
                            type="number"
                            variant="outlined"
                            fullWidth
                            value={formik.values.humanId}
                            onChange={formik.handleChange}
                            error={formik.touched.humanId && Boolean(formik.errors.humanId)}
                            helperText={formik.touched.humanId && formik.errors.humanId}
                        />

                        <Box sx={{ marginBottom: 2 }} />
                        <List disablePadding>
                            <ListItem divider>
                                <ListItemText primary="Бесплатно" />
                                <ListItemSecondaryAction>
                                    <Switch checked={formik.values.free} onChange={formik.handleChange} name="free" />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem
                                divider
                                sx={{ ...(formik.values.free && { pointerEvents: 'none', opacity: 0.38 }) }}
                            >
                                <ListItemText primary="Старт сессии" />
                                <ListItemSecondaryAction
                                    sx={{ ...(formik.values.free && { pointerEvents: 'none', opacity: 0.38 }) }}
                                >
                                    <TextField
                                        id="resources.session"
                                        size="small"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ input: { textAlign: 'right' }, minWidth: '15rem' }}
                                        value={formik.values.resources.session}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.resources?.session &&
                                            Boolean(formik.errors.resources?.session)
                                        }
                                        helperText={
                                            formik.touched.resources?.session && formik.errors.resources?.session
                                        }
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">₽</InputAdornment>,
                                        }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem
                                divider
                                sx={{ ...(formik.values.free && { pointerEvents: 'none', opacity: 0.38 }) }}
                            >
                                <ListItemText primary="Энергия" />
                                <ListItemSecondaryAction
                                    sx={{ ...(formik.values.free && { pointerEvents: 'none', opacity: 0.38 }) }}
                                >
                                    <TextField
                                        size="small"
                                        id="resources.energy"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ input: { textAlign: 'right' }, minWidth: '15em' }}
                                        value={formik.values.resources.energy}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.resources?.energy && Boolean(formik.errors.resources?.energy)
                                        }
                                        helperText={formik.touched.resources?.energy && formik.errors.resources?.energy}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">₽/кВт⋅ч</InputAdornment>,
                                        }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem
                                divider
                                sx={{ ...(formik.values.free && { pointerEvents: 'none', opacity: 0.38 }) }}
                            >
                                <ListItemText primary="Время" />
                                <ListItemSecondaryAction
                                    sx={{ ...(formik.values.free && { pointerEvents: 'none', opacity: 0.38 }) }}
                                >
                                    <TextField
                                        id="resources.time"
                                        size="small"
                                        // label="Номер транзакции"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ input: { textAlign: 'right' }, minWidth: '15em' }}
                                        value={formik.values.resources.time}
                                        onChange={formik.handleChange}
                                        error={formik.touched.resources?.time && Boolean(formik.errors.resources?.time)}
                                        helperText={formik.touched.resources?.time && formik.errors.resources?.time}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">₽/мин.</InputAdornment>,
                                        }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                            {/* <ResourceSessionItemBlock
                                item={priceMap[PriceResourceType.Session]}
                                onUpdate={onResourceAmountUpdate}
                                disabled={price.free}
                            />
                            <ResourceEnergyItemBlock
                                item={priceMap[PriceResourceType.Energy]}
                                onUpdate={onResourceAmountUpdate}
                                disabled={price.free}
                            />
                            <ResourceTimeItemBlock
                                item={priceMap[PriceResourceType.Time]}
                                onUpdate={onResourceAmountUpdate}
                                disabled={price.free}
                            /> */}
                        </List>
                    </FormControl>
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Далее</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
