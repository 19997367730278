import { RFIDTagType, AccessScope, OwnerType, TokenVariant } from '@electrifly/central-client-api';
import { useMemo } from 'react';
import { CustomerLink } from '../customers/elements/CustomerLink';
import { CustomerTitle } from '../customers/elements/CustomerTitle';
import { UserLink } from '../users/elements/UserLink';
import { UserTitle } from '../users/elements/UserTitle';
import { WithAccessScope } from '../wrappers/WithAccessScope';
import { RoamingTitle } from '../roaming/elements/RoamingTitle';
import { RoamingLink } from '../roaming/elements/RoamingLink';
import { exhaustiveCheck } from '../../misc/ExhaustiveCheck';
import { TransactionLink } from '../transaction/elements/TransactionLink';
import { WithCachedTransaction } from '../transaction/info/WithCachedTransaction';
import { CorporateLink } from '../corporate/elements/CorporateLink';
import { CorporateTitle } from '../corporate/elements/CorporateTitle';
import { SystemTitle } from '../system/elements/elements/SystemTitle';
import { HolderLink } from '../holders/elements/HolderLink';

export function useOwnerType(variant: TokenVariant): OwnerType {
    switch (variant) {
        case 'freevend':
        case 'customer':
            return 'customer';
        case 'administrator':
            return 'user';
        case 'roaming':
            return 'roaming-emsp';
        default:
            exhaustiveCheck(variant);
    }
}

interface CustomerVariantProps {
    owner: string;
    displayEMSP?: boolean;
}
interface CorporateVariantProps {
    owner: string;
    displayEMSP?: boolean;
}
interface AdministratorVariantProps {
    owner: string;
}
interface RoamingVariantProps {
    owner: string;
}
interface TransactionVariantProps {
    owner: string;
}

function CustomerVariant({ owner, displayEMSP }: CustomerVariantProps) {
    return (
        <WithAccessScope scope={AccessScope.OPERATOR} fallback={<CustomerTitle id={owner} displayEMPS={displayEMSP} />}>
            <CustomerLink id={owner} displayEMPS={displayEMSP} />
        </WithAccessScope>
    );
}
function CorporateVariant({ owner, displayEMSP }: CorporateVariantProps) {
    return (
        <WithAccessScope
            scope={AccessScope.OPERATOR}
            fallback={<CorporateTitle id={owner} displayEMPS={displayEMSP} />}
        >
            <CorporateLink id={owner} displayEMPS={displayEMSP} />
        </WithAccessScope>
    );
}

function AdministratorVariant({ owner }: AdministratorVariantProps) {
    return (
        <WithAccessScope scope={AccessScope.OPERATOR} fallback={<UserTitle id={owner} />}>
            <UserLink id={owner} />
        </WithAccessScope>
    );
}

function HolderVariant({ owner }: AdministratorVariantProps) {
    return (
        // <WithAccessScope scope={AccessScope.OPERATOR} fallback={<HolderTit id={owner} />}>
        <HolderLink id={owner} />
        // </WithAccessScope>
    );
}

function RoamingVariant({ owner }: RoamingVariantProps) {
    return (
        <WithAccessScope scope={AccessScope.OPERATOR} fallback={<RoamingTitle id={owner} />}>
            <RoamingLink id={owner} />
        </WithAccessScope>
    );
}

function TransactionVariant({ owner }: TransactionVariantProps) {
    return (
        <WithAccessScope scope={AccessScope.OPERATOR} fallback={<RoamingTitle id={owner} />}>
            <WithCachedTransaction id={owner}>
                {transaction => transaction && <TransactionLink humanId={transaction.transactionId} />}
            </WithCachedTransaction>
        </WithAccessScope>
    );
}

function ZeroVariant() {
    return (
        <WithAccessScope scope={AccessScope.OPERATOR} fallback={<>ZERO</>}>
            <>ZERO</>
        </WithAccessScope>
    );
}

interface OwnerLinkProps {
    type: OwnerType;
    owner: string;
    customerLinkProps?: {
        displayEMSP: boolean;
    };
}
export function OwnerLink({ type, owner, customerLinkProps }: OwnerLinkProps) {
    switch (type) {
        case 'customer':
            return <CustomerVariant owner={owner} {...customerLinkProps} />;
        case 'user':
            return <AdministratorVariant owner={owner} />;
        case 'roaming-emsp':
        case 'roaming-cpo':
            return <RoamingVariant owner={owner} />;
        case 'transaction':
            return <TransactionVariant owner={owner} />;
        case 'zero':
            return <ZeroVariant />;
        case 'corporate':
            return <CorporateVariant owner={owner} {...customerLinkProps} />;
        case 'system':
            return <SystemTitle />;
        case 'holder':
            // TODO add holder link
            return <HolderVariant owner={owner} />;
        default:
            exhaustiveCheck(type);
    }
}
