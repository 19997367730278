import { LoadingButton } from '@mui/lab';
import {
    Button,
    DialogContentText,
    FormControl,
    FormHelperText,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useState } from 'react';
import create from 'zustand';
import { useRegistryEditor } from '../../../services/GlobalRegistryEditor';
import { useChargePoint, WithChargePoint } from '../../wrappers/WithChargePoint';
import {useChargeLocation, WithChargeLocation} from "../../wrappers/WithChargeLocation";

type FormValues = {
    infoMessage: string;
};

type Store = {
    open: boolean;
    chargeLocationId: string;
    show: (chargePointId: string) => void;
    hide: () => void;
};

export const useChargeLocationEditInfoMessageDialog = create<Store>((set, get) => ({
    open: false,
    chargeLocationId: '',
    show: (chargeLocationId: string) => set({ open: true, chargeLocationId: chargeLocationId }),
    hide: () => set({ open: false, chargeLocationId: '' }),
}));

function DialogInternal() {
    const open = useChargeLocationEditInfoMessageDialog(store => store.open);
    const hide = useChargeLocationEditInfoMessageDialog(store => store.hide);
    // const humanTransactionId = useChargePointChangeStateDialog(store => store.humanTransactionId);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');
    const chargeLocation = useChargeLocation();
    const updateChargeLocation = useRegistryEditor(store => store.updateChargeLocation);

    const formik = useFormik<FormValues>({
        initialValues: {
            infoMessage: chargeLocation.infoMessage ?? '',
        },
        onSubmit: async values => {
            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);

            updateChargeLocation(chargeLocation, { infoMessage: values.infoMessage });
            hide();

            return;
        },
    });

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Изменение общедоступного комментария</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ paddingBottom: 1 }}></DialogContentText>
                <form onSubmit={formik.handleSubmit}>
                    <FormControl fullWidth>
                        <TextField
                            autoFocus
                            id="infoMessage"
                            label="Комментарий"
                            variant="outlined"
                            fullWidth
                            multiline
                            minRows={6}
                            // maxRows={20}
                            spellCheck={false}
                            value={formik.values.infoMessage}
                            onChange={formik.handleChange}
                            error={formik.touched.infoMessage && Boolean(formik.errors.infoMessage)}
                            helperText={formik.touched.infoMessage && formik.errors.infoMessage}
                        />
                    </FormControl>
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Изменить</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export function ChargePointEditInfoMessageDialog() {
    const chargePointId = useChargeLocationEditInfoMessageDialog(store => store.chargeLocationId);

    return (
        <WithChargeLocation id={chargePointId}>
            <DialogInternal />
        </WithChargeLocation>
    );
}
