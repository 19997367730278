import { AccessScope } from '@electrifly/central-client-api/data';
import { LoadingButton } from '@mui/lab';
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Collapse,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
    colors,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormikProps, useFormik } from 'formik';
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import create from 'zustand';
import { useAuthContext } from '../../../auth/AuthContext';
import { API } from '../../../core/api-client';
import {
    createCustomerSuggestionStore,
    CustomerSuggestionContext,
} from '../../customers/services/CustomerSuggestionContext';
import { WithAccessScope } from '../../wrappers/WithAccessScope';
import { DataWrap } from '../../event-log/components/DataWrap';
import { OperatorName } from '../../operators/elements/OperatorName';

type Store = {
    open: boolean;
    chargePointId: string;
    connectorId: string;
    show: (chargePointId: string, connectorId: string) => void;
    hide: () => void;
};

export const useChargePointRemoteStartDialog = create<Store>((set, get) => ({
    open: false,
    chargePointId: '',
    connectorId: '',
    show: (chargePointId: string, connectorId: string) => set({ open: true, chargePointId, connectorId }),
    hide: () => set({ open: false, chargePointId: '', connectorId: '' }),
}));

function CustomerSelector() {
    const { formik } = useContext(FormContext);
    const inputRef = useRef<HTMLInputElement>();

    const loading = CustomerSuggestionContext.useStore(store => store.loading);
    const setSearch = CustomerSuggestionContext.useStore(store => store.setSearch);
    const customersInfo = CustomerSuggestionContext.useStore(store => store.customers);

    const selectedValue = useMemo(
        () => customersInfo.find(item => item._id === formik.values.customer),
        [customersInfo, formik.values.customer],
    );

    useEffect(() => {
        if (formik.values.type === 'customer') {
            inputRef.current?.focus();
        }
    }, [formik.values.type, inputRef]);

    return (
        <Stack spacing={1}>
            <FormControl fullWidth>
                <Autocomplete
                    sx={{ minWidth: '15em' }}
                    fullWidth
                    options={customersInfo}
                    noOptionsText="Клиент не найден"
                    value={selectedValue || null} //null is neede for initialization render in controlled state
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    getOptionLabel={option => option.phone}
                    renderOption={(props, option, state) => (
                        <li {...props} key={option._id}>
                            <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
                                {option.operator && (
                                    <DataWrap color={colors.grey[200]}>
                                        <OperatorName id={option.operator} />
                                    </DataWrap>
                                )}
                                <Typography>{option.phone}</Typography>
                            </Stack>
                        </li>
                    )}
                    onInputChange={(event, newValue) => setSearch(newValue)}
                    onChange={(event, val) => formik.setValues(values => ({ ...values, customer: val?._id }))}
                    renderInput={params => (
                        <TextField
                            {...params}
                            inputRef={inputRef}
                            autoFocus
                            variant="outlined"
                            placeholder="+79990000000"
                            error={formik.touched.customer && Boolean(formik.errors.customer)}
                            helperText={formik.touched.customer && formik.errors.customer}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        {selectedValue?.operator && (
                                            <DataWrap color={colors.grey[200]}>
                                                <OperatorName id={selectedValue.operator} />
                                            </DataWrap>
                                        )}
                                    </>
                                ),
                                endAdornment: (
                                    <React.Fragment>
                                        {loading && <CircularProgress color="inherit" size={20} />}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </FormControl>
        </Stack>
    );
}

function ContraintsBlock() {
    const { formik } = useContext(FormContext);
    const isConstrainsActive = formik.values.constraints;

    return (
        <Box sx={{ marginY: 1 }}>
            <Button
                variant="outlined"
                fullWidth
                onClick={() => formik.setValues(values => ({ ...values, constraints: !isConstrainsActive }))}
            >
                {isConstrainsActive ? 'Убрать лимиты' : 'лимиты'}
            </Button>

            <Collapse in={isConstrainsActive}>
                <Stack spacing={1} sx={{ marginTop: 2 }} component={FormControl}>
                    <TextField
                        label="Энергия | кВт•ч"
                        variant="outlined"
                        type="number"
                        value={formik.values.constraintKWH}
                        onChange={event =>
                            formik.setValues(values => ({
                                ...values,
                                constraintKWH: event.target.value ? Number(event.target.value) : undefined,
                            }))
                        }
                    />
                    <TextField
                        label="Длительность | минуты"
                        variant="outlined"
                        type="number"
                        value={formik.values.constraintMinutes}
                        onChange={event =>
                            formik.setValues(values => ({
                                ...values,
                                constraintMinutes: event.target.value ? Number(event.target.value) : undefined,
                            }))
                        }
                    />
                    <TextField
                        label="SoC | 0-100 %"
                        variant="outlined"
                        type="number"
                        value={formik.values.constraintSOC}
                        onChange={event =>
                            formik.setValues(values => ({
                                ...values,
                                constraintSOC: event.target.value ? Number(event.target.value) : undefined,
                            }))
                        }
                    />
                </Stack>
            </Collapse>
        </Box>
    );
}

function FormInternal() {
    const { formik } = useContext(FormContext);
    const user = useAuthContext(store => store.profile);

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
                <List>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                    >
                        <ListItemButton
                            onClick={() => formik.setValues(values => ({ ...values, type: 'administrator' }))}
                        >
                            <ListItemText
                                primary={`От имени Администратора`}
                                secondary={
                                    <span>
                                        Запуск сессии от имени <strong>{user.email}</strong>
                                    </span>
                                }
                            />
                            <ListItemSecondaryAction>
                                <Radio checked={formik.values.type === 'administrator'} />
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        <WithAccessScope scope={AccessScope.OPERATOR}>
                            <ListItemButton
                                onClick={() => formik.setValues(values => ({ ...values, type: 'customer' }))}
                            >
                                <ListItemText
                                    primary={`От имени Клиента`}
                                    secondary={`Запуск сессии от имени автовладельца`}
                                />
                                <ListItemSecondaryAction>
                                    <Radio checked={formik.values.type === 'customer'} />
                                </ListItemSecondaryAction>
                            </ListItemButton>
                        </WithAccessScope>
                    </RadioGroup>
                </List>
            </FormControl>
            <Collapse in={formik.values.type === 'customer'}>
                <CustomerSuggestionContext.Provider createStore={() => createCustomerSuggestionStore()}>
                    <CustomerSelector />
                </CustomerSuggestionContext.Provider>
            </Collapse>
            <Divider sx={{ marginY: 1 }} />
            <ContraintsBlock />
        </form>
    );
}
interface FormData {
    type: 'administrator' | 'customer';
    customer?: string; //Used only if type == 'customer'
    constraints: boolean;
    constraintKWH?: number;
    constraintMinutes?: number;
    constraintSOC?: number;
}

interface FormContextType {
    formik: FormikProps<FormData>;
}

export const FormContext = createContext<FormContextType>({} as FormContextType);

export function DialogInternal() {
    const open = useChargePointRemoteStartDialog(store => store.open);
    const hide = useChargePointRemoteStartDialog(store => store.hide);
    const chargePointId = useChargePointRemoteStartDialog(store => store.chargePointId);
    const connectorId = useChargePointRemoteStartDialog(store => store.connectorId);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<FormData>({
        initialValues: {
            type: 'administrator',
            customer: '',
            constraints: false,
        },

        onSubmit: async values => {
            console.log(values);
            if (values.type === 'customer') {
                return startFromCustomer(values);
            }
            return startFromUser(values);
        },
    });

    const startFromUser = async (values: FormData) => {
        setDisplayError(false);
        setIsRequesting(true);
        const [error, res] = await API.remoteStart(chargePointId, {
            connectorId: connectorId,
            ...(values.constraints && {
                constraintKWH: values.constraintKWH,
                constraintMinutes: values.constraintMinutes,
                constraintSOC: values.constraintSOC,
            }),
        });
        setIsRequesting(false);
        if (!error) {
            hide();
            formik.resetForm();
            return;
        }
        setDisplayError(true);
        console.log(error.response?.data);
        setError(error.response?.data.message || 'Неизвестная ошибка');
        return;
    };

    const startFromCustomer = async (values: FormData) => {
        if (!values.customer) {
            setDisplayError(true);
            setError('Выберите клиента');
            return;
        }

        setDisplayError(false);
        setIsRequesting(true);
        const [error, res] = await API.remoteStartFromCustomer(chargePointId, {
            connectorId: connectorId,
            customerId: values.customer,
            ...(values.constraints && {
                constraintKWH: values.constraintKWH,
                constraintMinutes: values.constraintMinutes,
                constraintSOC: values.constraintSOC,
            }),
        });
        setIsRequesting(false);
        if (!error) {
            hide();
            formik.resetForm();
            return;
        }
        setDisplayError(true);
        setError(error.response?.data.message || 'Неизвестная ошибка');
        return;
    };

    return (
        <>
            <DialogTitle>Запрос на старт сессии</DialogTitle>
            <DialogContent>
                <FormContext.Provider value={{ formik }}>
                    <FormInternal />
                </FormContext.Provider>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Отправить команду</span>
                </LoadingButton>
            </DialogActions>
        </>
    );
}

export function ChargePointRemoteStartDialog() {
    const open = useChargePointRemoteStartDialog(store => store.open);
    const hide = useChargePointRemoteStartDialog(store => store.hide);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogInternal />
        </Dialog>
    );
}
