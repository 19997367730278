import {
    ChargeLocation,
    ChargePoint,
    Connector,
    EVSE,
    RoamingConnector,
    Transaction,
} from '@electrifly/central-client-api';
import _ from 'lodash';
import { useMemo } from 'react';
import { useRegistry } from '../services/GlobalRegistry';

// Optimistic extract chargePoint bu connector
export function useChargePointByConnector(connector: Connector): ChargePoint {
    const evse = useRegistry(store => store.evses[connector.evse]);
    const chargePoint = useRegistry(store => store.chargePoints[evse.chargePoint]);
    return chargePoint;
}

// Optimistic extract chargeLocation bu connector
export function useLocationByConnector(connector: Connector): ChargeLocation {
    const evse = useRegistry(store => store.evses[connector.evse]);
    const chargePoint = useRegistry(store => store.chargePoints[evse.chargePoint]);
    const chargeLocation = useRegistry(store => store.chargeLocations[chargePoint.location]);
    return chargeLocation;
}

// Optimistic extract connector by sequential number from chargePoint
export function useConnectorBySeqNumber(chargePoint: ChargePoint, connectorNumber: number): Connector {
    const connectorId = useMemo(() => {
        const connectros = _.chain(chargePoint.evses)
            .map(evse => evse.connectors)
            .flatten()
            .value();

        return connectros[connectorNumber - 1]._id;
    }, [chargePoint, connectorNumber]);

    return useRegistry(store => store.connectors[connectorId]);
}

export function useSeqNumberByConnector(chargePoint: ChargePoint, connector: Connector): number {
    return useMemo(() => {
        if (!chargePoint) {
            return -1;
        }
        const connectros = _.chain(chargePoint.evses)
            .map(evse => evse.connectors)
            .flatten()
            .value();

        const index = connectros.findIndex(item => connector._id === item._id);
        return index === -1 ? -1 : index + 1;
    }, [chargePoint, connector]);
}

export function useTransactionIdsByConnector(connector: Connector): Transaction[] {
    const chargePoint = useChargePointByConnector(connector);
    const connectorSeqNumber = useSeqNumberByConnector(chargePoint, connector);
    const transactions = useRegistry(store => store.transactions);

    return useMemo(() => {
        if (!chargePoint) {
            return [];
        }

        return transactions.filter(
            transaction =>
                transaction.chargePoint === chargePoint._id && transaction.connectorSeqNumber === connectorSeqNumber,
        );
    }, [transactions, chargePoint, connectorSeqNumber]);
}

export function useTransactionByRoamingConnector(connector: RoamingConnector): Transaction[] {
    const transactions = useRegistry(store => store.transactions);

    return useMemo(() => {
        return transactions.filter(transaction => transaction.connector === connector._id);
    }, [transactions, connector._id]);
}

export function useEVSENumber(evse: EVSE): number {
    const chargePoint = useRegistry(store => store.chargePoints[evse.chargePoint]);
    const index = useMemo(
        () => chargePoint.evses.findIndex(item => item._id === evse._id),
        [chargePoint.evses, evse._id],
    );
    return index + 1;
}

export function useConnectorNumber(connector: Connector): number {
    const evse = useRegistry(store => store.evses[connector.evse]);
    const index = useMemo(
        () => evse.connectors.findIndex(item => item._id === connector._id),
        [connector._id, evse.connectors],
    );
    return index + 1;
}
