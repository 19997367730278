import { ListItemText, Popover, ListItemTextProps, Box } from '@mui/material';
import _ from 'lodash';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import React, { useMemo } from 'react';
import { EditForm, EditFormVariant } from './EditForm';
import { EditableValueOption } from './forms/EditableValueOption';

interface ListItemTextClickableProps<
    T extends string | number,
    PrimaryTypographyComponent extends React.ElementType = 'span',
    SecondaryTypographyComponent extends React.ElementType = 'p',
> extends ListItemTextProps<PrimaryTypographyComponent, SecondaryTypographyComponent> {
    value: T;
    dimensionText?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}
export function ListItemTextClickable<T extends string | number>({
    value,
    dimensionText,
    onClick,
    ...props
}: ListItemTextClickableProps<T>) {
    const displayedText = useMemo(() => {
        const stage1 = value || String.fromCharCode(160).repeat(20);
        if (dimensionText) {
            return `${stage1} ${dimensionText}`.trim();
        }
        return `${stage1}`;
    }, [value, dimensionText]);

    return (
        <Box
            sx={{
                marginRight: -0.75,
                paddingX: 1.5,
                marginX: -1.5,
                paddingY: 0.25,
                marginY: -0.25,
                borderRadius: 1,
                ':hover': { backgroundColor: '#eee' },
            }}
        >
            <ListItemText {...props} primary={displayedText} onClick={onClick} />
        </Box>
    );
}

interface EditableValueProps<T extends string | number> {
    value: T;
    valueText?: (value: T) => string;
    dimensionText?: string;
    formVariant?: EditFormVariant;
    options?: EditableValueOption<T>[];
    onNewValue?: (value: T) => void;
}
export function EditableValue<T extends string | number>({
    value,
    valueText,
    dimensionText,
    formVariant,
    options = [],
    onNewValue: onNewAmount,
}: EditableValueProps<T>) {
    const displayText = valueText ? valueText(value) : `${value}`.trim();

    return (
        <PopupState variant="popover">
            {popupState => (
                <React.Fragment>
                    <ListItemTextClickable
                        value={displayText ?? String.fromCharCode(160).repeat(20)}
                        dimensionText={dimensionText}
                        {...bindTrigger(popupState)}
                        onClick={event => {
                            const selection = window.getSelection && window.getSelection();
                            if (selection && selection.type !== 'Range') {
                                popupState.setOpen(true, event);
                            }
                        }}
                    />
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
                    >
                        <EditForm
                            initialValue={value}
                            endAdornmentText={dimensionText}
                            type={formVariant}
                            options={options}
                            onCancel={popupState.close}
                            onSave={value => onNewAmount && onNewAmount(value)}
                        />
                    </Popover>
                </React.Fragment>
            )}
        </PopupState>
    );
}
