import { RFIDTagType, TokenType } from '@electrifly/central-client-api';
import { Tooltip } from '@mui/material';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import SmartphoneRoundedIcon from '@mui/icons-material/SmartphoneRounded';
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';
import CableRoundedIcon from '@mui/icons-material/CableRounded';
import ContactlessRoundedIcon from '@mui/icons-material/ContactlessRounded';
import PinRoundedIcon from '@mui/icons-material/PinRounded';
import NoAccountsRoundedIcon from '@mui/icons-material/NoAccountsRounded';
import QuizRoundedIcon from '@mui/icons-material/QuizRounded';
import { exhaustiveCheck } from '../../../misc/ExhaustiveCheck';

function OperatorIcon() {
    return (
        <Tooltip title="Оператор" enterDelay={500}>
            <PersonRoundedIcon fontSize="small" color="inherit" />
        </Tooltip>
    );
}

function RFIDCardIcon() {
    return (
        <Tooltip title="RFID-метка" enterDelay={500}>
            <ContactlessRoundedIcon fontSize="small" color="inherit" />
        </Tooltip>
    );
}

function MobileIcon() {
    return (
        <Tooltip title="Мобильное приложение" enterDelay={500}>
            <SmartphoneRoundedIcon fontSize="small" color="inherit" />
        </Tooltip>
    );
}

function FreevendIcon() {
    return (
        <Tooltip title="Freevend" enterDelay={500}>
            <CableRoundedIcon fontSize="small" color="inherit" />
        </Tooltip>
    );
}

interface Props {
    type: TokenType;
}
export function TagTypeIcon({ type }: Props) {
    switch (type) {
        case 'app':
            return <MobileIcon />;
        case 'rfid':
            return <RFIDCardIcon />;
        case 'one-time':
            return <PinRoundedIcon />;
        case 'other':
            return <QuizRoundedIcon />;
        case 'freevend':
            return <FreevendIcon />;
        default:
            exhaustiveCheck(type);
    }
}
