import * as yup from 'yup';
import { Billing } from '../../data';

export type ReqQuery = {
    skip?: number;
    limit?: number;
    account?: string;
};

export type ResData = Billing[];

export const validationSchema = yup.object({
    skip: yup.number().optional().min(0).default(0),
    limit: yup.number().optional().min(1).max(1000).default(100),
    account: yup.string().optional(),
});
