import {
    Box,
    Container,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Paper,
    Stack,
    TextField,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAuthContext } from './AuthContext';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const validationSchema = yup.object({
    email: yup.string().email('Enter a valid email').required('Email is required'),
    password: yup.string().required('Password is required'),
});

function LoginForm() {
    const login = useAuthContext(store => store.login);
    const navigate = useNavigate();

    const [isRequesting, setIsRequesting] = useState(false);
    const [error, setError] = useState('');
    const [displayError, setDisplayError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,

        onSubmit: async values => {
            setDisplayError(false);
            setIsRequesting(true);

            const error = await login(values.email, values.password);

            setIsRequesting(false);

            if (!error) {
                navigate('/', { replace: true });
                return;
            }

            setDisplayError(true);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack direction={'column'} spacing={2} component={Paper} sx={{ p: 2 }}>
                <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Логин"
                    margin="dense"
                    variant="standard"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />

                <FormControl fullWidth variant="standard">
                    <InputLabel>Пароль</InputLabel>
                    <Input
                        fullWidth
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        margin="dense"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        endAdornment={
                            <InputAdornment position="end" sx={{ marginX: 1 }}>
                                <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={event => event.preventDefault()}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText error={formik.touched.password && Boolean(formik.errors.password)}>
                        {formik.errors.password}
                    </FormHelperText>
                </FormControl>

                <FormHelperText error={displayError} sx={{ textAlign: 'center', color: '#d32f2f' }}>
                    {error}
                </FormHelperText>

                <LoadingButton
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    size="large"
                    loadingPosition="center"
                    loading={isRequesting}
                >
                    <span>Войти</span>
                </LoadingButton>
            </Stack>
        </form>
    );
}

export default function LoginPage() {
    return (
        <Container>
            <Grid container spacing={0} alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
                <Grid item lg={6} xs={12}>
                    <LoginForm />
                </Grid>
            </Grid>
        </Container>
    );
}
