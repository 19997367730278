import { Link, Skeleton } from '@mui/material';
import { RouterLink } from '../../../elements/RouterLink';
import { useRegistry } from '../../../services/GlobalRegistry';
import { useParams } from 'react-router-dom';

interface ChargePointLinkProps {
    id: string;
}

export function ChargePointLink({ id }: ChargePointLinkProps) {
    const chargePoint = useRegistry(store => store.chargePoints[id]);
    let { operatorId } = useParams();

    if (!chargePoint) {
        return <Skeleton width={100} />;
    }
    return (
        <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={`/${operatorId}/chargepoint/${id}`}
            onClick={event => event.stopPropagation()}
        >
            {chargePoint?.physicalReference || id}
        </Link>
    );
}
