import * as yup from 'yup';
import * as OCPI from '@electrifly/ocpi';
import { ChargePoint } from '../../data/infrastructure/ChargeLocation';

interface ConnectorFormData {
    power: number;
    standard: OCPI.V221.Types.ConnectorType;
    format: OCPI.V221.Types.ConnectorFormat;
    powerType: OCPI.V221.Types.PowerType;
}

export type ReqBody = {
    location: string;
    name: string;
    connectors: ConnectorFormData[];
};

export type ResData = ChargePoint;

const connectorSchema = yup.object({
    power: yup.number().required('Поле мощность должно быть заполнено').min(0, 'Мощность должна быть больше 0'),
    standard: yup.mixed<OCPI.V221.Types.ConnectorType>().required().oneOf(Object.values(OCPI.V221.Types.ConnectorType)),
    format: yup
        .mixed<OCPI.V221.Types.ConnectorFormat>()
        .required()
        .oneOf(Object.values(OCPI.V221.Types.ConnectorFormat)),
    powerType: yup.mixed<OCPI.V221.Types.PowerType>().required().oneOf(Object.values(OCPI.V221.Types.PowerType)),
});

export const validationSchema = yup.object({
    name: yup.string().optional(),
    connectors: yup
        .array<ConnectorFormData>()
        .of(connectorSchema)
        .min(1, 'У зарядной станции должено быть не менее 1 коннектора'),
});
