import {
    Alert,
    AlertTitle,
    Box,
    Button,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Stack,
} from '@mui/material';
import { Title } from '../../elements/Title';
import { useManualTransactionSubmitDialog } from './dialog/ManualTransactionSubmitDialog';
import { useTransactionChangePriceDialog } from './dialog/TransactionChangePriceDialog';

export function PageHeader() {
    return (
        <Stack direction={'row'} spacing={1} sx={{ mb: 4, alignItems: 'center' }}>
            <Title sx={{ flex: 1 }}>Специальные функции</Title>
        </Stack>
    );
}

export default function SpecialPage() {
    const showChangePrice = useTransactionChangePriceDialog(store => store.show);
    const showManualTransaction = useManualTransactionSubmitDialog(store => store.show);

    return (
        <Box sx={{ pb: 10 }}>
            <PageHeader />
            <Stack spacing={2}>
                <Alert severity="error" icon={null} sx={{ display: 'flex', width: '100%' }}>
                    <AlertTitle>Используйте данные функции под свою ответственность.</AlertTitle>Данные фукции
                    предназначены для ручного реагирования на нестандартные проблемы клиентов.
                </Alert>
                <List disablePadding component={Paper}>
                    <ListItem divider>
                        <ListItemText
                            primary="Создание транзакции"
                            secondary="Ручное добавление транзакции в историю"
                        />
                        <ListItemSecondaryAction>
                            <Button fullWidth variant="contained" onClick={() => showManualTransaction()}>
                                Создать
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider>
                        <ListItemText
                            primary="Изменить тариф транзакции"
                            secondary="Изменение тарифа активной транзакции"
                        />
                        <ListItemSecondaryAction>
                            <Button fullWidth variant="contained" onClick={() => showChangePrice()}>
                                Изменить
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Stack>
        </Box>
    );
}
