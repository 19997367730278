import { Alert, AlertTitle, Box, List, ListItem, ListItemText, Stack } from '@mui/material';
import { useChargePoint } from '../wrappers/WithChargePoint';
import { useMemo } from 'react';
import urlJoin from 'url-join';

export function ConnectionInfoBlock() {
    const chargePoint = useChargePoint();

    const connectionString = useMemo(
        () => urlJoin(process.env.REACT_APP_OCPP_WS_BASE_URL!, chargePoint.chargeBoxIdentity),
        [],
    );

    return (
        <Stack spacing={2}>
            <Alert severity="info" sx={{ display: 'flex', width: '100%' }}>
                <AlertTitle>Адрес подключения зарядной станции</AlertTitle>
                {connectionString}
            </Alert>

            <Alert severity="info" icon={null} sx={{ display: 'flex', width: '100%' }}>
                Обратитесь в поддержку если станция не поддерживает соединение через TLS/SSL или для станции нужен адрес
                подключения по SOAP.
            </Alert>
        </Stack>
    );
}
