import { Box, Button, Chip, DialogActions, Popover, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import _ from 'lodash';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React, { useMemo, useRef } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { usePopupStateInternal, WithPopupState } from '../../../../../../elements/WithPopupState';
import { useCustomerTitle } from '../../../../../customers/elements/CustomerTitle';
import { ActionEventListPageService } from '../../ListPageService';
import { DateRange, LocalizationProvider, PickersShortcutsItem, StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { DateTime } from 'luxon';
import shallow from 'zustand/shallow';
import { DateTimeHelpers } from '../../../../../../misc/DateTimeHelpers';

type DateFormRange = { rangeStart?: number; rangeEnd?: number };

const shortcutsItems: PickersShortcutsItem<DateRange<DateTime>>[] = [
    {
        label: 'Вчера',
        getValue: () => DateTimeHelpers.ranges.yesterday(),
    },
    {
        label: 'Текущая неделя',
        getValue: () => DateTimeHelpers.ranges.currentWeek(),
    },
    {
        label: 'Предыдущая неделя',
        getValue: () => DateTimeHelpers.ranges.lastWeek(),
    },
    {
        label: 'Предыдущие 7 дней',
        getValue: () => DateTimeHelpers.ranges.last7Days(),
    },
    {
        label: 'Текущий месяц',
        getValue: () => DateTimeHelpers.ranges.currentMonth(),
    },
    {
        label: 'Предыдущий месяц',
        getValue: () => DateTimeHelpers.ranges.lastMonth(),
    },
    { label: 'Сброс', getValue: () => [null, null] },
];

interface AutocompleteTypeFormProps {
    initialValue: DateFormRange;
    onCancel: () => void;
    onSave: (value: DateFormRange) => void;
}

export function DateForm({ initialValue, onCancel, onSave }: AutocompleteTypeFormProps) {
    const [value, setValue] = React.useState<DateRange<DateTime>>([
        initialValue.rangeStart ? DateTime.fromMillis(initialValue.rangeStart) : null,
        initialValue.rangeEnd ? DateTime.fromMillis(initialValue.rangeEnd) : null,
    ]);

    const formik = useFormik<Partial<DateFormRange>>({
        initialValues: {
            // rangeStart: '',
            // rangeEnd: '',
        },

        onSubmit: async values => {
            onSave({ rangeStart: values.rangeStart, rangeEnd: values.rangeEnd });
            onCancel();
        },
    });

    const onNewDate = (newValue: DateRange<DateTime>) => {
        console.log(newValue);
        formik.setValues(values => {
            const [rangeStart, rangeEnd] = newValue;

            return {
                ...values,
                rangeStart: rangeStart?.toMillis(),
                rangeEnd: rangeEnd?.toMillis(),
            };
        });
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack direction={'column'} sx={{ p: 1 }} spacing={1}>
                <LocalizationProvider
                    dateAdapter={AdapterLuxon}
                    adapterLocale={'ru'}
                    localeText={{ start: 'Начало', end: 'Окончание' }}
                >
                    <StaticDateRangePicker
                        displayStaticWrapperAs="desktop"
                        value={value}
                        onChange={newValue => {
                            setValue(newValue);
                            onNewDate(newValue);
                        }}
                        slotProps={{
                            toolbar: { hidden: false },
                            shortcuts: { items: shortcutsItems },
                        }}
                        localeText={{
                            toolbarTitle: 'Выберите диапазон времени',
                        }}
                    />
                </LocalizationProvider>
                <DialogActions>
                    <Button onClick={onCancel}>Закрыть</Button>
                    <Button type="submit">Применить</Button>
                </DialogActions>
            </Stack>
        </form>
    );
}

function SelectorInternal() {
    const setFilter = ActionEventListPageService.useStore(store => store.setFilter);
    const [rangeStart, rangeEnd] = ActionEventListPageService.useStore(
        store => [store.filter.rangeStart, store.filter.rangeEnd],
        shallow,
    );

    const popupState = usePopupStateInternal();

    return (
        <DateForm
            initialValue={{ rangeStart, rangeEnd }}
            onCancel={popupState.close}
            onSave={value => {
                setFilter({
                    rangeStart: value.rangeStart,
                    rangeEnd: value.rangeEnd,
                });
            }}
        />
    );
}

function SelectorChip() {
    const popupState = usePopupStateInternal();

    const setFilter = ActionEventListPageService.useStore(store => store.setFilter);
    const setFilterVisibility = ActionEventListPageService.useStore(store => store.setFilterVisibility);
    const [rangeStart, rangeEnd] = ActionEventListPageService.useStore(
        store => [store.filter.rangeStart, store.filter.rangeEnd],
        shallow,
    );

    const dateText = useMemo(() => {
        const rangeStartText = rangeStart
            ? DateTime.fromMillis(rangeStart).toFormat('dd.LL.yyyy', { locale: 'ru' })
            : '∞';
        const rangeEndText = rangeEnd ? DateTime.fromMillis(rangeEnd).toFormat('dd.LL.yyyy', { locale: 'ru' }) : '∞';
        return `${rangeStartText} – ${rangeEndText}`;
    }, [rangeEnd, rangeStart]);

    const ref = useRef<HTMLDivElement>(null);

    useEffectOnce(() => {
        if (!ref.current) {
            return;
        }
        popupState.setOpen(true, ref.current);
    });

    return (
        <Chip
            ref={ref}
            variant="outlined"
            sx={{ maxWidth: 250 }}
            label={<Typography fontSize="inherit">Дата: {dateText || ''}</Typography>}
            {...bindTrigger(popupState)}
            onClick={event => {
                const selection = window.getSelection && window.getSelection();
                if (selection && selection.type !== 'Range') {
                    popupState.setOpen(true, event);
                }
            }}
            onDelete={() => {
                setFilterVisibility({ type: 'date', visibility: false });
                setFilter({ rangeStart: undefined, rangeEnd: undefined });
            }}
        />
    );
}

export function DateSelector() {
    return (
        <WithPopupState>
            {popupState => (
                <>
                    <SelectorChip />

                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                        <SelectorInternal />
                    </Popover>
                </>
            )}
        </WithPopupState>
    );
}
