import * as yup from 'yup';
import { Corporate } from '../../data';

export type ReqQuery = {
    filter?: string;
    skip?: number;
    limit?: number;
};

export type ResData = Corporate[];

export const validationSchema = yup.object({
    filter: yup.string().optional().max(100),
    skip: yup.number().optional().min(0).default(0),
    limit: yup.number().optional().min(1).max(1000).default(100),
});
