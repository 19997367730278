import { BillingStatus } from '@electrifly/central-client-api';
import { colors } from '@mui/material';

const BILLING_STATUS_COLORS: Record<BillingStatus, string> = {
    Created: colors.grey[200],
    Exipired: colors.yellow[200],
    Authorized: colors.blue[200],
    Rejected: colors.red[200],
    Confirmed: colors.green[200],
    Canceled: colors.grey[200],
    Refunded: colors.purple[200],
    PartialRefunded: colors.purple[200],
};

export function useIndicatorColor(status: BillingStatus): string {
    return BILLING_STATUS_COLORS[status];
}
