import * as yup from 'yup';
import { RoamingXP } from '../../data';

export type ReqBody = { _id: string };
export type ResData = {
    token: string;
    versionInfoURL: string;
};

export const validationSchema = yup.object({
    _id: yup.string().required('Поле _id должно быть заполнено'),
});
