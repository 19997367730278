import moment from 'moment';
import { useMemo } from 'react';

interface DateTimeProps {
    time?: string; // ISO time
}

export function DateTime({ time }: DateTimeProps) {
    const formatted = useMemo(() => moment(time).format('DD.MM.YYYY HH:mm:ss'), [time]);
    return <>{formatted}</>;
}
