import { LoadingButton } from '@mui/lab';
import { Button, FormHelperText, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useState } from 'react';
import create from 'zustand';
import { API } from '../../../core/api-client';

type Store = {
    open: boolean;
    chargePointId: string;
    connectorId: string;
    show: (chargePointId: string, connectorId: string) => void;
    hide: () => void;
};

export const useConnectorRemoveDialog = create<Store>((set, get) => ({
    open: false,
    chargePointId: '',
    connectorId: '',
    show: (chargePointId: string, connectorId: string) => set({ open: true, chargePointId, connectorId }),
    hide: () => set({ open: false, chargePointId: '', connectorId: '' }),
}));

export function ConnectorRemoveDialog() {
    const open = useConnectorRemoveDialog(store => store.open);
    const hide = useConnectorRemoveDialog(store => store.hide);
    const chargePointId = useConnectorRemoveDialog(store => store.chargePointId);
    const connectorId = useConnectorRemoveDialog(store => store.connectorId);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik({
        initialValues: {
            // hard: false,
        },

        onSubmit: async values => {
            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.chargePointConnectorRemove(chargePointId, { connectorId: connectorId });
            setIsRequesting(false);
            if (!error) {
                hide();
                formik.resetForm();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Удаление коннектора</DialogTitle>
            <DialogContent>
                <DialogContentText>После удаления коннектора, его восстановление невозможно.</DialogContentText>
                {/* <form onSubmit={formik.handleSubmit}>
                    <FormControlLabel
                        control={<Switch checked={formik.values.hard} onChange={formik.handleChange} name="hard" />}
                        label="Hard"
                    />
                </form> */}
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Удалить</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
