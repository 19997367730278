import { ChargePoint, ChargePointState, Transaction } from '@electrifly/central-client-api';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import BatteryCharging50RoundedIcon from '@mui/icons-material/BatteryCharging50Rounded';
import EvStationIcon from '@mui/icons-material/EvStation';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
import {
    Badge,
    Box,
    Button,
    Chip,
    Collapse,
    Divider,
    Fab,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    Menu,
    MenuItem,
    Paper,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import moment from 'moment';
import React, { useMemo } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { RouterLink } from '../../elements/RouterLink';
import { useConnectorNumber, useEVSENumber, useTransactionIdsByConnector } from '../../hooks/chargePointHooks';
import { useRegistryEditor } from '../../services/GlobalRegistryEditor';
import { ConnectorTurnedOffChip } from '../connector/ConnectorTurnedOffChip';
import { ConnectorTypeText } from '../connector/Standard';
import { useChargePoint } from '../wrappers/WithChargePoint';
import { useConnector, WithConnector } from '../wrappers/WithConnector';
import { useEVSE, WithEVSE } from '../wrappers/WithEVSE';
import { ConnectionInfoBlock } from './ConnectionInfoBlock';
import { useChargePointChangeAvailabilityDialog } from './dialogs/ChargePointChangeAvailabilityDialog';
import { useChargePointClearCacheDialog } from './dialogs/ChargePointClearCache';
import { useChargePointRemoteStartDialog } from './dialogs/ChargePointRemoteStart';
import { useChargePointRemoteStopDialog } from './dialogs/ChargePointRemoteStop';
import { useChargePointResetDialog } from './dialogs/ChargePointReset';
import { useConnectorUnlockDialog } from './dialogs/ConnectorUnlock';
import { EventLogComponent } from './logs/ChargePointLogs';
import { ConnectionStatus } from './status/ConnectionStatus';
import { ConnectorStatus } from './status/ConnectorStatus';
import { useChargePointEditCommentDialog } from './dialogs/ChargePointEditCommentDialog';
import {CommentInfoBlock, InfoMessageBlockForChargeLocation} from './CommentInfoBlock';
import _ from 'lodash';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import WidgetsRoundedIcon from '@mui/icons-material/WidgetsRounded';
import { useChargePointEventLogsDialog } from './dialogs/ChargePointEventLogsDialog';
import { TransactionEnergyKWh } from '../transaction/elements/TransactionEnergy';
import { useChargePointTriggerMessageDialog } from './dialogs/ChargePointTriggerMessage';
import { useParams } from 'react-router-dom';
import {useChargeLocationEditInfoMessageDialog} from "./dialogs/ChargePointEditInfoMessageDialog";

type TransactionBlockProps = {
    chargePointOnline: boolean;
    transaction: Transaction;
};
function TransactionBlock({ chargePointOnline, transaction }: TransactionBlockProps) {
    const { operatorId } = useParams();
    const showRemoteStopDialog = useChargePointRemoteStopDialog(store => store.show);
    const startTime = useMemo(() => {
        return moment(transaction.startTime).format('DD.MM HH:mm:ss');
    }, [transaction.startTime]);

    return (
        <ListItem dense>
            <ListItemIcon sx={{ justifyContent: 'center' }}>
                <BatteryCharging50RoundedIcon color="status.charging" />
            </ListItemIcon>
            <ListItemText
                primaryTypographyProps={{ component: 'div' }}
                primary={
                    <Link
                        component={RouterLink}
                        to={`/${operatorId}/transactions/${transaction.transactionId}`}
                        sx={{
                            textDecoration: 'none',
                            ':hover': { textDecoration: 'underline' },
                        }}
                        color="inherit"
                    >
                        Транзакция #{transaction.transactionId}
                    </Link>
                }
                secondaryTypographyProps={{ component: 'div' }}
                secondary={
                    <Stack direction={'row'} spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                        <Typography fontSize={'inherit'}>{startTime}</Typography>
                        <Typography fontSize={'inherit'}>
                            <TransactionEnergyKWh energy={transaction.meterDiff} unit={'Wh'} />
                        </Typography>
                        <Typography fontSize={'inherit'}>{transaction.totalCost} ₽</Typography>
                    </Stack>
                }
            />

            <ListItemSecondaryAction>
                <Button
                    variant="text"
                    // size="small"
                    color={'error'}
                    disabled={!chargePointOnline}
                    onClick={() => showRemoteStopDialog(transaction.transactionId)}
                >
                    Остановить
                </Button>
            </ListItemSecondaryAction>
        </ListItem>
    );
}

function ConnectorRightMenu() {
    const chargePoint = useChargePoint();
    const connector = useConnector();
    const showUnlockConnectorDialog = useConnectorUnlockDialog(store => store.show);
    const showRemoteStartDialog = useChargePointRemoteStartDialog(store => store.show);
    const showChangeAvailabilityDialog = useChargePointChangeAvailabilityDialog(store => store.show);

    const updateConnector = useRegistryEditor(store => store.updateConnector);

    const isOnline = React.useMemo(() => ChargePoint.isOnline(chargePoint), [chargePoint]);
    const canRemoteStart = React.useMemo(
        () => ChargePoint.canRemoteStart(chargePoint, connector),
        [chargePoint, connector],
    );

    return (
        <Stack direction={'row'}>
            <Button
                variant="text"
                color={'primary'}
                disabled={!canRemoteStart}
                onClick={() => showRemoteStartDialog(chargePoint._id, connector._id)}
            >
                Старт
            </Button>

            <PopupState variant="popover">
                {popupState => (
                    <React.Fragment>
                        <IconButton color="primary" size="small" disabled={!isOnline} {...bindTrigger(popupState)}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu {...bindMenu(popupState)}>
                            <MenuItem
                                onClick={() => {
                                    showUnlockConnectorDialog(chargePoint._id, connector._id);
                                    popupState.close();
                                }}
                            >
                                <ListItemIcon>
                                    <LockOpenIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Разблокировать коннектор</ListItemText>
                            </MenuItem>

                            <MenuItem
                                onClick={() => {
                                    showChangeAvailabilityDialog(chargePoint._id, connector._id);
                                    popupState.close();
                                }}
                            >
                                <ListItemIcon>
                                    <AutorenewIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Изменить доступность</ListItemText>
                            </MenuItem>
                            <Divider />
                            <ListSubheader>Другое</ListSubheader>
                            <MenuItem
                                onClick={() => {
                                    updateConnector(connector, { isTurnedOff: !connector.isTurnedOff });
                                    popupState.close();
                                }}
                                color=""
                            >
                                <ListItemIcon>
                                    <PowerSettingsNewRoundedIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>{connector.isTurnedOff ? 'Включить' : 'Отключить'}</ListItemText>
                            </MenuItem>
                        </Menu>
                    </React.Fragment>
                )}
            </PopupState>
        </Stack>
    );
}

function ConnectorBlock() {
    const chargePoint = useChargePoint();
    const evse = useEVSE();
    const connector = useConnector();

    const evseNumber = useEVSENumber(evse);
    const connectorNumber = useConnectorNumber(connector);

    const isOnline = React.useMemo(() => ChargePoint.isOnline(chargePoint), [chargePoint]);
    const transactions = useTransactionIdsByConnector(connector);
    const hasActiveTransactions = React.useMemo(() => transactions.length > 0, [transactions]);

    return (
        <List component={Paper} key={connector._id} disablePadding>
            <ListItem
                divider={connector.statusMessage.status === 'Faulted' || hasActiveTransactions}
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: connector.statusMessage.status === 'Faulted' ? 'rgba(255, 0,0,0.05)' : '',
                }}
            >
                <ListItemIcon>
                    <Badge
                        badgeContent={
                            <Stack direction={'row'} divider={<Box>:</Box>}>
                                <Box>{evseNumber}</Box>
                                <Box>{connectorNumber}</Box>
                            </Stack>
                        }
                        color={`status.${connector.statusMessage.status.toLowerCase()}` as any}
                        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                    >
                        <ConnectorStatus status={connector.statusMessage.status} fontSize="large" />
                    </Badge>
                </ListItemIcon>

                <ListItemText
                    primaryTypographyProps={{ component: 'div' }}
                    primary={
                        <Stack direction={'row'} spacing={1}>
                            <Typography>{connector.statusMessage.status}</Typography>
                            {connector.isTurnedOff && <ConnectorTurnedOffChip />}
                        </Stack>
                    }
                    secondaryTypographyProps={{ component: 'div' }}
                    secondary={
                        <Stack direction={'row'} spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                            <Typography component="span">
                                <ConnectorTypeText type={connector.standard} />
                            </Typography>
                            <Typography component="span">{connector.power} кВт</Typography>
                            <Typography component="span">{connector.powerType}</Typography>
                        </Stack>
                    }
                />

                <ListItemSecondaryAction>
                    <ConnectorRightMenu />
                </ListItemSecondaryAction>
            </ListItem>

            {connector.statusMessage.status === 'Faulted' && (
                <ListItem
                    dense
                    sx={{
                        pl: 4,
                        flexWrap: 'wrap',
                        backgroundColor: 'rgba(255, 0, 0, 0.05)',
                    }}
                >
                    <Chip
                        sx={{ m: 0.5 }}
                        size={'small'}
                        color="error"
                        label={`errorCode: ${connector.statusMessage.errorCode}`}
                    />
                    {connector.statusMessage.vendorId && (
                        <Chip
                            sx={{ m: 0.5 }}
                            size={'small'}
                            color="error"
                            label={`vendorId: ${connector.statusMessage.vendorId}`}
                        />
                    )}
                    {connector.statusMessage.vendorErrorCode && (
                        <Chip
                            sx={{ m: 0.5 }}
                            size={'small'}
                            color="error"
                            label={`vendorErrorCode: ${connector.statusMessage.vendorErrorCode}`}
                        />
                    )}
                    {connector.statusMessage.info && (
                        <Chip
                            sx={{ m: 0.5 }}
                            size={'small'}
                            color="error"
                            label={`info: ${connector.statusMessage.info}`}
                        />
                    )}
                </ListItem>
            )}

            <TransitionGroup>
                {transactions.map(transaction => (
                    <Collapse key={transaction._id}>
                        <TransactionBlock
                            key={transaction._id}
                            transaction={transaction}
                            chargePointOnline={isOnline}
                        />
                    </Collapse>
                ))}
            </TransitionGroup>
        </List>
    );
}

function EventLogGridItem() {
    const chargePoint = useChargePoint();
    const theme = useTheme();

    const showChargePointEventLogsDialog = useChargePointEventLogsDialog(store => store.show);

    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    if (isMobile) {
        return (
            <Box
                component={Paper}
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    left: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    boxShadow: 3,
                    padding: 1,
                }}
            >
                <Button
                    onClick={() => showChargePointEventLogsDialog(chargePoint._id)}
                    startIcon={<WidgetsRoundedIcon />}
                >
                    События
                </Button>
            </Box>
        );
    }

    return (
        <Grid item lg={6} xs={12} sx={{ position: 'relative' }}>
            <Box component={Paper} sx={{ position: 'absolute', overflow: 'auto', top: 0, bottom: 0, width: '100%' }}>
                <EventLogComponent chargePointId={chargePoint._id} />
            </Box>
        </Grid>
    );
}

export default function ChargePointDashboardPage() {
    const chargePoint = useChargePoint();
    const showResetDialog = useChargePointResetDialog(store => store.show);
    const showClearCacheDialog = useChargePointClearCacheDialog(store => store.show);
    const showTriggerMessageDialog = useChargePointTriggerMessageDialog(store => store.show);
    const showChargePointEditCommentDialog = useChargePointEditCommentDialog(store => store.show);

    const isOnline = React.useMemo(() => ChargePoint.isOnline(chargePoint), [chargePoint]);
    const isNewChargePoint = React.useMemo(() => chargePoint.state === ChargePointState.CREATED, [chargePoint.state]);
    const hasComment = React.useMemo(() => !_.isEmpty(chargePoint.comment), [chargePoint.comment]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid container columnSpacing={2}>
            {isNewChargePoint && (
                <Grid item xs={12} sx={{ marginY: 2 }}>
                    <ConnectionInfoBlock />
                </Grid>
            )}

            <Grid item xs={12}>
                <Collapse in={hasComment}>
                    <Box sx={{ marginY: 1 }}>
                        <CommentInfoBlock />
                    </Box>
                </Collapse>
            </Grid>

            <Grid item lg={6} xs={12}>
                <List>
                    <ListItem>
                        <ListItemText>
                            <Typography variant="h5">Зарядная станция</Typography>
                        </ListItemText>
                        <ListItemSecondaryAction>
                            <ConnectionStatus status={chargePoint.ocpp.connectionStatus} mode="full" />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>

                <List component={Paper} disablePadding>
                    <ListItem
                        divider={chargePoint.statusMessage.status === 'Faulted'}
                        sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor:
                                chargePoint.statusMessage.status === 'Faulted' ? 'rgba(255, 0, 0, 0.05)' : '',
                        }}
                    >
                        <ListItemIcon>
                            <EvStationIcon
                                fontSize="large"
                                color={`status.${chargePoint.statusMessage.status.toLowerCase()}` as any}
                            />
                        </ListItemIcon>

                        <ListItemText
                            primary={chargePoint.statusMessage.status}
                            secondary={chargePoint.statusMessage.status}
                        />
                    </ListItem>
                    {chargePoint.statusMessage.status === 'Faulted' && (
                        <ListItem
                            sx={{
                                pl: 4,
                                flexWrap: 'wrap',
                                backgroundColor: 'rgba(255, 0,0,0.05)',
                            }}
                        >
                            <Chip
                                sx={{ m: 0.5 }}
                                size={'small'}
                                color="error"
                                label={`errorCode: ${chargePoint.statusMessage.errorCode}`}
                            />
                            {chargePoint.statusMessage.vendorId && (
                                <Chip
                                    sx={{ m: 0.5 }}
                                    size={'small'}
                                    color="error"
                                    label={`vendorId: ${chargePoint.statusMessage.vendorId}`}
                                />
                            )}
                            {chargePoint.statusMessage.vendorErrorCode && (
                                <Chip
                                    sx={{ m: 0.5 }}
                                    size={'small'}
                                    color="error"
                                    label={`vendorErrorCode: ${chargePoint.statusMessage.vendorErrorCode}`}
                                />
                            )}
                            {chargePoint.statusMessage.info && (
                                <Chip
                                    sx={{ m: 0.5 }}
                                    size={'small'}
                                    color="error"
                                    label={`info: ${chargePoint.statusMessage.info}`}
                                />
                            )}
                        </ListItem>
                    )}
                </List>
                <List>
                    <ListItem>
                        <ListItemText>
                            <Typography variant="h5">Коннекторы</Typography>
                        </ListItemText>
                    </ListItem>
                </List>

                <Stack spacing={2}>
                    {chargePoint.evses.map((evse, evseIndex) => (
                        <WithEVSE key={evse._id} id={evse._id}>
                            {evse.connectors.map((connector, connectorIndex) => (
                                <WithConnector key={connector._id} id={connector._id}>
                                    <ConnectorBlock />
                                </WithConnector>
                            ))}
                        </WithEVSE>
                    ))}
                </Stack>

                <List>
                    <ListItem>
                        <ListItemText>
                            <Typography variant="h5">Действия</Typography>
                        </ListItemText>
                    </ListItem>
                </List>

                <Stack spacing={1}>
                    <List disablePadding component={Paper}>
                        <ListItem divider>
                            <ListItemText primary="Перезагрузка" secondary="Отправить команду Reset" />
                            <ListItemSecondaryAction>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => showResetDialog(chargePoint._id)}
                                    disabled={!isOnline}
                                >
                                    Перезагрузка
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider>
                            <ListItemText primary="Очистка кэша" secondary="Отправить команду ClearCache" />
                            <ListItemSecondaryAction>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => showClearCacheDialog(chargePoint._id)}
                                    disabled={!isOnline}
                                >
                                    Очистить кэш
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Запрос сообщения" secondary="Отправить команду TriggerMessage" />
                            <ListItemSecondaryAction>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => showTriggerMessageDialog(chargePoint._id)}
                                    disabled={!isOnline}
                                >
                                    Отправить
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                    <List disablePadding component={Paper}>
                        <ListItem>
                            <ListItemText primary="Комментарий" secondary="Редактировать операционную информацию" />
                            <ListItemSecondaryAction>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => showChargePointEditCommentDialog(chargePoint._id)}
                                >
                                    Редактировать
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Stack>

                <List>
                    <ListItem>
                        <ListItemText>
                            <Typography variant="h5">Информация</Typography>
                        </ListItemText>
                    </ListItem>
                </List>
                <List component={Paper} disablePadding>
                    <ListItem divider>
                        <ListItemText primary="Производитель" />
                        <ListItemSecondaryAction>
                            <ListItemText primary={chargePoint.vendor} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary="Серийный номер" />
                        <ListItemSecondaryAction>
                            <ListItemText primary={chargePoint.serialNumber} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary="OCPP идентификатор" />
                        <ListItemSecondaryAction>
                            <ListItemText primary={chargePoint.chargeBoxIdentity} />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Grid>

            <EventLogGridItem />
        </Grid>
    );
}
