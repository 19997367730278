import { HolderInfo, OperatorInfo } from '@electrifly/central-client-api/data';
import { LoadingButton } from '@mui/lab';
import {
    Autocomplete,
    Button,
    CircularProgress,
    FormHelperText,
    Stack,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import create from 'zustand';
import { API } from '../../../core/api-client';
import { useChargeLocation, WithChargeLocation } from '../../wrappers/WithChargeLocation';

interface InternalService {
    operatorInfos: OperatorInfo[];
    holderInfos: HolderInfo[];

    operatorsLoading: boolean;
    holdersLoading: boolean;

    loadOperatorsInfo: () => Promise<void>;
    loadHoldersInfo: (operatorId?: string) => Promise<void>;
}

const useInternalService = create<InternalService>((set, get) => ({
    operatorInfos: [],
    holderInfos: [],

    operatorsLoading: false,
    holdersLoading: false,

    loadOperatorsInfo: async () => {
        if (get().operatorsLoading) {
            return;
        }
        set({ operatorsLoading: true });
        const [error, res] = await API.operatorListInfo({});
        set({ operatorsLoading: false });

        if (error) {
            console.error(error);
            return;
        }

        set({ operatorInfos: [...res.data] });
    },

    loadHoldersInfo: async (operatorId?: string) => {
        if (get().holdersLoading) {
            return;
        }
        set({ holdersLoading: true });
        const [error, res] = await API.holderListInfo({ operatorId });
        set({ holdersLoading: false });

        if (error) {
            console.error(error);
            return;
        }

        set({ holderInfos: [...res.data] });
    },
}));

type Store = {
    open: boolean;
    chargeLocationId: string;
    show: (chargeLocationId: string) => void;
    hide: () => void;
};

export const useChangeLocationChangeHolderDialog = create<Store>((set, get) => ({
    open: false,
    chargeLocationId: '',
    show: (chargeLocationId: string) => set({ open: true, chargeLocationId }),
    hide: () => set({ open: false, chargeLocationId: '' }),
}));

function DialogInternal() {
    const hide = useChangeLocationChangeHolderDialog(store => store.hide);
    const location = useChargeLocation();

    const loadOperatorsInfo = useInternalService(store => store.loadOperatorsInfo);
    const loadHoldersInfo = useInternalService(store => store.loadHoldersInfo);
    const operatorsLoading = useInternalService(store => store.operatorsLoading);
    const holdersLoading = useInternalService(store => store.holdersLoading);
    const operatorInfos = useInternalService(store => store.operatorInfos);
    const holderInfos = useInternalService(store => store.holderInfos);

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik({
        initialValues: {
            operator: location.operator,
            holder: location.holder,
        },

        onSubmit: async values => {
            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.chargeLocationHolderChange(location._id, {
                operator: values.operator,
                holder: values.holder,
            });
            setIsRequesting(false);
            if (!error) {
                hide();
                // formik.resetForm();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    useEffectOnce(() => {
        loadOperatorsInfo();
        loadHoldersInfo(location.operator);
    });

    useEffect(() => {
        loadHoldersInfo(formik.values.operator);
    }, [formik.values.operator]);

    const selectedOperator = useMemo(() => {
        return operatorInfos.find(item => item._id === formik.values.operator);
    }, [operatorInfos, formik.values.operator]);

    const selectedHolder = useMemo(() => {
        return holderInfos.find(item => item._id === formik.values.holder);
    }, [holderInfos, formik.values.holder]);

    return (
        <>
            <DialogContent>
                <DialogContentText sx={{ paddingBottom: 2 }}></DialogContentText>
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2}>
                        <Autocomplete
                            id="operator"
                            fullWidth
                            options={operatorInfos}
                            getOptionLabel={option => option.name}
                            renderOption={(props, option, state) => (
                                <li {...props} key={option._id}>
                                    {option.name}
                                </li>
                            )}
                            value={selectedOperator || null} //null is neede for initialization render in controlled state
                            onChange={(event, val) => formik.setFieldValue('operator', val?._id)}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Оператор"
                                    placeholder="Выберите оператора"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {operatorsLoading && <CircularProgress color="inherit" size={20} />}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                    error={formik.touched.operator && Boolean(formik.errors.operator)}
                                    helperText={formik.touched.operator && formik.errors.operator}
                                />
                            )}
                        />

                        <Autocomplete
                            id="holder"
                            fullWidth
                            options={holderInfos}
                            getOptionLabel={option => option.identity}
                            renderOption={(props, option, state) => (
                                <li {...props} key={option._id}>
                                    {option.identity}
                                </li>
                            )}
                            value={selectedHolder || null}
                            onChange={(event, val) => formik.setFieldValue('holder', val?._id)}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Владелец станций"
                                    placeholder="Выберите владельца станций"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {holdersLoading && <CircularProgress color="inherit" size={20} />}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                    error={formik.touched.holder && Boolean(formik.errors.holder)}
                                    helperText={formik.touched.holder && formik.errors.holder}
                                />
                            )}
                        />
                    </Stack>
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Изменить</span>
                </LoadingButton>
            </DialogActions>
        </>
    );
}

export function ChangeLocationChangeHolderDialog() {
    const open = useChangeLocationChangeHolderDialog(store => store.open);
    const hide = useChangeLocationChangeHolderDialog(store => store.hide);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const chargeLocationId = useChangeLocationChangeHolderDialog(store => store.chargeLocationId);

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Изменение владельца зарядной локации</DialogTitle>
            <WithChargeLocation id={chargeLocationId}>
                <DialogInternal />
            </WithChargeLocation>
        </Dialog>
    );
}
