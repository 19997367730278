import { Box } from '@mui/material';
import { TransactionListTableComponent } from '../../transaction/components/list-table/TransactionListTableComponent';
import { useCorporate } from '../../wrappers/WithCorporate';

export default function RoamingXPDetailsTransactionHistoryPage() {
    const corporate = useCorporate();

    return (
        <Box sx={{ marginTop: 2 }}>
            <TransactionListTableComponent filter={{ ownerAccount: corporate.account }} />
        </Box>
    );
}
