import { LoadingButton } from '@mui/lab';
import { Button, DialogContentText, FormControl, FormHelperText, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useState } from 'react';
import create from 'zustand';
import { API } from '../../../core/api-client';
import { WialonIntegrationUpdate } from '@electrifly/central-client-api';

type FormValues = {
    token: string;
};

type Store = {
    open: boolean;
    integrationId: string;
    show: (integrationId: string) => void;
    hide: () => void;
};

const useWialonTokenEditDialog = create<Store>((set, get) => ({
    open: false,
    integrationId: '',
    show: (integrationId: string) => set({ open: true, integrationId }),
    hide: () => set({ open: false, integrationId: '' }),
}));

function Stage1() {
    const hide = useWialonTokenEditDialog(store => store.hide);
    const integrationId = useWialonTokenEditDialog(store => store.integrationId);

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<FormValues>({
        initialValues: {
            token: '',
        },

        validationSchema: WialonIntegrationUpdate.validationSchema,

        onSubmit: async values => {
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.wialonIntegrationTokenUpdate(integrationId, {
                token: values.token,
            });
            setIsRequesting(false);
            if (!error) {
                formik.resetForm();
                WialonTokenEditDialogHelper.hide();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <>
            <DialogTitle>Изменение токена</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ paddingBottom: 2 }}>Введите новый токен</DialogContentText>
                <form onSubmit={formik.handleSubmit}>
                    <FormControl fullWidth>
                        <TextField
                            autoFocus
                            id="token"
                            label="Токен"
                            type="text"
                            variant="outlined"
                            fullWidth
                            value={formik.values.token}
                            onChange={formik.handleChange}
                            error={formik.touched.token && Boolean(formik.errors.token)}
                            helperText={formik.touched.token && formik.errors.token}
                        />
                    </FormControl>
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Отправить</span>
                </LoadingButton>
            </DialogActions>
        </>
    );
}

export function WialonTokenEditDialog() {
    const open = useWialonTokenEditDialog(store => store.open);
    const hide = useWialonTokenEditDialog(store => store.hide);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <Stage1 />
        </Dialog>
    );
}

export const WialonTokenEditDialogHelper = {
    show: (id: string) => useWialonTokenEditDialog.getState().show(id),
    hide: () => useWialonTokenEditDialog.getState().hide(),
};
