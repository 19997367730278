import * as yup from 'yup';
import { Holder } from '../../data/Holder';

export type ReqParams = { id: string };
export type ReqBody = {
    operator: string;
};

export type ResData = Holder;

export const validationSchema = yup.object({
    operator: yup.string().required().min(1, 'Поле оператор должно быть заполнено'), //prevent empty string
});
