import * as yup from 'yup';

export type ReqParams = { id: string };
export type ReqBody = {
    groupId: string;
    type: string
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type ResData = {};

export const validationSchema = yup.object({
    groupId: yup.string().required('Идентификатор группы должен быть заполнен'),
    type: yup.string().required('Тип тарифа должен быть заполнен'),
});
