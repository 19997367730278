import { Box } from '@mui/material';
import DashboardListView from './list-view/DashboardListView';
import DashboardSummary from './DashboardSummary';
import { RegistryData, useRegistry } from '../../services/GlobalRegistry';
import _ from 'lodash';
import {
    ChargeLocation,
    ChargePoint,
    ChargePointConnectionStatus,
    ChargePointState,
    Connector,
    EVSE,
    RoamingEVSE,
    RoamingLocation,
} from '@electrifly/central-client-api';
import { DashboardHeader } from './DashboardHeader';
import OCPP from '@electrifly/ocpp';
import { createWithImmer } from '../../misc/CreateWithImmer';
import DashboardRoamingView from './roaming-view/DashboardRoamingView';

interface FilteredMap {
    chargeLocations: Record<string, boolean>;
    chargePoints: Record<string, boolean>;
    evses: Record<string, boolean>;
    connectors: Record<string, boolean>;
    //
    roamingLocations: Record<string, boolean>;
    roamingEvses: Record<string, boolean>;
    roamingConnectors: Record<string, boolean>;
}

const FilteredMap = {
    default: (): FilteredMap => ({
        chargeLocations: {},
        chargePoints: {},
        evses: {},
        connectors: {},
        //
        roamingLocations: {},
        roamingEvses: {},
        roamingConnectors: {},
    }),
};

const normalize = (value: string) => _.toLower(_.deburr(value));

function locationMatch(location: ChargeLocation, filter: Filter) {
    const valuesToMatch = [location.address, location.name];
    return valuesToMatch.some(value => normalize(value).includes(filter.searchText));
}

function chargePointMatch(chargePoint: ChargePoint, filter: Filter) {
    const valuesToMatch = [
        chargePoint.chargeBoxIdentity,
        chargePoint.physicalReference,
        chargePoint.roamingIdentity,
        chargePoint.serialNumber,
    ];
    return valuesToMatch.some(value => normalize(value).includes(filter.searchText));
}

function evseMatch(evse: EVSE, filter: Filter) {
    const valuesToMatch = [] as string[];
    return valuesToMatch.some(value => normalize(value).includes(filter.searchText));
}

function connectorMatchSearch(connector: Connector, filter: Filter) {
    const valuesToMatch = [] as string[];
    return valuesToMatch.some(value => normalize(value).includes(filter.searchText));
}

function connectorMatchStatus(connector: Connector, filter: Filter) {
    const statusMatched =
        filter.connectorStatuses.length === 0 ||
        (filter.connectorStatuses.length > 0 && filter.connectorStatuses.includes(connector.statusMessage.status));
    return statusMatched;
}

function chargePointMatchStatus(chargePoint: ChargePoint, filter: Filter) {
    if (filter.chargePointStatuses.length === 0) {
        return true;
    }

    const statusMatched = filter.chargePointStatuses.every(status => {
        switch (status) {
            case 'Online':
                return chargePoint.ocpp.connectionStatus === ChargePointConnectionStatus.ONLINE;
            case 'Offline':
                return chargePoint.ocpp.connectionStatus === ChargePointConnectionStatus.OFFLINE;
            case 'Available':
                return chargePoint.statusMessage.status === 'Available';
            case 'Faulted':
                return chargePoint.statusMessage.status === 'Faulted';
            case 'Unavailable':
                return chargePoint.statusMessage.status === 'Unavailable';
        }

        return false;
    });

    return statusMatched;
}

function chargePointMatchState(chargePoint: ChargePoint, filter: Filter) {
    if (filter.chargePointStates.length === 0) {
        return true;
    }

    const stateMatched = filter.chargePointStates.some(status => {
        switch (status) {
            case ChargePointState.CREATED:
                return chargePoint.state === ChargePointState.CREATED;
            case ChargePointState.PRODUCTION:
                return chargePoint.state === ChargePointState.PRODUCTION;
            case ChargePointState.SERVICE:
                return chargePoint.state === ChargePointState.SERVICE;
            case ChargePointState.REPAIR:
                return chargePoint.state === ChargePointState.REPAIR;
            case ChargePointState.SUSPENDED:
                return chargePoint.state === ChargePointState.SUSPENDED;
            case ChargePointState.RETIRED:
                return chargePoint.state === ChargePointState.RETIRED;
        }

        return false;
    });

    return stateMatched;
}

function roamingLocationMatch(location: RoamingLocation, filter: Filter) {
    const valuesToMatch = [location.city, location.address, location.name || ''];
    return valuesToMatch.some(value => normalize(value).includes(filter.searchText));
}

function roamingEvseMatch(evse: RoamingEVSE, filter: Filter) {
    const valuesToMatch = [evse.physicalReference] as string[];
    return valuesToMatch.some(value => normalize(value).includes(filter.searchText));
}

function getFilteredMap(store: RegistryData, filter: Filter): FilteredMap {
    const normalizedFilter: Filter = { ...filter, searchText: normalize(filter.searchText) };

    const newFiltered = FilteredMap.default();
    Object.keys(store.chargeLocations).forEach(key => (newFiltered.chargePoints[key] = false));
    Object.keys(store.chargePoints).forEach(key => (newFiltered.chargePoints[key] = false));
    Object.keys(store.evses).forEach(key => (newFiltered.evses[key] = false));
    Object.keys(store.connectors).forEach(key => (newFiltered.connectors[key] = false));

    for (const connector of Object.values(store.connectors)) {
        const connectorMatchedSearch = connectorMatchSearch(connector, normalizedFilter);
        newFiltered.connectors[connector._id] = connectorMatchedSearch || newFiltered.connectors[connector._id];
        if (newFiltered.connectors[connector._id]) {
            continue;
        }

        const evse = store.evses[connector.evse];
        const evseMatchedSearch = evseMatch(evse, normalizedFilter);
        newFiltered.connectors[connector._id] = evseMatchedSearch || newFiltered.connectors[connector._id];
        if (newFiltered.connectors[connector._id]) {
            continue;
        }

        const chargePoint = store.chargePoints[evse.chargePoint];
        const chargePointMatchedSearch = chargePointMatch(chargePoint, normalizedFilter);
        newFiltered.connectors[connector._id] = chargePointMatchedSearch || newFiltered.connectors[connector._id];
        if (newFiltered.connectors[connector._id]) {
            continue;
        }

        const location = store.chargeLocations[chargePoint.location];
        const locationMatchedSearch = locationMatch(location, normalizedFilter);
        newFiltered.connectors[connector._id] = locationMatchedSearch || newFiltered.connectors[connector._id];
        if (newFiltered.connectors[connector._id]) {
            continue;
        }
    }

    for (const connector of Object.values(store.connectors)) {
        if (newFiltered.connectors[connector._id] === false) {
            continue;
        }
        const connectorMatchedSearch = connectorMatchStatus(connector, normalizedFilter);
        newFiltered.connectors[connector._id] = newFiltered.connectors[connector._id] && connectorMatchedSearch;
    }

    for (const connector of Object.values(store.connectors)) {
        if (newFiltered.connectors[connector._id] === false) {
            continue;
        }

        const evse = store.evses[connector.evse];
        const chargePoint = store.chargePoints[evse.chargePoint];
        const chargePointMatchedStatus = chargePointMatchStatus(chargePoint, normalizedFilter);
        const chargePointMatchedState = chargePointMatchState(chargePoint, normalizedFilter);
        newFiltered.connectors[connector._id] =
            chargePointMatchedState && chargePointMatchedStatus && newFiltered.connectors[connector._id];
        if (newFiltered.connectors[connector._id]) {
            continue;
        }
    }

    Object.keys(store.roamingLocations).forEach(key => (newFiltered.chargePoints[key] = false));
    for (const roamingLocation of Object.values(store.roamingLocations)) {
        const locationMatchedSearch = roamingLocationMatch(roamingLocation, normalizedFilter);
        if (locationMatchedSearch) {
            newFiltered.roamingLocations[roamingLocation._id] = true;
            roamingLocation.evses.forEach(evse => {
                newFiltered.roamingEvses[evse._id] = true;
                evse.connectors.forEach(connector => {
                    newFiltered.roamingConnectors[connector._id] = true;
                });
            });
            continue;
        }

        for (const roamingEVSE of roamingLocation.evses) {
            const evseMatchedSearch = roamingEvseMatch(roamingEVSE, normalizedFilter);
            if (evseMatchedSearch) {
                newFiltered.roamingLocations[roamingLocation._id] = true;
                newFiltered.roamingEvses[roamingEVSE._id] = true;
                roamingEVSE.connectors.forEach(connector => {
                    newFiltered.roamingConnectors[connector._id] = true;
                });
                continue;
            }
        }
    }

    return newFiltered;
}

type OCPPConnectorStatus = OCPP.V15.ChargePointStatus | OCPP.V16.ChargePointStatus;
type ChargePointStatus = 'Online' | 'Offline' | 'Available' | 'Faulted' | 'Unavailable';

type Filter = {
    searchText: string;
    connectorStatuses: OCPPConnectorStatus[];
    chargePointStatuses: ChargePointStatus[];
    chargePointStates: ChargePointState[];
};

type Store = {
    filter: Filter;
    filtered: FilteredMap;

    setSearchText(text: string): void;

    setNewConnectorFilter(values: OCPPConnectorStatus[]): void;
    removeConnectorFilter(value: OCPPConnectorStatus): void;

    setNewChargePointStatusFilter(values: ChargePointStatus[]): void;
    removeChargePointStatusFilter(value: ChargePointStatus): void;

    setNewChargePointStateFilter(values: ChargePointState[]): void;
    removeChargePointStateFilter(value: ChargePointState): void;
};

export const useDashboardService = createWithImmer<Store>((set, get) => {
    const sub1 = useRegistry.subscribe(store => {
        const newFiltered = getFilteredMap(store, get().filter);
        set({ filtered: newFiltered });
    });

    async function processFiltering() {
        const newFiltered = getFilteredMap(useRegistry.getState(), get().filter);
        set(draft => {
            draft.filtered = newFiltered;
        });
    }

    const debouncedFilter = _.debounce(() => processFiltering(), 50);

    return {
        filter: {
            searchText: '',
            connectorStatuses: [],
            chargePointStatuses: [],
            chargePointStates: [ChargePointState.PRODUCTION],
        },
        filtered: FilteredMap.default(),

        setSearchText: (text: string) => {
            set(draft => {
                draft.filter.searchText = text;
            });

            debouncedFilter();
        },

        setNewConnectorFilter: (values: OCPPConnectorStatus[]) => {
            const { filter } = get();
            const nextFilter: Filter = {
                ...filter,
                connectorStatuses: values,
            };

            const newFiltered = getFilteredMap(useRegistry.getState(), nextFilter);

            set(draft => {
                draft.filter.connectorStatuses = values;
                draft.filtered = newFiltered;
            });
        },

        removeConnectorFilter: (value: OCPPConnectorStatus) => {
            set(draft => {
                draft.filter.connectorStatuses = draft.filter.connectorStatuses.filter(item => item !== value);
            });

            const newFiltered = getFilteredMap(useRegistry.getState(), get().filter);
            set(draft => {
                draft.filtered = newFiltered;
            });
        },

        setNewChargePointStatusFilter: (values: ChargePointStatus[]) => {
            const { filter } = get();
            const nextFilter: Filter = {
                ...filter,
                chargePointStatuses: values,
            };

            const newFiltered = getFilteredMap(useRegistry.getState(), nextFilter);

            set(draft => {
                draft.filter.chargePointStatuses = values;
                draft.filtered = newFiltered;
            });
        },

        removeChargePointStatusFilter: (value: ChargePointStatus) => {
            set(draft => {
                draft.filter.chargePointStatuses = draft.filter.chargePointStatuses.filter(item => item !== value);
            });

            const newFiltered = getFilteredMap(useRegistry.getState(), get().filter);
            set(draft => {
                draft.filtered = newFiltered;
            });
        },

        setNewChargePointStateFilter: (values: ChargePointState[]) => {
            const { filter } = get();
            const nextFilter: Filter = {
                ...filter,
                chargePointStates: values,
            };

            const newFiltered = getFilteredMap(useRegistry.getState(), nextFilter);

            set(draft => {
                draft.filter.chargePointStates = values;
                draft.filtered = newFiltered;
            });
        },

        removeChargePointStateFilter: (value: ChargePointState) => {
            set(draft => {
                draft.filter.chargePointStates = draft.filter.chargePointStates.filter(item => item !== value);
            });

            const newFiltered = getFilteredMap(useRegistry.getState(), get().filter);
            set(draft => {
                draft.filtered = newFiltered;
            });
        },
    };
});

export default function DashboardPage() {
    return (
        <Box>
            <DashboardSummary />
            <DashboardHeader />
            <DashboardListView />
            <DashboardRoamingView />
        </Box>
    );
}
