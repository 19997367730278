import { Grid, Card, CardContent, Typography, colors } from '@mui/material';
import { DateTime, Duration } from 'luxon';
import { useMemo } from 'react';
import { ChargePointConnectionStatus, ChargePointUptime } from '@electrifly/central-client-api/data';
import BigNumber from 'bignumber.js';

interface ChartProps {
    data: ChargePointUptime[];
}
export function UptimeSummary({ data }: ChartProps) {
    const totalTime = useMemo(() => {
        if (data.length === 0) {
            return Duration.fromMillis(0);
        }
        return DateTime.fromISO(data[data.length - 1].endTime).diff(DateTime.fromISO(data[0].startTime));
    }, [data]);

    const offlineTime = useMemo(() => {
        const differences = data
            .filter(item => item.status === ChargePointConnectionStatus.OFFLINE)
            .map(item => DateTime.fromISO(item.endTime).diff(DateTime.fromISO(item.startTime)));

        const total = differences.reduce((acc, value) => acc.plus(value), Duration.fromMillis(0));
        return total;
    }, [data]);

    const onlineTime = useMemo(() => {
        const differences = data
            .filter(item => item.status === ChargePointConnectionStatus.ONLINE)
            .map(item => DateTime.fromISO(item.endTime).diff(DateTime.fromISO(item.startTime)));
        const total = differences.reduce((acc, value) => acc.plus(value), Duration.fromMillis(0));
        return total;
    }, [data]);

    const percent = useMemo(
        () => new BigNumber(onlineTime.toMillis()).div(totalTime.toMillis()).times(100).toFixed(3),
        [onlineTime, totalTime],
    );

    const totalTimeStr = useMemo(() => totalTime.toFormat('hh:mm:ss'), [totalTime]);
    const offlineTimeStr = useMemo(() => offlineTime.toFormat('hh:mm:ss'), [offlineTime]);
    const onlineTimeStr = useMemo(() => onlineTime.toFormat('hh:mm:ss'), [onlineTime]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
                <Card>
                    <CardContent>
                        <Typography variant="overline" color={colors.grey[700]}>
                            Доступность
                        </Typography>
                        <Typography variant="h5">{percent} %</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6} md={3}>
                <Card>
                    <CardContent>
                        <Typography variant="overline" color={colors.grey[700]}>
                            Время Offline
                        </Typography>
                        <Typography variant="h5">{offlineTimeStr}</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6} md={3}>
                <Card>
                    <CardContent>
                        <Typography variant="overline" color={colors.grey[700]}>
                            Время Online
                        </Typography>
                        <Typography variant="h5">{onlineTimeStr}</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6} md={3}>
                <Card>
                    <CardContent>
                        <Typography variant="overline" color={colors.grey[700]}>
                            Общее время
                        </Typography>
                        <Typography variant="h5">{totalTimeStr}</Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
