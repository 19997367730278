import { Popover, Chip, Typography } from '@mui/material';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useMemo, useRef } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { usePopupStateInternal, WithPopupState } from '../../../../../../elements/WithPopupState';
import { useRegistry } from '../../../../../../services/GlobalRegistry';
import { EditForm } from '../../../../../editable-values/EditForm';
import { ActionEventListPageService } from '../../ListPageService';

function SelectorInternal() {
    const setFilter = ActionEventListPageService.useStore(store => store.setFilter);
    const selectedChargePoint = ActionEventListPageService.useStore(store => store.filter.chargePoint);

    const popupState = usePopupStateInternal();

    const chargePoints = useRegistry(store => store.chargePoints);

    const chargePointOptions = useMemo(() => {
        return Object.values(chargePoints).map(item => ({
            value: item._id,
            label: item.physicalReference,
        }));
    }, [chargePoints]);

    return (
        <EditForm
            initialValue={selectedChargePoint || ''}
            type="autocomplete"
            options={chargePointOptions}
            onCancel={popupState.close}
            onSave={value => setFilter({ chargePoint: value })}
        />
    );
}

function SelectorChip() {
    const popupState = usePopupStateInternal();

    const setFilter = ActionEventListPageService.useStore(store => store.setFilter);
    const setFilterVisibility = ActionEventListPageService.useStore(store => store.setFilterVisibility);

    const chargePoints = useRegistry(store => store.chargePoints);
    const selectedChargePoint = ActionEventListPageService.useStore(store => store.filter.chargePoint);

    const chargePointNames = useMemo(
        () => selectedChargePoint && chargePoints[selectedChargePoint]?.physicalReference,
        [chargePoints, selectedChargePoint],
    );

    const ref = useRef<HTMLDivElement>(null);

    useEffectOnce(() => {
        if (!ref.current) {
            return;
        }
        popupState.setOpen(true, ref.current);
    });

    return (
        <Chip
            ref={ref}
            variant="outlined"
            sx={{ maxWidth: 200 }}
            label={<Typography fontSize="inherit">Станция: {chargePointNames || ''}</Typography>}
            {...bindTrigger(popupState)}
            onClick={event => {
                const selection = window.getSelection && window.getSelection();
                if (selection && selection.type !== 'Range') {
                    popupState.setOpen(true, event);
                }
            }}
            onDelete={() => {
                setFilterVisibility({ type: 'chargepoint', visibility: false });
                setFilter({ chargePoint: undefined });
            }}
        />
    );
}

export function ChargePointSelector() {
    return (
        <WithPopupState>
            {popupState => (
                <>
                    <SelectorChip />

                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                        <SelectorInternal />
                    </Popover>
                </>
            )}
        </WithPopupState>
    );
}
