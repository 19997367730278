import { ChargePointConnectionStatus } from '@electrifly/central-client-api';
import { Tooltip, colors, Chip, Typography, Stack } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import React from 'react';

type Mode = 'dot' | 'full';

type ConnectionStatusProps = {
    status: ChargePointConnectionStatus;
    mode?: Mode;
};

const ConnectionStatusOnline = React.memo<{ mode: Mode }>(({ mode }) => {
    if (mode === 'full') {
        return (
            <Tooltip title="Online">
                <Stack direction={'row'} sx={{ alignItems: 'center' }} spacing={1}>
                    <CircleIcon sx={{ fontSize: 'inherit', color: colors.green[500] }} />
                    <Typography color={colors.green[500]}>Online</Typography>
                </Stack>
            </Tooltip>
        );
    }

    return (
        <Tooltip title="Online">
            <CircleIcon sx={{ fontSize: 'inherit', color: colors.green[500] }} />
        </Tooltip>
    );
});

const ConnectionStatusOffline = React.memo<{ mode: Mode }>(({ mode }) => {
    if (mode === 'full') {
        return <Chip variant="filled" label="Offline" color="status.offline" />;
    }

    return (
        <Tooltip title="Offline">
            <CircleIcon sx={{ fontSize: 'inherit', color: colors.grey[900] }} />
        </Tooltip>
    );
});

export const ConnectionStatus = React.memo<ConnectionStatusProps>(
    ({ status, mode = 'dot' }) => {
        return status === ChargePointConnectionStatus.ONLINE ? (
            <ConnectionStatusOnline mode={mode} />
        ) : (
            <ConnectionStatusOffline mode={mode} />
        );
    },
    (prev, next) => prev.status === next.status && prev.mode === next.mode,
);
