export const OCPPTriggerMessageType = [
    'BootNotification',
    'DiagnosticsStatusNotification',
    'FirmwareStatusNotification',
    'Heartbeat',
    'MeterValues',
    'StatusNotification',
] as const;

export type OCPPTriggerMessageType = typeof OCPPTriggerMessageType[number];
