import * as yup from 'yup';
import { EventLogOCPP } from '../../data/EventLogOCPP';

export type ReqParams = { id: string };

export type ReqQuery = {
    skip?: number;
    limit?: number;
};

export type ResData = EventLogOCPP[];

export const validationSchema = yup.object({
    skip: yup.number().optional().min(0).default(0),
    limit: yup.number().optional().min(1).max(1000).default(100),
});
