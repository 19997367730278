export enum PriceResourceType {
    Time = 'Time',
    Energy = 'Energy',
    Session = 'Session',
}

export interface ResourceAmount {
    type: PriceResourceType;
    amount: number;
}

export interface TransactionPrice {
    free: boolean;
    resources: ResourceAmount[];
}
