import { Corporate } from '@electrifly/central-client-api';
import { Box, Collapse, Grid, Typography, colors } from '@mui/material';
import { DataWrap } from '../../event-log/components/DataWrap';
import { OperatorLink } from '../../operators/elements/OperatorLink';

interface CorporateItemProps {
    corporate: Corporate;
}

export function CorporateItem({ corporate }: CorporateItemProps) {
    return (
        <>
            <Grid container columnSpacing={1} alignItems={{ xs: 'flex-start', md: 'center' }}>
                <Grid item xs="auto">
                    <DataWrap color={colors.grey[200]}>
                        <OperatorLink id={corporate.operator} />
                    </DataWrap>
                </Grid>
                <Grid item xs="auto">
                    <Typography fontSize={'inherit'} sx={{ fontWeight: 500 }}>
                        {corporate.name}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Box display={{ xs: 'none', md: 'flex' }}>{/* <BillingMainBlock billing={billing} /> */}</Box>
                </Grid>
                {/* <Grid item xs="auto" justifyContent={'center'}>
                    <DataWrap color={colors.grey[200]}>
                        <Typography fontSize={'inherit'}>{corporate.status}</Typography>
                    </DataWrap>
                </Grid> */}
            </Grid>
        </>
    );
}
