import { Corporate, EventLog, EventLogOCPP, Integration, RegistryLoad, RoamingLocation, RoamingXP } from '..';
import { Account } from './Account';
import { Holder } from './Holder';
import {
    ChargeLocation,
    ChargeLocationDeleted,
    ChargePoint,
    ChargePointDeleted,
    Connector,
    ConnectorDeleted,
    EVSE,
    EVSEDeleted,
} from './infrastructure/ChargeLocation';
import { Transaction, TransactionMeasurement } from './transaction/Transaction';
import { User } from './User';

export enum MessageType {
    REGISTRY = 'REGISTRY',
    CONNECTOR = 'CONNECTOR',
    EVSE = 'EVSE',
    CHARGE_POINT = 'CHARGE_POINT',
    CHARGE_LOCATION = 'CHARGE_LOCATION',
    ROAMING_LOCATION = 'ROAMING_LOCATION',
    TRANSACTION = 'TRANSACTION',
    TRANSACTION_MEASUREMENT = 'TRANSACTION_MEASUREMENT',
    EVENT_LOG_OCPP = 'EVENT_LOG_OCPP',
    ACTION_EVENT = 'ACTION_EVENT',
    ACCOUNT = 'ACCOUNT',
    USER = 'USER',
    HOLDER = 'HOLDER',
    ROAMING_XP = 'ROAMING_XP',
    INTEGRATION = 'INTEGRATION',
    CORPORATE = 'CORPORATE',
}

export type MessageRegistry = {
    type: MessageType.REGISTRY;
    data: RegistryLoad.ResData;
};

export type MessageConnector = {
    type: MessageType.CONNECTOR;
    data: Connector | ConnectorDeleted;
};

export type MessageEVSE = {
    type: MessageType.EVSE;
    data: EVSE | EVSEDeleted;
};

export type MessageChargePoint = {
    type: MessageType.CHARGE_POINT;
    data: ChargePoint | ChargePointDeleted;
};

export type MessageChargeLocation = {
    type: MessageType.CHARGE_LOCATION;
    data: ChargeLocation | ChargeLocationDeleted;
};

export type MessageRoamingLocation = {
    type: MessageType.ROAMING_LOCATION;
    data: RoamingLocation;
};

export type MessageEventLogOCPP = {
    type: MessageType.EVENT_LOG_OCPP;
    data: EventLogOCPP;
};

export type MessageActionEvent = {
    type: MessageType.ACTION_EVENT;
    data: EventLog;
};

export type MessageTransaction = {
    type: MessageType.TRANSACTION;
    data: Transaction;
};

export type MessageTransactionMeasurement = {
    type: MessageType.TRANSACTION_MEASUREMENT;
    data: TransactionMeasurement;
};

export type MessageUser = {
    type: MessageType.USER;
    data: User;
};

export type MessageHolder = {
    type: MessageType.HOLDER;
    data: Holder;
};

export type MessageAccount = {
    type: MessageType.ACCOUNT;
    data: Account;
};

export type MessageRoamingXP = {
    type: MessageType.ROAMING_XP;
    data: RoamingXP;
};

export type MessageIntegration = {
    type: MessageType.INTEGRATION;
    data: Integration;
};
export type MessageCorporate = {
    type: MessageType.CORPORATE;
    data: Corporate;
};

export type InfrastructureMessage =
    | MessageConnector
    | MessageEVSE
    | MessageChargePoint
    | MessageChargeLocation
    | MessageEventLogOCPP
    | MessageActionEvent
    | MessageTransaction
    | MessageTransactionMeasurement;

export type AdministrativeMessage = MessageUser | MessageHolder;
export type OperatorMessage =
    | MessageAccount
    | MessageRoamingXP
    | MessageIntegration
    | MessageCorporate
    | MessageRoamingLocation;
export type UserPersonalMessage = MessageRegistry;

export type Message = InfrastructureMessage | AdministrativeMessage | OperatorMessage | UserPersonalMessage;
