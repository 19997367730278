import { Box, Button, InputAdornment, Stack, TextField } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { RouterLink } from '../../elements/RouterLink';
import { Title } from '../../elements/Title';
import ChargeLocationListTableComponent from './components/ChargeLocationListTableComponent';
import { useParams } from 'react-router-dom';

export function PageHeader2() {
    const { operatorId } = useParams();

    return (
        <Stack direction={'row'} spacing={1} sx={{ mb: 4, alignItems: 'center' }}>
            <Title sx={{ flex: 1 }}>Зарядные локации</Title>
            <TextField
                placeholder="Поиск"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    // endAdornment: (
                    //     <IconButton size="small">
                    //         <ClearRoundedIcon fontSize="small" />
                    //     </IconButton>
                    // ),
                }}
                variant="outlined"
                size="small"
                // value={searchText}
                // onChange={event => setSearchText(event.target.value)}
            />
            <Button
                variant="contained"
                startIcon={<AddIcon />}
                component={RouterLink}
                to={`/${operatorId}/locations/create`}
            >
                Добавить
            </Button>
        </Stack>
    );
}

export default function ChargeLocationListPage() {
    return (
        <Box sx={{ pb: 10 }}>
            <PageHeader2 />
            <ChargeLocationListTableComponent />
        </Box>
    );
}
