import { RemoteStartStopStatus } from '@electrifly/ocpp/src/OCPPV16';
import * as yup from 'yup';

export type ReqParams = { humanId: string };
export type ReqBody = {
    energy: number;
    duration: number;
};

export type ResData = {
    // status: RemoteStartStopStatus;
};

export const validationSchema = yup.object({
    energy: yup.number().required().positive().min(0),
    duration: yup.number().required().positive().min(0),
});
