import { Outlet, useParams } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

import { ChargePointTopMenu } from './ChargePointTopMenu';
import { WithChargePoint } from '../wrappers/WithChargePoint';
import { ChargePointHeader } from './ChargePointHeader';

type ChagePointLayoutParams = {
    id: string;
};

export default function ChargePointLayout() {
    const { id } = useParams<ChagePointLayoutParams>();
    if (!id) {
        return null;
    }

    return (
        <WithChargePoint id={id}>
            <CssBaseline />
            <ChargePointHeader />
            <ChargePointTopMenu />
            <Outlet />
        </WithChargePoint>
    );
}
