import { Stack, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { RouterLink } from '../../elements/RouterLink';
import { useChargeLocation } from '../wrappers/WithChargeLocation';
import { ChargePointTableBlock } from './details-page/ChargePointTableBlock';
import { useParams } from 'react-router-dom';

function PageInternal() {
    const location = useChargeLocation();
    const { operatorId } = useParams();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stack>
                    <List>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="h5">Зарядные станции</Typography>
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <Button
                                    variant="contained"
                                    startIcon={<AddIcon />}
                                    component={RouterLink}
                                    to={`/${operatorId}/locations/${location._id}/chargepoint/create`}
                                >
                                    Добавить
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>

                    <ChargePointTableBlock />
                </Stack>
            </Grid>
        </Grid>
    );
}

export function ChargeLocationDashboardPage() {
    return <PageInternal />;
}
