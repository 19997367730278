import { Link, Skeleton, Stack, colors } from '@mui/material';
import { useEffect } from 'react';
import { RouterLink } from '../../../elements/RouterLink';
import { useDataCache } from '../../../services/DataCache';
import { DataWrap } from '../../event-log/components/DataWrap';
import { OperatorLink } from '../../operators/elements/OperatorLink';
import { useParams } from 'react-router-dom';

interface Props {
    id: string;
    displayEMPS?: boolean;
}

export function CorporateLink({ id, displayEMPS = false }: Props) {
    const loadCorporate = useDataCache(store => store.loadCorporate);
    const corporate = useDataCache(store => store.corporates[id]);
    const { operatorId } = useParams();

    useEffect(() => {
        loadCorporate(id);
    }, [id, loadCorporate]);

    if (!corporate) {
        return <Skeleton width={100} />;
    }

    return (
        <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
            {displayEMPS && corporate?.operator && (
                <DataWrap color={colors.grey[200]}>
                    <OperatorLink id={corporate.operator} />
                </DataWrap>
            )}
            <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to={`/${operatorId}/corporate/${id}`}
                onClick={event => event.stopPropagation()}
            >
                {corporate?.name || id}
            </Link>
        </Stack>
    );
}
