import create from 'zustand';

type DrawerStore = {
    isOpen: boolean;
    open: () => void;
    close: () => void;
    toggle: () => void;
};

export const useDrawerStore = create<DrawerStore>((set, get) => ({
    isOpen: false,
    open: () => set({ isOpen: true }),
    close: () => set({ isOpen: false }),
    toggle: () => set({ isOpen: !get().isOpen }),
}));

export const DrawerHelper = {
    // open: () => useDrawerStore.getState().open,
};
