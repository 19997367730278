import { Box, Tabs, Tab, Stack } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import { Title } from '../../../elements/Title';
import { useRouteMatch } from '../../../hooks/useRouteMatch';
import { RouterLink } from '../../../elements/RouterLink';
import { useEffectOnce } from 'usehooks-ts';
import { ReactNode } from 'react';
import { CorporateDetailsPageService, useCorporateDetailsPageService } from './services/PageService';
import { WithCorporate, useCorporate } from '../../wrappers/WithCorporate';

function TopMenu() {
    const { operatorId } = useParams();
    const routeMatch = useRouteMatch([
        //
        `/${operatorId}/corporate/:id`,
        `/${operatorId}/corporate/:id/transactions`,
        `/${operatorId}/corporate/:id/employees`,
        `/${operatorId}/corporate/:id/contact`,
    ]);
    const currentTab = routeMatch?.pattern?.path;

    return (
        <Box sx={{ marginTop: 2, marginBottom: 1 }}>
            <Box sx={{ display: 'flex', width: '100%', borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={currentTab} sx={{ flex: 1 }} variant="scrollable" scrollButtons="auto">
                    <Tab label="Информация" value={`/${operatorId}/corporate/:id`} component={RouterLink} to="" />
                    <Tab
                        label="Сотрудники"
                        value={`/${operatorId}/corporate/:id/employees`}
                        component={RouterLink}
                        to="employees"
                    />
                    <Tab
                        label="Транзакции"
                        value={`/${operatorId}/corporate/:id/transactions`}
                        component={RouterLink}
                        to="transactions"
                    />
                    {/* <Tab label="Контакты" value={'/corporate/:id/contact'} component={RouterLink} to="contact" /> */}
                </Tabs>
            </Box>
            <Stack direction={'row'} spacing={1} sx={{ padding: 1 }}>
                {/* {chargePointStatuses.map(status => (
                    <Chip key={status} label={`Станции: ${status}`} onDelete={() => removeChargePointFilter(status)} />
                ))}
                {connectorStatuses.map(status => (
                    <Chip key={status} label={`Коннекторы: ${status}`} onDelete={() => removeConnectorFilter(status)} />
                ))} */}
            </Stack>
        </Box>
    );
}

function LayoutInternal() {
    const corporate = useCorporate();

    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Корпоративный клиент {corporate.name}</Title>
            </Stack>

            <TopMenu />
            <Outlet />
        </>
    );
}

interface WithDataLoaderProps {
    children: ReactNode;
}
function WithDataLoader({ children }: WithDataLoaderProps) {
    const reset = useCorporateDetailsPageService(store => store.reset);
    const loadInformation = useCorporateDetailsPageService(store => store.loadInformation);

    const corporate = useCorporateDetailsPageService(store => store.corporate);

    useEffectOnce(() => {
        loadInformation();
        return () => reset();
    });

    if (!corporate) {
        return null;
    }

    return <WithCorporate corporate={corporate}>{children}</WithCorporate>;
}

export type CorporateDetailsPageParams = {
    id: string;
};

export function CorporateDetailsLayout() {
    const { id } = useParams<CorporateDetailsPageParams>();

    if (!id) {
        return null;
    }

    return (
        <CorporateDetailsPageService.Provider createStore={() => CorporateDetailsPageService.createStore(id)}>
            <WithDataLoader>
                <LayoutInternal />
            </WithDataLoader>
        </CorporateDetailsPageService.Provider>
    );
}
