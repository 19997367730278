import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Stack, Paper, Divider, Box, colors, Link } from '@mui/material';
import { PropsWithChildren, useMemo } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { RouterLink } from '../../../elements/RouterLink';
import { Filter, RoamingXPListService } from './RoamingXPListService';
import { RoamingXPItem } from './RoamingXPItem';

function LoadingIndicator() {
    const canLoadMore = RoamingXPListService.useStore(store => store.canLoadMore);
    const loading = RoamingXPListService.useStore(store => store.loading);
    const loadNext = RoamingXPListService.useStore(store => store.loadNext);

    const display = useMemo(() => loading || canLoadMore, [canLoadMore, loading]);

    if (!display) {
        return null;
    }

    return (
        <LoadingButton
            loading={loading}
            variant="text"
            size="large"
            fullWidth
            sx={{ marginY: 2 }}
            onClick={() => loadNext()}
        >
            <span>Загрузить ещё</span>
        </LoadingButton>
    );
}

function EmptyDataIndicator() {
    const count = RoamingXPListService.useStore(store => store.roamingXPs.length);
    const loading = RoamingXPListService.useStore(store => store.loading);

    const display = useMemo(() => !loading && count === 0, [loading, count]);

    if (!display) {
        return null;
    }

    return (
        <Alert severity="warning" icon={<SearchOffRoundedIcon />} sx={{ my: 2, display: 'flex', width: '100%' }}>
            Данные по запросу не найдены
        </Alert>
    );
}

function RoamingXPTable() {
    const roamingXPs = RoamingXPListService.useStore(store => store.roamingXPs);

    return (
        <>
            <Stack component={Paper} sx={{ paddingY: 1 }} direction={'column'} spacing={1} divider={<Divider />}>
                {roamingXPs.map(roamingXP => (
                    <Link
                        key={roamingXP._id}
                        component={RouterLink}
                        to={roamingXP._id}
                        sx={{ textDecoration: 'none' }}
                        color="inherit"
                    >
                        <Box key={roamingXP._id}>
                            <Box sx={{ p: 1, my: -1, ':hover': { backgroundColor: colors.grey[100] } }}>
                                <RoamingXPItem roamingXP={roamingXP} />
                            </Box>
                        </Box>
                    </Link>
                ))}
            </Stack>

            <LoadingIndicator />
            <EmptyDataIndicator />
        </>
    );
}

function LoadWrapper({ children }: PropsWithChildren<{}>) {
    const loadNext = RoamingXPListService.useStore(store => store.loadNext);
    const reset = RoamingXPListService.useStore(store => store.reset);

    useEffectOnce(() => {
        loadNext();
        return () => reset();
    });

    return <>{children}</>;
}

interface RoamingXPListProps {
    filter?: Filter;
}
export function RoamingXPList({ filter }: RoamingXPListProps) {
    return (
        <RoamingXPListService.Provider createStore={() => RoamingXPListService.createStore(filter)}>
            {/* <EventLogListTopMenu /> */}
            <LoadWrapper>
                <RoamingXPTable />
            </LoadWrapper>
        </RoamingXPListService.Provider>
    );
}
