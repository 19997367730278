import {
    Autocomplete,
    CircularProgress,
    FormControl,
    FormHelperText,
    Stack,
    Typography,
    colors,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import {FormikProvider, useFormik, useFormikContext} from 'formik';
import {useMemo, useRef, useState} from 'react';
import {API} from '../../../core/api-client';
import React from 'react';
import {
    CustomerSuggestionContext,
    createCustomerSuggestionStore,
} from '../../customers/services/CustomerSuggestionContext';
import {DataWrap} from '../../event-log/components/DataWrap';
import {OperatorName} from '../../operators/elements/OperatorName';
import {useOperatorId} from "../../../hooks/useOperatorId";

type FormValues = {
    customer?: string;
};

type SelectorOptions = {
    onSelect: (customerId: string) => void
}

function CustomerSelector(options: SelectorOptions) {
    const formik = useFormikContext<FormValues>();
    const inputRef = useRef<HTMLInputElement>();

    const loading = CustomerSuggestionContext.useStore(store => store.loading);
    const setSearch = CustomerSuggestionContext.useStore(store => store.setSearch);
    const customersInfo = CustomerSuggestionContext.useStore(store => store.customers);

    const selectedValue = useMemo(
        () => customersInfo.find(item => item._id === formik.values.customer),
        [customersInfo, formik.values.customer],
    );

    const onSelect = (value: any) => {
        formik.setValues(values => {
            return ({...values, customer: ""})
        })

        setSearch("")

        if(inputRef?.current) {
            inputRef!.current!.value = ''
        }

        options.onSelect(value)
    }

    return (
        <Stack spacing={1}>
            <FormControl fullWidth>
                <Autocomplete
                    sx={{minWidth: '15em'}}
                    fullWidth
                    options={customersInfo}
                    noOptionsText="Клиент не найден"
                    value={selectedValue || null} //null is neede for initialization render in controlled state
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    getOptionLabel={option => option.phone}
                    renderOption={(props, option, state) => (
                        <li {...props} key={option._id}>
                            <Stack direction={'row'} spacing={1} sx={{alignItems: 'center'}}>
                                {option.operator && (
                                    <DataWrap color={colors.grey[200]}>
                                        <OperatorName id={option.operator}/>
                                    </DataWrap>
                                )}
                                <Typography>{option.phone}</Typography>
                            </Stack>
                        </li>
                    )}
                    onInputChange={(event, newValue) => setSearch(newValue)}
                    onChange={(event, val) => onSelect(val?._id)}
                    renderInput={params => (
                        <TextField
                            {...params}
                            inputRef={inputRef}
                            autoFocus
                            variant="outlined"
                            placeholder="+79990000000"
                            error={formik.touched.customer && Boolean(formik.errors.customer)}
                            helperText={formik.touched.customer && formik.errors.customer}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        {selectedValue?.operator && (
                                            <DataWrap color={colors.grey[200]}>
                                                <OperatorName id={selectedValue.operator}/>
                                            </DataWrap>
                                        )}
                                    </>
                                ),
                                endAdornment: (
                                    <React.Fragment>
                                        {loading && <CircularProgress color="inherit" size={20}/>}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </FormControl>
        </Stack>
    );
}

export function CustomerSearcherForm(options: SelectorOptions) {
    const currentOperatorId = useOperatorId()

    const formik = useFormik<FormValues>({
        initialValues: {
            customer: undefined,
        },

        // validationSchema: CorporateEmployeeAdd.validationSchema,

        onSubmit: async values => {
            if (!values.customer) {
                return;
            }

            console.log(values);

            return;
        },
    });

    return (
        <>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2}>
                        <CustomerSuggestionContext.Provider
                            createStore={() => createCustomerSuggestionStore({operator: currentOperatorId})}
                        >
                            <CustomerSelector onSelect={options.onSelect}/>
                        </CustomerSuggestionContext.Provider>
                    </Stack>
                </form>
            </FormikProvider>
        </>
    );
}
