import * as yup from 'yup';

export type ReqParams = { id: string };
export type ReqBody = {
    paymentMethodId?: string;
};

export type ResData = {};

export const validationSchema = yup.object({
    paymentMethodId: yup.string().optional(),
});
