import * as yup from 'yup';
import { RoamingXP } from '../../data';

export type ReqBody = { name: string; operator: string };
export type ResData = RoamingXP;

export const validationSchema = yup.object({
    name: yup.string().required('Поле имя должно быть заполнено'),
    operator: yup.string().required('Поле оператор должно быть заполнено'),
});
