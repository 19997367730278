import * as yup from 'yup';
import { CorporateInfo } from '../../data/Corporate';

export type ReqQuery = {
    filter?: string;
    skip?: number;
    limit?: number;
    operator?: string;
};

export type ResData = CorporateInfo[];

export const validationSchema = yup.object({
    filter: yup.string().optional().max(100),
    operator: yup.string().optional(),
    skip: yup.number().optional().min(0).default(0),
    limit: yup.number().optional().min(1).max(1000).default(100),
});
