import { Link } from '@mui/material';
import { RouterLink } from '../../../elements/RouterLink';
import { useRegistry } from '../../../services/GlobalRegistry';
import { useParams } from 'react-router-dom';

interface RoamingLocationLinkProps {
    id: string;
}

export function RoamingLocationLink({ id }: RoamingLocationLinkProps) {
    const location = useRegistry(store => store.roamingLocations[id]);
    let { operatorId } = useParams();

    return (
        <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={`/${operatorId}/roaminglocations/${id}`}
            onClick={event => event.stopPropagation()}
        >
            {location?.name || id}
        </Link>
    );
}
