import { TransactionReInvoice } from '@electrifly/central-client-api';
import { LoadingButton } from '@mui/lab';
import {
    Button,
    DialogContentText,
    FormControl,
    FormHelperText,
    InputAdornment,
    Stack,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useState } from 'react';
import create from 'zustand';
import { API } from '../../../core/api-client';

type Store = {
    open: boolean;
    humanTransactionId: number;
    show: (humanTransactionId: number) => void;
    hide: () => void;
};

const useTransactionReInvoiceDialog = create<Store>((set, get) => ({
    open: false,
    humanTransactionId: -1,
    show: (humanTransactionId: number) => set({ open: true, humanTransactionId }),
    hide: () => set({ open: false, humanTransactionId: -1 }),
}));

export const TransactionReInvoiceDialogHelper = {
    show: (humanTransactionId: number) => useTransactionReInvoiceDialog.getState().show(humanTransactionId),
};

interface FormData {
    comment: string;
    energy?: number;
    duration?: number;
}
export function TransactionReInvoiceDialog() {
    const open = useTransactionReInvoiceDialog(store => store.open);
    const hide = useTransactionReInvoiceDialog(store => store.hide);
    const humanTransactionId = useTransactionReInvoiceDialog(store => store.humanTransactionId);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<FormData>({
        initialValues: {
            comment: '',
            energy: 0,
            duration: 0,
        },

        validationSchema: TransactionReInvoice.validationSchema,

        onSubmit: async values => {
            console.log(values);

            const valid = TransactionReInvoice.validationSchema.validateSync(values);

            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.transactionReInvoice(humanTransactionId, { ...valid });
            setIsRequesting(false);
            if (!error) {
                hide();
                formik.resetForm();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Переоформление счёта транзакции</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ paddingBottom: 2 }}>
                    Рассчёты по зарядной сессии будут автоматически переоформлены исходя из новых показаний
                </DialogContentText>
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2}>
                        <TextField
                            autoFocus
                            required
                            id="comment"
                            label="Причина переоформления счёта"
                            variant="outlined"
                            fullWidth
                            spellCheck={false}
                            value={formik.values.comment}
                            onChange={formik.handleChange}
                            error={formik.touched.comment && Boolean(formik.errors.comment)}
                            helperText={formik.touched.comment && formik.errors.comment}
                        />

                        <TextField
                            required
                            id="energy"
                            label="Полученная энергия | Вт⋅ч"
                            type="number"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Вт⋅ч</InputAdornment>,
                                inputProps: { step: '1' },
                            }}
                            value={formik.values.energy}
                            onChange={formik.handleChange}
                            error={formik.touched.energy && Boolean(formik.errors.energy)}
                            helperText={formik.touched.energy && formik.errors.energy}
                        />

                        <TextField
                            required
                            id="duration"
                            label="Длительноть зарядной сессии | минуты"
                            type="number"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">мин.</InputAdornment>,
                                inputProps: { step: '1' },
                            }}
                            value={formik.values.duration}
                            onChange={formik.handleChange}
                            error={formik.touched.duration && Boolean(formik.errors.duration)}
                            helperText={formik.touched.duration && formik.errors.duration}
                        />
                    </Stack>
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Переоформить</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
