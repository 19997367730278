import { Box } from '@mui/material';
import { TransactionListTableComponent } from '../transaction/components/list-table/TransactionListTableComponent';
import { useChargePoint } from '../wrappers/WithChargePoint';

export default function ChargePointTransactionHistoryPage() {
    const chargePoint = useChargePoint();

    return (
        <Box sx={{ marginTop: 2 }}>
            <TransactionListTableComponent filter={{ chargePoint: chargePoint._id }} />
        </Box>
    );
}
