import { Title } from '../../elements/Title';
import Button from '@mui/material/Button';
import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate } from 'react-router-dom';
import create from 'zustand';
import { API } from '../../core/api-client';

import { Operator } from '@electrifly/central-client-api';

import { useEffectOnce } from 'usehooks-ts';
import LoadingButton from '@mui/lab/LoadingButton';

type PageServiceData = {
    operators: Operator[];
    loading: boolean;
    filter: string;
    skip: number;
    limit: number;
    canLoadMore: boolean;
};

type PageServiceActions = {
    reset: () => void;
    loadNext: () => Promise<void>;
};

type PageService = PageServiceData & PageServiceActions;

const DEFAULT: PageServiceData = {
    operators: [],
    loading: false,
    filter: '',
    skip: 0,
    limit: 100,
    canLoadMore: true,
};

const usePageService = create<PageService>((set, get) => ({
    ...DEFAULT,

    reset: () => set({ ...DEFAULT }),

    loadNext: async () => {
        if (get().loading) {
            return;
        }
        set({ loading: true });

        const { filter, skip, limit } = get();
        const [error, res] = await API.operatorList({ filter, skip, limit });
        if (error) {
            console.error(error);
            set({ loading: false });
            return;
        }

        const newData = res.data;
        const canLoadMore = newData.length === limit;
        const nextSkip = skip + limit;

        set({ loading: false, skip: nextSkip, canLoadMore: canLoadMore, operators: [...get().operators, ...res.data] });
    },
}));

export default function OperatorListPage() {
    const navigate = useNavigate();
    const loading = usePageService(store => store.loading);
    const loadNext = usePageService(store => store.loadNext);
    const canLoadMore = usePageService(store => store.canLoadMore);
    const reset = usePageService(store => store.reset);
    const operators = usePageService(store => store.operators);

    useEffectOnce(() => {
        loadNext();
        return () => reset();
    });

    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Операторы</Title>
                <Button variant="contained" startIcon={<AddIcon />} component={Link} to="create">
                    Добавить
                </Button>
            </Stack>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Имя</TableCell>
                            <TableCell align="right">Страна</TableCell>
                            <TableCell align="right">Идентификатор</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {operators.map(row => (
                            <TableRow
                                key={row._id}
                                onClick={() => navigate(row._id)}
                                hover={true}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{row.name}</TableCell>
                                <TableCell align="right">{row.country}</TableCell>
                                <TableCell align="right">{row.partyId}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {canLoadMore && (
                <LoadingButton
                    loading={loading}
                    variant="text"
                    size="large"
                    fullWidth
                    sx={{ marginY: 2 }}
                    onClick={() => loadNext()}
                >
                    <span>Загрузить ещё</span>
                </LoadingButton>
            )}
        </>
    );
}
