import OCPP from '@electrifly/ocpp';
import { List, ListItem, ListItemText, Typography, Paper, Stack, Chip, Box, CircularProgress } from '@mui/material';
import { useCallback, useState } from 'react';
import { API } from '../../../core/api-client';
import { EditableValue } from '../../editable-values/EditableValue';
import { useChargePoint } from '../../wrappers/WithChargePoint';

interface ConfigurationItemProps {
    item: OCPP.V15.KeyValue | OCPP.V16.KeyValue;
}
function ConfigurationItem({ item }: ConfigurationItemProps) {
    const chargePoint = useChargePoint();
    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const updateConfiguration = useCallback(
        async (newValue: string) => {
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.chargePointChangeConfiguration(chargePoint._id, {
                key: item.key,
                value: newValue,
            });

            setIsRequesting(false);
            if (!error) {
                if (res?.data.status === 'Accepted') {
                    return;
                }

                setDisplayError(true);
                setError(res?.data.status);
                setTimeout(() => {
                    setDisplayError(false);
                }, 5000);
                return;
            }

            setDisplayError(true);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            setTimeout(() => {
                setDisplayError(false);
            }, 5000);
        },
        [chargePoint._id, item.key],
    );

    return (
        <ListItem divider disabled={item.readonly}>
            <Stack
                direction={'row'}
                spacing={2}
                sx={{ flex: 1, justifyContent: 'space-between', alignItems: 'center' }}
            >
                <Stack direction={'row'} spacing={2} sx={{ flex: 1, alignItems: 'center' }}>
                    <ListItemText primary={item.key} sx={{ flex: 0, minWidth: 'inherit' }} />
                    {item.readonly && <Chip size="small" label="readonly" />}
                </Stack>

                {isRequesting && <CircularProgress size={'1rem'} color="inherit" />}
                {displayError && (
                    <Typography color={'red'} fontSize="inherit">
                        {error}
                    </Typography>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        wordBreak: 'break-all',
                        textAlign: 'end',
                    }}
                >
                    <EditableValue value={item.value ?? ''} onNewValue={newValue => updateConfiguration(newValue)} />
                </Box>
            </Stack>
        </ListItem>
    );
}

export function OCPPConfigurationBlock() {
    const chargePoint = useChargePoint();
    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Конфигурация</Typography>
                    </ListItemText>
                </ListItem>
            </List>
            <List component={Paper} disablePadding dense>
                {chargePoint.ocpp.configuration.map(item => (
                    <ConfigurationItem key={item.key} item={item} />
                ))}
            </List>
        </>
    );
}
