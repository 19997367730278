import {
    AccessScope,
    Data,
    HolderNotification,
    HolderNotificationDefaults,
    HolderNotifictionType,
} from '@electrifly/central-client-api';
import {
    Button,
    Chip,
    colors,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Stack,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import { EditableValue } from '../../editable-values/EditableValue';
import { OperatorLink } from '../../operators/elements/OperatorLink';
import { UserListTableComponent } from '../../users/components/UserListTableComponent';
import { WithAccessScope } from '../../wrappers/WithAccessScope';
import { useHolder } from '../../wrappers/WithHolder';
import { HolderNotificationComponent } from '../components/HolderNotificationBlock';
import { useHolderChangeOperatorDialog } from '../dialogs/HolderChangeOperatorDialog';
import { useHolderChangeStateDialog } from '../dialogs/HolderChangeStateDialog';
import { useHolderRemoveDialog } from '../dialogs/HolderRemoveDialog';
import { useHolderDetailsPageService } from './services/PageService';
import _ from 'lodash';

function AdministativeBlock() {
    const holder = useHolder();
    const updateHolder = useHolderDetailsPageService(store => store.update);

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Информация</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <List component={Paper} disablePadding>
                {holder.deleted && (
                    <ListItem divider sx={{ backgroundColor: colors.red[100] }}>
                        <ListItemText primary="Владелец удалён" />
                    </ListItem>
                )}
                <ListItem divider>
                    <ListItemText primary="Название" />
                    <ListItemSecondaryAction>
                        <EditableValue
                            value={holder.identity}
                            onNewValue={newValue => updateHolder({ identity: newValue })}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="Оператор" />
                    <ListItemSecondaryAction>
                        <OperatorLink id={holder.operatorId} />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Статус" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={holder.state} />
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        </>
    );
}

function ActionsBlock() {
    const holder = useHolder();
    const showHolderChangeOperatorDialog = useHolderChangeOperatorDialog(store => store.show);
    const showHolderChangeStateDialog = useHolderChangeStateDialog(store => store.show);
    const showHolderRemoveDialog = useHolderRemoveDialog(store => store.show);

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Действия</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <Stack spacing={2}>
                <List disablePadding component={Paper}>
                    <WithAccessScope scope={AccessScope.GLOBAL}>
                        <ListItem divider>
                            <ListItemText primary="Изменить оператора" secondary="Переход к другому оператору" />
                            <ListItemSecondaryAction>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => showHolderChangeOperatorDialog(holder)}
                                >
                                    Изменить
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </WithAccessScope>
                    <WithAccessScope scope={AccessScope.OPERATOR}>
                        <ListItem divider>
                            <ListItemText primary="Статус" secondary="Активировать / заблокировать Владельца" />
                            <ListItemSecondaryAction>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => showHolderChangeStateDialog(holder)}
                                >
                                    Изменить
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Удаление" secondary="Удаление владельца" />
                            <ListItemSecondaryAction>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={() => showHolderRemoveDialog(holder)}
                                >
                                    Удалить
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </WithAccessScope>
                </List>
            </Stack>
        </>
    );
}

function NotificationsBlock() {
    const holder = useHolder();
    const updateNotificationState = useHolderDetailsPageService(store => store.updateNotificationState);
    const addEmailToNotification = useHolderDetailsPageService(store => store.addEmailToNotification);
    const removeEmailFromNotification = useHolderDetailsPageService(store => store.removeEmailFromNotification);

    const mapped = useMemo<Record<HolderNotifictionType, HolderNotification>>(() => {
        return {
            [HolderNotifictionType.ChargePointOffline]:
                holder.notifications.find(item => item.type === HolderNotifictionType.ChargePointOffline) ||
                HolderNotificationDefaults[HolderNotifictionType.ChargePointOffline],

            [HolderNotifictionType.MonthlyReport]:
                holder.notifications.find(item => item.type === HolderNotifictionType.MonthlyReport) ||
                HolderNotificationDefaults[HolderNotifictionType.MonthlyReport],
        };
    }, [holder.notifications]);

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Нотификации</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                    <HolderNotificationComponent
                        data={mapped[HolderNotifictionType.ChargePointOffline]}
                        onChangeActive={value =>
                            updateNotificationState({
                                type: HolderNotifictionType.ChargePointOffline,
                                value: value,
                            })
                        }
                        onAddEmail={email =>
                            addEmailToNotification({ type: HolderNotifictionType.ChargePointOffline, email: email })
                        }
                        onRemoveEmail={email =>
                            removeEmailFromNotification({
                                type: HolderNotifictionType.ChargePointOffline,
                                email: email,
                            })
                        }
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <HolderNotificationComponent
                        data={mapped[HolderNotifictionType.MonthlyReport]}
                        onChangeActive={value =>
                            updateNotificationState({
                                type: HolderNotifictionType.MonthlyReport,
                                value: value,
                            })
                        }
                        onAddEmail={email =>
                            addEmailToNotification({ type: HolderNotifictionType.MonthlyReport, email: email })
                        }
                        onRemoveEmail={email =>
                            removeEmailFromNotification({
                                type: HolderNotifictionType.MonthlyReport,
                                email: email,
                            })
                        }
                    />
                </Grid>
            </Grid>
        </>
    );
}

function ContactsBlock() {
    const holder = useHolder();
    const updateHolder = useHolderDetailsPageService(store => store.update);
    const [value, setValue] = useState(holder.comment);

    const save = async () => {
        if (holder.comment === value) {
            return;
        }
        await updateHolder({ comment: value });
    };

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Контакты</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <Paper>
                <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    variant="outlined"
                    hiddenLabel={true}
                    value={value}
                    onChange={element => setValue(element.currentTarget.value)}
                    onBlur={element => save()}
                    InputProps={{
                        autoComplete: 'off',
                    }}
                />
            </Paper>
        </>
    );
}

export default function HolderDetailsPage() {
    const holder = useHolder();

    return (
        <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
                <AdministativeBlock />
            </Grid>
            <Grid item lg={6} xs={12}>
                <ActionsBlock />
            </Grid>

            <Grid item xs={12}>
                <NotificationsBlock />
            </Grid>

            <Grid item xs={12}>
                <List>
                    <ListItem>
                        <ListItemText>
                            <Typography variant="h5">Администраторы</Typography>
                        </ListItemText>
                    </ListItem>
                </List>

                <UserListTableComponent filter={{ holder: holder._id }} />
            </Grid>

            <Grid item xs={12}>
                <ContactsBlock />
            </Grid>
        </Grid>
    );
}
