import AddIcon from '@mui/icons-material/Add';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {LoadingButton} from '@mui/lab';
import {
    Button,
    Collapse,
    DialogContentText,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Menu,
    Paper,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import create from 'zustand';
import {useRegistryEditor} from '../../../services/GlobalRegistryEditor';
import {useChargeLocation, WithChargeLocation} from "../../wrappers/WithChargeLocation";
import {useCorporate, useCorporateUnsafe} from "../../wrappers/WithCorporate";
import {API} from "../../../core/api-client";
import {CustomerLink} from "../../customers/elements/CustomerLink";
import {CustomerSearcherForm} from "../../customers/dialogs/CustomerSearcherForm";


interface CustomerItemProps {
    customer: string;
    onRemove: () => void
}

function EmployeeItem({customer, onRemove}: CustomerItemProps) {
    const corporate = useCorporateUnsafe();

    return (
        <ListItem key={customer} divider>
            <ListItemText
                primary={
                    <Stack direction={'row'} spacing={1}>
                        <CustomerLink id={customer}/>
                    </Stack>
                }
            />

            <ListItemSecondaryAction>
                <IconButton onClick={onRemove}>
                    <DeleteRoundedIcon fontSize="small"/>
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}

type FormValues = {
    infoMessage: string;
};

type Store = {
    open: boolean;
    chargeLocationId: string;
    show: (chargePointId: string) => void;
    hide: () => void;
};

export const useChargePointAllowClientsDialog = create<Store>((set, get) => ({
    open: false,
    chargeLocationId: '',
    show: (chargeLocationId: string) => set({open: true, chargeLocationId: chargeLocationId}),
    hide: () => set({open: false, chargeLocationId: ''}),
}));

function DialogInternal() {
    const open = useChargePointAllowClientsDialog(store => store.open);
    const hide = useChargePointAllowClientsDialog(store => store.hide);
    // const humanTransactionId = useChargePointChangeStateDialog(store => store.humanTransactionId);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [allowClientIds, setAllowClientIds] = useState<string[]>([]);

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');
    const chargeLocation = useChargeLocation();
    const updateChargeLocation = useRegistryEditor(store => store.updateChargeLocation);

    const onSubmit = () => {
        updateChargeLocation(chargeLocation, { allowClientIds });

        hide()
    }

    const onRemove = (id: string) => {
        setAllowClientIds(allowClientIds.filter(e => e !== id))
    }

    const onSelect = (customerId: string) => {
        console.log(customerId);

        if (customerId) {
            setAllowClientIds([...allowClientIds, customerId])
        }
    }

    useEffect(() => {
        setAllowClientIds(chargeLocation.allowClientIds)
    }, [chargeLocation]);

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>
                Список разрешенных пользователей
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{paddingBottom: 1}}></DialogContentText>

                <CustomerSearcherForm onSelect={onSelect}/>

                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{textAlign: 'center', color: '#d32f2f'}}
                >
                    {error}
                </FormHelperText>

                <Grid item xs={12} pt={3}>
                    <Stack spacing={1}>
                        <List component={Paper} disablePadding>
                            {allowClientIds.map((customer: string) => (
                                <EmployeeItem key={customer} onRemove={() => onRemove(customer)} customer={customer}/>
                            ))}
                        </List>

                        {
                            allowClientIds.length == 0 ? <List>Список пользователей пуст.</List> : null
                        }
                    </Stack>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={() => onSubmit()} loading={isRequesting}>
                    <span>Сохранить</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export function ChargePointAllowClientsDialog() {
    const chargePointId = useChargePointAllowClientsDialog(store => store.chargeLocationId);

    return (
        <WithChargeLocation id={chargePointId}>
            <DialogInternal/>
        </WithChargeLocation>
    );
}
