import {
    Badge,
    Box,
    Button,
    Chip,
    Collapse,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Radio,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import {useParams} from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import React, {useEffect, useMemo, useState} from 'react';
import {ConnectorStatus} from '../status/ConnectorStatus';
import {AccessScope, ChargePoint, ConnectorTariff} from '@electrifly/central-client-api';
import {useRegistryEditor} from '../../../services/GlobalRegistryEditor';
import {useConnector, WithConnector} from '../../wrappers/WithConnector';
import {useChargePoint, WithChargePoint} from '../../wrappers/WithChargePoint';
import {WithEVSE} from '../../wrappers/WithEVSE';
import {useAccesScope} from '../../wrappers/WithAccessScope';
import {RestrictPriceEditionBlock} from './RestrictPriceEditionBlock';
import {ConnectorTypeText} from '../../connector/Standard';
import {ConnectorTurnedOffChip} from '../../connector/ConnectorTurnedOffChip';
import {useConnectorBySeqNumber} from '../../../hooks/chargePointHooks';
import SellRoundedIcon from '@mui/icons-material/SellRounded';
import {PriceBlock} from './components/PriceBlock';
import {createWithImmer} from '../../../misc/CreateWithImmer';
import createContext from 'zustand/context';
import {StoreApi} from 'zustand';
import {useDefaultTafiffGroupId} from '../../../hooks/connectorHooks';
import {API} from '../../../core/api-client';
import {nanoid} from 'nanoid';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    closestCenter,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {WithAccountLoader} from '../../wrappers/WithAccountLoader';
import {OwnerLink} from '../../links/OwnerLink';
import {ChargePointAddAccountToTariffDialogHelper} from '../dialogs/ChargePointAddAccountToTariffDialog';
import {ConnectorTariffType} from "@electrifly/central-client-api/data";
import {DateTime} from 'luxon';
import {LocalizationProvider, TimePicker} from '@mui/x-date-pickers-pro';
import {AdapterLuxon} from '@mui/x-date-pickers-pro/AdapterLuxon';
import {useRegistry} from "../../../services/GlobalRegistry";
import ConfirmationDialog from "../../mixin/ConfirmationDialog";
import {DefaultApprovalStatus} from "@electrifly/central-client-api/data/enums/ModelEnums";
import { useEffectOnce } from 'usehooks-ts';

interface InternalService {
    groupId: string;
    setGroupId: (groupId: string) => void;
}

let globalInterval: any = null

const createStore = (groupId: string) => {
    return createWithImmer<InternalService>((set, get) => ({
        groupId: groupId,
        setGroupId: groupId => set({groupId}),
    }));
};

const {Provider, useStore} = createContext<StoreApi<InternalService>>();

type ConnectorBlockProps = {
    chargePoint: ChargePoint;
    evseNumber: number;
    connectorNumber: number;
};

function ConnectorBlock({chargePoint, evseNumber, connectorNumber}: ConnectorBlockProps) {
    const tariffGroupId = useStore(store => store.groupId);
    const connector = useConnector();
    const updateConnectorPrice = useRegistryEditor(store => store.updateConnectorPrice);

    const tariff = useMemo(() => {
        return connector.tariffs.find(tariff => tariff.groupId === tariffGroupId);
    }, [connector.tariffs, tariffGroupId]);

    if (!tariff) {
        return null;
    }

    return (
        <List component={Paper} key={connector._id} disablePadding>
            <ListItem
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: connector.statusMessage.status === 'Faulted' ? 'rgba(255, 0,0,0.05)' : '',
                }}
            >
                <ListItemIcon>
                    <Badge
                        badgeContent={
                            <Stack direction={'row'} divider={<Box>:</Box>}>
                                <Box>{evseNumber}</Box>
                                <Box>{connectorNumber}</Box>
                            </Stack>
                        }
                        color={`status.${connector.statusMessage.status.toLowerCase()}` as any}
                        anchorOrigin={{horizontal: 'left', vertical: 'top'}}
                    >
                        <ConnectorStatus status={connector.statusMessage.status} fontSize="large"/>
                    </Badge>
                </ListItemIcon>

                <ListItemText
                    primaryTypographyProps={{component: 'div'}}
                    primary={
                        <Stack direction={'row'} spacing={1}>
                            <Typography>{connector.statusMessage.status}</Typography>
                            {connector.isTurnedOff && <ConnectorTurnedOffChip/>}
                        </Stack>
                    }
                    secondaryTypographyProps={{component: 'div'}}
                    secondary={
                        <Stack direction={'row'} spacing={1} divider={<Divider orientation="vertical" flexItem/>}>
                            <Typography component="span">
                                <ConnectorTypeText type={connector.standard}/>
                            </Typography>
                            <Typography component="span">{connector.power} кВт</Typography>
                            <Typography component="span">{connector.powerType}</Typography>
                        </Stack>
                    }
                />
            </ListItem>

            <PriceBlock
                price={tariff.price}
                onUpdate={newPrice => updateConnectorPrice(connector, tariff._id, newPrice)}
            />
        </List>
    );
}

interface TariffItemRestrictionProps {
    tariff: ConnectorTariff;
    isActive: boolean;
}

function TariffItemRestriction({tariff, isActive}: TariffItemRestrictionProps) {
    const chargePoint = useChargePoint();
    const firstConnector = useConnectorBySeqNumber(chargePoint, 1);
    const removeAccountFromTariffGroup = useRegistryEditor(store => store.removeAccountFromTariffGroup);

    return (
        <Collapse in={isActive} timeout="auto" unmountOnExit>
            <ListItem>
                <Grid container spacing={1}>
                    {tariff.accounts?.map((accountId, index) => (
                        <WithAccountLoader key={accountId} id={accountId}>
                            {account => (
                                <Grid item>
                                    <Chip
                                        key={index}
                                        label={<OwnerLink type={account.ownerType} owner={account.owner}/>}
                                        onDelete={() =>
                                            removeAccountFromTariffGroup(firstConnector, tariff.groupId, accountId)
                                        }
                                    />
                                </Grid>
                            )}
                        </WithAccountLoader>
                    ))}

                    <Grid item>
                        <Button
                            variant="text"
                            size="small"
                            onClick={event =>
                                ChargePointAddAccountToTariffDialogHelper.show(firstConnector, tariff.groupId)
                            }
                        >
                            + Добавить аккаунт
                        </Button>
                    </Grid>
                </Grid>
            </ListItem>
        </Collapse>
    );
}

function TariffItemNight({tariff, isActive}: TariffItemRestrictionProps) {
    const chargePoint = useChargePoint();
    const updateTariffRestrictions = useRegistryEditor(store => store.updateTariffRestrictions);
    const firstConnector = useConnectorBySeqNumber(chargePoint, 1);

    const [startDateTime, setStartDateTime] = useState<DateTime | null>(null);
    const [endDateTime, setEndDateTime] = useState<DateTime | null>(null);

    // console.log('Initial called', startDateTime?.toUnixInteger(), endDateTime?.toUnixInteger());

    const updateRestrictions = () => {
        if (globalInterval) {
            clearInterval(globalInterval)
        }

        globalInterval = setTimeout(() => {
            const restrictions = {
                start_time: startDateTime?.toFormat('HH:mm'),
                end_time: endDateTime?.toFormat('HH:mm'),
            }

            API.connectorTariffUpdateRestrictionTimes(firstConnector._id, {
                tariffGroupId: tariff.groupId,
                restrictions
            }).then(() => {
                updateTariffRestrictions({
                    ...tariff,
                    restrictions,
                })
            })
        }, 500)
    }

    useEffectOnce(() => {
        const defaultFrom = DateTime.fromFormat(tariff.restrictions?.start_time ?? '22:00', 'HH:mm')
        const defaultTo = DateTime.fromFormat(tariff.restrictions?.end_time ?? '08:00', 'HH:mm')

        setStartDateTime(defaultFrom)
        setEndDateTime(defaultTo)
    });

    useEffect(() => {
        console.log('UseMemoCalled', startDateTime?.toUnixInteger(), endDateTime?.toUnixInteger());
        updateRestrictions()
    }, [startDateTime, endDateTime]);

    return (
        <Collapse in={isActive} timeout="auto" unmountOnExit>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <ListItem>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Stack direction={'row'} spacing={2} sx={{flex: 1, alignItems: 'center'}}>
                                <TimePicker
                                    value={startDateTime}
                                    onChange={newValue => {
                                        console.log({newValue: newValue?.toFormat('HH:mm')});
                                        setStartDateTime(newValue);
                                    }}
                                    // slots={{
                                    //     textField: props => <TextField {...{...props, disabled: false}} size="small" sx={{maxWidth: 120}}/>,
                                    // }}
                                    ampm={false}
                                />
                                <Typography>–</Typography>
                                <TimePicker
                                    value={endDateTime}
                                    onChange={newValue => {
                                        console.log({newValue});

                                        setEndDateTime(newValue);
                                    }}
                                    // slots={{
                                    //     textField: props => <TextField {...props} size="small" sx={{maxWidth: 120}}/>,
                                    // }}
                                    ampm={false}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </ListItem>
            </LocalizationProvider>
        </Collapse>
    );
}

interface TariffItemProps {
    tariff: ConnectorTariff;
}

function TariffItem({tariff}: TariffItemProps) {
    const chargePoint = useChargePoint();
    const selectedTariffGroupId = useStore(store => store.groupId);
    const setGroupId = useStore(store => store.setGroupId);
    const isSelected = useMemo(() => tariff.groupId === selectedTariffGroupId, [tariff.groupId, selectedTariffGroupId]);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false)
    const removeTariffAction = useRegistryEditor(store => store.removeTariff);
    const firstConnector = useConnectorBySeqNumber(chargePoint, 1);

    const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: tariff._id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const onDeleteDialogClose = (val: boolean) => {
        setShowConfirmDeleteDialog(false)

        if (val) {
            removeTariffAction(tariff)

            API.removeTariff(firstConnector._id, {
                tariffGroupId: tariff.groupId,
            }).then(values => {
                const [_, error] = values

                if (!error) {
                    removeTariffAction(tariff)
                }
            })
        }
    }

    const tariffName = () => {
        if (tariff.type == "main") {
            return 'Основной тариф'
        }

        if (tariff.type == 'personal') {
            return 'Персональный тариф'
        }

        if (tariff.type == "night") {
            return 'Ночной тариф'
        }

        switch (tariff.type) {
            case "main":
                return 'Основной тариф'
            case "personal":
                return 'Персональный тариф'
            case "night":
                return 'Ночной тариф'

        }

        return 'Неизвестный тариф'
    }

    const isMain = tariff.type == 'main'

    return (
        <List
            key={tariff._id}
            component={Paper}
            disablePadding
            //sortable props
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            elevation={isSelected ? 4 : 1}
        >
            <ListItemButton selected={isSelected} onClick={event => setGroupId(tariff.groupId)}>
                <ListItemIcon>
                    <SellRoundedIcon/>
                </ListItemIcon>
                <ListItemText primary={tariffName()}/>
                <ListItemSecondaryAction>
                    {isSelected && <IconButton onClick={() => setShowConfirmDeleteDialog(true)} aria-label="delete"
                                               disabled={isMain}>
                        <DeleteIcon/>
                    </IconButton>}
                </ListItemSecondaryAction>
            </ListItemButton>
            {tariff.type == 'personal' && <TariffItemRestriction tariff={tariff} isActive={isSelected}/>}
            {tariff.type == 'night' && <TariffItemNight tariff={tariff} isActive={isSelected}/>}

            <ConfirmationDialog message={"Вы уверены что хотите удалить этот тариф?"} onClose={onDeleteDialogClose}
                                open={showConfirmDeleteDialog}/>
        </List>
    );
}

function TariffGroupSelector() {
    const chargePoint = useChargePoint();
    const firstConnector = useConnectorBySeqNumber(chargePoint, 1);
    const [loading, setLoading] = useState(false);
    const reorderConnectorTariff = useRegistryEditor(store => store.reorderConnectorTariff);
    const canEditTariff = useAccesScope(AccessScope.OPERATOR);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isAddedMenuOpen = Boolean(anchorEl);

    const handleShowOptionsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleShowOptionsMenuClose = () => {
        setAnchorEl(null);
    };

    const createTariff = async (type: ConnectorTariffType) => {
        const groupId = nanoid();
        setLoading(true);
        const [error, res] = await API.connectorTariffCreate(firstConnector._id, {groupId, type});
        setLoading(false);

        handleShowOptionsMenuClose()

        if (!error) {
            console.log(res.data);
            return;
        }

    };

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const tariffs = useMemo(() => {
        return firstConnector.tariffs.map(item => ({id: item._id, ...item}));
    }, [firstConnector.tariffs]);

    async function handleDragEnd(event: DragEndEvent) {
        const {active, over} = event;

        if (active.id !== over?.id) {
            const oldIndex = tariffs.findIndex(item => item._id === active.id);
            const newIndex = tariffs.findIndex(item => item._id === over?.id);

            reorderConnectorTariff(firstConnector, tariffs[oldIndex].groupId, oldIndex, newIndex);
        }
    }

    return (
        <Stack spacing={2}>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext items={tariffs} strategy={verticalListSortingStrategy}>
                    {tariffs.map(tariff => (
                        <TariffItem key={tariff._id} tariff={tariff}/>
                    ))}
                </SortableContext>
            </DndContext>
            {canEditTariff && (
                <Box textAlign='center'>
                    <LoadingButton
                        variant="text"
                        startIcon={<AddIcon/>}
                        // onClick={() => createTariff()}
                        onClick={handleShowOptionsMenu}
                        loading={loading}
                    >
                        <span>Добавить</span>
                    </LoadingButton>
                </Box>
            )}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={isAddedMenuOpen}
                onClose={handleShowOptionsMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => createTariff('personal')}>Персональный тариф</MenuItem>
                <MenuItem onClick={() => createTariff('night')}>Ночной тариф</MenuItem>
            </Menu>
        </Stack>
    );
}

function ChargePointPricePageInternal() {
    const chargePoint = useChargePoint();
    const canEditTariff = useAccesScope(AccessScope.OPERATOR);

    const firstConnector = useConnectorBySeqNumber(chargePoint, 1);
    const tariffGroupId = useDefaultTafiffGroupId(firstConnector);

    return (
        <Grid container spacing={2} sx={{marginTop: 0}}>
            {!canEditTariff && (
                <Grid item xs={12} sx={{marginTop: 2}}>
                    <RestrictPriceEditionBlock/>
                </Grid>
            )}
            <Grid item xs={12}>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Установка тарифа</Typography>
                    </ListItemText>
                </ListItem>
            </Grid>

            <Provider createStore={() => createStore(tariffGroupId)}>
                <Grid item lg={6} xs={12}>
                    <Stack spacing={2}>
                        <TariffGroupSelector/>
                    </Stack>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Stack spacing={2}>
                        {chargePoint.evses.map((evse, evseIndex) => (
                            <WithEVSE key={evse._id} id={evse._id}>
                                {evse.connectors.map((connector, connectorIndex) => (
                                    <WithConnector key={connector._id} id={connector._id}>
                                        <ConnectorBlock
                                            key={connector._id}
                                            chargePoint={chargePoint}
                                            evseNumber={evseIndex + 1}
                                            connectorNumber={connectorIndex + 1}
                                        />
                                    </WithConnector>
                                ))}
                            </WithEVSE>
                        ))}
                    </Stack>
                </Grid>
            </Provider>
        </Grid>
    );
}

type ChargePointPricePageParams = {
    id: string;
};
export default function ChargePointPricePage() {
    const {id} = useParams<ChargePointPricePageParams>();

    if (!id) {
        return null;
    }

    return (
        <WithChargePoint id={id}>
            <ChargePointPricePageInternal/>
        </WithChargePoint>
    );
}
