import { AddPaymentMethodBilling, BalanceBilling, Billing, ChargeBilling } from '@electrifly/central-client-api';
import FaceIcon from '@mui/icons-material/Face';
import { Alert, colors, Divider, Typography } from '@mui/material';
import { DataWrap } from '../../../../event-log/components/DataWrap';
import { OwnerLink } from '../../../../links/OwnerLink';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';

interface EventRowProps {
    billing: Billing;
}
export function TransactionBillingRow({ billing }: EventRowProps) {
    return (
        <>
            <Divider orientation="vertical" flexItem />
            {/* <DataWrap color={colors.grey[200]}> */}
            <Typography variant="caption" fontSize={'inherit'}>
                Оплата зарядной сессии
            </Typography>
            {/* </DataWrap> */}
        </>
    );
}
