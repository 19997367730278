import AddIcon from '@mui/icons-material/Add';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {
    Button,
    Chip,
    Collapse,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    Menu,
    MenuItem,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import { API } from '../../../core/api-client';
import AccountDetails from '../../account/AccountDetails';
import { AccountChangeBalanceDialogHelper } from '../../account/dialogs/AccountChangeBalanceDialog';
import { CustomerLink } from '../../customers/elements/CustomerLink';
import { useCorporate } from '../../wrappers/WithCorporate';
import { CorporateEmployeeAddDialogHelper } from '../dialogs/CorporateEmployeeAddDialog';
import { WithAccount } from '../../wrappers/WithAccount';
import { WithAccountLoader } from '../../wrappers/WithAccountLoader';
import { TransitionGroup } from 'react-transition-group';
import { Employee, EmployeeRole } from '@electrifly/central-client-api';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { useMemo, useRef } from 'react';
import { useHover } from 'usehooks-ts';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import React from 'react';
// import { useHover } from '../../../hooks/useHover';

function AccountBlock() {
    const corporate = useCorporate();

    return (
        <WithAccountLoader id={corporate.account}>
            {account => (
                <WithAccount account={account}>
                    <List>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="h5">Аккаунт</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>

                    <AccountDetails account={account} />
                </WithAccount>
            )}
        </WithAccountLoader>
    );
}

function ActionsBlock() {
    const corporate = useCorporate();

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Действия</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <Stack spacing={2}>
                <List disablePadding component={Paper}>
                    <ListItem divider>
                        <ListItemText
                            primary="Пополнение баланса"
                            secondary="Зарегистрировать пополнение баланса клиента (прямой перевод или другие способы)"
                        />
                        <ListItemSecondaryAction>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => AccountChangeBalanceDialogHelper.show(corporate.account)}
                            >
                                Пополнить
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Stack>
        </>
    );
}

interface EmployeeItemProps {
    employee: Employee;
}
function EmployeeItem({ employee }: EmployeeItemProps) {
    const corporate = useCorporate();
    const isManager = useMemo(() => employee.role === 'manager', [employee.role]);

    const remove = async (customerId: string) => {
        const [error, res] = await API.corporateEmployeeRemove(corporate._id, {
            customer: customerId,
        });
    };

    const setRole = async (customerId: string) => {
        const newRole: EmployeeRole = isManager ? 'member' : 'manager';
        const [error, res] = await API.corporateEmployeeSetRole(corporate._id, {
            customer: customerId,
            role: newRole,
        });
    };

    return (
        <ListItem key={employee.customer} divider>
            <ListItemText
                primary={
                    <Stack direction={'row'} spacing={1}>
                        <CustomerLink id={employee.customer} />
                        {isManager && (
                            <Chip icon={<SupervisorAccountRoundedIcon />} size="small" label="Администратор" />
                        )}
                    </Stack>
                }
            />

            <ListItemSecondaryAction>
                <PopupState variant="popover">
                    {popupState => (
                        <React.Fragment>
                            <IconButton size="small" {...bindTrigger(popupState)}>
                                <MoreVertRoundedIcon />
                            </IconButton>
                            <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                    onClick={() => {
                                        setRole(employee.customer);
                                        popupState.close();
                                    }}
                                >
                                    <ListItemIcon>
                                        <SupervisorAccountRoundedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        {isManager ? 'Убрать права администратора' : 'Назначить администратором'}
                                    </ListItemText>
                                </MenuItem>

                                <MenuItem
                                    onClick={() => {
                                        remove(employee.customer);
                                        popupState.close();
                                    }}
                                >
                                    <ListItemIcon>
                                        <DeleteRoundedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Удалить</ListItemText>
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    )}
                </PopupState>
            </ListItemSecondaryAction>
        </ListItem>
    );
}

function EmployeesBlock() {
    const corporate = useCorporate();

    return (
        <Grid item xs={12}>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Сотрудники</Typography>
                    </ListItemText>
                    <ListItemSecondaryAction>
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={event => CorporateEmployeeAddDialogHelper.show(corporate)}
                        >
                            Добавить
                        </Button>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>

            <Stack spacing={1}>
                <List component={Paper} disablePadding>
                    <TransitionGroup>
                        {corporate.employees.map(employee => (
                            <Collapse key={employee.customer}>
                                <EmployeeItem employee={employee} />
                            </Collapse>
                        ))}
                    </TransitionGroup>
                </List>
                <Button
                    fullWidth
                    variant="text"
                    startIcon={<AddIcon />}
                    onClick={event => CorporateEmployeeAddDialogHelper.show(corporate)}
                >
                    Добавить
                </Button>
            </Stack>
        </Grid>
    );
}

export function CorporateEmployeesPage() {
    return (
        <Grid container spacing={2}>
            <EmployeesBlock />
        </Grid>
    );
}
