import { ChargePointState } from '@electrifly/central-client-api';
import { useMemo } from 'react';

const TypeToText: Record<ChargePointState, string> = {
    CREATED: 'Настройка',
    PRODUCTION: 'Эксплуатация',
    SERVICE: 'Тех. обслуживание',
    REPAIR: 'Ремонт',
    SUSPENDED: 'Приостановлено',
    RETIRED: 'Выведена из эксплуатации',
};

export function useChargePointStateText(state: ChargePointState) {
    const text = useMemo(() => TypeToText[state] || state, [state]);
    return text;
}

interface ChargePointStateTextProps {
    state: ChargePointState;
}

export function ChargePointStateText({ state }: ChargePointStateTextProps) {
    const text = useChargePointStateText(state);
    return <>{text}</>;
}
