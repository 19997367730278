import React from 'react';
import { createContext, ReactNode, useContext } from 'react';
import invariant from 'tiny-invariant';
import { Holder } from '@electrifly/central-client-api';

const HolderContext = createContext<Holder | null>(null);

type Props = {
    holder: Holder;
    children: ReactNode;
};

export const WithHolder = ({ holder, children }: Props) => {
    const [currentHolder, setHolder] = React.useState(holder);
    React.useEffect(() => setHolder(holder), [holder]);
    return <HolderContext.Provider value={currentHolder}>{children}</HolderContext.Provider>;
};

export function useHolder() {
    const customer = useContext(HolderContext);
    invariant(!!customer, 'Error getting holder. Possible you forgot to add WithHolder wrapper');
    return customer;
}
