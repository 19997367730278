import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Title } from '../../../elements/Title';
import { CustomerListTopMenu } from './TopMenu';
import { WithCustomerListFilter } from '../components/customers-table/WithCustomerListFilter';

export function CustomerListLayout() {
    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Клиенты</Title>
            </Stack>

            <WithCustomerListFilter>
                <CustomerListTopMenu />
                <Outlet />
            </WithCustomerListFilter>
        </>
    );
}
