import {
    colors,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Duration } from 'luxon';
import { UptimeOverviewItem } from '@electrifly/central-client-api/data';
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import { useNavigate, useParams } from 'react-router-dom';
import { useChargePoint, WithChargePoint } from '../../../wrappers/WithChargePoint';
import { ChargePointFreevend } from '../../../charge-point/status/ChargePointFreevend';
import { ChargePointPrivate } from '../../../charge-point/status/ChargePointPrivate';
import { ChargePointStateShort } from '../../../charge-point/status/ChargePointStateShort';
import { ChargePointStatusShort } from '../../../charge-point/status/ChargePointStatusShort';
import { ConnectionStatus } from '../../../charge-point/status/ConnectionStatus';
import { useChargeLocation, WithChargeLocation } from '../../../wrappers/WithChargeLocation';
import { ChargeLocationLink } from '../../../charge-location/elements/ChargeLocationLink';
import { DataWrap } from '../../../event-log/components/DataWrap';
import { ChargePointLink } from '../../../charge-point/elements/ChargePointLink';

interface UptimePercentProps {
    percent: number;
}
function UptimePercent({ percent }: UptimePercentProps) {
    const uptimeStr = useMemo(() => new BigNumber(percent).toFixed(3), [percent]);
    const wrapperColor = useMemo(() => {
        if (percent > 90) return colors.green[300];
        if (percent > 75) return colors.orange[300];
        return colors.red[200];
    }, [percent]);

    return <DataWrap color={wrapperColor}>{uptimeStr} %</DataWrap>;
}

interface Props {
    item: UptimeOverviewItem;
}
function UptimeItem({ item }: Props) {
    const chargePoint = useChargePoint();
    const chargeLocation = useChargeLocation();
    const navigate = useNavigate();
    const { operatorId } = useParams();
    const onlineDurationStr = useMemo(() => Duration.fromMillis(item.online).toFormat('hh:mm:ss'), [item.online]);
    const offlineDurationStr = useMemo(() => Duration.fromMillis(item.offline).toFormat('hh:mm:ss'), [item.offline]);

    const onNavigate = () => {
        const selection = window.getSelection && window.getSelection();
        if (selection && selection.type !== 'Range') {
            navigate(`/${operatorId}/chargepoint/${item.chargePoint}/uptime`);
        }
    };

    return (
        <TableRow onClick={onNavigate} hover={true} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell>
                <Stack>
                    <ChargeLocationLink id={chargeLocation._id} />
                    <Typography variant="caption" color="text.secondary">
                        {chargeLocation.address}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell>
                <Stack direction={'row'} alignItems="center" spacing={1}>
                    <ConnectionStatus status={chargePoint.ocpp.connectionStatus} />
                    <ChargePointLink id={chargePoint._id} />
                    {/* <Typography fontSize={'inherit'} sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                        {chargePoint.physicalReference}
                    </Typography> */}
                    {!chargePoint.publish && <ChargePointPrivate />}
                    {chargePoint.freevend && <ChargePointFreevend />}
                    <ChargePointStatusShort chargePoint={chargePoint} />
                    <ChargePointStateShort chargePoint={chargePoint} />
                </Stack>
            </TableCell>
            <TableCell align="right">{onlineDurationStr}</TableCell>
            <TableCell align="right">{offlineDurationStr}</TableCell>
            <TableCell align="right">
                <UptimePercent percent={item.uptime} />
            </TableCell>
        </TableRow>
    );
}

function UptimeItemWrapper({ children }: PropsWithChildren<{}>) {
    const chargePoint = useChargePoint();
    return <WithChargeLocation id={chargePoint.location}>{children}</WithChargeLocation>;
}

interface ChartProps {
    data: UptimeOverviewItem[];
}
export function UptimeSummaryTable({ data }: ChartProps) {
    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Локация</TableCell>
                        <TableCell>Станция</TableCell>
                        <TableCell align="right">Online</TableCell>
                        <TableCell align="right">Offline</TableCell>
                        <TableCell align="right">Доступность</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, index) => (
                        <WithChargePoint key={item.chargePoint} id={item.chargePoint}>
                            <UptimeItemWrapper>
                                <UptimeItem item={item} />
                            </UptimeItemWrapper>
                        </WithChargePoint>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
