import { Box, Fab } from '@mui/material';
import React, { useMemo, useRef } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import MyLocationRoundedIcon from '@mui/icons-material/MyLocationRounded';
import { MapMarkerIconURI } from '../../../elements/MapMarketIcon';

type MapChargeLocationViewProps = {
    coordinate: {
        latitude: number;
        longitude: number;
    };
};

export function MapChargeLocationView({ coordinate }: MapChargeLocationViewProps) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLEMAPS_APIKEY!,
    });

    const mapRef = useRef<google.maps.Map>();

    const latLng = useMemo<google.maps.LatLngLiteral>(() => {
        return {
            lat: coordinate.latitude,
            lng: coordinate.longitude,
        };
    }, [coordinate]);

    const onLoad = React.useCallback((map: google.maps.Map) => {
        mapRef.current = map;
    }, []);

    const moveToMarker = React.useCallback(() => {
        mapRef.current?.moveCamera({ center: latLng });
    }, []);

    if (!isLoaded) {
        return null;
    }

    return (
        // <Box sx={{ position: 'absolute', top: 0, left: 0, height: '100vh', width: '100vw' }}>
        <Box sx={{ height: '400px', width: '100%', position: 'relative' }}>
            <GoogleMap
                key={'map'}
                mapContainerStyle={{ flexGrow: '1', height: '100%' }}
                center={latLng}
                zoom={14}
                // onZoomChanged={() => onZoomChange()}
                // onCenterChanged={() => onCenterChanged()}
                onLoad={onLoad}
                // onUnmount={onUnmount}
                // onClick={onClick}
                options={{
                    // mapTypeControl: false,
                    fullscreenControl: false,
                    streetViewControl: false,
                    minZoom: 4,
                    // zoom: zoom,
                    gestureHandling: 'greedy',
                }}
            >
                <Marker
                    icon={MapMarkerIconURI}
                    // key={loc._id}
                    position={latLng}
                    // clusterer={clusterer}
                    // onClick={() => onMarkerClick()}
                    // noClustererRedraw={noClustererRedraw}
                />
            </GoogleMap>
            <Fab size="medium" sx={{ position: 'absolute', top: 10, right: 10 }} onClick={() => moveToMarker()}>
                <MyLocationRoundedIcon />
            </Fab>
        </Box>
    );
}
