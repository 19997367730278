import { AccountChangeBalanceDialog } from '../components/account/dialogs/AccountChangeBalanceDialog';
import { BillingRefundDialog } from '../components/billings/dialogs/BillingRefundDialog';
import { BusinessHoursEditDialog } from '../components/charge-location/dialogs/BusinessHoursEditDialog';
import { ChangeLocationChangeHolderDialog } from '../components/charge-location/dialogs/ChargeLocationChangeHolder';
import { ChargeLocationRemoveDialog } from '../components/charge-location/dialogs/ChargeLocationRemove';
import { ChargeLocationPositionEditDialog } from '../components/charge-location/dialogs/CoordinateEditDialog';
import { ParkingTypeDialog } from '../components/charge-location/dialogs/ParkingTypeDialog';
import { ChargePointAddAccountToTariffDialog } from '../components/charge-point/dialogs/ChargePointAddAccountToTariffDialog';
import { ChargePointAuthChangeDialog } from '../components/charge-point/dialogs/ChargePointAuthChangeDialog';
import { ChargePointChangeAvailabilityDialog } from '../components/charge-point/dialogs/ChargePointChangeAvailabilityDialog';
import { ChargePointChangeLocationDialog } from '../components/charge-point/dialogs/ChargePointChangeLocationDialog';
import { ChargePointChangeStateDialog } from '../components/charge-point/dialogs/ChargePointChangeStateDialog';
import { ChargePointClearCacheDialog } from '../components/charge-point/dialogs/ChargePointClearCache';
import { ChargePointEditCommentDialog } from '../components/charge-point/dialogs/ChargePointEditCommentDialog';
import { ChargePointEventLogsDialog } from '../components/charge-point/dialogs/ChargePointEventLogsDialog';
import { ChargePointRemoteStartDialog } from '../components/charge-point/dialogs/ChargePointRemoteStart';
import { ChargePointRemoteStopDialog } from '../components/charge-point/dialogs/ChargePointRemoteStop';
import { ChargePointRemoveDialog } from '../components/charge-point/dialogs/ChargePointRemove';
import { ChargePointResetDialog } from '../components/charge-point/dialogs/ChargePointReset';
import { ChargePointTriggerMessageDialog } from '../components/charge-point/dialogs/ChargePointTriggerMessage';
import { ConnectorCreateDialog } from '../components/charge-point/dialogs/ConnectorCreate';
import { ConnectorRemoveDialog } from '../components/charge-point/dialogs/ConnectorRemove';
import { ConnectorUnlockDialog } from '../components/charge-point/dialogs/ConnectorUnlock';
import { CorporateCreateDialog } from '../components/corporate/dialogs/CorporateCreateDialog';
import { CorporateEmployeeAddDialog } from '../components/corporate/dialogs/CorporateEmployeeAddDialog';
import { CustomerBalanceRefundDialog } from '../components/customers/dialogs/BalanceRefundDialog';
import { CustomerCreatePayLinkDialog } from '../components/customers/dialogs/CreatePayLinkDialog';
import { HolderChangeOperatorDialog } from '../components/holders/dialogs/HolderChangeOperatorDialog';
import { HolderChangeStateDialog } from '../components/holders/dialogs/HolderChangeStateDialog';
import { HolderRemoveDialog } from '../components/holders/dialogs/HolderRemoveDialog';
import { IntegrationCreateDialog } from '../components/integrations/dialogs/IntegrationCreateDialog';
import { WialonAddBindingDialog } from '../components/integrations/dialogs/WialonAddBindingDialog';
import { WialonTokenEditDialog } from '../components/integrations/dialogs/WialonTokenEditDialog';
import { CreateRoamingXPDialog } from '../components/roaming/dialogs/RoamingXPCreateDialog';
import { RoamingXPTokenEditDialog } from '../components/roaming/dialogs/RoamingXPTokenEditDialog';
import { ManualTransactionSubmitDialog } from '../components/special/dialog/ManualTransactionSubmitDialog';
import { TransactionChangePriceDialog } from '../components/special/dialog/TransactionChangePriceDialog';
import { TransactionListExportDialog } from '../components/transaction/dialogs/export-dialog/TransactionListExportDialog';
import { TransactionReInvoiceDialog } from '../components/transaction/dialogs/TransactionReInvoiceDialog';
import { UserChangeOperatorHolderDialog } from '../components/users/dialogs/UserChangeOperatorHolderDialog';
import { UserChangeStateDialog } from '../components/users/dialogs/UserChangeStateDialog';
import { UserRemoveDialog } from '../components/users/dialogs/UserRemoveDialog';
import { UserChangePasswordDialog } from '../components/users/UserChangePasswordDialog';
import {ChargePointEditInfoMessageDialog} from "../components/charge-point/dialogs/ChargePointEditInfoMessageDialog";
import {ChargePointAllowClientsDialog} from "../components/charge-point/dialogs/ChargePointAllowClientsDialog";

export const DialogRegistry = () => (
    <>
        <UserChangePasswordDialog />
        <ChargePointClearCacheDialog />
        <ChargePointResetDialog />
        <ConnectorUnlockDialog />
        <ChargePointRemoteStartDialog />
        <ChargePointRemoteStopDialog />
        <ChargeLocationPositionEditDialog />
        <ConnectorCreateDialog />
        <ConnectorRemoveDialog />
        <ChangeLocationChangeHolderDialog />
        <ChargePointRemoveDialog />
        <ChargeLocationRemoveDialog />
        <HolderChangeOperatorDialog />
        <UserChangeOperatorHolderDialog />
        <CustomerCreatePayLinkDialog />
        <ChargePointChangeStateDialog />
        <ChargePointChangeLocationDialog />
        <UserChangeStateDialog />
        <HolderChangeStateDialog />
        <UserRemoveDialog />
        <HolderRemoveDialog />
        <TransactionListExportDialog />
        <ChargePointAuthChangeDialog />
        <ChargePointChangeAvailabilityDialog />
        <BusinessHoursEditDialog />
        <ParkingTypeDialog />
        <ChargePointEditCommentDialog />
        <ChargePointEditInfoMessageDialog />
        <ChargePointAllowClientsDialog />
        <ChargePointEventLogsDialog />
        <TransactionChangePriceDialog />
        <ManualTransactionSubmitDialog />
        <BillingRefundDialog />
        <TransactionReInvoiceDialog />
        <CreateRoamingXPDialog />
        <RoamingXPTokenEditDialog />
        <ChargePointTriggerMessageDialog />
        <IntegrationCreateDialog />
        <WialonTokenEditDialog />
        <WialonAddBindingDialog />
        <CorporateCreateDialog />
        <AccountChangeBalanceDialog />
        <CorporateEmployeeAddDialog />
        <ChargePointAddAccountToTariffDialog />
        <CustomerBalanceRefundDialog />
    </>
);
