import {
    Button,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import { API } from '../../../core/api-client';
import AccountDetails from '../../account/AccountDetails';
import { AccountChangeBalanceDialogHelper } from '../../account/dialogs/AccountChangeBalanceDialog';
import { EditableValue } from '../../editable-values/EditableValue';
import { OperatorLink } from '../../operators/elements/OperatorLink';
import { useCorporate } from '../../wrappers/WithCorporate';
import { WithAccount } from '../../wrappers/WithAccount';
import { WithAccountLoader } from '../../wrappers/WithAccountLoader';
import { BillingList } from '../../billings/components/BillingList';

function AccountBlock() {
    const corporate = useCorporate();

    return (
        <WithAccountLoader id={corporate.account}>
            {account => (
                <WithAccount account={account}>
                    <List>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="h5">Аккаунт</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>

                    <AccountDetails account={account} />
                </WithAccount>
            )}
        </WithAccountLoader>
    );
}

function ActionsBlock() {
    const corporate = useCorporate();

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Действия</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <Stack spacing={2}>
                <List disablePadding component={Paper}>
                    <ListItem divider>
                        <ListItemText
                            primary="Пополнение баланса"
                            secondary="Зарегистрировать пополнение баланса клиента (прямой перевод или другие способы)"
                        />
                        <ListItemSecondaryAction>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => AccountChangeBalanceDialogHelper.show(corporate.account)}
                            >
                                Пополнить
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Stack>
        </>
    );
}

function BillingListBlock() {
    const corporate = useCorporate();
    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Биллинг</Typography>
                    </ListItemText>
                </ListItem>
            </List>
            <BillingList filter={{ account: corporate.account }} />
        </>
    );
}

export function CorporateDetailsPage() {
    const corporate = useCorporate();

    const update = (name: string) => {
        void API.corporateUpdate(corporate._id, {
            name: name,
        });
    };

    return (
        <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
                <List>
                    <ListItem>
                        <ListItemText>
                            <Typography variant="h5">Информация</Typography>
                        </ListItemText>
                    </ListItem>
                </List>

                <List component={Paper} disablePadding>
                    <ListItem divider>
                        <ListItemText primary="Название" />
                        <ListItemSecondaryAction>
                            <EditableValue value={corporate.name} onNewValue={newValue => update(newValue)} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary="Оператор" />
                        <ListItemSecondaryAction>
                            <OperatorLink id={corporate.operator} />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Grid>

            <Grid item lg={6} xs={12}>
                <AccountBlock />
                <ActionsBlock />
            </Grid>

            <Grid item xs={12}>
                <BillingListBlock />
            </Grid>
        </Grid>
    );
}
