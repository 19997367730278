import * as yup from 'yup';
import { Receipt } from '../../../data';

export type ReqQuery = {
    skip?: number;
    limit?: number;
    billing?: string;
};

export type ResData = Receipt[];

export const validationSchema = yup.object({
    skip: yup.number().optional().min(0).default(0),
    limit: yup.number().optional().min(1).max(1000).default(100),
    billing: yup.string().optional(),
});
