import {
    Account,
    EventLog,
    ChargeLocation,
    ChargePoint,
    Connector,
    ConnectorDeleted,
    EventLogOCPP,
    EVSE,
    Holder,
    MessageType,
    RegistryLoad,
    Transaction,
    TransactionMeasurement,
    User,
    RoamingXP,
    Integration,
    Corporate,
    RoamingLocation,
} from '@electrifly/central-client-api';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { TypedEvent } from '../misc/TypedEvent';

const WS_URL = process.env.REACT_APP_WS_URL!;

const LOCALSTORAGE_WS_DEBUG = 'ws_debug';
const DEBUG = !!localStorage.getItem(LOCALSTORAGE_WS_DEBUG);

function createWebsocketClient() {
    const events = {
        open: new TypedEvent<void>(),
        close: new TypedEvent<void>(),
        [MessageType.REGISTRY]: new TypedEvent<RegistryLoad.ResData>(),
        [MessageType.ROAMING_LOCATION]: new TypedEvent<RoamingLocation>(),
        [MessageType.CHARGE_LOCATION]: new TypedEvent<ChargeLocation>(),
        [MessageType.CHARGE_POINT]: new TypedEvent<ChargePoint>(),
        [MessageType.EVSE]: new TypedEvent<EVSE>(),
        [MessageType.CONNECTOR]: new TypedEvent<Connector | ConnectorDeleted>(),
        [MessageType.EVENT_LOG_OCPP]: new TypedEvent<EventLogOCPP>(),
        [MessageType.ACTION_EVENT]: new TypedEvent<EventLog>(),
        [MessageType.TRANSACTION]: new TypedEvent<Transaction>(),
        [MessageType.TRANSACTION_MEASUREMENT]: new TypedEvent<TransactionMeasurement>(),
        [MessageType.ACCOUNT]: new TypedEvent<Account>(),
        [MessageType.USER]: new TypedEvent<User>(),
        [MessageType.HOLDER]: new TypedEvent<Holder>(),
        [MessageType.ROAMING_XP]: new TypedEvent<RoamingXP>(),
        [MessageType.INTEGRATION]: new TypedEvent<Integration>(),
        [MessageType.CORPORATE]: new TypedEvent<Corporate>(),
    };

    const rws = new ReconnectingWebSocket(WS_URL, undefined, { startClosed: true, debug: DEBUG });
    let _token = '';
    let _operator = '';
    let isAlive = false;

    rws.addEventListener('open', () => {
        console.log('WebsocketClient open');

        isAlive = true;

        const timer = setInterval(() => {
            if (isAlive === false) {
                rws.reconnect();
                return;
            }

            isAlive = false;
            rws.send(JSON.stringify({ type: 'heartbeat' }));
        }, 55 * 1000);

        rws.addEventListener('close', () => {
            clearInterval(timer);
        });

        events.open.emit();
    });

    rws.addEventListener('close', () => {
        console.log('WebsocketClient close');
        events.close.emit();
    });

    rws.addEventListener('message', (event: MessageEvent) => {
        // console.log(`ws message received: ${JSON.stringify(event)}`);
        let message = null;
        try {
            message = JSON.parse(event.data);
        } catch (e) {
            console.error('Failed to JSON.parse income message');
        }
        if (!message) {
            return;
        }

        if (message.type === 'heartbeat') {
            isAlive = true;
            return;
        }

        dispatch(message.type, message.data);
    });

    function dispatch<T extends keyof typeof events>(type: T, data?: any) {
        events[type] && events[type].emit(data);
    }

    function reconnect() {
        rws.reconnect();
    }

    function disconnect() {
        rws.close();
    }

    function authorizeConnection() {
        const message = { type: 'authorize', token: _token, operator: _operator };
        rws.send(JSON.stringify(message));
    }

    function deauthorizeConnection() {
        const message = { type: 'deauthorize' };
        rws.send(JSON.stringify(message));
    }

    return {
        events,
        authorizeConnection,
        deauthorizeConnection,
        reconnect,
        disconnect,

        setToken: (token: string) => {
            _token = token;
        },

        setOperator: (operator: string) => {
            _operator = operator;
        },

        removeToken: () => {
            _token = '';
        },
        removeOperator: () => {
            _operator = '';
        },
    };
}

export const WebsocketClient = createWebsocketClient();

const subscription = WebsocketClient.events.open.on(() => {
    WebsocketClient.authorizeConnection();
});
