import { Box } from '@mui/material';
import { TransactionListTableComponent } from '../../transaction/components/list-table/TransactionListTableComponent';
import { useUser } from '../../wrappers/WithUser';

export default function UserTransactionHistoryPage() {
    const user = useUser();

    return (
        <Box sx={{ marginTop: 2 }}>
            <TransactionListTableComponent filter={{ owner: user._id }} />
        </Box>
    );
}
