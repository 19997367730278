import { ReactNode } from 'react';
import { useRegistry } from '../../services/GlobalRegistry';
import invariant from 'tiny-invariant';
import create, { StoreApi } from 'zustand';
import createContext from 'zustand/context';

interface ChargeLocationWrapperStore {
    id: string;
}

function createStore(chargeLocationId: string) {
    return create<ChargeLocationWrapperStore>(set => ({
        id: chargeLocationId,
    }));
}

const { Provider, useStore } = createContext<StoreApi<ChargeLocationWrapperStore>>();

type Props = {
    id: string;
    children: ReactNode;
};

export const WithChargeLocation = ({ id, children }: Props) => {
    const chargeLocation = useRegistry(store => store.chargeLocations[id]);

    if (!chargeLocation) {
        return null;
    }

    return <Provider createStore={() => createStore(id)}>{children}</Provider>;
};

export function useChargeLocation() {
    const id = useStore(store => store.id);
    invariant(!!id, 'Error getting charge location. Possible you forgot to add WithChargeLocation wrapper');
    const chargeLocation = useRegistry(store => store.chargeLocations[id]);
    return chargeLocation;
}
