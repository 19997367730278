import * as yup from 'yup';
import { Integration } from '../../data';

export type ReqParams = {
    id: string;
};
export type ReqBody = {
    name?: string;
};

export type ResData = Integration;

export const validationSchema = yup.object({
    name: yup.string().optional(),
});
