import { UptimeFilter } from '@electrifly/central-client-api';
import { Grid, Stack, Typography, Box, Paper } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { DateRange, DateRangePicker, LocalizationProvider, PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { UptimeService } from './UptimeService';
import { API } from '../../../core/api-client';
import { ChargePointUptime } from '@electrifly/central-client-api/data';
import { UptimeTable } from './UptimeTable';
import { UptimeSummary } from './UptimeSummary';
import { UptimeChart3 } from './UptimeChart3';
import { DateTimeHelpers } from '../../../misc/DateTimeHelpers';
import _ from 'lodash';

const shortcutsItems: PickersShortcutsItem<DateRange<DateTime>>[] = [
    {
        label: 'Вчера',
        getValue: () => DateTimeHelpers.ranges.yesterday(),
    },
    {
        label: '7 дней',
        getValue: () => DateTimeHelpers.ranges.last7Days(),
    },
    {
        label: '30 дней',
        getValue: () => DateTimeHelpers.ranges.last30Days(),
    },
    {
        label: '90 дней',
        getValue: () => DateTimeHelpers.ranges.last90Days(),
    },
    {
        label: 'Предыдущая неделя',
        getValue: () => DateTimeHelpers.ranges.lastWeek(),
    },
    {
        label: 'Предыдущий месяц',
        getValue: () => DateTimeHelpers.ranges.lastMonth(),
    },
    {
        label: 'Предыдущий квартал',
        getValue: () => DateTimeHelpers.ranges.lastQuarter(),
    },
    // { label: 'Сброс', getValue: () => [null, null] },
];

function FilterBlock() {
    const range = UptimeService.useStore(store => store.range);
    const setRange = UptimeService.useStore(store => store.setRange);
    const dateRage = UptimeService.useStore(store => store.dateRage);
    const setDateRange = UptimeService.useStore(store => store.setDateRange);

    const [value, setValue] = React.useState<DateRange<DateTime>>([...dateRage]);

    useEffect(() => {
        setValue(dateRage);
    }, [dateRage]);

    return (
        <Grid
            container
            spacing={2}
            sx={{ marginTop: 0, marginBottom: 2 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid item lg={6} xs={12}>
                <Stack spacing={1} direction={'column'}>
                    <Typography variant="h6" sx={{ fontWeight: 400 }}>
                        Период выборки
                    </Typography>
                    <Stack spacing={2} direction={'row'} alignItems="center">
                        <LocalizationProvider
                            dateAdapter={AdapterLuxon}
                            adapterLocale={'ru'}
                            localeText={{ start: 'Начало', end: 'Окончание' }}
                        >
                            <DateRangePicker
                                value={value}
                                format="dd.MM.y"
                                onChange={newValue => setValue(newValue)}
                                onAccept={newValue => setDateRange(newValue)}
                                slotProps={{
                                    textField: { fullWidth: true, size: 'small' },
                                    fieldSeparator: { children: '→' },
                                    shortcuts: { items: shortcutsItems },
                                }}
                            />
                        </LocalizationProvider>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    );
}

function PageInternal() {
    const [data, setData] = useState<ChargePointUptime[]>([]);

    const dateRage = UptimeService.useStore(store => store.dateRage);
    const filter = UptimeService.useStore(store => store.filter);

    useEffect(() => {
        async function fetch() {
            const rangeStart = dateRage[0];
            const rangeEnd = dateRage[1];

            if (!rangeStart || !rangeEnd) {
                return;
            }

            const [error, res] = await API.statUptimeDetails({
                //@ts-ignore
                rangeStart: rangeStart.startOf('day').toISO(),
                //@ts-ignore
                rangeEnd: rangeEnd.endOf('day').toISO(),
                //@ts-ignore
                chargePoint: filter.chargePoint,
            });

            if (error) {
                return;
            }

            setData(res.data);
        }

        fetch();
    }, [dateRage, filter]);

    return (
        <>
            <Grid item xs={12}>
                <UptimeSummary data={data} />
            </Grid>
            <Grid item xs={12}>
                {data && (
                    <Stack component={Paper}>
                        <Typography variant="h5" sx={{ paddingY: 2, paddingX: 4 }}>
                            Доступность
                        </Typography>
                        <Box sx={{ height: 300 }}>
                            <UptimeChart3 data={data} />
                        </Box>
                    </Stack>
                )}
            </Grid>
            <Grid item xs={12}>
                <UptimeTable data={data} />
            </Grid>
        </>
    );
}

interface Props {
    filter: UptimeFilter;
}

export function UptimeComponent({ filter }: Props) {
    return (
        <UptimeService.Provider createStore={() => UptimeService.createStore(filter)}>
            <Grid container spacing={2} sx={{ marginTop: 0 }}>
                <Grid item xs={12}>
                    <FilterBlock />
                </Grid>

                <PageInternal />
            </Grid>
        </UptimeService.Provider>
    );
}
