import { OwnerType } from '@electrifly/central-client-api';
import { exhaustiveCheck } from '../../misc/ExhaustiveCheck';
import FaceRoundedIcon from '@mui/icons-material/FaceRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import HubRoundedIcon from '@mui/icons-material/HubRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';

interface OwnerIconProps {
    type: OwnerType;
}
export function OwnerIcon({ type }: OwnerIconProps) {
    switch (type) {
        case 'customer':
            return <FaceRoundedIcon />;
        case 'user':
            return <SupportAgentRoundedIcon />;
        case 'roaming-emsp':
        case 'roaming-cpo':
            return <HubRoundedIcon color="inherit" />;
        case 'transaction':
            return null;
        case 'zero':
            return null;
        case 'corporate':
            return null;
        case 'system':
            return <SettingsSuggestRoundedIcon color="inherit" />;
        case 'holder':
            return null;
        default:
            exhaustiveCheck(type);
            return null;
    }
}
