import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Alert,
    Divider,
    Link,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { PropsWithChildren, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { ChargeLocationLink } from '../../../charge-location/elements/ChargeLocationLink';
import { ChargePointLink } from '../../../charge-point/elements/ChargePointLink';
import { ConnectorTypeText } from '../../../connector/Standard';
import { TagTypeIcon } from '../../../tags/elements/TokenTypeText';
import { useTransaction, WithTransaction } from '../../../wrappers/WithTransaction';
import { TransactionDuration } from '../../elements/TransactionDuration';
import { TransactionTime } from '../../elements/TransactionTime';
import { useTotalCost } from '../../hooks/useTotalCost';
import { Filter, Options, TransactionListPageService } from './ListPageService';
import { TransactionActive } from '../TransactionActive';
import { COLUMN_NAMES } from './TableColumns';
import { TransactionListTableTopMenu } from './TransactionListTableTopMenu';
import { TransactionEnergyKWh } from '../../elements/TransactionEnergy';
import { TokenVariantIcon } from '../../../tags/elements/TokenVariantText';
import { RouterLink } from '../../../../elements/RouterLink';
import { RoamingLocationLink } from '../../../roaming-location/elements/RoamingLocationLink';

const useTransactionListService = TransactionListPageService.useStore;

function TransactionRow() {
    const options = useTransactionListService(store => store.options);
    const transaction = useTransaction();
    const navigate = useNavigate();
    let { operatorId } = useParams();

    const totalCost = useTotalCost(transaction);

    return (
        <TableRow
            onClick={() => navigate(`/${operatorId}/transactions/${transaction.transactionId}`)}
            hover={true}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            {options.humnaId && (
                <TableCell>
                    <Stack direction="row" alignItems="center">
                        <Link
                            underline="hover"
                            color="inherit"
                            component={RouterLink}
                            to={`/${operatorId}/transactions/${transaction.transactionId}`}
                            onClick={event => event.stopPropagation()}
                        >
                            {transaction.transactionId}
                        </Link>
                        {!transaction.completed && <TransactionActive />}
                    </Stack>
                </TableCell>
            )}
            {options.location && (
                <TableCell>
                    {transaction.infrastructureType === 'internal' && (
                        <ChargeLocationLink id={transaction.chargeLocation} />
                    )}
                    {transaction.infrastructureType === 'external' && (
                        <RoamingLocationLink id={transaction.chargeLocation} />
                    )}
                </TableCell>
            )}
            {options.chargePoint && (
                <TableCell>
                    {transaction.infrastructureType === 'internal' ? (
                        <ChargePointLink id={transaction.chargePoint} />
                    ) : (
                        <Typography fontSize={'inherit'}>---</Typography>
                    )}
                </TableCell>
            )}
            {options.connector && (
                <TableCell>
                    <Stack
                        direction={'row'}
                        alignItems="center"
                        spacing={1}
                        divider={<Divider orientation="vertical" flexItem />}
                        sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
                    >
                        <Typography fontSize={'inherit'}>{transaction.connectorSeqNumber}</Typography>
                        <Typography fontSize={'inherit'}>
                            <ConnectorTypeText type={transaction.standard} />
                        </Typography>
                    </Stack>
                </TableCell>
            )}

            {options.startTime && (
                <TableCell>
                    <TransactionTime time={transaction.startTime} />
                </TableCell>
            )}
            {options.endTime && (
                <TableCell>{transaction.completed && <TransactionTime time={transaction.endTime} />}</TableCell>
            )}
            {options.duration && (
                <TableCell align="right">
                    <TransactionDuration
                        startTime={transaction.startTime}
                        endTime={transaction.endTime}
                        completed={transaction.completed}
                    />
                </TableCell>
            )}
            {options.stopReason && <TableCell align="right">{transaction.stopReason}</TableCell>}
            {options.energy && (
                <TableCell align="right">
                    <TransactionEnergyKWh energy={transaction.meterDiff} unit={'Wh'} dimension={false} />
                </TableCell>
            )}
            {options.tokenType && (
                <TableCell align="right">
                    {transaction.tokenType !== 'freevend' && <TokenVariantIcon variant={transaction.tokenVariant} />}
                    {transaction.tokenType && <TagTypeIcon type={transaction.tokenType} />}
                </TableCell>
            )}
            {options.cost && <TableCell align="right">{totalCost}</TableCell>}
            {options.status && (
                <TableCell align="right">
                    {transaction.completed ? 'Завершена' : <TransactionActive mode="full" />}
                </TableCell>
            )}
        </TableRow>
    );
}

function LoadingIndicator() {
    const canLoadMore = useTransactionListService(store => store.canLoadMore);
    const loading = useTransactionListService(store => store.loading);
    const loadNext = useTransactionListService(store => store.loadNext);

    const display = useMemo(() => loading || canLoadMore, [canLoadMore, loading]);

    if (!display) {
        return null;
    }

    return (
        <LoadingButton
            loading={loading}
            variant="text"
            size="large"
            fullWidth
            sx={{ marginY: 2 }}
            onClick={() => loadNext()}
        >
            <span>Загрузить ещё</span>
        </LoadingButton>
    );
}

function EmptyDataIndicator() {
    const count = useTransactionListService(store => store.transactions.length);
    const loading = useTransactionListService(store => store.loading);

    const display = useMemo(() => !loading && count === 0, [loading, count]);

    if (!display) {
        return null;
    }

    return (
        <Alert severity="warning" icon={<SearchOffRoundedIcon />} sx={{ my: 2, display: 'flex', width: '100%' }}>
            Данные по запросу не найдены
        </Alert>
    );
}

function TransactionTable() {
    const options = useTransactionListService(store => store.options);
    const transactions = useTransactionListService(store => store.transactions);

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }} size="small">
                    <TableHead>
                        <TableRow>
                            {options.humnaId && <TableCell>{COLUMN_NAMES['humnaId']}</TableCell>}
                            {options.location && <TableCell>{COLUMN_NAMES['location']}</TableCell>}
                            {options.chargePoint && <TableCell>{COLUMN_NAMES['chargePoint']}</TableCell>}
                            {options.connector && <TableCell>{COLUMN_NAMES['connector']}</TableCell>}
                            {options.startTime && <TableCell>{COLUMN_NAMES['startTime']}</TableCell>}
                            {options.endTime && <TableCell>{COLUMN_NAMES['endTime']}</TableCell>}
                            {options.duration && <TableCell align="right">{COLUMN_NAMES['duration']}</TableCell>}
                            {options.stopReason && <TableCell align="right">{COLUMN_NAMES['stopReason']}</TableCell>}
                            {options.energy && <TableCell align="right">{COLUMN_NAMES['energy']}</TableCell>}
                            {options.tokenType && <TableCell align="right">{COLUMN_NAMES['tokenType']}</TableCell>}
                            {options.cost && <TableCell align="right">{COLUMN_NAMES['cost']}</TableCell>}
                            {options.status && <TableCell align="right">{COLUMN_NAMES['status']}</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions.map(transaction => (
                            <WithTransaction key={transaction._id} transaction={transaction}>
                                <TransactionRow />
                            </WithTransaction>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <LoadingIndicator />
            <EmptyDataIndicator />
        </>
    );
}

function LoadWrapper({ children }: PropsWithChildren<{}>) {
    const loadNext = TransactionListPageService.useStore(store => store.loadNext);
    const reset = TransactionListPageService.useStore(store => store.reset);

    useEffectOnce(() => {
        loadNext();
        return () => reset();
    });

    return <>{children}</>;
}

interface TransactionListTableComponentProps {
    filter?: Partial<Filter>;
    options?: Partial<Options>;
}
export function TransactionListTableComponent({ filter, options }: TransactionListTableComponentProps) {
    return (
        <TransactionListPageService.Provider
            createStore={() => TransactionListPageService.createStore(filter, options)}
        >
            <TransactionListTableTopMenu />
            <LoadWrapper>
                <TransactionTable />
            </LoadWrapper>
        </TransactionListPageService.Provider>
    );
}
