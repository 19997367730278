import * as yup from 'yup';
import { Account } from '../../data';

export type ReqParams = { id: string };
export type ReqBody = {
    overdraft: number;
};

export type ResData = Account;

export const validationSchema = yup.object({
    overdraft: yup.number().required().min(0).max(1000000),
});
