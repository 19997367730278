import { Title } from '../../../elements/Title';
import { FormHelperText, Grid, Paper, Stack, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import { HolderCreate } from '@electrifly/central-client-api';
import { API } from '../../../core/api-client';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function HolderCreatePage() {
    const navigate = useNavigate();
    const { operatorId } = useParams();

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik({
        initialValues: {
            identity: '',
        },
        validationSchema: HolderCreate.validationSchema,

        onSubmit: async values => {
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.holderCreate(values);
            setIsRequesting(false);
            if (!error) {
                navigate(`/${operatorId}/holders`);
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);

            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Добавление владельца</Title>
            </Stack>

            <Grid container>
                <Grid item lg={6} xs={12}>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack direction={'column'} spacing={2} component={Paper} sx={{ p: 2 }}>
                            <TextField
                                fullWidth
                                id="identity"
                                label="Имя"
                                variant="standard"
                                value={formik.values.identity}
                                onChange={formik.handleChange}
                                error={formik.touched.identity && Boolean(formik.errors.identity)}
                                helperText={formik.touched.identity && formik.errors.identity}
                            />

                            <FormHelperText
                                disabled={!displayError}
                                error={displayError}
                                sx={{ textAlign: 'center', color: '#d32f2f' }}
                            >
                                {error}
                            </FormHelperText>

                            <LoadingButton
                                color="primary"
                                variant="contained"
                                fullWidth
                                type="submit"
                                size="large"
                                loadingPosition="center"
                                loading={isRequesting}
                            >
                                <span>Добавить</span>
                            </LoadingButton>
                        </Stack>
                    </form>
                </Grid>
            </Grid>
        </>
    );
}
