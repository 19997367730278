import { Customer } from '@electrifly/central-client-api';
import create, { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { API } from '../../../../core/api-client';
import { WebsocketClient } from '../../../../core/ws-client';

interface Service {
    customer?: Customer;
    loading: boolean;

    reset: () => void;
    loadInformation: () => Promise<void>;
}

function createStore(id: string) {
    return create<Service>((set, get) => {
        return {
            customer: undefined,
            loading: false,

            reset: () => set({ customer: undefined, loading: false }),

            loadInformation: async () => {
                if (get().loading) {
                    return;
                }
                set({ loading: true });
                const [error, res] = await API.customerDetails(id);
                set({ loading: false });

                if (error) {
                    console.error(error);
                    return;
                }

                set({ customer: res.data });
            },
        };
    });
}

const { Provider, useStore } = createContext<StoreApi<Service>>();

export const CustomerDetailsPageService = {
    Provider,
    createStore,
    useStore,
};

export const useCustomerDetailsPageService = useStore;
