import { Box, Tabs, Tab, Stack } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import { Title } from '../../../elements/Title';
import { useRouteMatch } from '../../../hooks/useRouteMatch';
import { RouterLink } from '../../../elements/RouterLink';
import { useEffectOnce } from 'usehooks-ts';
import { ReactNode } from 'react';
import { IntegrationDetailsPageService, useIntegrationDetailsPageService } from './services/PageService';
import { WithIntegration, useIntegration } from '../../wrappers/WithIntegration';

function TopMenu() {
    const { operatorId } = useParams();
    const routeMatch = useRouteMatch([
        //
        `/${operatorId}/integration/:id`,
    ]);
    const currentTab = routeMatch?.pattern?.path;

    return (
        <Box sx={{ marginTop: 2, marginBottom: 1 }}>
            <Box sx={{ display: 'flex', width: '100%', borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={currentTab} sx={{ flex: 1 }} variant="scrollable" scrollButtons="auto">
                    <Tab label="Информация" value={`/${operatorId}/integration/:id`} component={RouterLink} to="" />
                </Tabs>
            </Box>
            <Stack direction={'row'} spacing={1} sx={{ padding: 1 }}>
                {/* {chargePointStatuses.map(status => (
                    <Chip key={status} label={`Станции: ${status}`} onDelete={() => removeChargePointFilter(status)} />
                ))}
                {connectorStatuses.map(status => (
                    <Chip key={status} label={`Коннекторы: ${status}`} onDelete={() => removeConnectorFilter(status)} />
                ))} */}
            </Stack>
        </Box>
    );
}

function LayoutInternal() {
    const integration = useIntegration();

    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Интеграция {integration.name}</Title>
            </Stack>

            <TopMenu />
            <Outlet />
        </>
    );
}

interface WithDataLoaderProps {
    children: ReactNode;
}
function WithDataLoader({ children }: WithDataLoaderProps) {
    const reset = useIntegrationDetailsPageService(store => store.reset);
    const loadInformation = useIntegrationDetailsPageService(store => store.loadInformation);

    const integration = useIntegrationDetailsPageService(store => store.integration);

    useEffectOnce(() => {
        loadInformation();
        return () => reset();
    });

    if (!integration) {
        return null;
    }

    return <WithIntegration integration={integration}>{children}</WithIntegration>;
}

export type IntegrationDetailsPageParams = {
    id: string;
};

export function IntegrationDetailsLayout() {
    const { id } = useParams<IntegrationDetailsPageParams>();

    if (!id) {
        return null;
    }

    return (
        <IntegrationDetailsPageService.Provider createStore={() => IntegrationDetailsPageService.createStore(id)}>
            <WithDataLoader>
                <LayoutInternal />
            </WithDataLoader>
        </IntegrationDetailsPageService.Provider>
    );
}
