import { StatFilter, UptimeOverview } from '@electrifly/central-client-api';
import { Grid, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { DateRange, DateRangePicker, LocalizationProvider, PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import React from 'react';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { API } from '../../../../core/api-client';
import { DateTimeHelpers } from '../../../../misc/DateTimeHelpers';
import { StatFilterService } from '../../main/filter/StatFilterService';
import { UptimeSummaryTable } from './UptimeSummaryTable';
import _ from 'lodash';

const shortcutsItems: PickersShortcutsItem<DateRange<DateTime>>[] = [
    {
        label: 'Вчера',
        getValue: () => DateTimeHelpers.ranges.yesterday(),
    },
    {
        label: '7 дней',
        getValue: () => DateTimeHelpers.ranges.last7Days(),
    },
    {
        label: '30 дней',
        getValue: () => DateTimeHelpers.ranges.last30Days(),
    },
    {
        label: '90 дней',
        getValue: () => DateTimeHelpers.ranges.last90Days(),
    },
    {
        label: 'Предыдущая неделя',
        getValue: () => DateTimeHelpers.ranges.lastWeek(),
    },
    {
        label: 'Предыдущий месяц',
        getValue: () => DateTimeHelpers.ranges.lastMonth(),
    },
    {
        label: 'Предыдущий квартал',
        getValue: () => DateTimeHelpers.ranges.lastQuarter(),
    },
    // { label: 'Сброс', getValue: () => [null, null] },
];

function FilterBlock() {
    const step = StatFilterService.useStore(store => store.filter.step);
    const setStep = StatFilterService.useStore(store => store.setStep);
    const range = StatFilterService.useStore(store => store.range);
    const setRange = StatFilterService.useStore(store => store.setRange);
    const dateRage = StatFilterService.useStore(store => store.dateRage);
    const setDateRange = StatFilterService.useStore(store => store.setDateRange);

    const [value, setValue] = React.useState<DateRange<DateTime>>([...dateRage]);

    return (
        <Grid
            container
            spacing={2}
            sx={{ marginTop: 0, marginBottom: 2 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid item lg={6} xs={12}>
                <Stack spacing={1} direction={'column'}>
                    <Typography variant="h6" sx={{ fontWeight: 400 }}>
                        Период выборки
                    </Typography>
                    <Stack spacing={2} direction={'row'} alignItems="center">
                        <LocalizationProvider
                            dateAdapter={AdapterLuxon}
                            adapterLocale={'ru'}
                            localeText={{ start: 'Начало', end: 'Окончание' }}
                        >
                            <DateRangePicker
                                value={value}
                                format="dd.MM.y"
                                onChange={newValue => setValue(newValue)}
                                onAccept={newValue => setDateRange(newValue)}
                                slotProps={{
                                    textField: { fullWidth: true, size: 'small', sx: { maxWidth: 120 } },
                                    fieldSeparator: { children: '→' },
                                    shortcuts: { items: shortcutsItems },
                                }}
                            />
                        </LocalizationProvider>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    );
}

function PageInternal() {
    const [data, setData] = useState<UptimeOverview.ResData>([]);

    const dateRage = StatFilterService.useStore(store => store.dateRage);
    const filter = StatFilterService.useStore(store => store.filter);

    useEffect(() => {
        async function fetch() {
            const rangeStart = dateRage[0];
            const rangeEnd = dateRage[1];

            if (!rangeStart || !rangeEnd) {
                return;
            }

            const [error, res] = await API.statUptimeOverview({
                //@ts-ignore
                rangeStart: rangeStart.toISODate(),
                //@ts-ignore
                rangeEnd: rangeEnd.toISODate(),
            });

            if (error) {
                return;
            }

            const sorted = _.orderBy(res.data, item => item.uptime, 'desc');

            setData(sorted);
        }

        fetch();
    }, [dateRage, filter]);

    return <UptimeSummaryTable data={data} />;
}

interface Props {
    filter?: Partial<StatFilter>;
}

export default function UptimeBlockComponent({ filter }: Props) {
    return (
        <StatFilterService.Provider createStore={() => StatFilterService.createStore(filter)}>
            <Grid container spacing={2} sx={{ marginTop: 0, paddingTop: 0 }}>
                <Grid item xs={12}>
                    <FilterBlock />
                </Grid>
                <PageInternal />
            </Grid>
        </StatFilterService.Provider>
    );
}
