export enum AccessScope {
    GLOBAL = 'GLOBAL',
    OPERATOR = 'OPERATOR',
    HOLDER = 'HOLDER',
}

export const AccessScopesByScopes: Record<AccessScope, AccessScope[]> = {
    [AccessScope.GLOBAL]: [AccessScope.GLOBAL, AccessScope.OPERATOR, AccessScope.HOLDER],
    [AccessScope.OPERATOR]: [AccessScope.OPERATOR, AccessScope.HOLDER],
    [AccessScope.HOLDER]: [AccessScope.HOLDER],
};
