import { Box, Stack } from '@mui/material';
import { Title } from '../../../elements/Title';
import StatsBlockComponent from './components/StatsBlockComponent';

export function PageHeader2() {
    return (
        <Stack direction={'row'} spacing={1} sx={{ mb: 4, alignItems: 'center' }}>
            <Title sx={{ flex: 1 }}>Статистика</Title>
        </Stack>
    );
}

export default function StatsPage() {
    return (
        <Box sx={{ pb: 10 }}>
            <PageHeader2 />
            <StatsBlockComponent />
        </Box>
    );
}
