import { Box } from '@mui/material';
import {useChargeLocation} from "../../wrappers/WithChargeLocation";
import MorphPhotosListTableComponent from "./MorphPhotosListTableComponent";
import {TransactionListTableComponent} from "../../transaction/components/list-table/TransactionListTableComponent";
import {DefaultApprovalStatus} from "@electrifly/central-client-api/data/enums/ModelEnums";

export default function ChargeLocationMorphPhotosHistoryPage() {
    const chargeLocation = useChargeLocation();

    return (
        <Box sx={{ marginTop: 2 }}>
            <MorphPhotosListTableComponent filter={{ locationId: chargeLocation._id, status: DefaultApprovalStatus.APPROVED}} />
        </Box>
    );
}

export function MorphPhotosListPageByStatus(props: { status: DefaultApprovalStatus }) {
    return (
        <Box sx={{ marginTop: 2 }}>
            <MorphPhotosListTableComponent filter={{ status: props.status }} />
        </Box>
    );
}