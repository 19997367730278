import * as yup from 'yup';

export type ReqParams = { id: string };
export type ReqBody = {
    connectorId: string;
    constraintKWH?: number;
    constraintMinutes?: number;
    constraintSOC?: number;
};

export type ResData = {};

export const validationSchema = yup.object({
    connectorId: yup.string().required('Connector Id must be provided'),
    constraintKWH: yup
        .number()
        .optional()
        .min(0, 'Constraint KWH must be greater than or equal to 0')
        .max(300, 'Constraint KWH must be less than or equal to 100'),
    constraintMinutes: yup
        .number()
        .optional()
        .min(0, 'Constraint Minutes must be greater than or equal to 0')
        .max(10000, 'Constraint Minutes must be less than or equal to 10000'),
    constraintSOC: yup
        .number()
        .optional()
        .min(0, 'Constraint SOC must be greater than or equal to 0')
        .max(100, 'Constraint SOC must be less than or equal to 100'),
});
