import * as yup from 'yup';

export type ReqParams = { id: string };
export type ReqBody = {
    amount: string;
    reason: string;
};

export type ResData = {};

export const validationSchema = yup.object({
    amount: yup.string().required(),
    reason: yup.string().required().min(10).max(250),
});
