import BigNumber from 'bignumber.js';
import { useMemo } from 'react';

interface TransactionDurationProps {
    energy: number;
    unit?: 'Wh' | 'KWh';
    dimension?: boolean;
}

export function TransactionEnergyKWh({ energy, unit, dimension = true }: TransactionDurationProps) {
    const formattedEnergy = useMemo(() => {
        const unitFactor = unit === 'KWh' ? 1 : 1000;
        return new BigNumber(energy).div(unitFactor).toFixed(2);
    }, [energy, unit]);

    const result = useMemo(() => {
        const items = [formattedEnergy, dimension && 'кВт⋅ч'];
        return items
            .filter(item => item)
            .join(' ')
            .trim();
    }, [dimension, formattedEnergy]);

    return <>{result}</>;
}
