import { Stack, IconButton, Autocomplete, TextField } from '@mui/material';
import { useFormik } from 'formik';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { EditableValueOption } from './EditableValueOption';
import { useMemo } from 'react';

interface AutocompleteTypeFormProps<T extends string | number> {
    initialValue: T;
    options: EditableValueOption<T>[];
    onCancel: () => void;
    onSave: (value: T) => void;
}

export function AutocompleteTypeForm<T extends string | number>({
    initialValue,
    options,
    onCancel,
    onSave,
}: AutocompleteTypeFormProps<T>) {
    const formik = useFormik({
        initialValues: {
            value: initialValue,
        },

        onSubmit: async values => {
            onSave(values.value);
            onCancel();
        },
    });

    const selectedValue = useMemo(
        () => options.find(item => item.value === formik.values.value),
        [options, formik.values.value],
    );

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack direction={'row'} sx={{ p: 1 }} alignItems="center" spacing={1}>
                <Autocomplete
                    sx={{ minWidth: '15em' }}
                    size="small"
                    fullWidth
                    options={options}
                    value={selectedValue || null} //null is neede for initialization render in controlled state
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderOption={(props, option, state) => (
                        <li {...props} key={option.value}>
                            {option.label}
                        </li>
                    )}
                    onChange={(event, val) => formik.setFieldValue('value', val?.value)}
                    renderInput={params => (
                        <TextField
                            {...params}
                            autoFocus
                            variant="outlined"
                            placeholder="Выберите значение"
                            error={formik.touched.value && Boolean(formik.errors.value)}
                            helperText={formik.touched.value && formik.errors.value}
                        />
                    )}
                />

                <IconButton size="small" color="primary" type="submit">
                    <DoneRoundedIcon />
                </IconButton>
                <IconButton size="small" onClick={onCancel}>
                    <CloseRoundedIcon />
                </IconButton>
            </Stack>
        </form>
    );
}
