import { Billing, AcquiringType, BillingStatus } from '@electrifly/central-client-api';
import BigNumber from 'bignumber.js';

const REFUNDABLE_BILLING_STATUSES: BillingStatus[] = ['Confirmed', 'PartialRefunded'];
const REFUNDABLE_ACQURING_TYPES: AcquiringType[] = ['yookassa', 'freedompay'];

export function useRefundable(billing: Billing): boolean {
    if (billing.type !== 'ChargeBilling') {
        return false;
    }
    if (!REFUNDABLE_BILLING_STATUSES.includes(billing.status)) {
        return false;
    }

    if (!billing.acquiring) {
        return false;
    }
    if (!REFUNDABLE_ACQURING_TYPES.includes(billing.acquiring.type)) {
        return false;
    }

    return new BigNumber(billing.value).isGreaterThanOrEqualTo(billing.refundedValue || 0);
}
