import { Grid } from '@mui/material';

import React from 'react';
import { ChargePoint } from '@electrifly/central-client-api';
import { useChargePointResetDialog } from '../dialogs/ChargePointReset';
import { useChargePointClearCacheDialog } from '../dialogs/ChargePointClearCache';

import { ConnectionInfoBlock } from '../ConnectionInfoBlock';
import { useChargePoint } from '../../wrappers/WithChargePoint';
import { OCPPConfigurationBlock } from './OCPPConfigurationBlock';
import { OCPPAuthorizationBlock } from './OCPPAuthorizationBlock';

export default function ChargePointOcppPage() {
    const chargePoint = useChargePoint();
    const showResetDialog = useChargePointResetDialog(store => store.show);
    const showClearCacheDialog = useChargePointClearCacheDialog(store => store.show);

    const isOnline = React.useMemo(() => ChargePoint.isOnline(chargePoint), [chargePoint]);

    return (
        <Grid container columnSpacing={2}>
            <Grid item xs={12} sx={{ marginY: 2 }}>
                <ConnectionInfoBlock />
            </Grid>

            <Grid item xs={12}>
                <OCPPAuthorizationBlock />
            </Grid>

            <Grid item xs={12}>
                <OCPPConfigurationBlock />
            </Grid>
        </Grid>
    );
}
