import { AccessScope, ChargePoint } from '@electrifly/central-client-api';
import * as OCPI from '@electrifly/ocpi';
import AddIcon from '@mui/icons-material/Add';
import CableRoundedIcon from '@mui/icons-material/CableRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import MobileFriendlyRounded from '@mui/icons-material/MobileFriendlyRounded';
import MobileOffRounded from '@mui/icons-material/MobileOffRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import {
    Button,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React from 'react';
import { useRegistryEditor } from '../../services/GlobalRegistryEditor';
import { ConnectorTypeText, useConnectorTypeText } from '../connector/Standard';
import { EditableTableCell } from '../editable-values/EditableTableCell';
import { EditableValue, ListItemTextClickable } from '../editable-values/EditableValue';
import { EditableValueOption } from '../editable-values/forms/EditableValueOption';
import { WithAccessScope } from '../wrappers/WithAccessScope';
import { useChargePoint } from '../wrappers/WithChargePoint';
import { useConnector, WithConnector } from '../wrappers/WithConnector';
import { WithEVSE } from '../wrappers/WithEVSE';
import { useChargePointChangeLocationDialog } from './dialogs/ChargePointChangeLocationDialog';
import { useChargePointChangeStateDialog } from './dialogs/ChargePointChangeStateDialog';
import { useChargePointRemoveDialog } from './dialogs/ChargePointRemove';
import { useConnectorCreateDialog } from './dialogs/ConnectorCreate';
import { useConnectorRemoveDialog } from './dialogs/ConnectorRemove';
import { useChargePointStateText } from './elements/ChargePointStateText';

const connectorTypeOptions: EditableValueOption<OCPI.V221.Types.ConnectorType>[] = Object.values(
    OCPI.V221.Types.ConnectorType,
).map(value => ({ label: <ConnectorTypeText type={value} />, value: value }));

const connectorFormatOptions: EditableValueOption<OCPI.V221.Types.ConnectorFormat>[] = Object.values(
    OCPI.V221.Types.ConnectorFormat,
).map(value => ({ label: value, value: value }));

const powerTypeOptions: EditableValueOption<OCPI.V221.Types.PowerType>[] = Object.values(OCPI.V221.Types.PowerType).map(
    value => ({
        label: value,
        value: value,
    }),
);

type ConnectorBlockProps = {
    chargePoint: ChargePoint;
    evseNumber: number;
    connectorNumber: number;
};
function ConnectorBlock({ chargePoint, evseNumber, connectorNumber }: ConnectorBlockProps) {
    const connector = useConnector();
    const showConnectorRemoveDialog = useConnectorRemoveDialog(store => store.show);
    const updateConnector = useRegistryEditor(store => store.updateConnector);

    return (
        <TableRow
            key={connector._id}
            // hover={true}
            sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
        >
            <TableCell>
                Коннектор {evseNumber}:{connectorNumber}
            </TableCell>
            <EditableTableCell
                align="right"
                value={connector.standard}
                valueText={useConnectorTypeText}
                formVariant={'select'}
                options={connectorTypeOptions}
                onNewValue={newValue => updateConnector(connector, { standard: newValue })}
            />
            <EditableTableCell
                align="right"
                value={connector.format}
                formVariant="select"
                options={connectorFormatOptions}
                onNewValue={newValue => updateConnector(connector, { format: newValue })}
            />
            <EditableTableCell
                align="right"
                value={connector.powerType}
                formVariant="select"
                options={powerTypeOptions}
                onNewValue={newValue => updateConnector(connector, { powerType: newValue })}
            />
            <EditableTableCell
                align="right"
                value={connector.power}
                dimensionText="кВт"
                onNewValue={newValue => updateConnector(connector, { power: newValue })}
            />

            <TableCell padding="checkbox">
                <PopupState variant="popover">
                    {popupState => (
                        <React.Fragment>
                            <IconButton {...bindTrigger(popupState)}>
                                <MoreVertRoundedIcon />
                            </IconButton>
                            <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                    onClick={() => {
                                        showConnectorRemoveDialog(chargePoint._id, connector._id);
                                        popupState.close();
                                    }}
                                >
                                    <ListItemIcon>
                                        <DeleteRoundedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Удалить</ListItemText>
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    )}
                </PopupState>
            </TableCell>
        </TableRow>
    );
}

function ConnectorListBlock() {
    const chargePoint = useChargePoint();
    const showConnectorCreateDialog = useConnectorCreateDialog(store => store.show);

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Коннекторы</Typography>
                    </ListItemText>
                    <ListItemSecondaryAction>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={event => showConnectorCreateDialog(chargePoint._id)}
                        >
                            Добавить
                        </Button>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right">Стандарт</TableCell>
                            <TableCell align="right">Формат</TableCell>
                            <TableCell align="right">Тип питания</TableCell>
                            <TableCell align="right">Макс. мощность</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {chargePoint.evses.map((evse, evseIndex) => (
                            <WithEVSE key={evse._id} id={evse._id}>
                                {evse.connectors.map((connector, connectorIndex) => (
                                    <WithConnector key={connector._id} id={connector._id}>
                                        <ConnectorBlock
                                            key={connector._id}
                                            chargePoint={chargePoint}
                                            evseNumber={evseIndex + 1}
                                            connectorNumber={connectorIndex + 1}
                                        />
                                    </WithConnector>
                                ))}
                            </WithEVSE>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

function OperationalInformationBlock() {
    const chargePoint = useChargePoint();
    const updateChargePoint = useRegistryEditor(store => store.updateChargePoint);
    const showChangeStateDialog = useChargePointChangeStateDialog(store => store.show);

    const stateText = useChargePointStateText(chargePoint.state);

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Операционная информация</Typography>
                    </ListItemText>
                    <ListItemSecondaryAction>
                        {/* <ConnectionStatus status={chargePoint.ocpp.connectionStatus} mode="full" /> */}
                    </ListItemSecondaryAction>
                </ListItem>
            </List>

            <List component={Paper} disablePadding>
                <ListItem divider>
                    <ListItemText primary="eMA ID" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={chargePoint.roamingIdentity} />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="OCPP идентификатор" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={chargePoint.chargeBoxIdentity} />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="Операционный статус" />

                    <ListItemSecondaryAction>
                        <ListItemTextClickable
                            value={stateText}
                            onClick={event => showChangeStateDialog(chargePoint._id)}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                    <ListItemIcon sx={{ marginRight: 2, minWidth: 0 }}>
                        {chargePoint.publish && <MobileFriendlyRounded />}
                        {!chargePoint.publish && <MobileOffRounded />}
                    </ListItemIcon>
                    <ListItemText primary="Публичная станция" secondary="Станция доступна в мобильном приложении" />
                    <ListItemSecondaryAction>
                        <Switch
                            checked={chargePoint.publish}
                            onChange={event => {
                                const publish = event.target.checked;
                                updateChargePoint(chargePoint, { publish: publish });
                            }}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                    <ListItemIcon sx={{ marginRight: 2, minWidth: 0 }}>
                        <CableRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Freevend" secondary="Сервер авторизирует любые rfid-карты" />
                    <ListItemSecondaryAction>
                        <Switch
                            checked={chargePoint.freevend}
                            onChange={event => {
                                const freevend = event.target.checked;
                                updateChargePoint(chargePoint, { freevend: freevend });
                            }}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                    <ListItemIcon sx={{ marginRight: 2, minWidth: 0 }}>
                        <CableRoundedIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Ignore Ping/Pong"
                        secondary="Сервер не учитывает ping/pong для определения статуса соединения"
                    />
                    <ListItemSecondaryAction>
                        <Switch
                            checked={!!chargePoint.ignorePingPong}
                            onChange={event => {
                                const ignorePingPong = event.target.checked;
                                updateChargePoint(chargePoint, { ignorePingPong: ignorePingPong });
                            }}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        </>
    );
}

function InformationBlock() {
    const chargePoint = useChargePoint();
    const updateChargePoint = useRegistryEditor(store => store.updateChargePoint);

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Информация</Typography>
                    </ListItemText>
                    <ListItemSecondaryAction>
                        {/* <ConnectionStatus status={chargePoint.ocpp.connectionStatus} mode="full" /> */}
                    </ListItemSecondaryAction>
                </ListItem>
            </List>

            <List component={Paper} disablePadding>
                <ListItem divider>
                    <ListItemText primary="Название" />
                    <ListItemSecondaryAction>
                        <EditableValue
                            value={chargePoint.physicalReference}
                            onNewValue={newValue => updateChargePoint(chargePoint, { physicalReference: newValue })}
                        />
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem divider>
                    <ListItemText primary="Производитель" />
                    <ListItemSecondaryAction>
                        <EditableValue
                            value={chargePoint.vendor}
                            onNewValue={newValue => updateChargePoint(chargePoint, { vendor: newValue })}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="Модель" />
                    <ListItemSecondaryAction>
                        <EditableValue
                            value={chargePoint.model}
                            onNewValue={newValue => updateChargePoint(chargePoint, { model: newValue })}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="Серийный номер" />
                    <ListItemSecondaryAction>
                        <EditableValue
                            value={chargePoint.serialNumber}
                            onNewValue={newValue => updateChargePoint(chargePoint, { serialNumber: newValue })}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="Номер сим-карты" />
                    <ListItemSecondaryAction>
                        <EditableValue
                            value={chargePoint.simNumber || ''}
                            onNewValue={newValue => updateChargePoint(chargePoint, { simNumber: newValue })}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        </>
    );
}

function ActionsBlock() {
    const chargePoint = useChargePoint();
    const showChargePointRemoveDialog = useChargePointRemoveDialog(store => store.show);
    const showChargePointChangeLocationDialog = useChargePointChangeLocationDialog(store => store.show);

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Действия</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <Stack spacing={2}>
                <List disablePadding component={Paper}>
                    <ListItem divider>
                        <ListItemText primary="Перенос" secondary="Перенос станции в другую локацию" />
                        <ListItemSecondaryAction>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => showChargePointChangeLocationDialog(chargePoint._id)}
                            >
                                Перенести
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <WithAccessScope scope={AccessScope.OPERATOR}>
                        <ListItem>
                            <ListItemText primary="Удаление" secondary="Удаление зарядной станции" />
                            <ListItemSecondaryAction>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={() => showChargePointRemoveDialog(chargePoint._id)}
                                >
                                    Удалить
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </WithAccessScope>
                </List>
            </Stack>
        </>
    );
}

export default function ChargePointSettingsPage() {
    return (
        <Grid container spacing={2} sx={{ marginTop: 0 }}>
            <Grid item xs={12}>
                <ConnectorListBlock />
            </Grid>
            <Grid item lg={6} xs={12}>
                <InformationBlock />
            </Grid>
            <Grid item lg={6} xs={12}>
                <OperationalInformationBlock />
            </Grid>
            <Grid item lg={6} xs={12}>
                <ActionsBlock />
            </Grid>
        </Grid>
    );
}
