import { useEffect } from 'react';
import { useDataCache } from '../../../services/DataCache';
import { Stack, Typography, colors } from '@mui/material';
import { DataWrap } from '../../event-log/components/DataWrap';
import { OperatorName } from '../../operators/elements/OperatorName';

interface Props {
    id: string;
    displayEMPS?: boolean;
}

export function CustomerTitle({ id, displayEMPS = false }: Props) {
    const customerTitle = useCustomerTitle(id);
    const customer = useDataCache(store => store.customers[id]);

    return (
        <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
            {displayEMPS && customer?.operator && (
                <DataWrap color={colors.grey[200]}>
                    <OperatorName id={customer.operator} />
                </DataWrap>
            )}
            <Typography fontStyle={'inherit'} fontSize={'inherit'}>
                {customerTitle}
            </Typography>
        </Stack>
    );
}

export function useCustomerTitle(id: string = '') {
    const loadCustomer = useDataCache(store => store.loadCustomer);
    const customer = useDataCache(store => store.customers[id]);

    useEffect(() => {
        if (!id) {
            return;
        }
        loadCustomer(id);
    }, [id, loadCustomer]);

    return customer?.phone || id;
}
