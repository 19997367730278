import { Billing } from '@electrifly/central-client-api';
import React, { createContext, ReactNode, useContext } from 'react';
import invariant from 'tiny-invariant';

const BillingContext = createContext<Billing | null>(null);

type Props = {
    billing: Billing;
    children: ReactNode;
};

export const WithBilling = ({ billing, children }: Props) => {
    const [currentBilling, setBilling] = React.useState(billing);
    React.useEffect(() => setBilling(billing), [billing]);
    return <BillingContext.Provider value={currentBilling}>{children}</BillingContext.Provider>;
};

export function useBilling() {
    const billing = useContext(BillingContext);
    invariant(!!billing, 'Error getting billing. Possible you forgot to add WithBilling wrapper');
    return billing;
}

export function useBillingUnsafe() {
    const billing = useContext(BillingContext);
    return billing;
}
