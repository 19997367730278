import { Billing, ReceiptStatus } from '@electrifly/central-client-api';
import { IconButton, Tooltip } from '@mui/material';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import { useMemo } from 'react';

const RECEIPT_STATUS_TO_COLOR: Record<ReceiptStatus, string> = {
    created: 'inherit',
    pending: 'inherit',
    succeeded: 'success',
    canceled: 'warning',
};

interface ReceiptButtonProps {
    billing: Billing;
}
function ReceiptButtonInternal({ billing }: ReceiptButtonProps) {
    const color = useMemo(() => {
        return RECEIPT_STATUS_TO_COLOR[billing.receiptStatus!];
    }, [billing.receiptStatus]);

    return (
        <Tooltip title={`Статус чека: ${billing.receiptStatus}`}>
            <IconButton size="small" color={color as any}>
                <ReceiptLongRoundedIcon />
            </IconButton>
        </Tooltip>
    );
}

export function ReceiptButton({ billing }: ReceiptButtonProps) {
    if (!billing.receiptable) {
        return null;
    }

    return <ReceiptButtonInternal billing={billing} />;
}
