import {
    Stack,
    Typography,
    List,
    ListItemText,
    ListItemButton,
    Paper,
    ListItemSecondaryAction,
    Grid,
    Tooltip,
    ListSubheader,
    colors,
} from '@mui/material';
import create from 'zustand';
import shallow from 'zustand/shallow';

import { useRegistry } from '../../services/GlobalRegistry';
import produce from 'immer';
import {
    ChargePoint,
    ChargePointConnectionStatus,
    ChargePointState,
    Connector,
    EVSE,
} from '@electrifly/central-client-api';
import { ConnectorStatus } from '../charge-point/status/ConnectorStatus';
import { ConnectionStatus } from '../charge-point/status/ConnectionStatus';
import { useDashboardService } from './DashboardPage';
import _ from 'lodash';
import { ChargePointStateText } from '../charge-point/elements/ChargePointStateText';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import HandymanRoundedIcon from '@mui/icons-material/HandymanRounded';
import StopCircleRoundedIcon from '@mui/icons-material/StopCircleRounded';
import DoDisturbOnRoundedIcon from '@mui/icons-material/DoDisturbOnRounded';

type ChargePointStates = Record<'Created' | 'Production' | 'Service' | 'Suspended' | 'Retired', number>;
type ChargePointStatuses = Record<'Online' | 'Offline' | 'Available' | 'Faulted' | 'Unavailable', number>;
type ConnectorStatuses = Record<'Available' | 'Charging' | 'Suspended' | 'Faulted' | 'Other', number>;

type Store = {
    chargePointStates: ChargePointStates;
    chargePointStatuses: ChargePointStatuses;
    connectorStatuses: ConnectorStatuses;
};

const DEFAULT_STORE: Store = {
    chargePointStates: {
        Created: 0,
        Production: 0,
        Service: 0,
        Suspended: 0,
        Retired: 0,
    },
    chargePointStatuses: {
        Online: 0,
        Offline: 0,
        Available: 0,
        Faulted: 0,
        Unavailable: 0,
    },
    connectorStatuses: {
        Available: 0,
        Charging: 0,
        Suspended: 0,
        Faulted: 0,
        Other: 0,
    },
};

function calculateChargePointStatesMap(chargePoints: ChargePoint[]): ChargePointStates {
    const result = { ...DEFAULT_STORE.chargePointStates };

    const groupped = _.groupBy(chargePoints, item => item.state);

    const serviceCount = (groupped[ChargePointState.SERVICE] || []).length;
    const repairCount = (groupped[ChargePointState.REPAIR] || []).length;

    result.Created = (groupped[ChargePointState.CREATED] || []).length;
    result.Production = (groupped[ChargePointState.PRODUCTION] || []).length;
    result.Service = serviceCount + repairCount;
    result.Suspended = (groupped[ChargePointState.SUSPENDED] || []).length;
    result.Retired = (groupped[ChargePointState.RETIRED] || []).length;
    return result;
}

function calculateChargePointStatusesMap(chargePoints: ChargePoint[]): ChargePointStatuses {
    const result = { ...DEFAULT_STORE.chargePointStatuses };
    const filtered = chargePoints.filter(item => item.state === ChargePointState.PRODUCTION);

    const grouppedByConnection = _.groupBy(filtered, item => item.ocpp.connectionStatus);

    result.Online = grouppedByConnection[ChargePointConnectionStatus.ONLINE]?.length ?? 0;
    result.Offline = grouppedByConnection[ChargePointConnectionStatus.OFFLINE]?.length ?? 0;

    const grouppedByStatus = _.groupBy(filtered, item => item.statusMessage.status);

    result.Available = grouppedByStatus['Available']?.length ?? 0;
    result.Faulted = grouppedByStatus['Faulted']?.length ?? 0;
    result.Unavailable = grouppedByStatus['Unavailable']?.length ?? 0;

    return result;
}

function calculateConnectorStatusesMap(
    chargePoints: ChargePoint[],
    evses: EVSE[],
    connectors: Connector[],
): ConnectorStatuses {
    const filteredChargePoints = chargePoints.filter(item => item.state === ChargePointState.PRODUCTION);
    const filteredChargePointsMap = _.keyBy(filteredChargePoints, item => item._id);
    const filteredEVSEsMap = _.chain(evses)
        .filter(evse => !!filteredChargePointsMap[evse.chargePoint])
        .keyBy(item => item._id)
        .value();
    const filteredConnectors = _.chain(connectors)
        .filter(connector => !!filteredEVSEsMap[connector.evse])
        .keyBy(item => item._id)
        .toArray()
        .value();

    const groupped = _.groupBy(filteredConnectors, item => item.statusMessage.status);
    const available = groupped['Available']?.length ?? 0;
    const preparing = groupped['Preparing']?.length ?? 0;
    const charging = groupped['Charging']?.length ?? 0;
    const occupied = groupped['Occupied']?.length ?? 0;
    const finishing = groupped['Finishing']?.length ?? 0;
    const suspendedEV = groupped['SuspendedEV']?.length ?? 0;
    const suspendedEVSE = groupped['SuspendedEVSE']?.length ?? 0;
    const faulted = groupped['Faulted']?.length ?? 0;
    const reserved = groupped['Reserved']?.length ?? 0;
    const unavailable = groupped['Unavailable']?.length ?? 0;

    const result = { ...DEFAULT_STORE.connectorStatuses };

    result.Available = available + preparing;
    result.Charging = charging + occupied + finishing;
    result.Suspended = suspendedEV + suspendedEVSE;
    result.Faulted = faulted;
    result.Other = reserved + unavailable;

    return result;
}

const useDashboardSummary = create<Store>((set, get) => {
    const subscription1 = useRegistry.subscribe<
        [Record<string, ChargePoint>, Record<string, EVSE>, Record<string, Connector>]
    >(
        store => [store.chargePoints, store.evses, store.connectors],
        ([chargePoints, evses, connectors]) => {
            const newChargePointStates = calculateChargePointStatesMap(Object.values(chargePoints));
            const newChargePointStatuses = calculateChargePointStatusesMap(Object.values(chargePoints));

            const newConnectorStatuses = calculateConnectorStatusesMap(
                Object.values(chargePoints),
                Object.values(evses),
                Object.values(connectors),
            );

            set(
                produce<Store>(draft => {
                    draft.connectorStatuses = newConnectorStatuses;
                    draft.chargePointStates = newChargePointStates;
                    draft.chargePointStatuses = newChargePointStatuses;
                }),
            );
        },
        { equalityFn: shallow },
    );

    return {
        ...DEFAULT_STORE,
    };
});

function ChargePointAllBlock() {
    const chargePointStates = useDashboardSummary(store => store.chargePointStates);
    const setNewChargePointStateFilter = useDashboardService(store => store.setNewChargePointStateFilter);

    return (
        <List component={Paper} dense={true}>
            <ListSubheader sx={{}}>Зарядные станции</ListSubheader>
            <ListItemButton onClick={() => setNewChargePointStateFilter([ChargePointState.CREATED])}>
                <ListItemText>
                    <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                        <AddRoundedIcon fontSize="inherit" sx={{ color: colors.grey[500] }} />

                        {/* <ConnectionStatus status={ChargePointConnectionStatus.ONLINE} mode="dot" /> */}
                        <Typography fontSize={'inherit'}>
                            <ChargePointStateText state={ChargePointState.CREATED} />
                        </Typography>
                    </Stack>
                </ListItemText>
                <ListItemSecondaryAction>{chargePointStates.Created}</ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton onClick={() => setNewChargePointStateFilter([ChargePointState.PRODUCTION])}>
                <ListItemText>
                    <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                        <ConnectionStatus status={ChargePointConnectionStatus.ONLINE} mode="dot" />
                        <Typography fontSize={'inherit'}>
                            <ChargePointStateText state={ChargePointState.PRODUCTION} />
                        </Typography>
                    </Stack>
                </ListItemText>
                <ListItemSecondaryAction>{chargePointStates.Production}</ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton
                onClick={() => setNewChargePointStateFilter([ChargePointState.SERVICE, ChargePointState.REPAIR])}
            >
                <ListItemText>
                    <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                        <HandymanRoundedIcon fontSize="inherit" sx={{ color: colors.red[500] }} />
                        <Typography fontSize={'inherit'}>
                            <ChargePointStateText state={ChargePointState.REPAIR} />
                        </Typography>
                    </Stack>
                </ListItemText>
                <ListItemSecondaryAction>{chargePointStates.Service}</ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton onClick={() => setNewChargePointStateFilter([ChargePointState.SUSPENDED])}>
                <ListItemText>
                    <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                        <StopCircleRoundedIcon fontSize="inherit" sx={{ color: colors.orange[500] }} />
                        <Typography fontSize={'inherit'}>
                            <ChargePointStateText state={ChargePointState.SUSPENDED} />
                        </Typography>
                    </Stack>
                </ListItemText>
                <ListItemSecondaryAction>{chargePointStates.Suspended}</ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton onClick={() => setNewChargePointStateFilter([ChargePointState.RETIRED])}>
                <ListItemText>
                    <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                        <DoDisturbOnRoundedIcon fontSize="inherit" sx={{ color: colors.grey[900] }} />
                        <Typography fontSize={'inherit'}>
                            <ChargePointStateText state={ChargePointState.RETIRED} />
                        </Typography>
                    </Stack>
                </ListItemText>
                <ListItemSecondaryAction>{chargePointStates.Retired}</ListItemSecondaryAction>
            </ListItemButton>
        </List>
    );
}

function ChargePointOperativeBlock() {
    const chargePointStatuses = useDashboardSummary(store => store.chargePointStatuses);
    const setNewChargePointStatusFilter = useDashboardService(store => store.setNewChargePointStatusFilter);

    return (
        <List component={Paper} dense={true}>
            <ListSubheader sx={{}}>Зарядные станции | Эксплуатация</ListSubheader>
            <ListItemButton onClick={() => setNewChargePointStatusFilter(['Online'])}>
                <ListItemText>
                    <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                        <ConnectionStatus status={ChargePointConnectionStatus.ONLINE} mode="dot" />
                        <Typography fontSize={'inherit'}>Online</Typography>
                    </Stack>
                </ListItemText>
                <ListItemSecondaryAction>{chargePointStatuses.Online}</ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton divider onClick={() => setNewChargePointStatusFilter(['Offline'])}>
                <ListItemText>
                    <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                        <ConnectionStatus status={ChargePointConnectionStatus.OFFLINE} mode="dot" />
                        <Typography fontSize={'inherit'}>Offline</Typography>
                    </Stack>
                </ListItemText>
                <ListItemSecondaryAction>{chargePointStatuses.Offline}</ListItemSecondaryAction>
            </ListItemButton>

            <ListItemButton onClick={() => setNewChargePointStatusFilter(['Available'])}>
                <ListItemText>
                    <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                        <ConnectorStatus status="Available" variant="dot" />
                        <Typography fontSize={'inherit'}>Available</Typography>
                    </Stack>
                </ListItemText>
                <ListItemSecondaryAction>{chargePointStatuses.Available}</ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton onClick={() => setNewChargePointStatusFilter(['Faulted'])}>
                <ListItemText>
                    <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                        <ConnectorStatus status="Faulted" variant="dot" />
                        <Typography fontSize={'inherit'}>Faulted</Typography>
                    </Stack>
                </ListItemText>
                <ListItemSecondaryAction>{chargePointStatuses.Faulted}</ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton onClick={() => setNewChargePointStatusFilter(['Unavailable'])}>
                <ListItemText>
                    <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                        <ConnectorStatus status="Unavailable" variant="dot" />
                        <Typography fontSize={'inherit'}>Unavailable</Typography>
                    </Stack>
                </ListItemText>
                <ListItemSecondaryAction>{chargePointStatuses.Unavailable}</ListItemSecondaryAction>
            </ListItemButton>
            {/* <ListItemButton onClick={() => setNewChargePointFilter(['Offline'])}>
        <ListItemText>
            <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                <ConnectionStatus status={ChargePointConnectionStatus.OFFLINE} mode="dot" />
                <Typography fontSize={'inherit'}>Offline</Typography>
            </Stack>
        </ListItemText>
        <ListItemSecondaryAction>{chargePointStatuses.Offline}</ListItemSecondaryAction>
    </ListItemButton>
    <ListItemButton>
        <ListItemText>
            <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                <ConnectionStatus status={ChargePointConnectionStatus.OFFLINE} mode="dot" />
                <Typography fontSize={'inherit'}>Maintenance</Typography>
            </Stack>
        </ListItemText>
        <ListItemSecondaryAction>{chargePointStatuses.Maintenance}</ListItemSecondaryAction>
    </ListItemButton> */}
        </List>
    );
}

function ConnectorOperativeBlock() {
    const connectorStatuses = useDashboardSummary(store => store.connectorStatuses);
    const setNewConnectorFilter = useDashboardService(store => store.setNewConnectorFilter);

    return (
        <List component={Paper} dense={true}>
            <ListSubheader>Коннекторы | Эксплуатация</ListSubheader>
            <ListItemButton onClick={() => setNewConnectorFilter(['Available', 'Preparing'])}>
                <ListItemText>
                    <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                        <ConnectorStatus status="Available" variant="dot" />
                        <Typography fontSize={'inherit'}>Available, Preparing</Typography>
                    </Stack>
                </ListItemText>
                <ListItemSecondaryAction>{connectorStatuses.Available}</ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton onClick={() => setNewConnectorFilter(['Charging', 'Occupied', 'Finishing'])}>
                <ListItemText>
                    <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                        <ConnectorStatus status="Charging" variant="dot" />
                        <Typography fontSize={'inherit'}>Charging, Finishing</Typography>
                    </Stack>
                </ListItemText>
                <ListItemSecondaryAction>{connectorStatuses.Charging}</ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton onClick={() => setNewConnectorFilter(['SuspendedEVSE', 'SuspendedEV'])}>
                <ListItemText>
                    <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                        <ConnectorStatus status="SuspendedEV" variant="dot" />
                        <Typography fontSize={'inherit'}>Suspended</Typography>
                    </Stack>
                </ListItemText>
                <ListItemSecondaryAction>{connectorStatuses.Suspended}</ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton onClick={() => setNewConnectorFilter(['Faulted'])}>
                <ListItemText>
                    <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                        <ConnectorStatus status="Faulted" variant="dot" />
                        <Typography fontSize={'inherit'}>Faulted</Typography>
                    </Stack>
                </ListItemText>

                <ListItemSecondaryAction>{connectorStatuses.Faulted}</ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton onClick={() => setNewConnectorFilter(['Unavailable', 'Reserved'])}>
                <ListItemText>
                    <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                        <ConnectorStatus status="Unavailable" variant="dot" />
                        <Typography fontSize={'inherit'}>Unavailable, Reserved</Typography>
                    </Stack>
                </ListItemText>
                <ListItemSecondaryAction>{connectorStatuses.Other}</ListItemSecondaryAction>
            </ListItemButton>
        </List>
    );
}

export default function DashboardSummary() {
    return (
        <Grid container spacing={2}>
            <Grid item lg={3} xs={12}>
                <ChargePointAllBlock />
            </Grid>
            <Grid item lg={3} xs={12}>
                <ChargePointOperativeBlock />
            </Grid>
            <Grid item lg={3} xs={12}>
                <ConnectorOperativeBlock />
            </Grid>
        </Grid>
    );
}
