import _ from 'lodash';
import {StoreApi} from 'zustand';
import createContext from 'zustand/context';
import {API} from "../../../core/api-client";
import {createWithImmer} from "../../../misc/CreateWithImmer";
import {ColumnKey} from "../../event-log/components/table/TableColumns";

export type Options = Record<ColumnKey, boolean>;

type Data = {
    gates: any[];
    loading: boolean;
    canLoadMore: boolean;

    filter: { limit: number }
};

type Actions = {
    reset: () => void;
    loadNext: () => Promise<void>;
};

type Service = Data & Actions;

function createDefaultData(): Data {
    return {
        gates: [],
        loading: false,
        canLoadMore: false,
        filter: {limit: 100}
    };
}

const createStore = (filter?: Partial<any> | undefined, options?: Partial<Options> | undefined) => {
    const initialData = {
        ...createDefaultData(),
        filter: {...createDefaultData().filter, ...filter},
    };

    return createWithImmer<Service>((set, get) => {
        // WebsocketClient.events.ACTION_EVENT.on(actionEvent => {
        //     const { events, filter } = get();
        //     const passed = transactionPassFilter(actionEvent, filter);
        //     if (!passed) {
        //         set(draft => {
        //             const index = draft.events.findIndex(item => item._id === actionEvent._id);
        //             if (index !== -1) {
        //                 draft.events.splice(index, 1);
        //             }
        //         });
        //
        //         return;
        //     }
        // });

        function resetData() {
            set({gates: []});
        }


        async function loadNext() {
            if (get().loading) {
                debouncedLoadNext();
                return;
            }

            set({loading: true});

            const {filter, gates} = get();

            const skip = gates.length;
            const [error, res] = await API.gatesList({
                skip,
                limit: filter.limit,
            });

            console.log(res?.data);

            if (error) {
                console.error(error);
                set({loading: false});
                return;
            }

            const newData = res.data;
            const canLoadMore = newData.length === filter.limit;

            set({
                loading: false,
                canLoadMore: canLoadMore,
                gates: [...get().gates, ...newData],
            });
        }

        function loadNexWithReset() {
            resetData();
            loadNext();
        }

        const debouncedLoadNext = _.debounce(() => loadNext(), 300);
        const debouncedLoadNextWithReset = _.debounce(() => loadNexWithReset(), 300);

        return {
            ...initialData,
            reset: () => set({...initialData}),
            loadNext: loadNext,
        };
    });
};

const {Provider, useStore} = createContext<StoreApi<Service>>();

export const GateService = {
    Provider,
    createStore,
    useStore,
};
