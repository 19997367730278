import { AccessScope, Connector } from '@electrifly/central-client-api/data';
import { LoadingButton } from '@mui/lab';
import {
    Autocomplete,
    Button,
    CircularProgress,
    Collapse,
    FormControl,
    FormHelperText,
    List,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
    colors,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormikProps, useFormik } from 'formik';
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import create from 'zustand';
import { useAuthContext } from '../../../auth/AuthContext';
import { API } from '../../../core/api-client';
import {
    createCustomerSuggestionStore,
    CustomerSuggestionContext,
} from '../../customers/services/CustomerSuggestionContext';
import { WithAccessScope } from '../../wrappers/WithAccessScope';
import { DataWrap } from '../../event-log/components/DataWrap';
import { OperatorName } from '../../operators/elements/OperatorName';
import {
    CorporateSuggestionContext,
    createCorporateSuggestionStore,
} from '../../corporate/services/CorporateSuggestionContext';
import { useLocationByConnector } from '../../../hooks/chargePointHooks';
import { useRegistry } from '../../../services/GlobalRegistry';

type Store = {
    open: boolean;
    tariffGroupId: string;
    connector: Connector;
    operatorId: string;
    show: (connector: Connector, tariffGroupId: string) => void;
    hide: () => void;
};

const useStore = create<Store>((set, get) => ({
    open: false,
    tariffGroupId: '',
    connector: {} as Connector,
    operatorId: '',
    show: (connector: Connector, tariffGroupId: string) => {
        const evse = useRegistry.getState().evses[connector.evse];
        const chargePoint = useRegistry.getState().chargePoints[evse.chargePoint];
        const chargeLocation = useRegistry.getState().chargeLocations[chargePoint.location];
        set({ open: true, connector, tariffGroupId, operatorId: chargeLocation.operator });
    },
    hide: () => set({ open: false }),
}));

function CustomerSelector() {
    const { formik } = useContext(FormContext);
    const inputRef = useRef<HTMLInputElement>();

    const loading = CustomerSuggestionContext.useStore(store => store.loading);
    const setSearch = CustomerSuggestionContext.useStore(store => store.setSearch);
    const customersInfo = CustomerSuggestionContext.useStore(store => store.customers);

    const selectedValue = useMemo(
        () => customersInfo.find(item => item._id === formik.values.owner),
        [customersInfo, formik.values.owner],
    );

    useEffect(() => {
        if (formik.values.type === 'customer') {
            inputRef.current?.focus();
        }
    }, [formik.values.type, inputRef]);

    return (
        <Stack spacing={1}>
            <FormControl fullWidth>
                <Autocomplete
                    sx={{ minWidth: '15em' }}
                    fullWidth
                    options={customersInfo}
                    noOptionsText="Клиент не найден"
                    value={selectedValue || null} //null is neede for initialization render in controlled state
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    getOptionLabel={option => option.phone}
                    renderOption={(props, option, state) => (
                        <li {...props} key={option._id}>
                            <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
                                {option.operator && (
                                    <DataWrap color={colors.grey[200]}>
                                        <OperatorName id={option.operator} />
                                    </DataWrap>
                                )}
                                <Typography>{option.phone}</Typography>
                            </Stack>
                        </li>
                    )}
                    onInputChange={(event, newValue) => setSearch(newValue)}
                    onChange={(event, val) =>
                        formik.setValues(values => ({ ...values, owner: val?._id, account: val?.account }))
                    }
                    renderInput={params => (
                        <TextField
                            {...params}
                            inputRef={inputRef}
                            autoFocus
                            variant="outlined"
                            placeholder="+79990000000"
                            error={formik.touched.owner && Boolean(formik.errors.owner)}
                            helperText={formik.touched.owner && formik.errors.owner}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        {selectedValue?.operator && (
                                            <DataWrap color={colors.grey[200]}>
                                                <OperatorName id={selectedValue.operator} />
                                            </DataWrap>
                                        )}
                                    </>
                                ),
                                endAdornment: (
                                    <React.Fragment>
                                        {loading && <CircularProgress color="inherit" size={20} />}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </FormControl>
        </Stack>
    );
}

function CorporateSelector() {
    const { formik } = useContext(FormContext);
    const inputRef = useRef<HTMLInputElement>();

    const loading = CorporateSuggestionContext.useStore(store => store.loading);
    const setSearch = CorporateSuggestionContext.useStore(store => store.setSearch);
    const corporatesInfo = CorporateSuggestionContext.useStore(store => store.corporates);

    const selectedValue = useMemo(
        () => corporatesInfo.find(item => item._id === formik.values.owner),
        [corporatesInfo, formik.values.owner],
    );

    useEffect(() => {
        if (formik.values.type === 'corporate') {
            inputRef.current?.focus();
        }
    }, [formik.values.type, inputRef]);

    return (
        <Stack spacing={1}>
            <FormControl fullWidth>
                <Autocomplete
                    sx={{ minWidth: '15em' }}
                    fullWidth
                    options={corporatesInfo}
                    noOptionsText="Клиент не найден"
                    value={selectedValue || null} //null is neede for initialization render in controlled state
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    getOptionLabel={option => option.name}
                    renderOption={(props, option, state) => (
                        <li {...props} key={option._id}>
                            <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
                                {option.operator && (
                                    <DataWrap color={colors.grey[200]}>
                                        <OperatorName id={option.operator} />
                                    </DataWrap>
                                )}
                                <Typography>{option.name}</Typography>
                            </Stack>
                        </li>
                    )}
                    onInputChange={(event, newValue) => setSearch(newValue)}
                    onChange={(event, val) =>
                        formik.setValues(values => ({ ...values, owner: val?._id, account: val?.account }))
                    }
                    renderInput={params => (
                        <TextField
                            {...params}
                            inputRef={inputRef}
                            autoFocus
                            variant="outlined"
                            placeholder="Имя корпоративного клиента"
                            error={formik.touched.owner && Boolean(formik.errors.owner)}
                            helperText={formik.touched.owner && formik.errors.owner}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        {selectedValue?.operator && (
                                            <DataWrap color={colors.grey[200]}>
                                                <OperatorName id={selectedValue.operator} />
                                            </DataWrap>
                                        )}
                                    </>
                                ),
                                endAdornment: (
                                    <React.Fragment>
                                        {loading && <CircularProgress color="inherit" size={20} />}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </FormControl>
        </Stack>
    );
}

function FormInternal() {
    const { formik } = useContext(FormContext);
    const user = useAuthContext(store => store.profile);
    const operatorId = useStore(store => store.operatorId);

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
                <List>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                    >
                        <ListItemButton onClick={() => formik.setValues(values => ({ ...values, type: 'corporate' }))}>
                            <ListItemText
                                primary={`Корпоративный клиент`}
                                secondary={`Добавление корпоративного клиента`}
                            />
                            <ListItemSecondaryAction>
                                <Radio checked={formik.values.type === 'corporate'} />
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        <Collapse in={formik.values.type === 'corporate'}>
                            <CorporateSuggestionContext.Provider
                                createStore={() => createCorporateSuggestionStore({ operator: operatorId })}
                            >
                                <CorporateSelector />
                            </CorporateSuggestionContext.Provider>
                        </Collapse>

                        <WithAccessScope scope={AccessScope.OPERATOR}>
                            <ListItemButton
                                onClick={() => formik.setValues(values => ({ ...values, type: 'customer' }))}
                            >
                                <ListItemText primary={`Клиент`} secondary={`Добавление автовладельца`} />
                                <ListItemSecondaryAction>
                                    <Radio checked={formik.values.type === 'customer'} />
                                </ListItemSecondaryAction>
                            </ListItemButton>
                        </WithAccessScope>
                        <Collapse in={formik.values.type === 'customer'}>
                            <CustomerSuggestionContext.Provider
                                createStore={() => createCustomerSuggestionStore({ operator: operatorId })}
                            >
                                <CustomerSelector />
                            </CustomerSuggestionContext.Provider>
                        </Collapse>
                    </RadioGroup>
                </List>
            </FormControl>
        </form>
    );
}
interface FormData {
    type: 'customer' | 'corporate';
    owner?: string;
    account?: string;
}

interface FormContextType {
    formik: FormikProps<FormData>;
}

export const FormContext = createContext<FormContextType>({} as FormContextType);

export function ChargePointAddAccountToTariffDialog() {
    const open = useStore(store => store.open);
    const hide = useStore(store => store.hide);
    const tariffGroupId = useStore(store => store.tariffGroupId);
    const connector = useStore(store => store.connector);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<FormData>({
        initialValues: {
            type: 'corporate',
            account: '',
        },

        onSubmit: async values => {
            console.log(values);
            if (!values.account) {
                return;
            }
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.
            connectorTariffAccountAdd(connector._id, {
                account: values.account,
                groupId: tariffGroupId,
            });
            setIsRequesting(false);
            if (!error) {
                hide();
                formik.resetForm();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Добавление клиента к тарифу</DialogTitle>
            <DialogContent>
                <FormContext.Provider value={{ formik }}>
                    <FormInternal />
                </FormContext.Provider>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Добавить</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export const ChargePointAddAccountToTariffDialogHelper = {
    show: (connector: Connector, tariffGroupId: string) => useStore.getState().show(connector, tariffGroupId),
};
