import { Corporate } from '@electrifly/central-client-api';
import { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { API } from '../../../../core/api-client';
import { WebsocketClient } from '../../../../core/ws-client';
import { createWithImmer } from '../../../../misc/CreateWithImmer';

interface Service {
    corporate?: Corporate;
    loading: boolean;

    reset: () => void;
    loadInformation: () => Promise<void>;
}

function createStore(id: string) {
    return createWithImmer<Service>((set, get) => {
        WebsocketClient.events.CORPORATE.on(updatedCorporate => {
            const corporate = get().corporate;
            if (!corporate) {
                return;
            }

            if (corporate._id !== updatedCorporate._id) {
                return;
            }

            set(draft => {
                draft.corporate = updatedCorporate;
            });
        });

        return {
            corporate: undefined,
            loading: false,

            reset: () => set({ corporate: undefined, loading: false }),

            loadInformation: async () => {
                if (get().loading) {
                    return;
                }
                set({ loading: true });
                const [error, res] = await API.corporateDetails(id);
                set({ loading: false });

                if (error) {
                    console.error(error);
                    return;
                }

                set({ corporate: res.data });
            },
        };
    });
}

const { Provider, useStore } = createContext<StoreApi<Service>>();

export const CorporateDetailsPageService = {
    Provider,
    createStore,
    useStore,
};

export const useCorporateDetailsPageService = useStore;
