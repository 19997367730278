import * as yup from 'yup';

export type ReqParams = { id: string };
export type ReqBody = {
    tariffGroupId: string;
};

export type ResData = {};

export const validationSchema = yup.object({
    tariffGroupId: yup.string().required('Идентификатор тарифа должен быть заполнен')
});
