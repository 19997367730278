import { Outlet, useParams } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

import { ChargeLocationTopMenu } from './RoamingLocationTopMenu';
import { WithChargeLocation } from '../wrappers/WithChargeLocation';
import { Stack } from '@mui/material';
import { Title } from '../../elements/Title';
import { ChargeLocationHeader } from './RoamingLocationHeader';
import { WithRoamingLocation } from '../wrappers/WithRoamingLocation';

export default function RoamingLocationLayout() {
    const { id } = useParams<{ id: string }>();
    if (!id) {
        return null;
    }

    return (
        <WithRoamingLocation id={id}>
            <CssBaseline />
            <ChargeLocationHeader />
            <ChargeLocationTopMenu />
            <Outlet />
        </WithRoamingLocation>
    );
}
