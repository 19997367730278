import { Corporate } from '@electrifly/central-client-api';
import _ from 'lodash';
import { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { API } from '../../../core/api-client';
import { createWithImmer } from '../../../misc/CreateWithImmer';

export type Filter = {
    limit?: number;
    search?: string;
    account?: string;
};

type Data = {
    corporates: Corporate[];
    loading: boolean;
    canLoadMore: boolean;

    filter: Filter;
};

type Actions = {
    reset: () => void;
    loadNext: () => Promise<void>;
    setFilter: (data: Partial<Filter>) => void;
};

type Service = Data & Actions;

const DEFAULT_FILTER: Filter = {
    limit: 20,
};

function createDefaultData(): Data {
    return {
        corporates: [],
        loading: false,
        canLoadMore: false,

        filter: DEFAULT_FILTER,
    };
}

const createStore = (filter?: Partial<Filter>) => {
    const initialData = {
        ...createDefaultData(),
        filter: { ...createDefaultData().filter, ...filter },
    };

    return createWithImmer<Service>((set, get) => {
        function resetData() {
            set({ corporates: [] });
        }

        async function loadNext() {
            if (get().loading) {
                debouncedLoadNext();
                return;
            }

            set({ loading: true });

            const { filter, corporates } = get();

            const skip = corporates.length;
            const [error, res] = await API.corporateList({
                skip,
                limit: filter.limit,
            });

            if (error) {
                console.error(error);
                set({ loading: false });
                return;
            }

            const newData = res.data;
            const canLoadMore = newData.length === filter.limit;

            set({
                loading: false,
                canLoadMore: canLoadMore,
                corporates: [...get().corporates, ...newData],
            });
        }

        function loadNexWithReset() {
            resetData();
            loadNext();
        }

        const debouncedLoadNext = _.debounce(() => loadNext(), 300);
        const debouncedLoadNextWithReset = _.debounce(() => loadNexWithReset(), 300);

        return {
            ...initialData,

            reset: () => set({ ...initialData }),

            setFilter: (data: Partial<Filter>) => {
                set(draft => {
                    draft.filter = { ...draft.filter, ...data };
                });

                debouncedLoadNextWithReset();
            },

            loadNext: loadNext,
        };
    });
};

const { Provider, useStore } = createContext<StoreApi<Service>>();

export const CorporateListService = {
    Provider,
    createStore,
    useStore,
};
