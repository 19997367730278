import { Chip } from '@mui/material';
import { PropsWithChildren } from 'react';

interface DataWrapProps {
    color: string;
    icon?: React.ReactElement;
}
export function DataWrap({ children, color, icon }: PropsWithChildren<DataWrapProps>) {
    return (
        <Chip
            sx={{ borderRadius: 1, backgroundColor: color, paddingLeft: 1 }}
            icon={icon}
            label={children}
            component="div"
        />
    );
}
