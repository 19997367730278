import { Link, Skeleton } from '@mui/material';
import { useEffect } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { RouterLink } from '../../../elements/RouterLink';
import { useDataCache } from '../../../services/DataCache';
import { useParams } from 'react-router-dom';

interface Props {
    id: string;
}

export function UserLink({ id }: Props) {
    const { operatorId } = useParams();
    const loadUser = useDataCache(store => store.loadUser);
    const user = useDataCache(store => store.users[id]);

    useEffect(() => {
        loadUser(id);
    }, [id, loadUser]);

    if (!user) {
        return <Skeleton width={100} />;
    }

    return (
        <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={`/${operatorId}/users/${id}`}
            onClick={event => event.stopPropagation()}
        >
            {user?.name || id}
        </Link>
    );
}
