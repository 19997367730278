import * as yup from 'yup';
import { ChargeLocation } from '../../data/infrastructure/ChargeLocation';

export type ReqBody = {
    name: string;
    address: string;
    city?: string;
    region: string;
    latitude: number;
    longitude: number;
};

export type ResData = ChargeLocation;

export const validationSchema = yup.object({
    name: yup.string().required('Поле имя должно быть заполнено'),
    address: yup.string().required('Поле адрес должно быть заполнено'),
    city: yup.string().max(100).optional(),
    region: yup.string().max(200).optional(),
    latitude: yup.number().required('Поле широта должно быть заполнено').min(-90).max(90),
    longitude: yup.number().required('Поле долгота должно быть заполнено').min(-180).max(180),
});
