import { ReactNode } from 'react';
import { useRegistry } from '../../services/GlobalRegistry';
import invariant from 'tiny-invariant';
import create, { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { Connector } from '@electrifly/central-client-api/data';

interface ConnectorWrapperStore {
    id: string;
}

function createStore(connectorId: string) {
    return create<ConnectorWrapperStore>(set => ({
        id: connectorId,
    }));
}

const { Provider, useStore } = createContext<StoreApi<ConnectorWrapperStore>>();

type Props = {
    id: string;
    children: ReactNode | ((evse: Connector) => ReactNode);
};

export const WithConnector = ({ id, children }: Props) => {
    const connector = useRegistry(store => store.connectors[id]);

    if (!connector) {
        return null;
    }

    if (typeof children === 'function') {
        return <Provider createStore={() => createStore(id)}>{children(connector)}</Provider>;
    }

    return <Provider createStore={() => createStore(id)}>{children}</Provider>;
};

export function useConnector() {
    const id = useStore(store => store.id);
    invariant(!!id, 'Error getting connector. Possible you forgot to add WithConnector wrapper');
    const connector = useRegistry(store => store.connectors[id]);
    return connector;
}
