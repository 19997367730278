import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { colors, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';

export type Sign = 'positive' | 'negative';
const SIGN_TO_COLOR: Record<Sign, string> = {
    positive: colors.green[800],
    negative: colors.red[700],
};

interface BillingValueProps {
    value: number | string;
    sign: Sign;
    significant?: boolean;
}
export function BillingValue({ value, sign, significant = true }: BillingValueProps) {
    const signText = useMemo(() => (sign === 'positive' ? '+' : '-'), [sign]);
    const text = useMemo(() => new BigNumber(value).abs().toFixed(2), [value]);
    const color = useMemo(() => {
        return significant ? SIGN_TO_COLOR[sign] : colors.grey[500];
    }, [sign, significant]);

    return (
        <Typography variant="button" sx={{ fontWeight: 500 }} color={color}>
            {signText}
            {text} ₽
        </Typography>
    );
}
