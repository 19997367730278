import { useEffect } from 'react';
import { Stack, Typography, colors } from '@mui/material';

interface Props {}

export function SystemTitle() {
    // const customerTitle = useCustomerTitle(id);
    // const customer = useDataCache(store => store.customers[id]);

    return (
        <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
            {/* {displayEMPS && customer?.operator && (
                <DataWrap color={colors.grey[200]}>
                    <OperatorName id={customer.operator} />
                </DataWrap>
            )} */}
            <Typography fontStyle={'inherit'} fontSize={'inherit'}>
                System
            </Typography>
        </Stack>
    );
}
