import { TransactionsExport } from '@electrifly/central-client-api';
import { Dialog, DialogTitle, Step, StepLabel, Stepper, useMediaQuery, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { createWithImmer } from '../../../../misc/CreateWithImmer';
import { FirstStep } from './FirstStep';
import { SecondStep } from './SecondStep';
import { ThirdStep } from './ThirdStep';
import { Filter } from '../../components/list-table/ListPageService';

type Store = {
    open: boolean;
    filter?: TransactionsExport.Filter;
    format?: TransactionsExport.Format;
    activeStep: number;

    show: (dataFilter?: Filter) => void;
    hide: () => void;
    setFilter: (filter: TransactionsExport.Filter) => void;
    setFormat: (format: TransactionsExport.Format) => void;
    increaseStep: () => void;
};

export const useTransactionListExportDialog = createWithImmer<Store>((set, get) => {
    function reset() {
        set({ filter: undefined, format: undefined, activeStep: 0 });
    }

    return {
        open: false,
        filter: undefined,
        format: undefined,
        activeStep: 0,

        show: (dataFilter?: Filter) => {
            if (!dataFilter) {
                return set({ open: true });
            }

            console.log('useTransactionListExportDialog filter', dataFilter);
            set(draft => {
                draft.filter = {
                    operators: dataFilter.operator ? [dataFilter.operator] : [],
                    holders: dataFilter.holder ? [dataFilter.holder] : [],
                    chargePoints: dataFilter.chargePoint ? [dataFilter.chargePoint] : [],
                    locations: dataFilter.chargeLocation ? [dataFilter.chargeLocation] : [],
                    owners: dataFilter.owner ? [dataFilter.owner] : [],
                    ownerAccounts: dataFilter.ownerAccount ? [dataFilter.ownerAccount] : [],
                };
                draft.open = true;
            });
        },
        hide: () => {
            set({ open: false });
            reset();
        },

        setFilter: (filter: TransactionsExport.Filter) => set({ filter: filter }),
        setFormat: (format: TransactionsExport.Format) => set({ format: format }),

        increaseStep: () =>
            set(draft => {
                draft.activeStep += 1;
            }),
    };
});

function DialogInternal() {
    const activeStep = useTransactionListExportDialog(store => store.activeStep);
    const increaseStep = useTransactionListExportDialog(store => store.increaseStep);

    const onSuccessFirstStep = useCallback(() => increaseStep(), []);
    const onSuccessSecondStep = useCallback(() => increaseStep(), []);

    return (
        <>
            <Stepper sx={{ marginX: 2, marginY: 0 }} activeStep={activeStep}>
                <Step>
                    <StepLabel>Фильтр</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Форматирование</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Скачивание</StepLabel>
                </Step>
            </Stepper>

            {activeStep === 0 && <FirstStep onSuccess={onSuccessFirstStep} />}
            {activeStep === 1 && <SecondStep onSuccess={onSuccessSecondStep} />}
            {activeStep === 2 && <ThirdStep />}
        </>
    );
}

export function TransactionListExportDialog() {
    const open = useTransactionListExportDialog(store => store.open);
    const hide = useTransactionListExportDialog(store => store.hide);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog open={open} onClose={hide} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Экспорт зарядных транзакций</DialogTitle>
            <DialogInternal />
        </Dialog>
    );
}
