export const PaymentMethodType = [
    'bank_card',
    'apple_pay',
    'google_pay',
    'yoo_money',
    'qiwi',
    'webmoney',
    'sberbank',
    'alfabank',
    'tinkoff_bank',
    'b2b_sberbank',
    'mobile_balance',
    'cash',
    'installments',
    'sbp',
] as const;

export type PaymentMethodType = typeof PaymentMethodType[number];
