import { RFIDTagType, TokenType, TokenVariant } from '@electrifly/central-client-api';
import { Tooltip } from '@mui/material';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import SmartphoneRoundedIcon from '@mui/icons-material/SmartphoneRounded';
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';
import CableRoundedIcon from '@mui/icons-material/CableRounded';
import ContactlessRoundedIcon from '@mui/icons-material/ContactlessRounded';
import PinRoundedIcon from '@mui/icons-material/PinRounded';
import NoAccountsRoundedIcon from '@mui/icons-material/NoAccountsRounded';
import QuizRoundedIcon from '@mui/icons-material/QuizRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import HubRoundedIcon from '@mui/icons-material/HubRounded';
import { exhaustiveCheck } from '../../../misc/ExhaustiveCheck';

function OperatorIcon() {
    return (
        <Tooltip title="Оператор" enterDelay={500}>
            <PersonRoundedIcon fontSize="small" color="inherit" />
        </Tooltip>
    );
}

function RFIDCardIcon() {
    return (
        <Tooltip title="RFID-метка" enterDelay={500}>
            <ContactlessRoundedIcon fontSize="small" color="inherit" />
        </Tooltip>
    );
}

function MobileIcon() {
    return (
        <Tooltip title="Мобильное приложение" enterDelay={500}>
            <SmartphoneRoundedIcon fontSize="small" color="inherit" />
        </Tooltip>
    );
}

function FreevendIcon() {
    return (
        <Tooltip title="Freevend" enterDelay={500}>
            <CableRoundedIcon fontSize="small" color="inherit" />
        </Tooltip>
    );
}

interface Props {
    variant: TokenVariant;
}
export function TokenVariantIcon({ variant }: Props) {
    switch (variant) {
        case 'freevend':
        case 'customer':
            return null; //<PersonRoundedIcon fontSize="small" color="inherit" />;
        case 'administrator':
            return <SupportAgentRoundedIcon fontSize="small" color="inherit" />;
        case 'roaming':
            return <HubRoundedIcon fontSize="small" color="inherit" />;
        default:
            exhaustiveCheck(variant);
    }
}
