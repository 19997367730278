import { colors, createTheme, PaletteColorOptions } from '@mui/material';

declare module '@mui/material/styles' {
    export interface PaletteOptions {
        ['status.online']?: PaletteColorOptions;
        ['status.offline']?: PaletteColorOptions;
        ['status.available']?: PaletteColorOptions;
        ['status.preparing']?: PaletteColorOptions;
        ['status.charging']?: PaletteColorOptions;
        ['status.occupied']?: PaletteColorOptions;
        ['status.suspendedevse']?: PaletteColorOptions;
        ['status.suspendedev']?: PaletteColorOptions;
        ['status.finishing']?: PaletteColorOptions;
        ['status.faulted']?: PaletteColorOptions;
        ['status.unavailable']?: PaletteColorOptions;
        ['status.reserved']?: PaletteColorOptions;

        ['ocpi.status.available']?: PaletteColorOptions;
        ['ocpi.status.blocked']?: PaletteColorOptions;
        ['ocpi.status.charging']?: PaletteColorOptions;
        ['ocpi.status.inoperative']?: PaletteColorOptions;
        ['ocpi.status.outoforder']?: PaletteColorOptions;
        ['ocpi.status.planned']?: PaletteColorOptions;
        ['ocpi.status.removed']?: PaletteColorOptions;
        ['ocpi.status.reserved']?: PaletteColorOptions;
        ['ocpi.status.unknown']?: PaletteColorOptions;

        ['state.created']?: PaletteColorOptions;
        ['state.production']?: PaletteColorOptions;
        ['state.service']?: PaletteColorOptions;
        ['state.repair']?: PaletteColorOptions;
        ['state.suspended']?: PaletteColorOptions;
        ['state.retired']?: PaletteColorOptions;
    }
}

declare module '@mui/material/Chip' {
    interface ChipPropsColorOverrides {
        ['status.online']: true;
        ['status.offline']: true;
        ['status.available']: true;
        ['status.preparing']: true;
        ['status.charging']: true;
        ['status.occupied']: true;
        ['status.suspendedevse']: true;
        ['status.suspendedev']: true;
        ['status.finishing']: true;
        ['status.faulted']: true;
        ['status.unavailable']: true;
        ['status.reserved']: true;

        ['ocpi.status.available']: true;
        ['ocpi.status.blocked']: true;
        ['ocpi.status.charging']: true;
        ['ocpi.status.inoperative']: true;
        ['ocpi.status.outoforder']: true;
        ['ocpi.status.planned']: true;
        ['ocpi.status.removed']: true;
        ['ocpi.status.reserved']: true;
        ['ocpi.status.unknown']: true;

        ['state.created']: true;
        ['state.production']: true;
        ['state.service']: true;
        ['state.repair']: true;
        ['state.suspended']: true;
        ['state.retired']: true;
    }
}

declare module '@mui/material/Badge' {
    interface BadgePropsColorOverrides {
        ['status.online']: true;
        ['status.offline']: true;
        ['status.available']: true;
        ['status.preparing']: true;
        ['status.charging']: true;
        ['status.occupied']: true;
        ['status.suspendedevse']: true;
        ['status.suspendedev']: true;
        ['status.finishing']: true;
        ['status.faulted']: true;
        ['status.unavailable']: true;
        ['status.reserved']: true;

        ['ocpi.status.available']: true;
        ['ocpi.status.blocked']: true;
        ['ocpi.status.charging']: true;
        ['ocpi.status.inoperative']: true;
        ['ocpi.status.outoforder']: true;
        ['ocpi.status.planned']: true;
        ['ocpi.status.removed']: true;
        ['ocpi.status.reserved']: true;
        ['ocpi.status.unknown']: true;

        ['state.created']: true;
        ['state.production']: true;
        ['state.service']: true;
        ['state.repair']: true;
        ['state.suspended']: true;
        ['state.retired']: true;
    }
}

declare module '@mui/material/SvgIcon' {
    interface SvgIconPropsColorOverrides {
        ['status.online']: true;
        ['status.offline']: true;
        ['status.available']: true;
        ['status.preparing']: true;
        ['status.charging']: true;
        ['status.occupied']: true;
        ['status.suspendedevse']: true;
        ['status.suspendedev']: true;
        ['status.finishing']: true;
        ['status.faulted']: true;
        ['status.unavailable']: true;
        ['status.reserved']: true;

        ['ocpi.status.available']: true;
        ['ocpi.status.blocked']: true;
        ['ocpi.status.charging']: true;
        ['ocpi.status.inoperative']: true;
        ['ocpi.status.outoforder']: true;
        ['ocpi.status.planned']: true;
        ['ocpi.status.removed']: true;
        ['ocpi.status.reserved']: true;
        ['ocpi.status.unknown']: true;

        ['state.created']: true;
        ['state.production']: true;
        ['state.service']: true;
        ['state.repair']: true;
        ['state.suspended']: true;
        ['state.retired']: true;
    }
}

export const ElectriflyTheme = createTheme({
    palette: {
        'status.online': { main: colors.green[700], contrastText: '#ffffff' },
        'status.offline': { main: colors.grey[900], contrastText: '#ffffff' },
        'status.available': { main: colors.green[500], contrastText: '#ffffff' },
        'status.preparing': { main: colors.green[500], contrastText: '#ffffff' },
        'status.charging': { main: colors.blue[500], contrastText: '#ffffff' },
        'status.occupied': { main: colors.blue[500], contrastText: '#ffffff' },
        'status.suspendedevse': { main: colors.orange[500], contrastText: '#ffffff' },
        'status.suspendedev': { main: colors.orange[500], contrastText: '#ffffff' },
        'status.finishing': { main: colors.blue[500], contrastText: '#ffffff' },
        'status.faulted': { main: colors.red[500], contrastText: '#ffffff' },
        'status.unavailable': { main: colors.grey[500], contrastText: '#ffffff' },
        'status.reserved': { main: colors.purple[500], contrastText: '#ffffff' },

        'ocpi.status.available': { main: colors.green[500], contrastText: '#ffffff' },
        'ocpi.status.blocked': { main: colors.red[500], contrastText: '#ffffff' },
        'ocpi.status.charging': { main: colors.blue[500], contrastText: '#ffffff' },
        'ocpi.status.inoperative': { main: colors.red[500], contrastText: '#ffffff' },
        'ocpi.status.outoforder': { main: colors.orange[500], contrastText: '#ffffff' },
        'ocpi.status.planned': { main: colors.orange[500], contrastText: '#ffffff' },
        'ocpi.status.removed': { main: colors.red[500], contrastText: '#ffffff' },
        'ocpi.status.reserved': { main: colors.purple[500], contrastText: '#ffffff' },
        'ocpi.status.unknown': { main: colors.grey[500], contrastText: '#ffffff' },

        'state.created': { main: colors.grey[500], contrastText: '#ffffff' },
        'state.production': { main: colors.green[500], contrastText: '#ffffff' },
        'state.service': { main: colors.red[500], contrastText: '#ffffff' },
        'state.repair': { main: colors.red[500], contrastText: '#ffffff' },
        'state.suspended': { main: colors.orange[500], contrastText: '#ffffff' },
        'state.retired': { main: colors.grey[900], contrastText: '#ffffff' },

        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
});
