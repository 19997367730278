import * as yup from 'yup';
import { ChargePointUptime } from '../../data';

export type ReqBody = {
    rangeStart: string;
    rangeEnd: string;
    chargePoint: string;
};

export type ResData = ChargePointUptime[];

export const validationSchema = yup.object({
    rangeStart: yup.string().test(dateString => new Date(dateString).toString() !== 'Invalid Date'),
    rangeEnd: yup.string().test(dateString => new Date(dateString).toString() !== 'Invalid Date'),
    chargePoint: yup.string().optional(),
});
