import { DateTime } from 'luxon';
import { useMemo } from 'react';

interface TimestampProps {
    time: string;
}
export function Timestamp({ time }: TimestampProps) {
    const timeString = useMemo(() => {
        const dateTime = DateTime.fromISO(time);
        if (!dateTime.isValid) {
            return '';
        }
        return dateTime.toLocaleString(DateTime.DATETIME_SHORT, { locale: 'ru' });
    }, [time]);

    return <>{timeString}</>;
}
