import { Box } from '@mui/material';
import { TransactionListTableComponent } from '../../transaction/components/list-table/TransactionListTableComponent';
import { useCustomer } from '../../wrappers/WithCustomer';

export default function CustomerTransactionHistoryPage() {
    const customer = useCustomer();

    return (
        <Box sx={{ marginTop: 2 }}>
            <TransactionListTableComponent filter={{ owner: customer._id }} />
        </Box>
    );
}
