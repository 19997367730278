export const ColumnKeysArray = [
    'humnaId',
    'location',
    'chargePoint',
    'connector',
    'startTime',
    'endTime',
    'duration',
    'stopReason',
    'energy',
    'tokenType',
    'cost',
    'status',
] as const;

export type ColumnKey = typeof ColumnKeysArray[number];

export const COLUMN_NAMES: Record<ColumnKey, string> = {
    humnaId: '# Номер',
    location: 'Зарядная локация',
    chargePoint: 'Зарядная станция',
    connector: 'Коннектор',
    startTime: 'Начало',
    endTime: 'Завершение',
    duration: 'Длительность',
    stopReason: 'Причина завершения',
    energy: 'Энергия | кВт⋅ч',
    tokenType: 'Авторизация',
    cost: 'Стоимость | ₽',
    status: 'Статус',
};
