import { TransactionRefundBilling } from '@electrifly/central-client-api';
import { Divider, Typography } from '@mui/material';

interface EventRowProps {
    billing: TransactionRefundBilling;
}
export function TransactionRefundBillingRow({ billing }: EventRowProps) {
    return (
        <>
            <Divider orientation="vertical" flexItem />
            <Typography variant="caption" fontSize={'inherit'}>
                {billing.comment}
            </Typography>
        </>
    );
}
