import { Box, Tabs, Tab, Stack } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import { Title } from '../../../elements/Title';
import { useRouteMatch } from '../../../hooks/useRouteMatch';
import { RouterLink } from '../../../elements/RouterLink';
import { useEffectOnce } from 'usehooks-ts';
import { ReactNode } from 'react';
import { HolderDetailsPageService, useHolderDetailsPageService } from './services/PageService';
import { useHolder, WithHolder } from '../../wrappers/WithHolder';

function TopMenu() {
    const { operatorId } = useParams();
    const routeMatch = useRouteMatch([
        `/${operatorId}/holders/:id`,
        `/${operatorId}/holders/:id/locations`,
        `/${operatorId}/holders/:id/transactions`,
        `/${operatorId}/holders/:id/stats`,
        `/${operatorId}/holders/:id/contact`,
    ]);
    const currentTab = routeMatch?.pattern?.path;

    return (
        <Box sx={{ marginTop: 2, marginBottom: 1 }}>
            <Box sx={{ display: 'flex', width: '100%', borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={currentTab} sx={{ flex: 1 }} variant="scrollable" scrollButtons="auto">
                    <Tab label="Информация" value={`/${operatorId}/holders/:id`} component={RouterLink} to="" />
                    <Tab
                        label="Зарядные локации"
                        value={`/${operatorId}/holders/:id/locations`}
                        component={RouterLink}
                        to="locations"
                    />
                    <Tab
                        label="Транзакции"
                        value={`/${operatorId}/holders/:id/transactions`}
                        component={RouterLink}
                        to="transactions"
                    />
                    <Tab
                        label="Статистика"
                        value={`/${operatorId}/holders/:id/stats`}
                        component={RouterLink}
                        to="stats"
                    />
                </Tabs>

                {/* <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
                    <TextField
                        placeholder="Поиск"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        size="small"
                        disabled={true}
                        // value={searchText}
                        // onChange={event => setSearchText(event.target.value)}
                    />
                    <Button color="inherit" disabled>
                        Фильтр
                    </Button>
                    <Button color="inherit" disabled>
                        Опции
                    </Button>
                </Stack> */}
            </Box>
            <Stack direction={'row'} spacing={1} sx={{ padding: 1 }}>
                {/* {chargePointStatuses.map(status => (
                    <Chip key={status} label={`Станции: ${status}`} onDelete={() => removeChargePointFilter(status)} />
                ))}
                {connectorStatuses.map(status => (
                    <Chip key={status} label={`Коннекторы: ${status}`} onDelete={() => removeConnectorFilter(status)} />
                ))} */}
            </Stack>
        </Box>
    );
}

function LayoutInternal() {
    const holder = useHolder();

    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Владелец "{holder.identity}"</Title>
            </Stack>

            <TopMenu />
            <Outlet />
        </>
    );
}

interface WithDataLoaderProps {
    children: ReactNode;
}
function WithDataLoader({ children }: WithDataLoaderProps) {
    const reset = useHolderDetailsPageService(store => store.reset);
    const loadInformation = useHolderDetailsPageService(store => store.loadInformation);

    const holder = useHolderDetailsPageService(store => store.holder);

    useEffectOnce(() => {
        loadInformation();
        return () => reset();
    });

    if (!holder) {
        return null;
    }

    return <WithHolder holder={holder}>{children}</WithHolder>;
}

export type HolderDetailsLayoutParams = {
    id: string;
};

export function HolderDetailsLayout() {
    const { id } = useParams<HolderDetailsLayoutParams>();

    if (!id) {
        return null;
    }

    return (
        <HolderDetailsPageService.Provider createStore={() => HolderDetailsPageService.createStore(id)}>
            <WithDataLoader>
                <LayoutInternal />
            </WithDataLoader>
        </HolderDetailsPageService.Provider>
    );
}
