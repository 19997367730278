import * as yup from 'yup';
import { RFIDTag } from '../../data/RFIDTag';
import { User } from '../../data/User';

export type ReqQuery = {
    filter?: string;
    skip?: number;
    limit?: number;
    operator?: string;
    holder?: string;
};

export type ResData = RFIDTag[];

export const validationSchema = yup.object({
    filter: yup.string().optional().max(100),
    skip: yup.number().optional().min(0).default(0),
    limit: yup.number().optional().min(1).max(1000).default(100),
    operator: yup.string().optional(),
    holder: yup.string().optional(),
});
