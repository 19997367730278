import { Link } from '@mui/material';
import { RouterLink } from '../../../elements/RouterLink';
import { useParams } from 'react-router-dom';

interface Props {
    humanId: number;
    icon?: boolean;
}

export function TransactionLink({ humanId, icon = false }: Props) {
    const { operatorId } = useParams();

    return (
        <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={`/${operatorId}/transactions/${humanId}`}
            onClick={event => event.stopPropagation()}
        >
            #{humanId}
        </Link>
    );
}
