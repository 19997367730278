import { Box } from '@mui/material';
import {useChargeLocation} from "../../wrappers/WithChargeLocation";
import ReviewsListTableComponent from "./ReviewsListTableComponent";
import MorphPhotosListTableComponent from "../../morph-photos/components/MorphPhotosListTableComponent";
import {DefaultApprovalStatus} from "@electrifly/central-client-api/data/enums/ModelEnums";

export default function ChargeLocationReviewHistoryPage() {
    const chargeLocation = useChargeLocation();

    return (
        <Box sx={{ marginTop: 2 }}>
            <ReviewsListTableComponent filter={{ locationId: chargeLocation._id, status: DefaultApprovalStatus.APPROVED }} />
        </Box>
    );
}


export function ChargeLocationReviewListByStatus(props: {status: DefaultApprovalStatus}) {
    return (
        <Box sx={{ marginTop: 2 }}>
            <ReviewsListTableComponent filter={props} />
        </Box>
    );
}