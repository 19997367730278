import { Box, Tooltip } from '@mui/material';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import MobileOffRoundedIcon from '@mui/icons-material/MobileOffRounded';

export const ChargePointPrivate = () => {
    return (
        <Box sx={{ marginLeft: 1 }}>
            <Tooltip title="Приватная станция">
                <MobileOffRoundedIcon sx={{ display: 'flex', alignSelf: 'center' }} fontSize="small" />
            </Tooltip>
        </Box>
    );
};
