import { Connector } from '@electrifly/central-client-api';
import { Tooltip, colors, SvgIconProps, Chip } from '@mui/material';
import React from 'react';
import SettingsInputSvideoIcon from '@mui/icons-material/SettingsInputSvideo';
import OCPP from '@electrifly/ocpp';
import CircleIcon from '@mui/icons-material/Circle';

type OCPPChargePointStatus = OCPP.V15.ChargePointStatus | OCPP.V16.ChargePointStatus;

const STATUS_TO_COLOR: Record<OCPPChargePointStatus, string> = {
    Available: colors.green[500],
    Preparing: colors.green[500],
    Charging: colors.blue[500],
    Occupied: colors.blue[500],
    SuspendedEVSE: colors.orange[500],
    SuspendedEV: colors.orange[500],
    Finishing: colors.blue[500],
    Faulted: colors.red[500],
    Unavailable: colors.grey[500],
    Reserved: colors.purple[500],
};

type Variant = 'icon' | 'text' | 'dot';

type ConnectorStatusProps = SvgIconProps & {
    status: OCPPChargePointStatus;
    variant?: Variant;
};

function ConnectorStatusIcon({ status, ...props }: ConnectorStatusProps) {
    const color = React.useMemo(() => STATUS_TO_COLOR[status], [status]);
    return (
        <Tooltip title={status}>
            <SettingsInputSvideoIcon {...props} sx={{ color: color }} />
        </Tooltip>
    );
}

function ConnectorStatusText({ status }: ConnectorStatusProps) {
    return <Chip size="small" color={`status.${status.toLowerCase()}` as any} label={status} />;
}

function ConnectorStatusDot({ status }: ConnectorStatusProps) {
    const color = React.useMemo(() => STATUS_TO_COLOR[status], [status]);
    return <CircleIcon sx={{ fontSize: 'inherit', color: color }} />;
}

export const ConnectorStatus = ({ status, variant = 'icon', ...props }: ConnectorStatusProps) => {
    switch (variant) {
        case 'icon':
            return <ConnectorStatusIcon {...props} status={status} />;
        case 'text':
            return <ConnectorStatusText {...props} status={status} />;
        case 'dot':
        default:
            return <ConnectorStatusDot {...props} status={status} />;
    }
};
