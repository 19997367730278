import * as yup from 'yup';
import { OperatorNotifictionType, Operator } from '../../../data';

export type ReqParams = { id: string };
export type ReqBody = {
    type: OperatorNotifictionType;
    email: string;
};

export type ResData = Operator;

export const validationSchema = yup.object({
    type: yup.mixed<OperatorNotifictionType>().required().oneOf(Object.values(OperatorNotifictionType)),
    email: yup.string().required().email(),
});
