import { DateTime } from 'luxon';

type Range = [DateTime, DateTime];

export const DateTimeHelpers = {
    ranges: {
        today: (): Range => [DateTime.now().startOf('day'), DateTime.now().endOf('day')],
        yesterday: (): Range => [
            DateTime.now().minus({ day: 1 }).startOf('day'),
            DateTime.now().minus({ day: 1 }).endOf('day'),
        ],
        last7Days: (): Range => [DateTime.now().minus({ days: 7 }), DateTime.now()],
        last30Days: (): Range => [DateTime.now().minus({ days: 30 }), DateTime.now()],
        last90Days: (): Range => [DateTime.now().minus({ days: 90 }), DateTime.now()],
        last365Days: (): Range => [DateTime.now().minus({ days: 365 }), DateTime.now()],
        currentWeek: (): Range => [DateTime.now().startOf('week'), DateTime.now().endOf('week')],
        lastWeek: (): Range => [
            DateTime.now().minus({ week: 1 }).startOf('week'),
            DateTime.now().minus({ week: 1 }).endOf('week'),
        ],
        currentMonth: (): Range => [DateTime.now().startOf('month'), DateTime.now().endOf('month')],
        lastMonth: (): Range => [
            DateTime.now().minus({ month: 1 }).startOf('month'),
            DateTime.now().minus({ month: 1 }).endOf('month'),
        ],
        currentQuarter: (): Range => [DateTime.now().startOf('quarter'), DateTime.now().endOf('quarter')],
        lastQuarter: (): Range => [
            DateTime.now().minus({ quarter: 1 }).startOf('quarter'),
            DateTime.now().minus({ quarter: 1 }).endOf('quarter'),
        ],
        currentYear: (): Range => [DateTime.now().startOf('year'), DateTime.now().endOf('year')],
        lastYear: (): Range => [
            DateTime.now().minus({ year: 1 }).startOf('year'),
            DateTime.now().minus({ year: 1 }).endOf('year'),
        ],
    },
};
