import * as yup from 'yup';
import { User, UserState } from '../../data';

export type ReqParams = { id: string };
export type ReqBody = {
    state: UserState;
};

export type ResData = User;

export const validationSchema = yup.object({
    state: yup.mixed<UserState>().required().oneOf(Object.values(UserState)),
});
