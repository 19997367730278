import { useDebounce } from 'usehooks-ts';
import { CustomerListTableComponent } from '../components/customers-table/CustomerListTableComponent';
import { CustomerListFilterService } from '../components/customers-table/WithCustomerListFilter';

export function CustomerListAllPage() {
    const filter = CustomerListFilterService.useStore(store => store.filter);
    const debounced = useDebounce(filter, 300);

    return <CustomerListTableComponent filter={debounced} />;
}
