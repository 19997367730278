import { Title } from '../../../elements/Title';
import Button from '@mui/material/Button';
import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate } from 'react-router-dom';
import create from 'zustand';
import { API } from '../../../core/api-client';

import { Holder } from '@electrifly/central-client-api';

import { useEffectOnce } from 'usehooks-ts';
import LoadingButton from '@mui/lab/LoadingButton';
import { OperatorLink } from '../../operators/elements/OperatorLink';
import { WithHolder, useHolder } from '../../wrappers/WithHolder';
import { useFilteredLocationIds } from '../../charge-location/hooks/useFilteredLocationIds';
import { useMemo } from 'react';
import { useChargePointCount } from '../../charge-location/hooks/useChargePointCount';

type PageServiceData = {
    holders: Holder[];
    loading: boolean;
    filter: string;
    skip: number;
    limit: number;
    canLoadMore: boolean;
};

type PageServiceActions = {
    reset: () => void;
    loadNext: () => Promise<void>;
};

type PageService = PageServiceData & PageServiceActions;

const DEFAULT: PageServiceData = {
    holders: [],
    loading: false,
    filter: '',
    skip: 0,
    limit: 100,
    canLoadMore: true,
};

const usePageService = create<PageService>((set, get) => ({
    ...DEFAULT,

    reset: () => set({ ...DEFAULT }),

    loadNext: async () => {
        if (get().loading) {
            return;
        }
        set({ loading: true });

        const { filter, skip, limit } = get();
        const [error, res] = await API.holderList({ filter, skip, limit });
        if (error) {
            console.error(error);
            set({ loading: false });
            return;
        }

        const newData = res.data;
        const canLoadMore = newData.length === limit;
        const nextSkip = skip + limit;

        set({ loading: false, skip: nextSkip, canLoadMore: canLoadMore, holders: [...get().holders, ...newData] });
    },
}));

function HolderRow() {
    const navigate = useNavigate();
    const holder = useHolder();

    const locationIds = useFilteredLocationIds({ holder: holder._id });
    const locationsCount = useMemo(() => locationIds.length, [locationIds]);
    const chargePointsCount = useChargePointCount(locationIds);

    return (
        <TableRow
            key={holder._id}
            onClick={() => navigate(holder._id)}
            hover={true}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell>{holder.identity}</TableCell>
            <TableCell>{holder.state}</TableCell>
            <TableCell align="right">{locationsCount}</TableCell>
            <TableCell align="right">{chargePointsCount}</TableCell>
            <TableCell align="right">
                <OperatorLink id={holder.operatorId} />
            </TableCell>
        </TableRow>
    );
}

export default function HolderListPage() {
    const loading = usePageService(store => store.loading);
    const loadNext = usePageService(store => store.loadNext);
    const canLoadMore = usePageService(store => store.canLoadMore);
    const reset = usePageService(store => store.reset);
    const holders = usePageService(store => store.holders);

    useEffectOnce(() => {
        loadNext();
        return () => reset();
    });

    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Владельцы станций</Title>
                <Button variant="contained" startIcon={<AddIcon />} component={Link} to="create">
                    Добавить
                </Button>
            </Stack>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Имя</TableCell>
                            <TableCell>Статус</TableCell>
                            <TableCell align="right">Количество локаций</TableCell>
                            <TableCell align="right">Количество станций</TableCell>
                            <TableCell align="right">Оператор</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {holders.map(holder => (
                            <WithHolder key={holder._id} holder={holder}>
                                <HolderRow />
                            </WithHolder>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {canLoadMore && (
                <LoadingButton
                    loading={loading}
                    variant="text"
                    size="large"
                    fullWidth
                    sx={{ marginY: 2 }}
                    onClick={() => loadNext()}
                >
                    <span>Загрузить ещё</span>
                </LoadingButton>
            )}
        </>
    );
}
