import { RFIDTagType, Transaction } from '@electrifly/central-client-api';

export function useTotalCost(transaction: Transaction) {
    if (transaction.authorizationTokenType === RFIDTagType.OperatorTag) {
        return '0.00';
    }
    if (transaction.authorizationTokenType === RFIDTagType.OperatorCard) {
        return '0.00';
    }
    if (transaction.isAudiBonus === true) {
        return '0.00';
    }
    if (transaction.isEPROMBonus === true) {
        return '0.00';
    }
    if (transaction.isStartedByOperator === true) {
        return '0.00';
    }

    return transaction.totalCost;
}
