import { Box } from '@mui/material';

import { useRoamingLocation } from '../../wrappers/WithRoamingLocation';
import { TransactionListTableComponent } from '../../transaction/components/list-table/TransactionListTableComponent';

export default function RoamingLocationTransactionHistoryPage() {
    const chargeLocation = useRoamingLocation();

    return (
        <Box sx={{ marginTop: 2 }}>
            <TransactionListTableComponent filter={{ chargeLocation: chargeLocation._id }} />
        </Box>
    );
}
