import * as yup from 'yup';
import { RoamingXP } from '../../data';

export type ReqBody = {
    _id: string;
    token: string;
    versionInfoURL: string;
};
export type ResData = RoamingXP;

export const validationSchema = yup.object({
    _id: yup.string().required('Поле _id должно быть заполнено'),
    token: yup.string().required('Поле токен должно быть заполнено'),
    versionInfoURL: yup.string().required('Поле versionInfoURL должно быть заполнено'),
});
