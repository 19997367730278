import { ChargePoint, ChargePointConnectionStatus, ChargePointState } from '@electrifly/central-client-api';
import { Chip, Box } from '@mui/material';
import { ChargePointStateText } from '../elements/ChargePointStateText';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import HandymanRoundedIcon from '@mui/icons-material/HandymanRounded';
import StopCircleRoundedIcon from '@mui/icons-material/StopCircleRounded';
import DoDisturbOnRoundedIcon from '@mui/icons-material/DoDisturbOnRounded';

function CreatedStateChip() {
    return (
        <Chip
            size="small"
            icon={<AddRoundedIcon />}
            label={<ChargePointStateText state={ChargePointState.CREATED} />}
            color="state.created"
        />
    );
}

function ServiceStateChip() {
    return (
        <Chip
            size="small"
            icon={<HandymanRoundedIcon />}
            label={<ChargePointStateText state={ChargePointState.SERVICE} />}
            color="state.service"
        />
    );
}

function RepairStateChip() {
    return (
        <Chip
            size="small"
            icon={<HandymanRoundedIcon />}
            label={<ChargePointStateText state={ChargePointState.REPAIR} />}
            color="state.repair"
        />
    );
}

function SuspendedStateChip() {
    return (
        <Chip
            size="small"
            icon={<StopCircleRoundedIcon />}
            label={<ChargePointStateText state={ChargePointState.SUSPENDED} />}
            color="state.suspended"
        />
    );
}

function RetiredStateChip() {
    return (
        <Chip
            size="small"
            icon={<DoDisturbOnRoundedIcon />}
            label={<ChargePointStateText state={ChargePointState.RETIRED} />}
            color="state.retired"
        />
    );
}

interface InternalProps {
    state: ChargePointState;
}

const ChargePointStateShortInternal = ({ state }: InternalProps) => {
    switch (state) {
        case ChargePointState.CREATED:
            return <CreatedStateChip />;
        case ChargePointState.PRODUCTION:
            return null;
        case ChargePointState.SERVICE:
            return <ServiceStateChip />;
        case ChargePointState.REPAIR:
            return <RepairStateChip />;
        case ChargePointState.SUSPENDED:
            return <SuspendedStateChip />;
        case ChargePointState.RETIRED:
            return <RetiredStateChip />;
    }

    // switch (chargePoint.statusMessage.status) {
    //     case 'Faulted':
    //         return <Chip size="small" label="Faulted" color="status.faulted" />;
    //     case 'Unavailable':
    //         return <Chip size="small" label="Unavailable" color="status.unavailable" />;
    // }

    return null;
};

interface ChargePointStateShortProps {
    chargePoint: ChargePoint;
}
export const ChargePointStateShort = ({ chargePoint }: ChargePointStateShortProps) => {
    return (
        <Box sx={{ marginLeft: 1 }}>
            <ChargePointStateShortInternal state={chargePoint.state} />
        </Box>
    );
};
