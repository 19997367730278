import { useEffect } from 'react';
import { useDataCache } from '../../../services/DataCache';
import { Stack, Typography, colors } from '@mui/material';
import { DataWrap } from '../../event-log/components/DataWrap';
import { OperatorName } from '../../operators/elements/OperatorName';

interface Props {
    id: string;
    displayEMPS?: boolean;
}

export function CorporateTitle({ id, displayEMPS = false }: Props) {
    const corporateTitle = useCorporateTitle(id);
    const corporate = useDataCache(store => store.corporates[id]);

    return (
        <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
            {displayEMPS && corporate?.operator && (
                <DataWrap color={colors.grey[200]}>
                    <OperatorName id={corporate.operator} />
                </DataWrap>
            )}
            <Typography fontStyle={'inherit'} fontSize={'inherit'}>
                {corporateTitle}
            </Typography>
        </Stack>
    );
}

export function useCorporateTitle(id: string = '') {
    const loadCorporate = useDataCache(store => store.loadCorporate);
    const corporate = useDataCache(store => store.corporates[id]);

    useEffect(() => {
        if (!id) {
            return;
        }
        loadCorporate(id);
    }, [id, loadCorporate]);

    return corporate?.name || id;
}
