import { Box, Stack } from '@mui/material';
import { Title } from '../../../elements/Title';
import UptimeBlockComponent from './component/UptimeBlockComponent';

export function PageHeader2() {
    return (
        <Stack direction={'row'} spacing={1} sx={{ mb: 4, alignItems: 'center' }}>
            <Title sx={{ flex: 1 }}>Доступность</Title>
        </Stack>
    );
}

export default function UptimePage() {
    return (
        <Box sx={{ pb: 10 }}>
            <PageHeader2 />
            <UptimeBlockComponent />
        </Box>
    );
}
