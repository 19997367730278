import * as yup from 'yup';
import { AvailabilityType } from '@electrifly/ocpp/OCPPV16';

export type ReqParams = { id: string };
export type ReqBody = {
    connectorId: string;
    type: AvailabilityType;
};

export type ResData = {};

export const validationSchema = yup.object({
    connectorId: yup.string().required('Connector Id must be provided'),
    type: yup
        .mixed<AvailabilityType>()
        .oneOf(['Inoperative', 'Operative'], 'Тип должен быть заполнен')
        .required('Тип должен быть заполнен'),
});
