export const EmployeeRole = ['member', 'manager'] as const;
export type EmployeeRole = typeof EmployeeRole[number];

export type Employee = {
    customer: string;
    role: EmployeeRole;
};

export type Corporate = {
    _id: string;
    operator: string;
    account: string;
    name: string;
    employees: Employee[];
};

export type CorporateInfo = {
    _id: string;
    name: string;
    operator: string;
    account: string;
};
