import { Title } from '../../elements/Title';
import { FormHelperText, Grid, Paper, Stack, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import { ChargeLocationCreate } from '@electrifly/central-client-api';
import { API } from '../../core/api-client';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MapChargeLocationCreate } from './map/MapChargeLocationCreate';
import _ from 'lodash';

export default function ChargeLocationCreatePage() {
    const navigate = useNavigate();
    const { operatorId } = useParams();

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik({
        initialValues: {
            name: '',
            address: '',
            city: '',
            region: '',
            latitude: 55.801,
            longitude: 37.627,
        },
        validationSchema: ChargeLocationCreate.validationSchema,

        onSubmit: async values => {
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.chargeLocationCreate(values);
            setIsRequesting(false);
            if (!error) {
                console.log(res.data);
                navigate(`/${operatorId}/locations/${res.data._id}`);
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);

            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Новая зарядная локация</Title>
            </Stack>

            <Grid container>
                <Grid item lg={6} xs={12}>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack direction={'column'} spacing={2} component={Paper} sx={{ p: 2 }}>
                            <TextField
                                fullWidth
                                id="name"
                                label="Название"
                                required={true}
                                variant="standard"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                autoComplete={'off'}
                            />
                            <TextField
                                fullWidth
                                id="address"
                                label="Адрес"
                                required={true}
                                variant="standard"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                error={formik.touched.address && Boolean(formik.errors.address)}
                                helperText={formik.touched.address && formik.errors.address}
                                InputProps={{ autoComplete: 'none' }}
                                autoComplete={'off'}
                            />
                            <TextField
                                fullWidth
                                id="city"
                                label="Населённый пункт"
                                variant="standard"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                error={formik.touched.city && Boolean(formik.errors.city)}
                                helperText={formik.touched.city && formik.errors.city}
                                InputProps={{ autoComplete: 'none' }}
                                autoComplete={'off'}
                            />
                            <TextField
                                fullWidth
                                id="region"
                                label="Регион"
                                variant="standard"
                                value={formik.values.region}
                                onChange={formik.handleChange}
                                error={formik.touched.region && Boolean(formik.errors.region)}
                                helperText={formik.touched.region && formik.errors.region}
                                InputProps={{ autoComplete: 'none' }}
                                autoComplete={'off'}
                            />
                            <Stack direction={'row'} spacing={2}>
                                <TextField
                                    fullWidth
                                    id="latitude"
                                    label="Широта"
                                    required={true}
                                    variant="standard"
                                    type={'number'}
                                    value={formik.values.latitude}
                                    onChange={formik.handleChange}
                                    error={formik.touched.latitude && Boolean(formik.errors.latitude)}
                                    helperText={formik.touched.latitude && formik.errors.latitude}
                                />
                                <TextField
                                    fullWidth
                                    id="longitude"
                                    label="Долгота"
                                    required={true}
                                    variant="standard"
                                    type={'number'}
                                    value={formik.values.longitude}
                                    onChange={formik.handleChange}
                                    error={formik.touched.longitude && Boolean(formik.errors.longitude)}
                                    helperText={formik.touched.longitude && formik.errors.longitude}
                                />
                            </Stack>

                            <MapChargeLocationCreate
                                latitude={formik.values.latitude}
                                longitude={formik.values.longitude}
                                onChange={position => {
                                    formik.setValues(values => ({
                                        ...values,
                                        latitude: _.round(position.lat, 7),
                                        longitude: _.round(position.lng, 7),
                                    }));
                                }}
                            />

                            <FormHelperText
                                disabled={!displayError}
                                error={displayError}
                                sx={{ textAlign: 'center', color: '#d32f2f' }}
                            >
                                {error}
                            </FormHelperText>

                            <LoadingButton
                                color="primary"
                                variant="contained"
                                fullWidth
                                type="submit"
                                size="large"
                                loadingPosition="center"
                                loading={isRequesting}
                            >
                                <span>Добавить</span>
                            </LoadingButton>
                        </Stack>
                    </form>
                </Grid>
            </Grid>
        </>
    );
}
