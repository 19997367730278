import _ from 'lodash';
import { useMemo } from 'react';
import { useRegistry } from '../../../services/GlobalRegistry';

export function useChargePointCount(locationIds: string[]) {
    const locations = useRegistry(store => store.chargeLocations);
    const result = useMemo(() => {
        return _.chain(locationIds)
            .map(id => {
                const location = locations[id];
                return location && location.chargePoints.length;
            })
            .filter(item => !!item)
            .sum()
            .value();
    }, [locationIds, locations]);

    return result;
}
