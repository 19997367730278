import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useRegistry } from '../../services/GlobalRegistry';
import invariant from 'tiny-invariant';
import create, { StoreApi } from 'zustand';
// import createContext from 'zustand/context';

// interface ChargePointWrapperStore {
//     id: string;
// }

// function createStore(chargePointId: string) {
//     return create<ChargePointWrapperStore>(set => ({
//         id: chargePointId,
//     }));
// }

// const { Provider, useStore } = createContext<StoreApi<ChargePointWrapperStore>>();

const ChargePointContext = createContext<string | null>(null);

type Props = {
    id: string;
    children: ReactNode;
};

export const WithChargePoint = ({ id, children }: Props) => {
    const [chargePointId, setChargePointId] = useState(id);
    const chargePoint = useRegistry(store => store.chargePoints[chargePointId]);

    useEffect(() => setChargePointId(id), [id]);

    if (!chargePoint) {
        return null;
    }

    return <ChargePointContext.Provider value={chargePointId}>{children}</ChargePointContext.Provider>;
};

export function useChargePoint() {
    const id = useContext(ChargePointContext);
    invariant(!!id, 'Error getting chargePoint. Possible you forgot to add WithChargePoint wrapper');
    const chargePoint = useRegistry(store => store.chargePoints[id]);
    return chargePoint;
}
