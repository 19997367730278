import * as yup from 'yup';
import { Holder, HolderNotifictionType } from '../../../data';

export type ReqParams = { id: string };
export type ReqBody = {
    type: HolderNotifictionType;
    email: string;
};

export type ResData = Holder;

export const validationSchema = yup.object({
    type: yup.mixed<HolderNotifictionType>().required().oneOf(Object.values(HolderNotifictionType)),
    email: yup.string().required().email(),
});
