import { Stack, IconButton, Select, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { EditableValueOption } from './EditableValueOption';

interface SelectTypeFormProps<T extends string | number> {
    initialValue: T;
    options: EditableValueOption<T>[];
    onCancel: () => void;
    onSave: (value: T) => void;
    onInputChange?: (event: React.SyntheticEvent, value: string) => void;
}

export function SelectTypeForm<T extends string | number>({
    initialValue,
    options,
    onCancel,
    onSave,
}: SelectTypeFormProps<T>) {
    const formik = useFormik({
        initialValues: {
            value: initialValue,
        },

        onSubmit: async values => {
            onSave(values.value);
            onCancel();
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack direction={'row'} sx={{ p: 1 }} alignItems="center" spacing={1}>
                <Select
                    name={'value'}
                    size="small"
                    value={formik.values.value}
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.value && formik.errors.value)}
                    sx={{ minWidth: '15em' }}
                >
                    {options.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>

                <IconButton size="small" color="primary" type="submit">
                    <DoneRoundedIcon />
                </IconButton>
                <IconButton size="small" onClick={onCancel}>
                    <CloseRoundedIcon />
                </IconButton>
            </Stack>
        </form>
    );
}
