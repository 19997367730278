import * as yup from 'yup';
import { Integration, IntegrationType } from '../../data';

export type ReqBody = {
    name: string;
    type: IntegrationType;
    operator: string;
};
export type ResData = Integration;

export const validationSchema = yup.object({
    name: yup.string().required('Поле имя должно быть заполнено'),
    type: yup.mixed<IntegrationType>().required().oneOf(Object.values(IntegrationType)),
    operator: yup.string().required('Поле оператор должно быть заполнено'),
});
