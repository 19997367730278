import {
    Holder,
    HolderNotificationAddEmail,
    HolderNotificationChangeState,
    HolderNotificationRemoveEmail,
    HolderUpdate,
} from '@electrifly/central-client-api';
import create, { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { API } from '../../../../core/api-client';
import { WebsocketClient } from '../../../../core/ws-client';
import { createWithImmer } from '../../../../misc/CreateWithImmer';
import to from 'await-to-js';

interface Service {
    holder?: Holder;
    loading: boolean;

    reset: () => void;
    loadInformation: () => Promise<void>;
    update: (data: HolderUpdate.ReqBody) => Promise<void>;
    updateNotificationState: (data: HolderNotificationChangeState.ReqBody) => Promise<void>;
    addEmailToNotification: (data: HolderNotificationAddEmail.ReqBody) => Promise<void>;
    removeEmailFromNotification: (data: HolderNotificationRemoveEmail.ReqBody) => Promise<void>;
}

function createStore(id: string) {
    return createWithImmer<Service>((set, get) => {
        WebsocketClient.events.HOLDER.on(updatedHolder => {
            const holder = get().holder;
            if (!holder) {
                return;
            }

            if (holder._id !== updatedHolder._id) {
                return;
            }

            set(draft => {
                draft.holder = updatedHolder;
            });
        });

        return {
            holder: undefined,
            loading: false,

            reset: () => set({ holder: undefined, loading: false }),

            loadInformation: async () => {
                if (get().loading) {
                    return;
                }
                set({ loading: true });
                const [error, res] = await API.holderDetails(id);
                set({ loading: false });

                if (error) {
                    console.error(error);
                    return;
                }

                set({ holder: res.data });
            },

            update: async (data: HolderUpdate.ReqBody) => {
                const holder = get().holder;
                if (!holder) {
                    return;
                }

                set(draft => {
                    if (!draft.holder) {
                        return;
                    }

                    draft.holder.identity = data.identity || draft.holder.identity;
                });

                const [error, res] = await API.holderUpdate(holder._id, data);

                if (!error) {
                    console.log(res.data);
                    return;
                }

                console.log(error.response?.data);
            },

            updateNotificationState: async (data: HolderNotificationChangeState.ReqBody) => {
                const holder = get().holder;
                if (!holder) {
                    return;
                }

                set(draft => {
                    if (!draft.holder) {
                        return;
                    }

                    const notification = draft.holder.notifications.find(item => item.type === data.type);
                    if (!notification) {
                        draft.holder.notifications.push({ type: data.type, active: data.value, emails: [] });
                    } else {
                        notification.active = data.value;
                    }
                });

                const [error, res] = await API.holderNotificationChangeState(holder._id, data);

                if (!error) {
                    console.log(res.data);
                    return;
                }

                console.log(error.response?.data);
            },

            addEmailToNotification: async (data: HolderNotificationAddEmail.ReqBody) => {
                const holder = get().holder;
                if (!holder) {
                    return;
                }

                const [validateError, valid] = await to(
                    HolderNotificationAddEmail.validationSchema.validate(data, { stripUnknown: true }),
                );
                if (validateError) {
                    return;
                }

                set(draft => {
                    if (!draft.holder) {
                        return;
                    }

                    const notification = draft.holder.notifications.find(item => item.type === data.type);
                    if (!notification) {
                        return;
                    }
                    notification.emails.push(data.email);
                });

                const [error, res] = await API.holderNotificationAddEmail(holder._id, data);

                if (!error) {
                    console.log(res.data);
                    return;
                }

                console.log(error.response?.data);
            },

            removeEmailFromNotification: async (data: HolderNotificationRemoveEmail.ReqBody) => {
                const holder = get().holder;
                if (!holder) {
                    return;
                }

                const [validateError, valid] = await to(
                    HolderNotificationRemoveEmail.validationSchema.validate(data, { stripUnknown: true }),
                );
                if (validateError) {
                    return;
                }

                set(draft => {
                    if (!draft.holder) {
                        return;
                    }

                    const notification = draft.holder.notifications.find(item => item.type === data.type);
                    if (!notification) {
                        return;
                    }

                    notification.emails = notification.emails.filter(item => item !== data.email);
                });

                const [error, res] = await API.holderNotificationRemoveEmail(holder._id, data);

                if (!error) {
                    console.log(res.data);
                    return;
                }

                console.log(error.response?.data);
            },
        };
    });
}

const { Provider, useStore } = createContext<StoreApi<Service>>();

export const HolderDetailsPageService = {
    Provider,
    createStore,
    useStore,
};

export const useHolderDetailsPageService = useStore;
