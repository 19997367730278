import moment from "moment";
import { useMemo } from "react";

interface HumanDateProps {
    time?: string; // ISO time
    displaySeconds?: boolean;
}

export function HumanDate({ time, displaySeconds = true }: HumanDateProps) {
    const formatted = useMemo(
        () => moment(time).format(`DD.MM.YYYY HH:mm${displaySeconds ? ':ss' : ''}`),
        [displaySeconds, time],
    );
    return <>{formatted}</>;
}