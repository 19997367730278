import { LoadingButton } from '@mui/lab';
import {
    Autocomplete,
    Button,
    CircularProgress,
    DialogContentText,
    FormControl,
    FormHelperText,
    Stack,
    Typography,
    colors,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { useMemo, useRef, useState } from 'react';
import create from 'zustand';
import { API } from '../../../core/api-client';
import { Corporate, CorporateEmployeeAdd } from '@electrifly/central-client-api';
import React from 'react';
import {
    CustomerSuggestionContext,
    createCustomerSuggestionStore,
} from '../../customers/services/CustomerSuggestionContext';
import { DataWrap } from '../../event-log/components/DataWrap';
import { OperatorName } from '../../operators/elements/OperatorName';

type FormValues = {
    customer?: string;
};

type Store = {
    open: boolean;
    corporate?: Corporate;
    show: (corporate: Corporate) => void;
    hide: () => void;
};

const useCorporateEmployeeAddDialog = create<Store>((set, get) => {
    return {
        open: false,
        corporate: undefined,
        show: (corporate: Corporate) => {
            set({ open: true, corporate: corporate });
        },
        hide: () => set({ open: false }),
    };
});

function CustomerSelector() {
    const formik = useFormikContext<FormValues>();
    const inputRef = useRef<HTMLInputElement>();

    const loading = CustomerSuggestionContext.useStore(store => store.loading);
    const setSearch = CustomerSuggestionContext.useStore(store => store.setSearch);
    const customersInfo = CustomerSuggestionContext.useStore(store => store.customers);

    const selectedValue = useMemo(
        () => customersInfo.find(item => item._id === formik.values.customer),
        [customersInfo, formik.values.customer],
    );

    return (
        <Stack spacing={1}>
            <FormControl fullWidth>
                <Autocomplete
                    sx={{ minWidth: '15em' }}
                    fullWidth
                    options={customersInfo}
                    noOptionsText="Клиент не найден"
                    value={selectedValue || null} //null is neede for initialization render in controlled state
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    getOptionLabel={option => option.phone}
                    renderOption={(props, option, state) => (
                        <li {...props} key={option._id}>
                            <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
                                {option.operator && (
                                    <DataWrap color={colors.grey[200]}>
                                        <OperatorName id={option.operator} />
                                    </DataWrap>
                                )}
                                <Typography>{option.phone}</Typography>
                            </Stack>
                        </li>
                    )}
                    onInputChange={(event, newValue) => setSearch(newValue)}
                    onChange={(event, val) => formik.setValues(values => ({ ...values, customer: val?._id }))}
                    renderInput={params => (
                        <TextField
                            {...params}
                            inputRef={inputRef}
                            autoFocus
                            variant="outlined"
                            placeholder="+79990000000"
                            error={formik.touched.customer && Boolean(formik.errors.customer)}
                            helperText={formik.touched.customer && formik.errors.customer}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        {selectedValue?.operator && (
                                            <DataWrap color={colors.grey[200]}>
                                                <OperatorName id={selectedValue.operator} />
                                            </DataWrap>
                                        )}
                                    </>
                                ),
                                endAdornment: (
                                    <React.Fragment>
                                        {loading && <CircularProgress color="inherit" size={20} />}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </FormControl>
        </Stack>
    );
}

function DialogInternal() {
    const hide = useCorporateEmployeeAddDialog(store => store.hide);
    const corporate = useCorporateEmployeeAddDialog(store => store.corporate);

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<FormValues>({
        initialValues: {
            customer: undefined,
        },

        // validationSchema: CorporateEmployeeAdd.validationSchema,

        onSubmit: async values => {
            if (!corporate) {
                return;
            }

            if (!values.customer) {
                return;
            }

            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.corporateEmployeeAdd(corporate._id, {
                customer: values.customer,
                role: 'member',
            });
            setIsRequesting(false);
            if (!error) {
                formik.resetForm();
                CorporateEmployeeAddDialogHelper.hide();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    if (!corporate) {
        return null;
    }

    return (
        <>
            <DialogTitle>Добавить сотрудника</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ paddingBottom: 2 }}></DialogContentText>

                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack spacing={2}>
                            <CustomerSuggestionContext.Provider
                                createStore={() => createCustomerSuggestionStore({ operator: corporate.operator })}
                            >
                                <CustomerSelector />
                            </CustomerSuggestionContext.Provider>
                        </Stack>
                    </form>
                </FormikProvider>

                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Добавить</span>
                </LoadingButton>
            </DialogActions>
        </>
    );
}

export function CorporateEmployeeAddDialog() {
    const open = useCorporateEmployeeAddDialog(store => store.open);
    const hide = useCorporateEmployeeAddDialog(store => store.hide);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogInternal />
        </Dialog>
    );
}

export const CorporateEmployeeAddDialogHelper = {
    show: (corporate: Corporate) => useCorporateEmployeeAddDialog.getState().show(corporate),
    hide: () => useCorporateEmployeeAddDialog.getState().hide(),
};
