import * as yup from 'yup';
import { AccessType } from '../../data';
import { User } from '../../data/User';

export type ReqParams = { id: string };
export type ReqBody = {
    type: AccessType;
    locations?: string[];
};

export type ResData = User;

export const validationSchema = yup.object({
    type: yup.mixed<AccessType>().required().oneOf(Object.values(AccessType)),
    locations: yup.array().of(yup.string()).optional(),
});
