import { Stack, Tooltip, Box, Button, InputAdornment, Tab, Tabs, TextField, Chip, Paper } from '@mui/material';

import { useDashboardService } from './DashboardPage';
import SearchIcon from '@mui/icons-material/Search';
import { ChargePointStateText } from '../charge-point/elements/ChargePointStateText';

export function DashboardHeader() {
    const searchText = useDashboardService(store => store.filter.searchText);
    const setSearchText = useDashboardService(store => store.setSearchText);
    const connectorStatuses = useDashboardService(store => store.filter.connectorStatuses);
    const chargePointStatuses = useDashboardService(store => store.filter.chargePointStatuses);
    const chargePointStates = useDashboardService(store => store.filter.chargePointStates);
    const removeConnectorFilter = useDashboardService(store => store.removeConnectorFilter);
    const removeChargePointStatusFilter = useDashboardService(store => store.removeChargePointStatusFilter);
    const removeChargePointStateFilter = useDashboardService(store => store.removeChargePointStateFilter);

    return (
        <Box sx={{ marginTop: 2, marginBottom: 1 }} component={Paper}>
            <Box sx={{ display: 'flex', width: '100%', borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={0} sx={{ flex: 1 }}>
                    <Tab label="Все" />
                </Tabs>
                <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
                    <TextField
                        placeholder="Поиск"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        size="small"
                        value={searchText}
                        onChange={event => setSearchText(event.target.value)}
                    />
                    <Button color="inherit" disabled>
                        Фильтр
                    </Button>
                    <Button color="inherit" disabled>
                        Опции
                    </Button>
                </Stack>
            </Box>
            <Stack direction={'row'} spacing={1} sx={{ padding: 1 }}>
                {chargePointStates.map(state => (
                    <Chip
                        key={state}
                        label={
                            <>
                                Состояние: <ChargePointStateText state={state} />
                            </>
                        }
                        onDelete={() => removeChargePointStateFilter(state)}
                    />
                ))}
                {chargePointStatuses.map(status => (
                    <Chip
                        key={status}
                        label={`Станции: ${status}`}
                        onDelete={() => removeChargePointStatusFilter(status)}
                    />
                ))}
                {connectorStatuses.map(status => (
                    <Chip key={status} label={`Коннекторы: ${status}`} onDelete={() => removeConnectorFilter(status)} />
                ))}
            </Stack>
        </Box>
    );
}
