import { OperatorInfo } from '@electrifly/central-client-api/data';
import { Autocomplete, TextField } from '@mui/material';
import _ from 'lodash';
import { useContext } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import create, { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { API } from '../../../core/api-client';
import { useFormikContext } from 'formik';
import { FormValues } from './RoamingXPCreateDialog';

interface InternalService {
    operatorInfos: OperatorInfo[];
    operatorsLoading: boolean;
    loadOperatorsInfo: () => Promise<void>;
}

const createStore = () => {
    return create<InternalService>((set, get) => ({
        operatorInfos: [],
        operatorsLoading: false,
        loadOperatorsInfo: async () => {
            if (get().operatorsLoading) {
                return;
            }
            set({ operatorsLoading: true });
            const [error, res] = await API.operatorListInfo({});
            set({ operatorsLoading: false });

            if (error) {
                console.error(error);
                return;
            }

            set({ operatorInfos: [...res.data] });
        },
    }));
};

const { Provider, useStore } = createContext<StoreApi<InternalService>>();

export const InternalService = {
    Provider,
    createStore,
    useStore,
};

function OperatorFieldInternal() {
    const loadOperatorsInfo = InternalService.useStore(store => store.loadOperatorsInfo);
    const operatorInfos = InternalService.useStore(store => store.operatorInfos);

    const formik = useFormikContext<FormValues>();

    useEffectOnce(() => {
        loadOperatorsInfo();
    });

    return (
        <Autocomplete
            id="operator"
            fullWidth
            options={operatorInfos}
            getOptionLabel={option => option.name}
            renderOption={(props, option, state) => (
                <li {...props} key={option._id}>
                    {option.name}
                </li>
            )}
            // value={currentLocation || null} //null is needed for initialization render in controlled state
            onChange={(event, val) => {
                console.log(val);
                formik.setFieldValue('operator', val?._id);
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Оператор"
                    placeholder="Выберите оператора"
                    error={formik.touched.operator && Boolean(formik.errors.operator)}
                    helperText={formik.touched.operator && formik.errors.operator}
                />
            )}
        />
    );
}

const WithInternalService: React.FC = ({ children }) => {
    return (
        <InternalService.Provider createStore={() => InternalService.createStore()}>
            {children}
        </InternalService.Provider>
    );
};

export function OperatorField() {
    return (
        <WithInternalService>
            <OperatorFieldInternal />
        </WithInternalService>
    );
}
