import React from 'react';
import { createContext, ReactNode, useContext } from 'react';
import invariant from 'tiny-invariant';
import { Customer } from '@electrifly/central-client-api';

const CustomerContext = createContext<Customer | null>(null);

type Props = {
    customer: Customer;
    children: ReactNode;
};

export const WithCustomer = ({ customer, children }: Props) => {
    const [currentTransaction, setTransaction] = React.useState(customer);
    React.useEffect(() => setTransaction(customer), [customer]);
    return <CustomerContext.Provider value={currentTransaction}>{children}</CustomerContext.Provider>;
};

export function useCustomer() {
    const customer = useContext(CustomerContext);
    invariant(!!customer, 'Error getting customer. Possible you forgot to add WithCustomer wrapper');
    return customer;
}
