import React from 'react';
import { createContext, ReactNode, useContext } from 'react';
import invariant from 'tiny-invariant';
import { Operator } from '@electrifly/central-client-api';

const OperatorContext = createContext<Operator | null>(null);

type Props = {
    operator: Operator;
    children: ReactNode;
};

export const WithOperator = ({ operator, children }: Props) => {
    const [currentOperator, setOperator] = React.useState(operator);
    React.useEffect(() => setOperator(operator), [operator]);
    return <OperatorContext.Provider value={currentOperator}>{children}</OperatorContext.Provider>;
};

export function useOperator() {
    const operator = useContext(OperatorContext);
    invariant(!!operator, 'Error getting operator. Possible you forgot to add WithOperator wrapper');
    return operator;
}
